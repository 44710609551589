import { useCallback, useEffect, useRef } from 'react'
import {
  EMPTY,
  filter,
  from,
  map,
  mergeMap,
  of,
  Subscription,
  switchMap,
  tap,
  toArray,
} from 'rxjs'

import {
  FeatureFlagNames,
  GorillaResumeParsingStatus,
  ICartCandidate,
  IEntityType,
  IJobSubmission,
  useIsFeatureFlagEnabled,
  useServices,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'

import { useMultipleOnePagerPolling } from '@submission-portal/hooks/candidate-one-pager-parser-hook'
import { ISubmissionSummary } from '@submission-portal/models'
import {
  candidateOnePagerService,
  cartService,
  contractorProfileService,
  submissionService,
} from '@submission-portal/services'
import {
  ISubmissionStoreState,
  submissionStore,
} from '@submission-portal/stores'

export const useRemoveCandidateFromList = (): ((
  jobSubmissionId: string
) => void) => {
  const subscriptionRef = useSubscriptionRef()

  const { cart } = useSubjectSelector(submissionStore, ['cart'])
  const deleteCart = useCallback(() => {
    if (cart?.id) {
      return cartService.deleteCart(cart.id)
    }
    return EMPTY
  }, [cart?.id])

  const removeCandidate = useCallback(
    (jobSubmissionId: string) => {
      if (cart?.id) {
        return cartService.removeCandidatesFromCart(cart.id, [jobSubmissionId])
      }
      return EMPTY
    },
    [cart?.id]
  )
  return useCallback(
    (jobSubmissionId) => {
      if (cart && cart?.entities.length > 1) {
        subscriptionRef.current = removeCandidate(jobSubmissionId).subscribe({
          next: () => {
            submissionStore.dispatch({
              cart: {
                ...cart,
                entities: [
                  ...cart.entities.filter((c) => c.id !== jobSubmissionId),
                ],
              },
              isCandidateRemoveModalOpen: false,
            })
          },
        })
      } else {
        subscriptionRef.current = deleteCart().subscribe({
          next: () => {
            submissionStore.dispatch({
              cart: null,
              isCandidateRemoveModalOpen: false,
            })
          },
        })
      }
    },
    [cart, deleteCart, removeCandidate, subscriptionRef]
  )
}

export const useAddCandidateToList = (): ((
  submission: ISubmissionSummary
) => void) => {
  const { clientJobService } = useServices()
  const { activeJob } = useSubjectSelector(clientJobService.clientJobStore, [
    'activeJob',
  ])
  const { cart, submissions } = useSubjectSelector(submissionStore, [
    'cart',
    'submissions',
  ])
  const subscriptionRef = useSubscriptionRef()
  const noSubmissionSubscriptionRef = useRef<Subscription | null>(null)

  const createCart = useCallback(
    (jobSubmissionId: string) => {
      if (activeJob) {
        return cartService.createCart({
          parentEntityId: activeJob.id,
          parentEntityType: IEntityType.Job,
          entityType: IEntityType.JobSubmission,
          entityIds: [jobSubmissionId],
          setEntitiesStatus: true,
        })
      }
      return EMPTY
    },
    [activeJob]
  )

  const addCandidateToCart = useCallback(
    (jobSubmissionId: string) => {
      if (cart?.id) {
        return cartService.addCandidatesToCart(cart.id, [jobSubmissionId], true)
      }
      return EMPTY
    },
    [cart?.id]
  )

  const handleUpdateCart = useCallback(
    (submission: ISubmissionSummary) => {
      if (!submission.jobSubmissionId) {
        noSubmissionSubscriptionRef.current = submissionService
          .createSubmissionFromAts(submission.atsJobSubmissionId)
          .pipe(
            switchMap(({ id, status }) => {
              const index = submissions.findIndex(
                (submissionSummary) =>
                  submissionSummary.atsJobSubmissionId ===
                  submission.atsJobSubmissionId
              )
              if (index >= 0) {
                const submissionsCopy = [...submissions]
                submissionsCopy[index].jobSubmissionId = id
                submissionsCopy[index].status = status
                submissionStore.dispatch({
                  submissions: [...submissionsCopy],
                })
              }

              if (cart?.id) {
                return addCandidateToCart(id)
              }
              return createCart(id)
            })
          )
          .subscribe({
            next: (c) =>
              submissionStore.dispatch({
                cart: c,
              }),
          })
      } else if (cart?.id) {
        subscriptionRef.current = addCandidateToCart(
          submission.jobSubmissionId
        ).subscribe({
          next: (c) =>
            submissionStore.dispatch({
              cart: c,
            }),
        })
      } else {
        subscriptionRef.current = createCart(
          submission.jobSubmissionId
        ).subscribe({
          next: (c) =>
            submissionStore.dispatch({
              cart: c,
            }),
        })
      }
      return () => {
        if (noSubmissionSubscriptionRef.current) {
          noSubmissionSubscriptionRef.current.unsubscribe()
        }
      }
    },
    [addCandidateToCart, createCart, cart?.id, subscriptionRef, submissions]
  )

  return handleUpdateCart
}

export const useParseMultipleOnePagers = (
  selectedCandidates: ICartCandidate[]
): void => {
  const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
    FeatureFlagNames.ResumeOnePager
  )
  const updateResumeSubscriptionRef = useSubscriptionRef()
  const fetchOnePagersSubscriptionRef = useSubscriptionRef()

  const { fetchedSubmissions } = useSubjectSelector(submissionStore, [
    'fetchedSubmissions',
    'pendingOnePagers',
  ])
  const addOnePagerIdToResume = useCallback(
    (id: string) => {
      updateResumeSubscriptionRef.current = submissionService
        .addOnePagerIdToResume(id)
        .subscribe()
    },
    [updateResumeSubscriptionRef]
  )

  const fetchOnePagers = useCallback(
    (submissions: IJobSubmission[]) => {
      fetchOnePagersSubscriptionRef.current = from(submissions)
        .pipe(
          mergeMap((submission) => {
            const onePagerId = submission.candidate.resume?.onePagerId
            const fileStorageId = submission.candidate?.resume?.fileStorageId
            const name = submission.candidate.resume?.name
            if (onePagerId) {
              return candidateOnePagerService
                .getCandidateOnePager(onePagerId)
                .pipe(
                  map((onePager) => {
                    if (
                      onePager.status !== GorillaResumeParsingStatus.Complete &&
                      onePager.status !== GorillaResumeParsingStatus.Error
                    ) {
                      return {
                        jobSubmissionId: submission.id,
                        taskId: onePager.parsedResumeId,
                      }
                    }
                    return null
                  })
                )
            }
            if (fileStorageId && name) {
              return contractorProfileService
                .downloadResume(fileStorageId)
                .pipe(
                  switchMap((data) => {
                    const blob = new Blob([data], { type: 'application/pdf' })
                    const file = new File([blob], `${name}.pdf`, {
                      type: 'application/pdf',
                    })
                    const formData = new FormData()
                    formData.append('file', file)
                    return candidateOnePagerService.startResumeParse(
                      formData,
                      submission.id,
                      fileStorageId
                    )
                  }),
                  tap((response) => addOnePagerIdToResume(response.onePagerId)),
                  map(({ taskId }) => ({
                    taskId,
                    jobSubmissionId: submission.id,
                  }))
                )
            }
            return of(null)
          }),
          filter((taskId) => {
            return taskId !== null
          }),
          toArray()
        )
        .subscribe({
          next: (resList) => {
            submissionStore.dispatch({
              pendingOnePagers: resList.reduce((acc, res) => {
                if (res) {
                  acc.push({
                    taskId: res.taskId,
                    jobSubmissionId: res.jobSubmissionId,
                    progress: '',
                  })
                }
                return acc
              }, [] as ISubmissionStoreState['pendingOnePagers']),
            })
          },
        })
    },
    [addOnePagerIdToResume, fetchOnePagersSubscriptionRef]
  )

  const updateFetchedSubmissions = useCallback(
    (jobSubmissions: IJobSubmission[]) => {
      const copy = [...fetchedSubmissions]
      jobSubmissions.forEach((r) => {
        if (!copy.find((c) => c.id === r.id)) {
          copy.push(r)
        }
      })
      submissionStore.dispatch({ fetchedSubmissions: copy })
      fetchOnePagers(copy)
    },
    [fetchOnePagers, fetchedSubmissions]
  )

  const fetchCandidateDetails = useCallback(
    (candidatesToFetch: ICartCandidate[]) => {
      return from(candidatesToFetch)
        .pipe(
          mergeMap((entity) =>
            submissionService.getSubmissionDetail(entity.atsJobSubmissionId)
          ),
          toArray()
        )
        .subscribe({
          next: (res) => {
            updateFetchedSubmissions(res)
          },
        })
    },
    [updateFetchedSubmissions]
  )

  useEffect(() => {
    return () => {
      submissionStore.dispatch({
        fetchedSubmissions: [],
        pendingOnePagers: [],
        finishedOnePagers: [],
      })
    }
  }, [])

  useEffect(() => {
    const subscription = new Subscription()

    if (IS_ONE_PAGER_ENABLED) {
      const candidatesToFetch = selectedCandidates.filter(
        (c) => !fetchedSubmissions.find((s) => s.id === c.jobSubmissionId)
      )
      if (candidatesToFetch.length) {
        subscription.add(fetchCandidateDetails(candidatesToFetch))
      }
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [
    IS_ONE_PAGER_ENABLED,
    selectedCandidates,
    fetchedSubmissions,
    fetchOnePagers,
    fetchCandidateDetails,
  ])

  useMultipleOnePagerPolling()
}
