/* eslint-disable import/no-extraneous-dependencies */
import { forwardRef, useCallback, Dispatch, SetStateAction } from 'react'
import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor'
import { MenuItemModel } from '@syncfusion/ej2-navigations'
import { Toolbar, ToolbarItem } from '@syncfusion/ej2-documenteditor'
import { registerLicense, L10n } from '@syncfusion/ej2-base'
import EJ2_LOCALE from '@syncfusion/ej2-locale/src/fr.json'
import { Button, Grid } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { useTranslation } from 'react-i18next'
import { AiFeatureLocations, DocumentEditorActions } from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'
import { TextFieldWithEdit } from '../textfield-with-edit'

import '@syncfusion/ej2-base/styles/material.css'
import '@syncfusion/ej2-buttons/styles/material.css'
import '@syncfusion/ej2-inputs/styles/material.css'
import '@syncfusion/ej2-popups/styles/material.css'
import '@syncfusion/ej2-lists/styles/material.css'
import '@syncfusion/ej2-navigations/styles/material.css'
import '@syncfusion/ej2-splitbuttons/styles/material.css'
import '@syncfusion/ej2-dropdowns/styles/material.css'
import '@syncfusion/ej2-react-documenteditor/styles/material.css'

DocumentEditorContainerComponent.Inject(Toolbar)

const toolbarItems: ToolbarItem[] = [
  'Undo',
  'Redo',
  'Separator',
  'Image',
  'Table',
  'Hyperlink',
  'Bookmark',
  'Separator',
  'Header',
  'Footer',
  'PageNumber',
  'Break',
  'Separator',
  'Find',
]
// only load FR localization for editor when i18.language === 'fr'
L10n.load({ fr: EJ2_LOCALE.fr })

interface IDocumentEditor {
  serviceUrl: string
  height?: string
  resumeTitle: string
  setResumeTitle: Dispatch<SetStateAction<string>>
  disableDownload: boolean
  onDownload?: () => void
  featureLocation?: AiFeatureLocations
  handleOpenReplaceModal?: (
    text: string,
    selectedType: DocumentEditorActions
  ) => void
}

export type IDocumentEditorRef = DocumentEditorContainerComponent

export const registerEditorLicense = (key: string): void => registerLicense(key)

export const DocumentEditor = forwardRef<
  IDocumentEditorRef | undefined,
  IDocumentEditor
>(
  (
    {
      serviceUrl,
      height,
      resumeTitle,
      setResumeTitle,
      disableDownload,
      onDownload,
      featureLocation,
      handleOpenReplaceModal,
    }: IDocumentEditor,
    ref: any
  ) => {
    const { t, i18n } = useTranslation('main')
    const { addAlert } = useAlert()
    const saveDocument = useCallback(() => {
      ref?.current?.documentEditor?.save(resumeTitle, 'Docx')
      onDownload?.()
    }, [ref, resumeTitle, onDownload])

    const onCreate = (): void => {
      // only allow at submission prep tool at bullhorn portal
      if (featureLocation === AiFeatureLocations.Bullhorn) {
        const menuItems: MenuItemModel[] = [
          {
            text: t('resumeCopilot.resumeCopilot'),
            id: 'resume_copilot',
            iconCss: 'e-icons e-repeat',
            items: [
              {
                text: t('resumeCopilot.bulletPoints'),
                id: DocumentEditorActions.BulletPoints,
                iconCss: 'e-icons e-list-unordered',
              },
              {
                text: t('resumeCopilot.fixSpellingAndGrammar'),
                id: DocumentEditorActions.GrammarCheck,
                iconCss: 'e-icons e-circle-check',
              },
            ],
          },
        ]
        ref?.current?.documentEditor.contextMenu.addCustomMenu(menuItems, false)
        // eslint-disable-next-line no-param-reassign
        ref.current.documentEditor.customContextMenuSelect = (
          args: any
        ): void => {
          const selectedText: string = ref.current.documentEditor.selection.text
          switch (args.id) {
            case DocumentEditorActions.BulletPoints: {
              handleOpenReplaceModal?.(
                selectedText,
                DocumentEditorActions.BulletPoints
              )
              break
            }
            case DocumentEditorActions.GrammarCheck: {
              const hasThreeWords = selectedText?.split(' ')
              if (hasThreeWords.length >= 3) {
                // do spelling and grammar check
                handleOpenReplaceModal?.(
                  selectedText,
                  DocumentEditorActions.GrammarCheck
                )
              } else {
                addAlert({
                  message: t('resumeCopilot.atLeastThreeWords'),
                  severity: 'error',
                })
              }
              break
            }
            default:
              break
          }
        }
        // custom options hide/show functionality
        // eslint-disable-next-line no-param-reassign
        ref.current.documentEditor.customContextMenuBeforeOpen = (
          args: any
        ): void => {
          const search: any = document.getElementById(args?.ids[0])
          search.style.display = 'none'
          const searchContent: string =
            ref.current.documentEditor.selection.text
          if (
            !ref.current.documentEditor.selection.isEmpty &&
            /\S/.test(searchContent)
          ) {
            search.style.display = 'block'
          }
        }
      }
    }

    return (
      <Grid container px={1}>
        <Grid item container justifyContent="space-between" p={1}>
          <Grid item container xs={8} alignItems="center">
            <TextFieldWithEdit
              resumeTitle={resumeTitle}
              setResumeFieldValue={setResumeTitle}
            />
          </Grid>
          <Button
            id="cta-resume-download"
            variant="contained"
            color="secondary"
            size="small"
            onClick={saveDocument}
            startIcon={<DownloadIcon />}
            disabled={disableDownload}
          >
            {t('common.btn.download')}
          </Button>
        </Grid>
        <DocumentEditorContainerComponent
          locale={i18n.language}
          enableToolbar
          id="container"
          height={height ?? '480px'}
          serviceUrl={serviceUrl}
          toolbarItems={toolbarItems}
          showPropertiesPane={false}
          ref={ref}
          created={onCreate}
        />
      </Grid>
    )
  }
)
