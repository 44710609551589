import React, { useCallback } from 'react'
import { Button, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ShowMoreActionMenuButton } from '@procom-labs/molecules'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import { useNavigateToCandidateSubmission } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-core-hooks'
import { useUpdatePortalStatus } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-info-hooks'
import {
  SubmissionActions,
  JobSubmissionStatusTypes,
  JobSubmissionPortalStatusLabels,
} from '@procom-labs/common'
import { useFormContext } from 'react-hook-form'
import { ICandidatePrepFormValues } from '@submission-portal/types'

interface CandidatePrepActionsProps {
  jobSubmissionId: string
  status: string
}

interface CandidatePrepMoreActionsProps {
  jobSubmissionId: string
  close: () => void
  status: string
}

export const CandidatePrepActionsContent: React.FC<
  CandidatePrepMoreActionsProps
> = ({ jobSubmissionId, close, status }) => {
  const { t } = useTranslation('main')
  const items = [
    {
      id: 'btn-view-timeline',
      label: t('candidatePreb.portalAction.viewTimeline'),
      icon: <AccessTimeIcon fontSize="medium" />,
      disabled: false,
    },
    {
      id: 'btn-submit-to-client',
      label: t('candidatePreb.portalAction.submitToClient'),
      icon: <BusinessIcon fontSize="medium" />,
      disabled:
        [JobSubmissionStatusTypes.ClientSubmission as string].includes(
          status
        ) ||
        ![
          JobSubmissionPortalStatusLabels.ReadyToPrep as string,
          JobSubmissionPortalStatusLabels.SubmittedToManager as string,
          JobSubmissionPortalStatusLabels.SubmittedToClient as string,
          JobSubmissionPortalStatusLabels.ClientSubmission as string,
        ].includes(status),
    },
    {
      id: 'btn-submit-to-manager',
      label: t('candidatePreb.portalAction.submitToManager'),
      icon: <PersonIcon fontSize="medium" />,
      disabled:
        [
          JobSubmissionStatusTypes.ClientSubmission as string,
          JobSubmissionStatusTypes.SubmittedToManager as string,
        ].includes(status) ||
        ![
          JobSubmissionPortalStatusLabels.ReadyToPrep as string,
          JobSubmissionPortalStatusLabels.SubmittedToManager as string,
          JobSubmissionPortalStatusLabels.SubmittedToClient as string,
          JobSubmissionPortalStatusLabels.ClientSubmission as string,
        ].includes(status),
    },
  ]

  const updatePortalStatus = useUpdatePortalStatus()

  const methods = useFormContext()

  const handleMenuItemClick = useCallback(
    (id: string): void => {
      switch (id) {
        case 'btn-view-timeline':
          break
        case 'btn-submit-to-client':
          updatePortalStatus(
            jobSubmissionId,
            SubmissionActions.ClientSubmission,
            methods?.getValues() as ICandidatePrepFormValues
          )
          break
        case 'btn-submit-to-manager':
          updatePortalStatus(
            jobSubmissionId,
            SubmissionActions.SubmittedToManager,
            methods?.getValues() as ICandidatePrepFormValues
          )
          break
        default:
          break
      }

      // Close the menu after action
      close()
    },
    [close, methods, jobSubmissionId, updatePortalStatus]
  )

  return (
    <>
      {items.map(({ id: itemId, icon, label, disabled }) => (
        <MenuItem
          id={itemId}
          key={itemId}
          onClick={() => handleMenuItemClick(itemId)}
          disabled={disabled}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      ))}
    </>
  )
}
export const CandidatePrepActions: React.FC<CandidatePrepActionsProps> = ({
  jobSubmissionId,
  status,
}) => {
  const navigateToCandidateSubmission = useNavigateToCandidateSubmission()
  const { t } = useTranslation('main')

  const handleView = useCallback(() => {
    navigateToCandidateSubmission(jobSubmissionId)
  }, [navigateToCandidateSubmission, jobSubmissionId])

  return (
    <>
      <Button size="medium" onClick={handleView}>
        {t('candidatePreb.view')}
      </Button>

      <ShowMoreActionMenuButton id={jobSubmissionId}>
        {(close) => (
          <CandidatePrepActionsContent
            jobSubmissionId={jobSubmissionId}
            close={close}
            status={status}
          />
        )}
      </ShowMoreActionMenuButton>
    </>
  )
}
