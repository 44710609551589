import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import { useAlert } from '@procom-labs/molecules'

import { ISubmissionSummary } from '@submission-portal/models'
import { rollbarInstance } from '@submission-portal/providers'
import { SubmissionSource, ValidViewSubmissionStatuses } from '@submission-portal/types'
import { VendorCodes } from '@procom-labs/common'
import { vendorStorage } from '@submission-portal/lib'

export const useNavigateToSubmission = (): any => {
  const { addAlert } = useAlert()
  const navigate = useNavigate()
  const isVendorVanderHouwen = vendorStorage.get()?.vendorCode === VendorCodes.VNDRHWN

  return useCallback(
    (submission: ISubmissionSummary) => {
      const isView = isVendorVanderHouwen ?
        ValidViewSubmissionStatuses.includes(submission.status?.toLowerCase() ?? '') && submission.submissionSource === SubmissionSource.BullhornUtility :
        ValidViewSubmissionStatuses.includes(submission.status?.toLowerCase() ?? '')
      const isHmRequired =
        submission.hiringManagerName && !submission.hiringManagerEmail
      if (!submission.resourceManagerEmail || isHmRequired) {
        rollbarInstance.error('Missing email')
        addAlert({
          timeout: 10000,
          severity: 'error',
          variant: 'outlined',
          title: 'Missing email',
          message: (
            <Typography>
              To be able to submit candidates, please add a valid email address
              for&nbsp;
              {!submission.resourceManagerEmail && (
                <>
                  <strong>{submission.resourceManagerName}</strong> (Contact)
                </>
              )}
              {!submission.resourceManagerEmail && isHmRequired
                ? ' and '
                : null}
              {isHmRequired && (
                <>
                  <strong>{submission.hiringManagerName}</strong> (Reporting To)
                </>
              )}
            </Typography>
          ),
        })
      } else {
        // For distinction, we have two modes, Preview and Review.
        // When we use the Review mode we are able to go back and review submission.
        // This review view is the same one we use for the Preview mode.
        // Thus, we need to use the flag below in order to determine whether we are only
        // intending to view the submission (Preview Mode) and nothing else
        if (isView) {
          sessionStorage.setItem('isView', 'true')
        } else {
          sessionStorage.removeItem('isView')
        }

        navigate(`/${submission.atsJobSubmissionId}`)
      }
    },
    [addAlert, navigate]
  )
}
