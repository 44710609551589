import { FC, useEffect, useRef, useState, CSSProperties } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import { useTranslation } from 'react-i18next'
import {
  byteToMegabyte,
  DragStatus,
  getDropzoneColor,
  IDocument,
  MAX_FILE_UPLOAD_UNIT,
} from '@procom-labs/common'
import { ConfirmationDialog, useAlert } from '@procom-labs/molecules'
import { combineLatest, concatAll, Observable, of, Subscription } from 'rxjs'
import { Button, IconButton, LinearProgress } from '@mui/material'
import { DateTime } from 'luxon'

const Dot = styled('span')(({ theme }) => ({
  display: 'inline-block',
  height: '7px',
  width: '7px',
  borderRadius: '7px',
  backgroundColor: theme.palette.text.disabled,
  margin: '0 7px',
}))

const Dropzone = styled('div', {
  shouldForwardProp: (prop) =>
    ['isFocused', 'isDragAccept', 'isDragReject', 'dropzoneStyles'].indexOf(
      prop as string
    ) === -1,
})<DragStatus>((props: any) => ({
  marginTop: '10px',
  height: '180px',
  display: 'flex',
  padding: '0 24px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: '2px',
  borderRadius: '2px',
  borderStyle: 'dashed',
  backgroundColor: props.theme.palette.offWhite,
  borderColor: getDropzoneColor(props),
  ...props.dropzoneStyles,
}))

type DocumentUploaderProps = {
  additionalDocumentsValue: IDocument[] | null
  onChange: (value: IDocument | null) => void
  uploadDocument: (
    formData: FormData,
    onUploadProgress: (event: ProgressEvent) => void
  ) => Observable<Partial<IDocument>>
  deleteDocument?: (fileStorageId: string) => Observable<any>
  size?: number
  limit: number
  dropzoneStyles?: CSSProperties
  fileStyles?: CSSProperties
  direction?: 'row' | 'column'
  error?: boolean
  upload?: boolean
  onFocus?: () => void
  onBlur?: () => void
  logToRollbar?: (error: string) => void
  maxSizeAlert?: string
  successAlert?: string
  showSuccessAlert?: boolean
  onError?: (err: string) => void
}

export const DocumentUploader: FC<DocumentUploaderProps> = ({
  additionalDocumentsValue,
  error,
  dropzoneStyles,
  fileStyles,
  onChange,
  uploadDocument,
  deleteDocument,
  size = 5,
  direction = 'column',
  logToRollbar,
  maxSizeAlert,
  successAlert,
  showSuccessAlert = true,
  onError,
  limit,
}) => {
  const MAX_FILE_UPLOAD_SIZE = size * MAX_FILE_UPLOAD_UNIT
  const { addAlert } = useAlert()
  const { t } = useTranslation('main')
  const uploadSubscription = useRef<Subscription>()
  const deleteSubscription = useRef<Subscription | null>()

  const [progress, setProgress] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const [documentLimit] = useState(limit)
  const [uploadDoc, setUploadDoc] = useState<IDocument | null>(null)
  const [additionalDocuments, setAdditionalDocuments] = useState<
    IDocument[] | null
  >([])
  const [documentDeleteId, setDocumentDeleteId] = useState<string | null>(null)

  const onDropAccepted = (files: File[]): void => {
    onError?.('')
    if (files.length) {
      const calls = files.map((file) => {
        const extension = file.name.split('.').pop() || ''
        const name = file.name.replace(`.${extension}`, '')
        const fileSize = file.size.toString()
        setUploadDoc((prev) => ({
          ...prev,
          size: fileSize,
          name,
          extension,
        }))
        const formData = new FormData()
        formData.append('file', file)
        formData.append('size', fileSize)
        return combineLatest([
          of({ name, fileSize }),
          uploadDocument(formData, (event: ProgressEvent) => {
            setProgress((event.loaded / event.total) * 100)
          }),
        ])
      })

      const source = of(...calls)

      source.pipe(concatAll()).subscribe({
        next: ([{ name, fileSize }, response]) => {
          onChange({
            ...response,
            name,
            extension: response.extension || '.pdf',
            size: fileSize,
          })
          if (showSuccessAlert) {
            addAlert({
              message:
                successAlert || t('organisms.documentUploader.alert.upload'),
            })
          }
          setProgress(0)
          setUploadDoc(null)
        },
        error: () => {
          onError?.(t('organisms.documentUploader.alert.failed'))
          addAlert({
            severity: 'error',
            message: t('organisms.documentUploader.alert.failed'),
          })
          setProgress(0)
          setUploadDoc(null)
        },
      })
    }
  }

  const onDropRejected = (rejections: FileRejection[]): void => {
    if (rejections.length) {
      const { errors } = rejections[0]
      if (errors.length) {
        const { code, message } = errors[0]
        let errorMessage = message
        let inputErr = errorMessage
        if (code === 'file-too-large') {
          if (logToRollbar) {
            logToRollbar('File is too large')
          }
          errorMessage =
            maxSizeAlert ||
            t('organisms.documentUploader.alert.sizeError', {
              fileSize: MAX_FILE_UPLOAD_SIZE / MAX_FILE_UPLOAD_UNIT,
            })
          inputErr = t('organisms.documentUploader.alert.sizeErrorInput', {
            fileSize: MAX_FILE_UPLOAD_SIZE / MAX_FILE_UPLOAD_UNIT,
          })
        }
        onError?.(inputErr)
        addAlert({
          severity: 'error',
          message: errorMessage,
        })
      }
    }
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept } = useDropzone({
    maxFiles: 3,
    maxSize: MAX_FILE_UPLOAD_SIZE,
    accept: {
      'application/pdf': ['.pdf'],
      'application/office': ['.doc', '.docx'],
    },
    onDropRejected,
    onDropAccepted,
  })
  const handleDocumentDelete = (fileId: string | undefined): void => {
    if (fileId) {
      setDocumentDeleteId(fileId)
      setConfirmation(true)
    }
  }
  const handleDocumentConfirmation = (shouldDelete: boolean): void => {
    setConfirmation(false)
    if (shouldDelete && documentDeleteId && deleteDocument) {
      setIsDeleting(true)
      deleteSubscription.current = deleteDocument(documentDeleteId).subscribe({
        complete: () => {
          setProgress(0)
          addAlert({
            message: t('organisms.documentUploader.alert.deleted'),
          })
        },
        error: () => {
          addAlert({
            severity: 'error',
            message: t('organisms.documentUploader.alert.deleteFailed'),
          })
        },
      })
      deleteSubscription.current.add(() => {
        setProgress(0)
        setIsDeleting(false)
      })
    }
  }

  useEffect(() => {
    if (additionalDocumentsValue) {
      let doc: IDocument
      const additionalDocumentsArr: IDocument[] = []
      additionalDocumentsValue.forEach((document) => {
        doc = {
          uploadedOn: document.uploadedOn,
          size: document.size,
          name: document.name,
          extension: document.extension,
          uploadedBy: document.uploadedBy,
          fileStorageId: document.fileStorageId,
        }
        if (document.uploadedOn) {
          const date = DateTime.fromISO(document.uploadedOn)
          doc.uploadedOn = `${date.toLocaleString(
            DateTime.DATE_FULL
          )} ${date.toLocaleString(DateTime.TIME_SIMPLE)}`
        }
        if (document.size) {
          doc.size = byteToMegabyte(+document.size).toString()
        }
        additionalDocumentsArr.push(doc)
      })
      setAdditionalDocuments(additionalDocumentsArr)
    }
  }, [additionalDocumentsValue])

  useEffect(() => {
    return () => {
      if (uploadSubscription.current && !uploadSubscription.current.closed) {
        uploadSubscription.current.unsubscribe()
      }

      if (deleteSubscription.current && !deleteSubscription.current.closed) {
        deleteSubscription.current.unsubscribe()
      }
    }
  }, [])

  return (
    <>
      {additionalDocuments ? (
        <>
          {additionalDocuments.map((document, index) => (
            <Grid
              item
              key={index}
              container
              wrap="nowrap"
              spacing={1}
              sx={{
                p: 1,
                backgroundColor: `${'background.default'}`,
                ...fileStyles,
                mb: 2,
              }}
            >
              <Grid item>
                <FilePresentIcon color="primary" fontSize="large" />
              </Grid>
              <Grid
                container
                item
                direction="column"
                spacing={1}
                wrap="nowrap"
                justifyContent="center"
              >
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  sx={{ paddingTop: 0 }}
                >
                  <Grid
                    item
                    container
                    xs={10}
                    component={Stack}
                    divider={<Dot />}
                    alignItems="center"
                  >
                    {document.name && (
                      <>
                        <Grid item xs={5}>
                          <Typography
                            noWrap
                            variant="body2"
                            color="text.secondary"
                          >
                            {document.name}
                            {document.extension &&
                            !document.extension.startsWith('.')
                              ? `.${document.extension}`
                              : `${document.extension}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            noWrap
                            variant="body2"
                            color="text.secondary"
                          >
                            {document.size && ` ${document.size} MB`}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            noWrap
                            variant="body2"
                            color="text.secondary"
                          >
                            {document.uploadedOn}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {document?.fileStorageId && deleteDocument && (
                      <IconButton
                        disabled={isDeleting}
                        sx={{
                          p: 0,
                          float: 'right',
                          color: 'theme.palette.action.active',
                        }}
                        aria-label={t('common.aria.close')}
                        onClick={() =>
                          handleDocumentDelete(document.fileStorageId)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      ) : null}
      {/* To show upload progress */}
      {uploadDoc && (
        <>
          <Grid
            item
            container
            wrap="nowrap"
            spacing={1}
            sx={{
              p: 1,
              backgroundColor: `${'background.default'}`,
              ...fileStyles,
            }}
          >
            <Grid item>
              <FilePresentIcon color="primary" fontSize="large" />
            </Grid>
            <Grid
              container
              item
              direction="column"
              spacing={1}
              wrap="nowrap"
              justifyContent="center"
            >
              <Grid
                container
                item
                justifyContent="space-between"
                sx={{ paddingTop: 0 }}
              >
                <Grid
                  item
                  container
                  xs={10}
                  component={Stack}
                  divider={<Dot />}
                  alignItems="center"
                >
                  {uploadDoc.name && (
                    <Grid
                      item
                      xs="auto"
                      sx={{ maxWidth: '80% !important', display: 'flex' }}
                      alignItems="center"
                    >
                      <Typography noWrap variant="body2" color="text.secondary">
                        {uploadDoc.name}
                        {uploadDoc.extension &&
                        !uploadDoc.extension.startsWith('.')
                          ? `.${uploadDoc.extension}`
                          : `${uploadDoc.extension}`}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <LinearProgress variant="determinate" value={progress} />
          </Grid>
        </>
      )}
      {/* To show add additional document */}
      <Dropzone
        {...getRootProps({
          dropzoneStyles,
          isDragAccept,
          isFocused: error ? false : isFocused,
          isDragReject: error ?? false,
        })}
      >
        <input {...getInputProps()} />
        <Grid
          container
          rowGap={1}
          columnGap={2}
          direction={direction}
          alignItems="center"
        >
          <Grid item xs="auto">
            <CloudUploadIcon color="primary" fontSize="large" />
          </Grid>
          <Grid item xs sx={{ textAlign: 'center' }}>
            <Typography variant="body2Bold">
              {t('organisms.documentUploader.label')}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {t('organisms.documentUploader.sublabel', { fileSize: size })}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              disabled={
                !!(
                  additionalDocuments &&
                  additionalDocuments.length >= documentLimit
                )
              }
            >
              {t('common.btn.upload')}
            </Button>
          </Grid>
        </Grid>
      </Dropzone>
      {additionalDocuments && additionalDocuments.length === documentLimit && (
        <Typography color="#F44336" variant="body2" marginTop={2}>
          {t('organisms.documentUploader.docLimitMessage')}
        </Typography>
      )}
      <ConfirmationDialog
        title=""
        content={t('organisms.documentUploader.alert.deletePermission')}
        cancelBtnText={t('organisms.documentUploader.alert.cancelBtn')}
        okBtnText={t('common.btn.delete')}
        open={confirmation}
        handleClose={handleDocumentConfirmation}
      />
    </>
  )
}
