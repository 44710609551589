import { AvailabilityDefaults, DateAvailableDataTypes } from '../enums'

export const mapDataTypeToAvailability = (
  dateAvailableType: string
): AvailabilityDefaults => {
  switch (dateAvailableType) {
    case DateAvailableDataTypes.Custom:
      return AvailabilityDefaults.OTHER
    case DateAvailableDataTypes.Immediate:
      return AvailabilityDefaults.IMMEDIATE
    case DateAvailableDataTypes.TwoWeeksNotice:
      return AvailabilityDefaults.TWO_WEEKS
    case DateAvailableDataTypes.Date:
      return AvailabilityDefaults.DATE
    default:
      return AvailabilityDefaults.IMMEDIATE
  }
}

export const mapAvailabilityToDataType = (value: string): string => {
  switch (value) {
    case AvailabilityDefaults.DATE:
      return DateAvailableDataTypes.Date
    case AvailabilityDefaults.IMMEDIATE:
      return DateAvailableDataTypes.Immediate
    case AvailabilityDefaults.TWO_WEEKS:
      return DateAvailableDataTypes.TwoWeeksNotice
    case AvailabilityDefaults.OTHER:
      return DateAvailableDataTypes.Custom
    default:
      return DateAvailableDataTypes.Date
  }
}
