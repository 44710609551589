import { FC, memo, ReactElement, useMemo } from 'react'
import Parse from 'html-react-parser'
import { TFuncKey, useTranslation } from 'react-i18next'

import {
  AttachMoneyRounded,
  CalendarTodayOutlined,
  DocumentScannerOutlined,
  HomeWorkOutlined,
  PersonOutlineRounded,
  PlaceOutlined,
} from '@mui/icons-material'
import { alpha, Divider, SvgIconTypeMap, useMediaQuery } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { GorillaStack } from '@procom-labs/atoms'
import {
  formatNumberToCurrency,
  getRatePeriodLabelKey,
  OnePagerCandidateDescription,
  useFormatDateAvailable,
} from '@procom-labs/common'

import { SectionTitle } from '../common'

const Column: FC<{
  title: string
  icon: ReactElement
  value: string
  isMobileOrTab: boolean
}> = ({ title, value, icon, isMobileOrTab }) => {
  return (
    <GorillaStack flex={1} gap={0.5}>
      {!isMobileOrTab && (
        <Typography sx={{ color: 'text.secondary' }}>{title}</Typography>
      )}
      <GorillaStack gap={1} direction="row" alignItems="center">
        {icon}
        <Typography fontWeight={700}>{value}</Typography>
      </GorillaStack>
    </GorillaStack>
  )
}
export const Candidate: FC<{
  candidate: OnePagerCandidateDescription
  visibility: Record<string, boolean>
  summary?: string | null
}> = memo(({ candidate, visibility, summary }) => {
  const theme = useTheme()
  const { i18n, t } = useTranslation()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))
  const dateAvailable = useFormatDateAvailable(
    candidate.dateAvailable ?? undefined,
    candidate.dateAvailableType ?? '',
    candidate.dateAvailableUserInput ?? undefined
  )
  const visibleFields: {
    key: string
    icon: OverridableComponent<SvgIconTypeMap> & { muiName: string }
    title: string
    value?: string | null
  }[] = useMemo(() => {
    const rate =
      candidate.currency && candidate.rate
        ? formatNumberToCurrency(
            i18n.language,
            candidate.currency,
            +candidate.rate
          )
        : null

    const ratePeriod = candidate.rateType
      ? t(getRatePeriodLabelKey(candidate?.rateType)).toString()
      : null

    const fields = [
      {
        key: 'Location',
        icon: PlaceOutlined,
        title: `${t('common.candidates.details.location')}:`,
        value: candidate?.location.addressLine1 ?? '',
      },
      {
        key: 'Rate',
        icon: AttachMoneyRounded,
        title: `${t('common.candidates.details.rate')}:`,
        value:
          rate && ratePeriod
            ? `${rate}/${ratePeriod} ${candidate.currency}`
            : null,
      },
      {
        key: 'WorkplaceType',
        icon: HomeWorkOutlined,
        title: `${t(
          'organisms.candidateOnePager.candidate.fields.workplace'
        )}:`,
        value: candidate?.workplaceType
          ?.map(
            (type) => t(`common.enums.jobSite.${type}` as TFuncKey) as string
          )
          .join(', '),
      },
      {
        key: 'Contract',
        icon: DocumentScannerOutlined,
        title: `${t('organisms.candidateOnePager.candidate.fields.contract')}:`,
        value: candidate?.contract,
      },
      {
        key: 'Availability',
        icon: CalendarTodayOutlined,
        title: `${t('common.form.availability.label')}:`,
        value: dateAvailable,
      },
    ]
    return fields.filter((field) => {
      return visibility[field.key] && !!field.value
    })
  }, [i18n.language, t, candidate, dateAvailable, visibility])

  return (
    <GorillaStack gap={2}>
      <SectionTitle
        icon={
          <PersonOutlineRounded
            sx={{ color: theme.palette.secondary.main, fontSize: '18px' }}
          />
        }
        sx={{
          paddingBottom: isMobileOrTab ? '0px' : '12px',
          borderBottom: isMobileOrTab
            ? 'none'
            : `2px solid ${alpha(theme.palette.divider, 0.3)}`,
        }}
        title={t('organisms.candidateOnePager.candidate.title')}
      />
      <GorillaStack
        direction={isMobileOrTab ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isMobileOrTab ? 'start' : 'center'}
        gap={isMobileOrTab ? 1 : 3}
      >
        {visibleFields.map(({ title, icon: IconComponent, value }, index) => (
          <>
            <Column
              title={title}
              isMobileOrTab={isMobileOrTab}
              icon={
                <IconComponent
                  sx={{ color: theme.palette.secondary.main }}
                  fontSize="small"
                />
              }
              value={value ?? ''}
            />
            {visibleFields.length > 1 && index < visibleFields.length - 1 && (
              <Divider
                sx={{ height: '80%', my: 'auto' }}
                orientation="vertical"
              />
            )}
          </>
        ))}
      </GorillaStack>
      <Typography variant="body2">
        {summary
          ? Parse(summary)
          : t('organisms.candidateOnePager.candidate.noSummary')}
      </Typography>
    </GorillaStack>
  )
})
