import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ControllerFieldState } from 'react-hook-form'

import { FormErrorType } from '@procom-labs/common'

export const useHookFieldError = (
  fieldState: ControllerFieldState,
  label: React.ReactNode
) => {
  const { t } = useTranslation('main')
  return useMemo(() => {
    const hasErr = Boolean(fieldState.invalid && fieldState.error)
    let errText
    if (hasErr) {
      if (fieldState?.error?.message === FormErrorType.requiredWithLabel) {
        errText = t('common.validations.requiredFieldError', {
          label,
        })
      } else {
        errText = fieldState.error?.message || ''
      }
    }
    return errText
  }, [fieldState.isTouched, fieldState.error, t, label])
}
