import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GridColDef, GridSortModel } from '@mui/x-data-grid'
import { GridCallbackDetails } from '@mui/x-data-grid/models/api'
import {
  ColumnDescriptor,
  SortOrder,
  useDebouncedSubjectSelector,
  useSubjectSelector,
} from '@procom-labs/common'
import { CellDateTime, CellText } from '@procom-labs/organisms'

import { CandidatePrepActions } from '@submission-portal/components/candidate-prep/candidate-prep-actions'
import { CandidatePrepInfo } from '@submission-portal/components/candidate-prep/components/candidate-prep-info'
import { useFetchSubmissions } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-core-hooks'
import { ISubmissionSummary } from '@submission-portal/models'
import { submissionStore } from '@submission-portal/stores'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import {
  CandidatePrepPortalStatusColor,
  CandidatesPrepFields,
  CandidatesPrepSearchFilter,
} from '@submission-portal/types'

export const useGetSubmissionColumnDescriptors = (): ColumnDescriptor[] => {
  const { t } = useTranslation('main')

  return [
    {
      field: CandidatesPrepFields.Candidate,
      headerName: t(`candidatePreb.candidate`),
      minWidth: 510,
    },
    {
      field: CandidatesPrepFields.LastModified,
      headerName: t(`candidatePreb.lastModified`),
      minWidth: 80,
    },
    {
      field: CandidatesPrepFields.LastViewed,
      headerName: t('candidatePreb.lastViewed'),
      minWidth: 80,
    },
    {
      field: CandidatesPrepFields.Status,
      headerName: t(`candidatePreb.status`),
    },
    {
      field: CandidatesPrepFields.Actions,
      headerName: t(`candidatePreb.actions`),
      maxWidth: 200,
      sortable: false,
    },
  ]
}

export const useCandidatePrepDataColumns = (): GridColDef<any, any, any>[] => {
  const theme = useTheme()
  const mobileView = useMediaQuery(() => theme.breakpoints.down('md'))
  const colorMap = CandidatePrepPortalStatusColor(theme)

  const columnDescriptors = useGetSubmissionColumnDescriptors()

  const cellRenderer = useMemo(
    () =>
      (field: string, cell: { row: ISubmissionSummary }): React.ReactNode => {
        const { row } = cell

        switch (field) {
          case CandidatesPrepFields.Candidate:
            return <CandidatePrepInfo record={row} />
          case CandidatesPrepFields.LastModified:
            return <CellDateTime value={row.dateAddedOnAts} />
          case CandidatesPrepFields.LastViewed:
            return <CellDateTime value={row.dateLastViewed} />
          case CandidatesPrepFields.Status:
            return (
              <Box
                sx={{
                  backgroundColor: colorMap[row.status ?? ''] ?? '#fff',
                  display: 'flex',
                  width: '100%',
                  borderRadius: '4px',
                  p: '6px',
                  justifyContent: 'center',
                }}
              >
                <CellText value={row.status ?? ''} />
              </Box>
            )
          case CandidatesPrepFields.Actions:
            return (
              <CandidatePrepActions
                jobSubmissionId={row.jobSubmissionId}
                status={row.status ?? ''}
              />
            )
          default:
            // Should not happen
            return null
        }
      },
    [colorMap]
  )

  return useMemo(
    () =>
      columnDescriptors.map((columnDescriptor) => ({
        ...columnDescriptor,
        flex: Number(!mobileView),
        renderCell: (cell: any) => cellRenderer(columnDescriptor.field, cell),
      })),
    [cellRenderer, columnDescriptors, mobileView]
  )
}

export const useCandidatePrepDataRecords = (
  searchFilter: CandidatesPrepSearchFilter
): { rows: ISubmissionSummary[]; rowCount: number; loading: boolean } => {
  const {
    submissions: rows,
    count: rowCount,
    loading,
  } = useSubjectSelector(submissionStore, ['submissions', 'count', 'loading'])

  const fetchSubmissions = useFetchSubmissions()

  useEffect(() => {
    fetchSubmissions(searchFilter.keyword)
  }, [fetchSubmissions, searchFilter.keyword])

  return { rows, rowCount, loading }
}
export const useOnSortCandidatePrepModelChange = (): ((
  model: GridSortModel,
  details: GridCallbackDetails
) => void) => {
  const { searchFilter } = useDebouncedSubjectSelector(
    candidatesPrepStore,
    ['searchFilter'],
    300
  )

  return useCallback(
    (model: GridSortModel): void => {
      const sortModel = model[0] ?? { field: '', sort: SortOrder.desc }
      const { field: sortField, sort } = sortModel

      candidatesPrepStore.dispatch({
        searchFilter: {
          ...searchFilter,
          sortField,
          sortOrder: SortOrder[sort ?? SortOrder.asc],
        },
      })
    },
    [searchFilter]
  )
}
