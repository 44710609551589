import { GorillaBox } from '@procom-labs/atoms'
import { FC } from 'react'
import { BoxProps } from '@mui/material'

export const GlassBoxWrapper: FC<BoxProps> = ({
  sx = {},
  children,
  ...props
}) => {
  return (
    <GorillaBox
      isGlassMode
      isThemeBackgroundApplied
      sx={{
        flexGrow: 1,
        borderRadius: '20px',
        padding: 2,
        maxWidth: '100%',
        ...sx,
      }}
      {...props}
    >
      {children}
    </GorillaBox>
  )
}
