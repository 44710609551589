import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Observable, Subscription } from 'rxjs'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import {
  Button,
  FormHelperText,
  IconButton,
  LinearProgress,
  SxProps,
  Theme,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { DateFormatter } from '@procom-labs/atoms'
import {
  DateValueFormat,
  DragStatus,
  getDropzoneColor,
  IResume,
  MAX_FILE_UPLOAD_UNIT,
  useIsGorillaTheme,
} from '@procom-labs/common'
import { ConfirmationDialog, useAlert } from '@procom-labs/molecules'

const Dot = styled('span')(({ theme }) => ({
  display: 'inline-block',
  height: '7px',
  width: '7px',
  borderRadius: '7px',
  backgroundColor: theme.palette.text.disabled,
  margin: '0 7px',
}))

const Dropzone = styled('div', {
  shouldForwardProp: (prop) =>
    [
      'isFocused',
      'isDragAccept',
      'isDragReject',
      'dropzoneStyles',
      'isDisabled',
      'isGorillaTheme',
    ].indexOf(prop as string) === -1,
})<DragStatus>((props: any) => ({
  height: '180px',
  display: 'flex',
  padding: '0 24px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: '2px',
  borderRadius: '20px',
  borderStyle: 'dashed',
  backgroundColor: props.theme.palette.offWhite,
  borderColor: getDropzoneColor(props),
  opacity: props.isDisabled ? 0.5 : 1,
  ...props.dropzoneStyles,
}))

const CustomUploader = styled('div', {
  shouldForwardProp: (prop) =>
    ['isFocused', 'isDragAccept', 'isDragReject', 'isGorillaTheme'].indexOf(
      prop as string
    ) === -1,
})<DragStatus>((props) => ({
  border: `1px solid ${getDropzoneColor(props)}`,
  borderRadius: 4,
}))

export enum VariantTypes {
  Regular = 'regular',
  Compact = 'compact',
}

type ResumeUploaderProps = {
  value: IResume | null
  onChange: (value: IResume | null) => void
  uploadResume: (
    formData: FormData,
    onUploadProgress: (event: ProgressEvent) => void,
    onStaticUploadProgress?: () => void
  ) => Observable<Partial<IResume>>
  deleteResume?: (fileStorageId: string) => Observable<any>
  viewResume?: () => void
  size?: number
  dropzoneStyles?: CSSProperties
  fileStyles?: SxProps<Theme>
  direction?: 'row' | 'column'
  error?: boolean
  upload?: boolean
  downloadResume?: (name: string, fileStorageId: string) => Observable<any>
  onFocus?: () => void
  onBlur?: () => void
  logToRollbar?: (error: string) => void
  maxSizeAlert?: string
  successAlert?: string
  showSuccessAlert?: boolean
  onError?: (err: string) => void
  disabled?: boolean
  fileDataKey?: string
  preferWordDoc?: boolean
  isPdfAllowed?: boolean
  viewBtnText?: string
  variant?: VariantTypes
}

export const ResumeUploader: FC<ResumeUploaderProps> = ({
  value,
  error,
  dropzoneStyles,
  fileStyles,
  onFocus,
  onBlur,
  onChange,
  uploadResume,
  deleteResume,
  downloadResume,
  viewResume,
  size = 5,
  viewBtnText,
  direction = 'column',
  children,
  logToRollbar,
  successAlert,
  showSuccessAlert = true,
  onError,
  disabled = false,
  fileDataKey,
  preferWordDoc = false,
  isPdfAllowed = true,
  variant = VariantTypes.Regular,
}) => {
  const acceptedFileTypes = {
    'application/pdf': ['.pdf'],
    'application/office': ['.doc', '.docx'],
  }

  const acceptedFileTypesForResumeCopilot = {
    'application/office': ['.doc', '.docx'],
  }

  const MAX_FILE_UPLOAD_SIZE = size * MAX_FILE_UPLOAD_UNIT

  const isGorillaTheme = useIsGorillaTheme()

  const { addAlert } = useAlert()
  const { t } = useTranslation('main')

  const uploadSubscription = useRef<Subscription>()
  const deleteSubscription = useRef<Subscription | null>()
  const downloadSubscription = useRef<Subscription | null>()

  const [progress, setProgress] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const [resume, setResume] = useState<IResume | null>(value)
  const [uploadedFileExtension, setUploadedFileExtension] = useState<string>('')
  const [displaySizeError, setDisplaySizeError] = useState(false)

  const onDropAccepted = (files: File[]): void => {
    onError?.('')
    if (files.length) {
      const file = files[0]
      const extension = file.name.split('.').pop() || ''
      const name = file.name.replace(`.${extension}`, '')
      setUploadedFileExtension(extension)
      setResume((prev) => ({
        ...prev,
        size: file.size.toString(),
        name,
        extension,
      }))

      const formData = new FormData()
      formData.append(fileDataKey || 'file', file)
      uploadSubscription.current = uploadResume(
        formData,
        (event: ProgressEvent) => {
          setProgress((event.loaded / event.total) * 100)
        },
        () => {
          setProgress(100)
        }
      ).subscribe({
        next: (response) => {
          setDisplaySizeError(false)
          onChange({
            ...response,
            name,
            extension: isPdfAllowed ? response.extension || '.pdf' : extension,
            size: file.size.toString(),
          })
          if (showSuccessAlert) {
            addAlert({
              message:
                successAlert || t('organisms.resumeUploader.alert.upload'),
            })
          }
        },
        error: () => {
          setResume(null)
          onError?.(t('organisms.resumeUploader.alert.failed'))
          addAlert({
            severity: 'error',
            message: t('organisms.resumeUploader.alert.failed'),
          })
        },
      })
      uploadSubscription.current.add(() => {
        setProgress(0)
      })
    }
  }

  const onDropRejected = (rejections: FileRejection[]): void => {
    if (rejections.length) {
      const { errors } = rejections[0]
      if (errors.length) {
        const { code, message } = errors[0]
        let errorMessage = message
        let inputErr = errorMessage
        if (code === 'file-too-large') {
          if (logToRollbar) {
            logToRollbar('File is too large')
          }
          inputErr = t('organisms.resumeUploader.alert.sizeErrorInput', {
            fileSize: MAX_FILE_UPLOAD_SIZE / MAX_FILE_UPLOAD_UNIT,
          })
          setDisplaySizeError(true)
        } else if (code === 'file-invalid-type') {
          errorMessage = t(
            isPdfAllowed
              ? 'organisms.resumeUploader.alert.notAccepted'
              : 'organisms.resumeUploader.alert.invalidFileFormatError'
          )
          addAlert({
            severity: 'error',
            message: errorMessage,
          })
        }
        onError?.(inputErr)
      }
    }
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept } = useDropzone({
    maxFiles: 1,
    maxSize: MAX_FILE_UPLOAD_SIZE,
    accept: isPdfAllowed
      ? acceptedFileTypes
      : acceptedFileTypesForResumeCopilot,
    onDropRejected,
    onDropAccepted,
    disabled,
  })

  const handleDelete = (): void => {
    if (value?.fileStorageId) {
      setConfirmation(true)
    } else if (uploadSubscription.current) {
      addAlert({
        severity: 'warning',
        message: t('organisms.resumeUploader.alert.cancel'),
      })
      uploadSubscription.current.unsubscribe()
      setResume(null)
      onChange(null)
      setProgress(0)
    }
  }

  const handleConfirmation = (shouldDelete: boolean): void => {
    setConfirmation(false)

    if (shouldDelete && value?.fileStorageId && deleteResume) {
      setIsDeleting(true)
      deleteSubscription.current = deleteResume(value.fileStorageId).subscribe({
        complete: () => {
          setProgress(0)
          onChange(null)
          setResume(null)
          addAlert({
            message: t('organisms.resumeUploader.alert.deleted'),
          })
        },
        error: () => {
          addAlert({
            severity: 'error',
            message: t('organisms.resumeUploader.alert.deleteFailed'),
          })
        },
      })
      deleteSubscription.current.add(() => {
        setResume(null)
        onChange(null)
        setProgress(0)
        setIsDeleting(false)
      })
    } else if (shouldDelete && value?.fileStorageId) {
      setResume(null)
      onChange(null)
      setProgress(0)
      setIsDeleting(false)
    }
  }

  const handleDownload = (): void => {
    if (value?.fileStorageId && downloadResume) {
      downloadSubscription.current = downloadResume(
        value.name,
        value.fileStorageId
      ).subscribe({
        complete: () => {
          addAlert({
            message: t('organisms.resumeUploader.alert.downloadComplete'),
          })
        },
        error: () => {
          addAlert({
            severity: 'error',
            message: t('organisms.resumeUploader.alert.downloadFailed'),
          })
        },
      })

      downloadSubscription.current.add(() => setIsDeleting(false))
    }
  }

  const fileNameAndExtension = useMemo(() => {
    const fileExtension = isPdfAllowed
      ? resume?.extension
      : resume?.wordFileExtension ?? uploadedFileExtension

    return `${resume?.name}${
      fileExtension?.startsWith('.') ? `${fileExtension}` : `.${fileExtension}`
    }`
  }, [
    isPdfAllowed,
    resume?.extension,
    resume?.name,
    resume?.wordFileExtension,
    uploadedFileExtension,
  ])

  const fileSize = useMemo(() => {
    if (resume?.size) {
      const val = +resume.size / MAX_FILE_UPLOAD_UNIT
      return val >= 0.1 ? val.toFixed(1) : null
    }
    return null
  }, [resume?.size])

  useEffect(() => {
    if (isFocused && !value?.fileStorageId) {
      if (onFocus) {
        onFocus()
      }
    } else if (onBlur) {
      onBlur()
    }
  }, [isFocused, onBlur, onFocus, value?.fileStorageId, value])

  useEffect(() => {
    setResume(value)
  }, [value])

  useEffect(() => {
    return () => {
      if (uploadSubscription.current && !uploadSubscription.current.closed) {
        uploadSubscription.current.unsubscribe()
      }

      if (deleteSubscription.current && !deleteSubscription.current.closed) {
        deleteSubscription.current.unsubscribe()
      }
    }
  }, [])

  return (
    <>
      {resume ? (
        <>
          <Grid
            item
            container
            wrap="nowrap"
            columnGap={1}
            alignItems="center"
            sx={{
              p: 1,
              backgroundColor: `${
                downloadResume || viewResume
                  ? 'action.hover'
                  : 'background.default'
              }`,
              ...fileStyles,
            }}
          >
            <Grid item>
              <FilePresentIcon color="primary" fontSize="large" />
            </Grid>

            <Grid container item direction="column" justifyContent="center">
              <Grid container item sx={{ paddingTop: 0 }}>
                <Grid
                  item
                  container
                  component={Stack}
                  divider={<Dot />}
                  alignItems="center"
                >
                  {fileNameAndExtension && (
                    <Grid
                      item
                      xs="auto"
                      sx={{ maxWidth: '80% !important', display: 'grid' }}
                      alignItems="center"
                    >
                      <Typography noWrap variant="body2" color="text.secondary">
                        {fileNameAndExtension}
                      </Typography>
                    </Grid>
                  )}
                  {fileSize ? (
                    <Grid item xs="auto" alignItems="center">
                      <Typography
                        noWrap
                        display="inline"
                        variant="body2"
                        color="text.secondary"
                      >
                        {fileSize} MB
                      </Typography>
                    </Grid>
                  ) : null}

                  {downloadResume ? (
                    <Grid item xs="auto" alignItems="center">
                      <Button
                        variant="text"
                        sx={{ p: 0 }}
                        onClick={handleDownload}
                        aria-label={t(
                          'organisms.resumeUploader.downloadResume'
                        )}
                        id={`cta-download-resume-${
                          resume.fileStorageId || resume.name
                        }`}
                      >
                        {t('organisms.resumeUploader.downloadResume')}
                      </Button>
                    </Grid>
                  ) : null}

                  {viewResume && resume.fileStorageId ? (
                    <Grid item xs="auto" alignItems="center">
                      <Button variant="text" size="small" onClick={viewResume}>
                        {viewBtnText ||
                          t('organisms.resumeUploader.viewResume')}
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              {!value?.fileStorageId && (
                <Grid item>
                  <LinearProgress variant="determinate" value={progress} />
                </Grid>
              )}
            </Grid>

            <Grid item>
              {value?.fileStorageId && deleteResume && (
                <IconButton
                  disabled={isDeleting || disabled}
                  sx={{
                    p: 0,
                    color: 'theme.palette.action.active',
                  }}
                  aria-label={t('common.aria.deleteResume')}
                  onClick={handleDelete}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {!value?.fileStorageId && (
                <IconButton
                  sx={{
                    p: 0,
                    color: 'theme.palette.action.active',
                  }}
                  aria-label={t('common.aria.close')}
                  onClick={handleDelete}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>

          {downloadResume && value?.fileStorageId ? (
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent={disabled ? 'end' : 'space-between'}
              alignItems="center"
              sx={{ marginTop: 0.875 }}
              spacing={1}
            >
              <Button
                component="label"
                disableRipple
                sx={{
                  padding: 0,
                  textTransform: 'none',
                  fontWeight: '400',
                  textDecoration: 'underline',
                  '&:hover': {
                    background: 'none',
                  },
                  display: disabled ? 'none' : 'inline-flex',
                }}
              >
                {t('organisms.resumeUploader.updateResume')}
                <input {...getInputProps()} />
              </Button>
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  display: 'flex',
                }}
              >
                <AccessTimeIcon fontSize="inherit" sx={{ mt: 0.4, mr: 0.4 }} />
                {t('organisms.resumeUploader.lastUpdated')}
                &nbsp;
                <DateFormatter
                  date={resume.uploadedDatetime}
                  dateFormat={DateValueFormat.DayMonthYearShort}
                />
              </Typography>
            </Stack>
          ) : null}
        </>
      ) : (
        <>
          {children ? (
            <CustomUploader
              {...getRootProps({
                isFocused,
                isDragAccept,
                isDragReject:
                  error || displaySizeError ? !value?.fileStorageId : false,
                isGorillaTheme,
              })}
            >
              <input {...getInputProps()} />
              {children}
            </CustomUploader>
          ) : (
            <>
              <Dropzone
                {...getRootProps({
                  dropzoneStyles,
                  isDragAccept,
                  isFocused: error || displaySizeError ? false : isFocused,
                  isDragReject:
                    error || displaySizeError ? !value?.fileStorageId : false,
                  isDisabled: disabled,
                  isGorillaTheme,
                })}
              >
                <input {...getInputProps()} />
                <Grid
                  container
                  rowGap={1}
                  columnGap={2}
                  direction={direction}
                  alignItems="center"
                >
                  {variant === VariantTypes.Regular && (
                    <Grid item xs="auto">
                      <CloudUploadIcon
                        color="primary"
                        fontSize="large"
                        sx={{ color: disabled ? '#DCDCDC' : '' }}
                      />
                    </Grid>
                  )}

                  <Grid item xs sx={{ textAlign: 'center' }}>
                    <Typography
                      variant="body2Bold"
                      sx={{ opacity: disabled ? 0.5 : 1 }}
                    >
                      {t('organisms.resumeUploader.label')}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {isPdfAllowed
                        ? t('organisms.resumeUploader.sublabel', {
                            fileSize: size,
                          })
                        : t('organisms.resumeUploader.sublabel2', {
                            fileSize: size,
                          })}
                    </Typography>
                    {preferWordDoc && (
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        gap={0.5}
                      >
                        <ErrorOutlineIcon
                          sx={{
                            color: 'text.secondary',
                            width: 18,
                            height: 18,
                          }}
                        />
                        <Typography color="text.secondary" variant="body2">
                          {t('organisms.resumeUploader.preferWordDoc')}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={disabled}
                    >
                      {t('organisms.resumeUploader.uploadResume')}
                    </Button>
                  </Grid>
                </Grid>
              </Dropzone>
              {displaySizeError && (
                <FormHelperText
                  sx={{ color: 'error.main', my: '12px', marginLeft: 0 }}
                >
                  {t('organisms.resumeUploader.alert.sizeErrorInput', {
                    fileSize: MAX_FILE_UPLOAD_SIZE / MAX_FILE_UPLOAD_UNIT,
                  })}
                </FormHelperText>
              )}
            </>
          )}
        </>
      )}
      <ConfirmationDialog
        title=""
        content={t('organisms.resumeUploader.alert.deletePermission')}
        cancelBtnText={t('organisms.resumeUploader.alert.cancelBtn')}
        okBtnText={t('common.btn.delete')}
        open={confirmation}
        handleClose={handleConfirmation}
      />
    </>
  )
}
