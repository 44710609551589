import React, { FC } from 'react'
import { TableRow, TableCell, Typography } from '@mui/material'
import { TableRowSpacer } from '@procom-labs/organisms'

interface ILeaderBoardListItem {
  submitter: string
  vmsEvents: number
  emailEvents: number
  portalEvents: number
}

export const LeaderBoardListItem: FC<ILeaderBoardListItem> = ({
  submitter,
  vmsEvents,
  emailEvents,
  portalEvents,
}) => {
  return (
    <>
      <TableRow key={submitter}>
        <TableCell>
          <Typography variant="body1">{submitter}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">{vmsEvents}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">{emailEvents}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body1">{portalEvents}</Typography>
        </TableCell>
      </TableRow>
      <TableRowSpacer sx={{ height: 20 }} />
    </>
  )
}
