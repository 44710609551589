import { ITimeSlot } from './interfaces'

export const timeSlots30: ITimeSlot[] = [
  { id: 1, fromTime: '8:00 AM', toTime: '8:30 AM' },
  { id: 2, fromTime: '8:30 AM', toTime: '9:00 AM' },
  { id: 3, fromTime: '9:00 AM', toTime: '9:30 AM' },
  { id: 4, fromTime: '9:30 AM', toTime: '10:00 AM' },
  { id: 5, fromTime: '10:00 AM', toTime: '10:30 AM' },
  { id: 6, fromTime: '10:30 AM', toTime: '11:00 AM' },
  { id: 7, fromTime: '11:00 AM', toTime: '11:30 AM' },
  { id: 8, fromTime: '11:30 AM', toTime: '12:00 PM' },
  { id: 9, fromTime: '12:00 PM', toTime: '12:30 PM' },
  { id: 10, fromTime: '12:30 PM', toTime: '1:00 PM' },
  { id: 11, fromTime: '1:00 PM', toTime: '1:30 PM' },
  { id: 12, fromTime: '1:30 PM', toTime: '2:00 PM' },
  { id: 13, fromTime: '2:00 PM', toTime: '2:30 PM' },
  { id: 14, fromTime: '2:30 PM', toTime: '3:00 PM' },
  { id: 15, fromTime: '3:00 PM', toTime: '3:30 PM' },
  { id: 16, fromTime: '3:30 PM', toTime: '4:00 PM' },
  { id: 17, fromTime: '4:00 PM', toTime: '4:30 PM' },
  { id: 18, fromTime: '4:30 PM', toTime: '5:00 PM' },
  { id: 19, fromTime: '5:00 PM', toTime: '5:30 PM' },
  { id: 20, fromTime: '5:30 PM', toTime: '6:00 PM' },
  { id: 21, fromTime: '6:00 PM', toTime: '6:30 PM' },
  { id: 22, fromTime: '6:30 PM', toTime: '7:00 PM' },
  { id: 23, fromTime: '7:00 PM', toTime: '7:30 PM' },
  { id: 24, fromTime: '7:30 PM', toTime: '8:00 PM' },
]

export const timeSlots60: ITimeSlot[] = [
  { id: 1, fromTime: '8:00 AM', toTime: '9:00 AM' },
  { id: 2, fromTime: '9:00 AM', toTime: '10:00 AM' },
  { id: 3, fromTime: '10:00 AM', toTime: '11:00 AM' },
  { id: 4, fromTime: '11:00 AM', toTime: '12:00 PM' },
  { id: 5, fromTime: '12:00 PM', toTime: '1:00 PM' },
  { id: 6, fromTime: '1:00 PM', toTime: '2:00 PM' },
  { id: 7, fromTime: '2:00 PM', toTime: '3:00 PM' },
  { id: 8, fromTime: '3:00 PM', toTime: '4:00 PM' },
  { id: 9, fromTime: '4:00 PM', toTime: '5:00 PM' },
  { id: 10, fromTime: '5:00 PM', toTime: '6:00 PM' },
  { id: 11, fromTime: '6:00 PM', toTime: '7:00 PM' },
  { id: 12, fromTime: '7:00 PM', toTime: '8:00 PM' },
]
