import { ReactElement } from 'react'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'

import { useHookFieldError } from '../../hooks/use-hook-field-error'
import {
  LocationAutocomplete,
  LocationAutocompleteProps,
} from './location-autocomplete'

interface LocationAutocompleteHookProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Partial<LocationAutocompleteProps> {
  control: Control<TFieldValues>
  name: TName
  apiKey: string
}
export const LocationAutocompleteHook = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  ...restProps
}: LocationAutocompleteHookProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { ref, ...restField },
    fieldState,
  } = useController({
    name,
    control,
  })
  const errText = useHookFieldError(fieldState, restProps.label)
  return (
    <LocationAutocomplete
      {...restProps}
      {...restField}
      error={!!(fieldState.invalid && errText)}
      helperText={errText}
      inputRef={ref}
    />
  )
}
