export type DateRangeFilter = {
  startDate?: string
  endDate?: string
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export interface ColumnDescriptor {
  field: string
  headerName: string
  minWidth?: number
  maxWidth?: number
  sortable?: boolean
}

export type DragStatus = {
  isFocused: boolean
  isDragAccept: boolean
  isDragReject: boolean
}

export enum LanguageCode {
  English = 'en-CA',
  French = 'fr-CA',
}

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K]
}
