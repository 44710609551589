import React, { useCallback } from 'react'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Drawer, Stack, Typography } from '@mui/material'
import { TipsSuggestionsCard } from '@procom-labs/molecules'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import DownloadIcon from '@mui/icons-material/Download'
import ShareIcon from '@mui/icons-material/Share'

const tipsAndSuggestions = [
  "**Objective**: Reframe the objective to specifically emphasize Mat's passion and expertise in developing sophisticated machine learning models, and his aspiration to push the boundaries of AI technology.",
  '**Education**:\n' +
    '   - Mention any relevant coursework or projects related to math, probability, statistics, and algorithms.\n' +
    '   - Highlight if Mat had specialized modules that focused on data structures, data modeling, and software architecture.',
  '**Skills**:\n' +
    '   - Add “data modeling” and “software architecture” to the skills section to directly address the job requirements.\n' +
    "   - Specify the languages he can write robust code in. If he's proficient in R, ensure it's listed.\n" +
    '   - While TensorFlow and PyTorch are mentioned, consider adding Keras and scikit-learn to further resonate with the job description.',
  '**Professional Experience**:\n' +
    '   - In the roles listed, emphasize projects where Mat transformed data science prototypes, signaling his practical experience.\n' +
    '   - Highlight any instances where he was actively involved in selecting datasets and data representation methods.\n' +
    '   - Detail specific statistical analyses and fine-tuning methodologies employed in previous roles.\n' +
    '   - If there were occasions where Mat extended ML libraries or frameworks, those should be clearly stated.',
  '**Achievements**: Include any accolades or acknowledgments received for successfully implementing ML algorithms or developing cutting-edge AI products.',
  '**Certifications**:\n' +
    "   - Given the mention of a Master's degree as a plus, if Mat has any additional postgraduate qualifications, even if they're short courses or certifications, they should be prominently displayed.",
  "**Languages**: Since the job description doesn't emphasize language requirements, consider moving the languages section to the end of the resume or removing it if you need space for more relevant content.",
  '**Communication & Teamwork**: In the professional experience section, highlight instances where Mat worked collaboratively with a team, showcasing his team spirit and communication skills.',
  '**Problem-solving Skills**: Emphasize projects or challenges where Mat displayed outstanding analytical and problem-solving skills, highlighting successful outcomes.',
  '**Continuous Learning**: Include any workshops, seminars, or conferences that Mat attended (even as an attendee) to demonstrate his commitment to staying updated in the field.',
]
export const SubmissionPrepTipsDrawer: React.FC<{
  open: boolean
  setOpen: (value: boolean) => void
}> = ({ open, setOpen }) => {
  const { t } = useTranslation('main')
  const theme = useTheme()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      elevation={1}
      hideBackdrop
      PaperProps={{
        style: {
          position: 'absolute',
          left: '-40px',
          width: '52%',
          height: '106%',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          backgroundColor: theme.palette.grey['50'],
        },
      }}
      ModalProps={{
        style: { position: 'absolute' },
      }}
      onClose={handleClose}
    >
      <Button
        sx={{ alignSelf: 'end', mr: '12px' }}
        startIcon={<CloseIcon />}
        onClick={handleClose}
      >
        {t('common.btn.close')}
      </Button>
      <Box
        height={950}
        pr={1.5}
        pl={1.5}
        sx={{
          overflow: 'auto',
          pr: 1.5,
        }}
        width="100%"
      >
        <Typography px={2} py={1} fontWeight={500}>
          {t('candidatePreb.tipsAndSuggestions')}
        </Typography>

        {tipsAndSuggestions.map((tip, index) => (
          <TipsSuggestionsCard
            key={`${tip}`}
            title={`Tip ${index + 1}`}
            description={tip}
          />
        ))}
      </Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: 'end', mr: '12px' }}
      >
        <Button
          id="cta-resume-download"
          variant="text"
          size="small"
          startIcon={<ShareIcon />}
        >
          {t('common.btn.share')}
        </Button>
        <Button
          id="cta-resume-download"
          variant="text"
          size="small"
          startIcon={<DownloadIcon />}
        >
          {t('common.btn.download')}
        </Button>
      </Stack>
    </Drawer>
  )
}
