import { FC } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import i18n from '@submission-portal/i18n'

import { CandidateOnePagerView } from './candidate-one-pager-view'

export const CandidateOnePagerPoc: FC = () => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={i18n.language || 'en'}
    >
      <CandidateOnePagerView />
    </LocalizationProvider>
  )
}
