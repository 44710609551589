// Made specifically to reduce cognitive complexity
export const conditionHandler = <T, D>(
  condition: unknown,
  ifValue: T,
  elseValue: D
): D | T => {
  if (condition) {
    return ifValue
  }
  return elseValue
}
