import React, {
  forwardRef,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { TFuncKey, useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'
import { Subject } from 'rxjs'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PaymentsIcon from '@mui/icons-material/Payments'
import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CapsuleButton, RichTextEditor } from '@procom-labs/atoms'
import {
  CandidateCardsStatusList,
  CandidateStatusType,
  CandidateTabs,
  EmploymentTypes,
  formatNumberToCurrency,
  getInitials,
  getRatePeriodLabelKey,
  ICandidate,
  IEntityType,
  ITrackData,
  JobAiService,
  TrackingEvents,
  useFormatDateAvailable,
  useStatusDate,
  useSubscriptionRef,
} from '@procom-labs/common'

import { AiSummaryGenerator } from '../ai-summary-generator/ai-summary-generator'
import { CandidateActionsMenu } from '../candidate-actions-menu'
import { CandidateDetailsTabs } from './candidate-details-tabs'
import {
  CandidateAvatar,
  ContainerGrid,
  ScrollableBox,
} from './styled-components'

type CandidateDetailProps = {
  avatarColor: string
  data: ICandidate
  handleCandidateRejected?: () => void
  handleCandidateRequestInterview?: () => void
  handleCandidateExtendOffer?: () => void
  handleContactAm?: () => void
  disableButtons?: boolean
  onePagerTab?: ReactElement
  notesTab?: ReactElement
  timelineTab?: ReactElement
  resumeTab?: ReactElement
  additionalDocumentsTab?: ReactElement
  candidateTabDefault: CandidateTabs | undefined
  handleOpenNotePrompt?: () => void
  unsavedNote?: () => boolean
  AccountManager?: ReactElement
  jobAiService: JobAiService
  environment?: any
  handleGenerateCandidateHighlights?: (preferredLanguage?: string) => void
  start?: Subject<string>
  pause?: Subject<string>
  isSubmissionPortal?: boolean
  handleResumeViewEvent?: () => void
  handleSetActiveTab?: (newVal: number) => void
  hasContractorProfileId?: boolean
  isModalProfileOpened?: boolean
  handleAddCandidateToJob?: () => void
  handleAddCandidateToPayroll?: () => void
  handleAddCandidateToTalentList?: () => void
}

export const CandidateDetails = memo(
  forwardRef<HTMLDivElement, CandidateDetailProps>(
    (
      {
        avatarColor,
        data,
        handleCandidateRejected,
        handleCandidateRequestInterview,
        handleCandidateExtendOffer,
        handleContactAm,
        disableButtons = false,
        onePagerTab,
        notesTab,
        timelineTab,
        additionalDocumentsTab,
        resumeTab,
        candidateTabDefault = CandidateTabs.resume,
        handleOpenNotePrompt,
        unsavedNote,
        AccountManager,
        jobAiService,
        environment,
        handleGenerateCandidateHighlights,
        start,
        pause,
        isSubmissionPortal = false,
        handleResumeViewEvent,
        handleSetActiveTab,
        hasContractorProfileId,
        isModalProfileOpened,
        handleAddCandidateToJob,
        handleAddCandidateToPayroll,
        handleAddCandidateToTalentList,
      },
      ref
    ) => {
      const { t, i18n } = useTranslation('main')
      const theme = useTheme()
      const candidateStreamRef = useSubscriptionRef()
      const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('sm'))

      const { Track } = useTracking<ITrackData>({
        entityType: IEntityType.JobSubmission,
        entityId: data.jobSubmissionId,
      })

      const dateAvailable = useFormatDateAvailable(
        data.dateAvailable,
        data.dateAvailableType,
        data.dateAvailableUserInput
      )

      const [activeTab, setActiveTab] = React.useState(candidateTabDefault)
      const hasOnePagerTabRef = useRef<boolean>(!!onePagerTab)

      const initials: string = useMemo(() => {
        return `${getInitials(data.firstName)}${getInitials(data.lastName)}`
      }, [data.firstName, data.lastName])

      const isAvailabilitySent = useMemo(
        () =>
          data.interviewRequest &&
          data.interviewRequest.interviewDateTimes.length > 0,
        [data.interviewRequest]
      )

      const firstCta: { label: string; onClick?: () => void } = useMemo(() => {
        switch (data.jobSubmissionStatus) {
          case CandidateStatusType.Interview:
            return {
              label: t('organisms.candidateDetails.btn.offer'),
              onClick: handleCandidateExtendOffer,
            }
          default:
            return {
              label: t('organisms.candidateDetails.btn.interview'),
              onClick: handleCandidateRequestInterview,
            }
        }
      }, [
        data.jobSubmissionStatus,
        handleCandidateRequestInterview,
        handleCandidateExtendOffer,
        t,
      ])

      const secondCta: { label: string; onClick?: () => void } = useMemo(() => {
        switch (data.jobSubmissionStatus) {
          case CandidateStatusType.Rejected:
            return {
              label: t('organisms.candidateDetails.btn.offer'),
              onClick: handleCandidateExtendOffer,
            }
          default:
            return {
              label: t('organisms.candidateDetails.btn.reject'),
              onClick: handleCandidateRejected,
            }
        }
      }, [
        data.jobSubmissionStatus,
        handleCandidateExtendOffer,
        handleCandidateRejected,
        t,
      ])

      const showBillRate = useMemo(
        () =>
          !!(
            data.billRate &&
            [
              EmploymentTypes.Contract.toLowerCase(),
              EmploymentTypes.ContractToHire.toLowerCase(),
            ].includes(data.employmentType.toLowerCase()) &&
            data.showBillRate
          ),
        [data]
      )
      const showSalary = useMemo(
        () =>
          !!(
            data.salary &&
            data.employmentType.toLowerCase() ===
              EmploymentTypes.Permanent.toLowerCase() &&
            data.showBillRate
          ),
        [data]
      )

      const statusDate = useStatusDate(data)
      const [statusTransKey, setStatusTransKey] = useState<TFuncKey | null>(
        null
      )
      const hasNewDocument = data?.additionalDocuments?.find(
        (document) => !document.isViewed
      )
      const noteTabIndex = onePagerTab ? 3 : 2

      const handleChangeTab = useCallback(
        (event: React.SyntheticEvent, newValue: number): void => {
          const triggerProfileView = onePagerTab
            ? activeTab !== 0 &&
              activeTab !== 1 &&
              (newValue === 0 || newValue === 1)
            : newValue === 0
          if (triggerProfileView) {
            handleResumeViewEvent?.()
          }

          const shouldSetTab =
            activeTab === noteTabIndex && unsavedNote && handleOpenNotePrompt
              ? unsavedNote()
                ? handleOpenNotePrompt()
                : true
              : true

          if (shouldSetTab) {
            setActiveTab(newValue)
            handleSetActiveTab?.(newValue)
          }
        },
        [
          onePagerTab,
          noteTabIndex,
          unsavedNote,
          handleOpenNotePrompt,
          handleResumeViewEvent,
          handleSetActiveTab,
          activeTab,
        ]
      )

      const handleOpenNotes = useCallback(
        (event: React.SyntheticEvent) => {
          handleChangeTab(event, noteTabIndex)
        },
        [handleChangeTab, noteTabIndex]
      )

      useEffect(() => {
        // We account for the tab index difference whenever the OnePager tab is added or removed
        if (!hasOnePagerTabRef.current && onePagerTab && activeTab !== 0) {
          setActiveTab(activeTab + 1)
        } else if (
          hasOnePagerTabRef.current &&
          !onePagerTab &&
          activeTab !== 0
        ) {
          setActiveTab(activeTab - 1)
        }
        hasOnePagerTabRef.current = !!onePagerTab
      }, [onePagerTab, activeTab])

      useEffect(() => {
        const transKey = CandidateCardsStatusList.find(
          (card) => card.submissionStatus === data.jobSubmissionStatus
        )?.transKey
        if (transKey) {
          setStatusTransKey(transKey)
        }
        return () => {
          jobAiService.setLoadingState(false)
        }
      }, [data.jobSubmissionStatus, jobAiService])

      useEffect(() => {
        if (!isSubmissionPortal) {
          candidateStreamRef.current = jobAiService
            .getCandidateHighlightsStream(50, start, pause)
            .subscribe()
        }
      }, [pause, candidateStreamRef, jobAiService, start, isSubmissionPortal])

      useEffect(() => {
        if (data?.highlights) {
          jobAiService.setCandidateHighlightStream(data?.highlights)
        }
      }, [data?.highlights, jobAiService])

      useEffect(() => {
        if (candidateTabDefault !== undefined) {
          setActiveTab(candidateTabDefault)
          handleSetActiveTab?.(candidateTabDefault)
        }
      }, [candidateTabDefault, handleSetActiveTab])

      return (
        <Track>
          <Grid
            container
            sx={{
              height: '100%',
              columnGap: 2.5,
              [theme.breakpoints.down('lg')]: { mt: theme.spacing(1) },
              [theme.breakpoints.up('lg')]: { flexWrap: 'nowrap' },
            }}
          >
            <ContainerGrid
              item
              xs={12}
              lg={3.96}
              ref={ref}
              sx={{
                [theme.breakpoints.down('md')]: { padding: '20px' },
              }}
            >
              <ScrollableBox>
                <Grid container rowSpacing={3}>
                  <Grid item container>
                    <Grid item mr={{ xs: 2, lg: 3 }}>
                      <CandidateAvatar
                        sx={{
                          backgroundColor: `#${avatarColor}`,
                        }}
                      >
                        {initials}
                      </CandidateAvatar>
                    </Grid>
                    <Grid item>
                      <Stack spacing={1}>
                        <Typography variant="h6Bold800" component="h2">
                          {`${data.firstName} ${data.lastName}`}
                        </Typography>
                        {!isSubmissionPortal && (
                          <Typography
                            variant="subtitle1"
                            component="h5"
                            sx={{ color: 'text.disabled' }}
                          >
                            {statusTransKey && t(statusTransKey)}
                            &nbsp;
                            {statusDate}
                          </Typography>
                        )}
                        {/* Badge */}
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CandidateActionsMenu
                        handleContactAM={handleContactAm}
                        handleCandidateRequestInterview={
                          handleCandidateRequestInterview
                        }
                        handleAddInterviewNotes={handleOpenNotes}
                        handleCandidateRejected={handleCandidateRejected}
                        handleOfferExtended={handleCandidateExtendOffer}
                        zIndexIconButton={2}
                        disableButtons={disableButtons}
                        isAvailabilitySent={isAvailabilitySent}
                        jobSubmissionStatus={data.jobSubmissionStatus}
                        isSubmissionPortal={isSubmissionPortal}
                        isModalProfileOpened={isModalProfileOpened}
                        handleAddCandidateToJob={handleAddCandidateToJob}
                        handleAddCandidateToPayroll={
                          handleAddCandidateToPayroll
                        }
                        handleAddCandidateToTalentList={
                          handleAddCandidateToTalentList
                        }
                        hasContractorProfileId={hasContractorProfileId}
                      />
                    </Grid>
                  </Grid>

                  {handleContactAm && AccountManager ? (
                    <Grid item xs={12}>
                      {AccountManager}
                    </Grid>
                  ) : null}

                  <Grid item xs={12} container spacing={2}>
                    {isMobileOrTab && (
                      <Grid item xs={12}>
                        <CapsuleButton
                          color="secondary"
                          variant="contained"
                          id="btn-download-resume-link"
                          startIcon={<ArrowDownwardIcon />}
                          onClick={() => {
                            const res = document.getElementById(
                              'btn-download-resume'
                            )
                            if (res) res.click()
                          }}
                          sx={{
                            marginBottom: 1,
                            zIndex: 999,
                            width: '100%',
                          }}
                        >
                          {t('organisms.candidateDetails.downloadResume')}
                        </CapsuleButton>
                      </Grid>
                    )}
                    <Grid item xs={12} md={4.5} lg={5.5}>
                      <Button
                        fullWidth
                        size="large"
                        disabled={disableButtons}
                        variant="outlined"
                        onClick={firstCta.onClick}
                      >
                        {firstCta.label}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={4.5} lg={5.5}>
                      <Button
                        fullWidth
                        size="large"
                        disabled={disableButtons}
                        variant="outlined"
                        onClick={secondCta.onClick}
                      >
                        {secondCta.label}
                      </Button>
                    </Grid>
                  </Grid>

                  {data.billRate ||
                  data.salary ||
                  data.payRate ||
                  data.jobMarginPercentage ||
                  data.employeeType ||
                  dateAvailable ||
                  (data.city && data.state) ? (
                    <Grid item xs={12} container rowSpacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          {t('organisms.candidateDetails.details')}
                        </Typography>
                      </Grid>
                      {dateAvailable ? (
                        <Grid
                          item
                          xs={12}
                          container
                          columnGap={1.75}
                          alignItems="center"
                          flexWrap="nowrap"
                        >
                          <EventAvailableIcon fontSize="small" />
                          <Typography variant="subtitle1Bold" component="span">
                            {t('common.candidates.details.availability')}:
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {dateAvailable}
                          </Typography>
                        </Grid>
                      ) : null}

                      {showBillRate && (
                        <Grid
                          item
                          xs={12}
                          container
                          columnGap={1.75}
                          alignItems="center"
                          flexWrap="nowrap"
                        >
                          <PaymentsIcon fontSize="small" />
                          <Typography variant="subtitle1Bold" component="span">
                            {t('common.candidates.details.billRate')}:
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {formatNumberToCurrency(
                              i18n.language,
                              data.currency,
                              data.billRate as number
                            )}
                            /{t(getRatePeriodLabelKey(data.ratePeriodType))}{' '}
                            {data.currency && <>({data.currency})</>}
                          </Typography>
                        </Grid>
                      )}

                      {showSalary && (
                        <Grid
                          item
                          xs={12}
                          container
                          columnGap={1.75}
                          alignItems="center"
                          flexWrap="nowrap"
                        >
                          <PaymentsIcon fontSize="small" />
                          <Typography variant="subtitle1Bold" component="span">
                            {t('common.candidates.details.salary')}:
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {formatNumberToCurrency(
                              i18n.language,
                              data.currency,
                              data.salary as number
                            )}
                            /{t('common.compensation.year')}{' '}
                            {data.currency && <>({data.currency})</>}
                          </Typography>
                        </Grid>
                      )}
                      {/* Temporarily removed because of issues from BH. See 15076 story for details */}
                      {/* {data.payRate ? (
                    <ListItem disableGutters>
                      <ListItemIcon>
                        <PaymentsIcon sx={{ fontSize: 20, color: theme.palette.secondary.dark, }} />
                      </ListItemIcon>
                      <Typography
                        variant="subtitle1Bold"
                        component="span"
                        sx={{ mr: 1 }}
                      >
                        Pay Rate:
                      </Typography>
                      <Typography variant="subtitle1" component="span">
                        ${data.payRate}/hr (CAD)
                      </Typography>
                    </ListItem>
                  ) : null}

                  {data.jobMarginPercentage ? (
                    <ListItem disableGutters>
                      <ListItemIcon>
                        <ArrowCircleUpIcon sx={{ fontSize: 20, color: theme.palette.secondary.dark, }} />
                      </ListItemIcon>
                      <Typography
                        variant="subtitle1Bold"
                        component="span"
                        sx={{ mr: 1 }}
                      >
                        Markup:
                      </Typography>
                      <Typography variant="subtitle1" component="span">
                        {data.jobMarginPercentage}%
                      </Typography>
                    </ListItem>
                  ) : null} */}

                      {/* {data.employeeType ? ( */}
                      {/*   <Grid */}
                      {/*     item */}
                      {/*     xs={12} */}
                      {/*     container */}
                      {/*     columnGap={1.75} */}
                      {/*     alignItems="center" */}
                      {/*     flexWrap="nowrap" */}
                      {/*   > */}
                      {/*     <HowToRegIcon fontSize="small" /> */}
                      {/*     <Typography variant="subtitle1Bold" component="span"> */}
                      {/*       {t('common.candidates.details.status')}: */}
                      {/*     </Typography> */}
                      {/*     <Typography variant="subtitle1" component="span"> */}
                      {/*       {data.employeeType} */}
                      {/*     </Typography> */}
                      {/*   </Grid> */}
                      {/* ) : null} */}

                      {data.city && data.state ? (
                        <Grid
                          item
                          xs={12}
                          container
                          columnGap={1.75}
                          alignItems="center"
                          flexWrap="nowrap"
                        >
                          <LocationOnIcon fontSize="small" />
                          <Typography variant="subtitle1Bold" component="span">
                            {t('common.candidates.details.candidateLocation')}:
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {data.city}, {data.state}
                          </Typography>
                        </Grid>
                      ) : null}

                      {data?.workplaceType.length ? (
                        <Grid
                          item
                          xs={12}
                          container
                          columnGap={1.75}
                          alignItems="center"
                          flexWrap="nowrap"
                        >
                          <HomeWorkIcon fontSize="small" />
                          <Typography variant="subtitle1Bold" component="span">
                            {t(
                              'organisms.candidateOnePager.candidate.fields.workplace'
                            )}
                            :
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {data.workplaceType
                              .map(
                                (type) =>
                                  t(
                                    `common.enums.jobSite.${type}` as TFuncKey
                                  ) as string
                              )
                              .join(', ')}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  ) : null}
                  {data.comments ? (
                    <Grid item xs={12} container rowSpacing={0}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h3">
                          {t('organisms.candidateDetails.commentsNew')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <RichTextEditor rawHTML={data.comments} />
                      </Grid>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} container>
                    <AiSummaryGenerator
                      title={t('organisms.summaryGenerator.title2')}
                      description={t('organisms.summaryGenerator.description')}
                      environment={environment}
                      jobAiService={jobAiService}
                      isSubmittedJobCandidateView
                      handleGenerateCandidateSummary={
                        handleGenerateCandidateHighlights
                      }
                      isSubmissionPortal={isSubmissionPortal}
                      isEditable={!disableButtons}
                      hasResume={!disableButtons}
                      trackingEventName={
                        TrackingEvents.AICopilots
                          .CandidateHighlightsClientPortal
                      }
                    />
                  </Grid>
                </Grid>
              </ScrollableBox>
            </ContainerGrid>

            <CandidateDetailsTabs
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
              timelineTab={timelineTab}
              additionalDocumentsTab={additionalDocumentsTab}
              onePagerTab={onePagerTab}
              notesTab={notesTab}
              resumeTab={resumeTab}
              hasNewDocument={!!hasNewDocument}
              disableTabs={isSubmissionPortal}
            />
          </Grid>
        </Track>
      ) as ReactElement
    }
  )
)
