import {
  Button,
  DialogTitle,
  Grid,
  useMediaQuery,
  IconButton,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { GorillaDialog } from '@procom-labs/atoms'

interface ModalDialogProps {
  isModalOpen: boolean
  onClose: () => void
  onSubmit: (data: any) => void
  onClearFilters: () => void
}

export const ModalFilterDialog: React.FC<ModalDialogProps> = ({
  children,
  isModalOpen,
  onClose,
  onSubmit,
  onClearFilters,
}) => {
  const { t } = useTranslation('main')
  const theme = useTheme()
  const mobileView = useMediaQuery(() => theme.breakpoints.down('sm'))

  return (
    <GorillaDialog
      fullScreen={mobileView}
      maxWidth="sm"
      isLightMode
      open={isModalOpen}
      onClose={onClose}
    >
      <DialogTitle textAlign="right">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid
        width={mobileView ? '100%' : 445}
        container
        item
        sx={{
          rowGap: 5,
          alignItems: 'start',
          paddingBottom: '60px',
          paddingX: '40px',
        }}
        component="form"
        onSubmit={onSubmit}
        noValidate
      >
        <Grid container item xs={12} columnGap={2.5} width="100%">
          {children}
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          direction="row"
          columnGap={2.5}
        >
          <Button variant="outlined" onClick={onClearFilters}>
            {t('common.btn.clearAll')}
          </Button>
          <Button type="submit" variant="contained" color="secondary">
            {t('common.btn.apply')}
          </Button>
        </Grid>
      </Grid>
    </GorillaDialog>
  )
}
