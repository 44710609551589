import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDataTimeStrings } from './cell-date-hooks'

export interface CellDateTimePropsInternal {
  value: string | number | null
}

const CellDateTimeInternal: React.FC<CellDateTimePropsInternal> = ({
  value,
}) => {
  const [dateString, timeString] = useDataTimeStrings(value)

  return (
    <Box>
      <Typography noWrap>{dateString}</Typography>
      <Typography noWrap sx={{ color: 'text.secondary' }}>
        {timeString}
      </Typography>
    </Box>
  )
}

export interface CellDateTimeProps {
  value: string | number | null
  unavailableText?: string
}

export const CellDateTime: React.FC<CellDateTimeProps> = ({
  value,
  unavailableText,
}) => {
  const { t } = useTranslation('main')

  if (value === null) {
    return (
      <Box>
        <Typography noWrap>
          {unavailableText ?? `${t('organisms.unavailable')}`}
        </Typography>
      </Box>
    )
  }

  return <CellDateTimeInternal value={value} />
}
