import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { Grid, SxProps, Theme } from '@mui/material'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import {
  DatePicker,
  DatePickerSlotsComponentsProps,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateRangeFilter } from '@procom-labs/common'

interface IDateRangeInput {
  value: DateRangeFilter
  direction?: 'row' | 'column'
  label?: {
    startDate: string
    endDate: string
  }
  onChange: (val: DateRangeFilter) => void
  maxDate?: Date
  slotProps?: DatePickerSlotsComponentsProps<DateTime>
  sx?: SxProps<Theme>
}

interface IDateRange {
  startDate?: DateTime
  endDate?: DateTime
}

export const DateRangeInput: React.FC<IDateRangeInput> = ({
  value,
  direction = 'row',
  label = {
    startDate: 'Start Date',
    endDate: 'End Date',
  },
  onChange,
  maxDate,
  slotProps,
  sx,
}) => {
  const { t, i18n } = useTranslation('main')

  const [dateRange, setDateRange] = useState<IDateRange>({})

  const inputContainerRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const startDateValue = useMemo(
    (): DateTime | null =>
      value.startDate
        ? DateTime.fromISO(value.startDate, { setZone: true })
        : null,
    [value]
  )

  const endDateValue = useMemo(
    (): DateTime | null =>
      value.endDate
        ? DateTime.fromISO(value.endDate, {
            setZone: true,
          })
        : null,
    [value]
  )

  const handleSelect = useCallback(
    (ranges: IDateRange): void => {
      setDateRange((obj: IDateRange) => ({ ...obj, ...ranges }))
      if (ranges.startDate && dateRange.endDate) {
        onChange({
          startDate:
            ranges?.startDate
              ?.setZone('UTC', { keepLocalTime: true })
              .toISO() ?? undefined,
          endDate:
            dateRange?.endDate
              ?.setZone('UTC', { keepLocalTime: true })
              .endOf('day')
              .toISO() ?? undefined,
        })
      } else if (ranges.endDate && dateRange.startDate) {
        onChange({
          startDate:
            dateRange?.startDate
              ?.setZone('UTC', { keepLocalTime: true })
              .toISO() ?? undefined,
          endDate:
            ranges?.endDate
              ?.setZone('UTC', { keepLocalTime: true })
              .endOf('day')
              .toISO() ?? undefined,
        })
      }
    },
    [dateRange?.startDate, dateRange?.endDate, onChange]
  )

  useEffect(() => {
    setDateRange((obj: IDateRange) => ({
      ...obj,
      startDate: startDateValue ?? undefined,
      endDate: endDateValue ?? undefined,
    }))
  }, [startDateValue, endDateValue])

  const startMaxDate = useMemo((): DateTime | undefined => {
    let date

    if (dateRange?.endDate && maxDate) {
      const maxDateToDateTime = DateTime.fromJSDate(maxDate)
      date =
        dateRange.endDate > maxDateToDateTime
          ? maxDateToDateTime
          : dateRange.endDate
    } else if (dateRange?.endDate) {
      date = dateRange?.endDate
    } else if (maxDate) {
      date = DateTime.fromJSDate(maxDate)
    } else {
      date = undefined
    }

    return date
  }, [dateRange.endDate, maxDate])

  return (
    <div ref={inputContainerRef} style={{ width: '100%' }}>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        adapterLocale={i18n.language}
        localeText={{
          cancelButtonLabel: t('common.btn.cancel'),
          okButtonLabel: t('common.btn.ok'),
        }}
      >
        <Grid
          item
          container
          xs={12}
          columnGap={2.5}
          rowGap={2}
          direction={direction}
        >
          <Grid item xs={12} sm>
            <DatePicker
              label={label.startDate}
              value={startDateValue}
              onChange={(date: DateTime | null) => {
                handleSelect({
                  startDate: date ?? undefined,
                })
              }}
              maxDate={startMaxDate}
              slotProps={{
                ...slotProps,
                textField: {
                  ...slotProps?.textField,
                  onKeyDown: (event: any) => {
                    event.preventDefault()
                  },
                },
              }}
              sx={{ width: '100%', ...sx }}
            />
          </Grid>
          <Grid item xs={12} sm>
            <DatePicker
              value={endDateValue}
              label={label.endDate}
              onChange={(date: DateTime | null) => {
                handleSelect({
                  endDate: date ?? undefined,
                })
              }}
              minDate={dateRange?.startDate}
              maxDate={maxDate ? DateTime.fromJSDate(maxDate) : undefined}
              slotProps={{
                ...slotProps,
                textField: {
                  ...slotProps?.textField,
                  onKeyDown: (event: any) => {
                    event.preventDefault()
                  },
                },
              }}
              sx={{ width: '100%', ...sx }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </div>
  )
}
