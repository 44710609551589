import { FC, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { InputGlobalStyles, Preloader } from '@procom-labs/atoms'
import { ServicesProvider, TrackingProvider } from '@procom-labs/common'
import { registerEditorLicense } from '@procom-labs/organisms'

import { muiTheme, services } from '@submission-portal/config'
import { environment } from '@submission-portal/environment'

import 'external-svg-loader'

import { RollbarProvider } from './providers'
import { AppRoutes } from './routes'

import './style/main.scss'

registerEditorLicense(environment.SYNC_FUSION_EDITOR_KEY)

const App: FC<{}> = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={muiTheme}>
      <Suspense fallback={<Preloader center />}>
        <BrowserRouter>
          <CssBaseline />
          {InputGlobalStyles}
          <RollbarProvider>
            <ServicesProvider services={services as any}>
              <TrackingProvider token={environment.MIXPANEL_TOKEN}>
                <AppRoutes />
              </TrackingProvider>
            </ServicesProvider>
          </RollbarProvider>
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  </StyledEngineProvider>
)

export default App
