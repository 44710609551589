import { useCallback } from 'react'
import {
  finalize,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  switchMap,
  tap,
} from 'rxjs'

import {
  DataObjectContent,
  IContractorProfileResume,
} from '@procom-labs/common'

import { uploadDataToResumeFileStorage } from '@submission-portal/lib'
import {
  IResourceStoreState,
  resourceStore,
} from '@submission-portal/stores/resource-store'

export enum ResourceFetchScope {
  AtsCandidate = 0,
  AtsJob = 1,
}

export interface ResourceFetchRequest {
  fileId: string
  convertToPdf: boolean
  scope: ResourceFetchScope
}

const generateCacheKey = (
  fileId: string,
  convertToPdf: boolean,
  scope: ResourceFetchScope
): string => {
  return `${fileId}_${convertToPdf}_${scope}`
}

export type AtsResourcesFetcherReturn = (
  argsArray: ResourceFetchRequest[]
) => Observable<IContractorProfileResume[]>

/**
 * useAtsResourceFetcher is a custom hook that manages the fetching of ATS resources
 * and ensures that redundant fetches are avoided. If a resource fetch is in progress,
 * any subsequent requests for that resource will be registered as 'handlers' that will
 * be notified upon completion of the fetch operation.
 *
 * Once the ATS resources are fetched, they are automatically uploaded to our store,
 * thus obtaining a storage ID for future reference.
 *
 * @param {Function} onFetchFile - A function to fetch the file data which returns an observable.
 * @returns {Function} - Returns a callback function that, when invoked with a 'newSelection',
 *                       will either return the already fetched resource, initiate a new fetch,
 *                       or register a handler to be notified upon completion of an in-progress fetch.
 */
export const useAtsResourcesFetcher = (
  onFetchFile: (args: ResourceFetchRequest) => Observable<DataObjectContent>
): AtsResourcesFetcherReturn => {
  return useCallback(
    (argsArray) => {
      // We're going to return an array of Observables, then merge them using forkJoin
      const observables: Observable<IContractorProfileResume>[] = argsArray.map(
        ({ fileId, convertToPdf, scope }) => {
          const { resumes, pending, pendingHandlers } =
            resourceStore.getStateValue()
          const cacheKey = generateCacheKey(fileId, convertToPdf, scope)

          // If the resource has already been fetched, return it directly.
          const current = resumes[cacheKey]
          if (current) {
            return of(current)
          }

          // If there's already a fetch in progress for this resource, register a new handler.
          if (pending.indexOf(cacheKey) > -1) {
            const subject = new ReplaySubject<IContractorProfileResume>(1) // Set to 1 to remember the last value
            pendingHandlers[cacheKey] = pendingHandlers[cacheKey] || []
            pendingHandlers[cacheKey].push(subject)
            resourceStore.dispatch({ pendingHandlers })

            return subject.asObservable()
          }

          // Initiate a new fetch for the resource.
          resourceStore.dispatch({
            pending: [cacheKey, ...pending],
          })

          return onFetchFile({
            fileId,
            convertToPdf,
            scope,
          }).pipe(
            switchMap((res) => uploadDataToResumeFileStorage(res)),
            tap((resume) => {
              const {
                resumes: nestedResumes,
                pendingHandlers: nestedPendingHandlers,
              } = resourceStore.getStateValue()

              const updatedPendingHandlers = { ...nestedPendingHandlers }

              const update = {
                resumes: {
                  ...nestedResumes,
                  [cacheKey]: resume,
                },
              } as Partial<IResourceStoreState>

              if (updatedPendingHandlers[cacheKey]) {
                updatedPendingHandlers[cacheKey].forEach((handler) => {
                  handler.next(resume)
                  handler.complete()
                })
                delete updatedPendingHandlers[cacheKey]

                update.pendingHandlers = updatedPendingHandlers
              }

              resourceStore.dispatch(update)
            }),
            finalize(() => {
              const { pending: nestedPending } = resourceStore.getStateValue()
              const updatedPending = nestedPending.filter((p) => p !== cacheKey)
              resourceStore.dispatch({
                pending: updatedPending,
              })
            })
          )
        }
      )

      // Use forkJoin to wait for all observables to complete, then return the resulting array.
      return forkJoin(observables)
    },
    [onFetchFile]
  )
}
