export enum SignalrEventNames {
  JobDescriptionDelta = 'ai-job-description-delta',
  CandidateHighlightsDelta = 'ai-candidate-highlights-delta',
  EmployerJobDescriptionDelta = 'employer-job-description-delta',
  InterviewPreparationDelta = 'ai-interview-preparation-delta',
  LanguageTranslationDelta = 'ai-language-translation-delta',
  ResumeCopilotDelta = 'ai-resume-copilot-delta',
  BullhornCandidateHighlightsDelta = 'ai-bullhorn-candidate-highlights-delta',
  AiAssistedResumeDelta = 'ai-assisted-resume-delta',
}
