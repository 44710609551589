import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, IconButton } from '@mui/material'
import {
  JobSubmissionAtsStatusLabels,
  useSubjectSelector,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import { CandidatePrepInfo } from '@submission-portal/components/candidate-prep/components/candidate-prep-info'
import { useNavigateToCandidateSubmissions } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-core-hooks'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

export const CandidatePrepEditorInfo: React.FC = () => {
  const { t } = useTranslation('main')
  const { addAlert } = useAlert()

  const { candidate: submission, candidateStatus } = useSubjectSelector(
    candidatesPrepStore,
    ['candidate', 'candidateStatus']
  )

  const handleGoBack = useNavigateToCandidateSubmissions()

  const onChange = useCallback(
    (status) => {
      if (submission) {
        candidatesPrepStore.dispatch({
          candidateStatus: status as JobSubmissionAtsStatusLabels,
        })

        addAlert({
          severity: 'success',
          message: t('common.alert.itemSaved'),
        })
      }
    },
    [submission, addAlert, t]
  )

  if (!submission) {
    return null
  }

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        onClick={handleGoBack}
        edge="start"
        color="primary"
        aria-label={t('common.btn.back')}
      >
        <ArrowBackIcon />
      </IconButton>
      <CandidatePrepInfo
        onChange={onChange}
        record={{
          atsUserId: Number(submission.candidate.atsUserId),
          atsJobSubmissionId: submission.atsJobSubmissionId,
          jobSubmissionId: submission.id,
          atsSubmissionStatus: candidateStatus,
          dateAddedOnAts: submission.dateAddedOnAts,
          firstName: submission.candidate.firstName,
          hiringManagerName: submission.hiringManagerName,
          lastName: submission.candidate.lastName,
        }}
      />
    </Box>
  )
}
