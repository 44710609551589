// Reference Link: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones

export interface ITimeZone {
  value: string
  std: string
  dst: string
}

export const timezones: ITimeZone[] = [
  {
    value: 'Africa/Abidjan',
    std: '+00:00',
    dst: '+00:00',
  },
  {
    value: 'Africa/Bangui',
    std: '+01:00',
    dst: '+01:00',
  },
  {
    value: 'Africa/Blantyre',
    std: '+02:00',
    dst: '+02:00',
  },
  {
    value: 'Africa/Nairobi',
    std: '+03:00',
    dst: '+03:00',
  },
  {
    value: 'America/Adak',
    std: '-10:00',
    dst: '-09:00',
  },
  {
    value: 'America/Anchorage',
    std: '-09:00',
    dst: '-08:00',
  },
  {
    value: 'America/Antigua',
    std: '-04:00',
    dst: '-04:00',
  },
  {
    value: 'America/Araguaina',
    std: '-03:00',
    dst: '-03:00',
  },
  {
    value: 'America/Aruba',
    std: '-04:00',
    dst: '-04:00',
  },
  {
    value: 'America/Asuncion',
    std: '-04:00',
    dst: '-03:00',
  },
  {
    value: 'America/Atikokan',
    std: '-05:00',
    dst: '-05:00',
  },
  {
    value: 'America/Atka',
    std: '-10:00',
    dst: '-09:00',
  },
  {
    value: 'America/Bahia',
    std: '-03:00',
    dst: '-03:00',
  },
  {
    value: 'America/Bahia_Banderas',
    std: '-06:00',
    dst: '-06:00',
  },
  {
    value: 'America/Belize',
    std: '-06:00',
    dst: '-06:00',
  },
  {
    value: 'America/Cambridge_Bay',
    std: '-07:00',
    dst: '-06:00',
  },
  {
    value: 'America/Chicago',
    std: '-06:00',
    dst: '-05:00',
  },
  {
    value: 'America/Costa_Rica',
    std: '-06:00',
    dst: '-06:00',
  },
  {
    value: 'America/Detroit',
    std: '-05:00',
    dst: '-04:00',
  },
  {
    value: 'America/Indianapolis',
    std: '-05:00',
    dst: '-04:00',
  },
  {
    value: 'America/Jamaica',
    std: '-05:00',
    dst: '-05:00',
  },
  {
    value: 'America/Los_Angeles',
    std: '-08:00',
    dst: '-07:00',
  },
  {
    value: 'America/Mexico_City',
    std: '-06:00',
    dst: '-06:00',
  },
  {
    value: 'America/New_York',
    std: '-05:00',
    dst: '-04:00',
  },
  {
    value: 'America/Panama',
    std: '-05:00',
    dst: '-05:00',
  },
  {
    value: 'America/Toronto',
    std: '-05:00',
    dst: '-04:00',
  },
  {
    value: 'America/Montreal',
    std: '-05:00',
    dst: '-04:00',
  },
  {
    value: 'America/Vancouver',
    std: '-08:00',
    dst: '-07:00',
  },
  {
    value: 'America/Winnipeg',
    std: '-06:00',
    dst: '-05:00',
  },
  {
    value: 'America/Yakutat',
    std: '-09:00',
    dst: '-08:00',
  },
  {
    value: 'Antarctica/Casey',
    std: '+08:00',
    dst: '+08:00',
  },
  {
    value: 'Antarctica/South_Pole',
    std: '+12:00',
    dst: '+13:00',
  },
  {
    value: 'Asia/Bangkok',
    std: '+07:00',
    dst: '+07:00',
  },
  {
    value: 'Asia/Calcutta',
    std: '+05:30',
    dst: '+05:30',
  },
  {
    value: 'Asia/Dubai',
    std: '+04:00',
    dst: '+04:00',
  },
  {
    value: 'Asia/Hong_Kong',
    std: '+08:00',
    dst: '+08:00',
  },
  {
    value: 'Australia/Brisbane',
    std: '+10:00',
    dst: '+10:00',
  },
  {
    value: 'Australia/Melbourne',
    std: '+10:00',
    dst: '+11:00',
  },
  {
    value: 'Australia/Sydney',
    std: '+10:00',
    dst: '+11:00',
  },
  {
    value: 'Brazil/East',
    std: '-03:00',
    dst: '-03:00',
  },
  {
    value: 'Brazil/West',
    std: '-04:00',
    dst: '-04:00',
  },
  {
    value: 'Canada/Atlantic',
    std: '-04:00',
    dst: '-03:00',
  },
  {
    value: 'Canada/Central',
    std: '-06:00',
    dst: '-05:00',
  },
  {
    value: 'Canada/Eastern',
    std: '-05:00',
    dst: '-04:00',
  },
  {
    value: 'Canada/Mountain',
    std: '-07:00',
    dst: '-06:00',
  },
  {
    value: 'Canada/Newfoundland',
    std: '-03:00',
    dst: '-02:00',
  },
  {
    value: 'Canada/Pacific',
    std: '-08:00',
    dst: '-07:00',
  },
  {
    value: 'Canada/Saskatchewan',
    std: '-06:00',
    dst: '-06:00',
  },
  {
    value: 'Canada/Yukon',
    std: '-07:00',
    dst: '-07:00',
  },
  {
    value: 'Europe/Berlin',
    std: '+01:00',
    dst: '+02:00',
  },
  {
    value: 'Europe/Budapest',
    std: '+01:00',
    dst: '+02:00',
  },
  {
    value: 'Europe/London',
    std: '+00:00',
    dst: '+01:00',
  },
  {
    value: 'Europe/London',
    std: '+01:00',
    dst: '+02:00',
  },
  {
    value: 'Pacific/Auckland',
    std: '+12:00',
    dst: '+13:00',
  },
  {
    value: 'Pacific/Easter',
    std: '-06:00',
    dst: '-05:00',
  },
  {
    value: 'US/Central',
    std: '-06:00',
    dst: '-05:00',
  },
  {
    value: 'US/Eastern',
    std: '-05:00',
    dst: '-04:00',
  },
  {
    value: 'US/Hawaii',
    std: '-10:00',
    dst: '-10:00',
  },
  {
    value: 'US/Pacific',
    std: '-08:00',
    dst: '-07:00',
  },
]
