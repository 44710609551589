import { BlobTypes } from '../enums/blob'

export const convertBlobtoBase64 = (
  blob: Blob
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export const jsonToBase64 = (jsonObj: Record<string, any>): string => {
  const str = JSON.stringify(jsonObj)
  return btoa(encodeURIComponent(str))
}

export const stringToBase64 = (str: string): string => {
  return btoa(encodeURIComponent(str))
}

export const base64ToJson = (base64Str: string): Record<string, any> => {
  const decodedStr = decodeURIComponent(atob(base64Str))
  return JSON.parse(decodedStr)
}

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result?.toString().split(',')[1] || ''
      resolve(base64String)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const base64ToArrayBuffer = (base64: string): Uint8Array => {
  const binary = atob(base64)
  const array = new Uint8Array(binary.length)

  for (let i = 0; i < binary.length; i += 1) {
    array[i] = binary.charCodeAt(i)
  }

  return array
}

export const arrayToBlob = (
  array: Uint8Array | ArrayBuffer,
  contentType: string
): Blob => {
  return new Blob([array], { type: contentType })
}

export const arrayToBlobUrl = (
  array: Uint8Array | ArrayBuffer,
  contentType = 'application/pdf'
): string => {
  const blob = arrayToBlob(array, contentType)
  return URL.createObjectURL(blob)
}
export const base64ToBlobUrl = (
  base64: string,
  contentType = 'application/pdf'
): string => {
  const array = base64ToArrayBuffer(base64)
  return arrayToBlobUrl(array, contentType)
}

export const getBlobBase64 = async (
  file: Uint8Array | ArrayBuffer | File,
  blobType: BlobTypes = BlobTypes.Image
): Promise<string | ArrayBuffer | null> => {
  const newFile = new Blob([file], { type: blobType })

  return convertBlobtoBase64(newFile)
}

export const getBlobUrl = (
  file: ArrayBuffer | File,
  blobType: BlobTypes = BlobTypes.Image
): string => {
  const newFile = new Blob([file], { type: blobType })
  return URL.createObjectURL(newFile)
}

export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  const bytes = new Uint8Array(buffer)
  const binary = bytes.reduce(
    (acc, byte) => acc + String.fromCharCode(byte),
    ''
  )
  return btoa(binary)
}

export const arrayBufferToString = (buffer: ArrayBuffer): string => {
  const decoder = new TextDecoder('utf-8')
  return decoder.decode(buffer)
}
