import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'
import { arrayToBlobUrl, useSubjectSelector } from '@procom-labs/common'
import {
  PDFViewer,
  useAlert,
  useDefaultErrorHandler,
} from '@procom-labs/molecules'

import { submissionService } from '@submission-portal/services'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

export const SubmissionPrepOriginalResumeTab: FC = () => {
  const { t } = useTranslation('main')

  const { addAlert } = useAlert()
  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const { candidate: submission } = useSubjectSelector(candidatesPrepStore, [
    'candidate',
  ])

  const [blobUrl, setBlobUrl] = useState<string | null>(null)
  const fileStorageId = submission?.candidate?.resume?.fileStorageId

  useEffect(() => {
    if (fileStorageId) {
      submissionService.getResume(fileStorageId).subscribe({
        next: (resume) => {
          setBlobUrl(arrayToBlobUrl(resume))
        },
        error: handleError,
      })
    }
  }, [fileStorageId, handleError])

  return <Box>{blobUrl && <PDFViewer file={blobUrl} />}</Box>
}
