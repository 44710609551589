import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useTranslation } from 'react-i18next'
import { ModalCloseButton } from '@procom-labs/molecules'

export const InterviewSuccessModal: React.FC<{
  isUpdatingRequest: boolean
  open: boolean
  accountManagerName: string
  handleClose: () => void
}> = ({ isUpdatingRequest, open, accountManagerName, handleClose }) => {
  const theme = useTheme()
  const { t } = useTranslation('main')
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ mt: { xs: 6 } }}
      PaperProps={{ sx: { maxWidth: { xs: 'auto', sm: 778 } } }}
    >
      <ModalCloseButton
        handleClose={handleClose}
        id="btn-close-interview-success-modal"
      />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ mx: 3, my: 3 }}
        >
          <CheckCircleOutlineIcon
            sx={{ color: theme.palette.success.main, fontSize: 50 }}
          />
          <Typography
            variant="subtitle1Bold"
            gutterBottom
            component="p"
            sx={{ color: theme.palette.success.dark, fontSize: 20 }}
          >
            {isUpdatingRequest
              ? t('common.candidates.dialog.interview.updatedSuccess')
              : t('common.candidates.dialog.interview.success1')}{' '}
            {accountManagerName}.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            component="p"
            sx={{ color: theme.palette.common.black }}
          >
            {t('common.candidates.dialog.interview.success2')}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          id="cta-interview-success-modal"
          color="secondary"
          variant="contained"
          onClick={handleClose}
          sx={{ px: 4 }}
        >
          {t('common.btn.gotIt')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
