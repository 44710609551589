import Axios from 'axios-observable'
import { Observable, map } from 'rxjs'

export class InterviewCopilotService {
  environment: any

  axios: Axios

  constructor(environment: any, axios: any) {
    this.environment = environment
    this.axios = axios
  }

  sendEmailCopy({
    jobTitle,
    body,
    atsUserId,
  }: {
    jobTitle: string
    body: string
    atsUserId?: string
  }): Observable<void> {
    let url = `${this.environment.JOB_API_URL}/Copilot/`
    if (atsUserId) {
      url += 'ats/email'
    } else {
      url += 'email'
    }
    return this.axios
      .post<void>(url, { jobTitle, body })
      .pipe(map(({ data }) => data))
  }

  downloadQuestionsPDF({
    jobTitle,
    body,
    atsUserId,
    language,
  }: {
    jobTitle: string
    body: string
    atsUserId?: string
    language?: string
  }): Observable<ArrayBuffer> {
    let url = `${this.environment.JOB_API_URL}/Copilot/`
    if (atsUserId) {
      url += `ats/pdf-download`
    } else {
      url += `pdf-download`
    }
    return this.axios
      .post<ArrayBuffer>(
        url,
        { jobTitle, body, language },
        {
          responseType: 'arraybuffer',
        }
      )
      .pipe(map(({ data }) => data))
  }
}
