export const formatNumberToCurrency = (
  lang: string,
  currency: string,
  amount: number
): string => {
  if (currency && lang) {
    try {
      return new Intl.NumberFormat(lang, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
      }).format(amount)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  return amount.toString()
}
