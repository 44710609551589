import { map, Observable } from 'rxjs'

import {
  arrayToBlob,
  AtsFileInfo,
  base64ToArrayBuffer,
  DataObjectContent,
  IContractorProfileResume,
} from '@procom-labs/common'

import { contractorProfileService } from '../services/contractor-profile.service'

export const uploadToResumeFileStorage = (
  content: string,
  contentType: string,
  name: string
): Observable<IContractorProfileResume> => {
  const data = base64ToArrayBuffer(content)

  const blob = arrayToBlob(data, contentType)
  const formData: FormData = new FormData()
  formData.append('ShouldParseResume', 'false')
  formData.append('file', blob, `${name}`)

  return contractorProfileService
    .uploadResume(formData)
    .pipe(map(({ contractorResume: resume }) => resume))
}

export const uploadDataToResumeFileStorage = (
  res: DataObjectContent
): Observable<IContractorProfileResume> => {
  const { content, name, contentType } = res
  return uploadToResumeFileStorage(content, contentType, name)
}

export const doGetAtsFileInfo = (
  fileStorageId: string | undefined,
  entityId: string | undefined,
  fileId: string | undefined,
  isDoc: boolean,
  extension?: string
): AtsFileInfo | undefined => {
  // We prioritize custom file upload over existing selection
  if (fileStorageId) {
    return {
      fileStorageInfo: { fileStorageId },
      extension: isDoc ? 'docx' : 'pdf',
    }
  }

  if (entityId && fileId && extension) {
    return {
      atsFileInfo: {
        entityId: Number(entityId),
        fileId: Number(fileId),
      },
      extension,
    }
  }

  return undefined
}

export const getAtsFileInfo = (
  file: IContractorProfileResume | null,
  entityId: string,
  fileId: string
): AtsFileInfo | undefined => {
  return doGetAtsFileInfo(
    file?.wordFileStorageId ?? file?.fileStorageId,
    entityId,
    fileId,
    !!file?.wordFileStorageId
  )
}
