import React, { useEffect, useState } from 'react'
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { GorillaDialog } from '@procom-labs/atoms'
import { ICandidate, RejectionHeadings } from '@procom-labs/common'
import { ModalCloseButton } from '@procom-labs/molecules'

export type RejectedFormPayload = {
  rejectionHeading: string
  rejectionReason: string
}

export const CandidateRejectedModalContent: React.FC<{
  open: boolean
  handleClose: () => void
  handleRejectCandidate: (
    values: RejectedFormPayload,
    actions?: FormikHelpers<RejectedFormPayload>
  ) => void
  candidate: Partial<ICandidate> | null
}> = ({ open, handleClose, handleRejectCandidate, candidate }) => {
  const { t } = useTranslation('main')
  const [initialFormValues, setInitialFormValues] = useState({
    rejectionHeading: '',
    rejectionReason: '',
  } as RejectedFormPayload)

  useEffect(() => {
    if (candidate) {
      setInitialFormValues({
        rejectionHeading: candidate.rejectionHeading ?? '',
        rejectionReason: candidate.rejectionReason ?? '',
      } as RejectedFormPayload)
    }
  }, [candidate])

  const validationSchema = Yup.object().shape({
    rejectionHeading: Yup.string()
      .required(`${t('common.candidates.dialog.reject.headingLabel')}.`)
      .nullable(),
    rejectionReason: Yup.string()
      .required(t('common.candidates.dialog.reject.requiredError'))
      .nullable(),
  })

  return (
    <GorillaDialog
      isLightMode
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      sx={{ mt: { xs: 6 } }}
      PaperProps={{
        sx: { paddingY: { xs: 3.5, lg: 6.5 }, paddingX: { xs: 2.5, lg: 5 } },
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          handleRejectCandidate(values, actions)
        }}
      >
        {({ isSubmitting, setFieldValue }) => {
          return (
            <Form>
              <DialogTitle sx={{ paddingBottom: 0 }}>
                <Typography variant="h22Bold800" gutterBottom component="p">
                  {t('common.candidates.dialog.reject.prompt')}{' '}
                  {candidate?.name ??
                    `${candidate?.firstName} ${candidate?.lastName}`}
                  .
                </Typography>
                <ModalCloseButton
                  handleClose={handleClose}
                  id="btn-close-candidate-rejected-modal"
                />
              </DialogTitle>
              <DialogContent sx={{ mt: { xs: 1, md: 5 } }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{ pt: 0.5 }}
                >
                  <Field name="rejectionHeading">
                    {({ field, meta }: FieldProps) => (
                      <FormControl
                        fullWidth
                        error={meta.touched && !!meta.error}
                      >
                        <InputLabel id="rejectionHeadingLabel">
                          {t('common.candidates.dialog.reject.headingLabel')}
                        </InputLabel>
                        <Select
                          {...field}
                          label={t(
                            'common.candidates.dialog.reject.headingLabel'
                          )}
                          onChange={(event) =>
                            setFieldValue(
                              'rejectionHeading',
                              event.target.value
                            )
                          }
                        >
                          {RejectionHeadings.map((item) => (
                            <MenuItem key={item} value={t(item) as string}>
                              {t(item)}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          <ErrorMessage name={field.name} />
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    component={TextField}
                    id="outlined-multiline-static"
                    multiline
                    name="rejectionReason"
                    rows={6}
                    fullWidth
                  />
                </Stack>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                  id="btn-close-candidate-rejected-modal"
                  variant="outlined"
                  onClick={handleClose}
                >
                  {t('common.btn.cancel')}
                </Button>
                <Button
                  color="secondary"
                  id="cta-confirm-candidate-rejected"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={18} />}
                >
                  {t('common.btn.submit')}
                </Button>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </GorillaDialog>
  )
}
