import { FC, memo } from 'react'

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export const IconButtonComponent: FC<{
  direction: 'left' | 'right'
  buttonText: string
  navigateSlides: () => void
  isDisabled: boolean
}> = memo(({ buttonText, navigateSlides, isDisabled, direction }) => (
  <IconButton
    onClick={navigateSlides}
    sx={{ color: 'common.white', fontSize: '14px' }}
    disabled={isDisabled}
  >
    {direction === 'left' ? (
      <>
        <KeyboardArrowLeft />
        {buttonText}
      </>
    ) : (
      <>
        {buttonText}
        <KeyboardArrowRight />
      </>
    )}
  </IconButton>
))
