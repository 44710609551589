import { JobAiService } from '@procom-labs/common'
import { axios } from '@submission-portal/lib'
import { environment } from '@submission-portal/environment'
import { authService } from '@submission-portal/services/auth.service'

export const jobAiService = new JobAiService(
  environment,
  axios,
  authService.authData$
)
