import Axios from 'axios-observable'
import i18n from 'i18next'
import {
  catchError,
  debounceTime,
  finalize,
  map,
  mergeAll,
  Observable,
  ObservableInput,
  Subject,
  tap,
  throwError,
  windowToggle,
} from 'rxjs'

import { ResponseErrorCodes, SignalrEventNames, VendorCodes } from '../enums'
import {
  IAiAssistedResume,
  IAiEmployerJobPromptPayload,
  IAiJobPromptPayload,
  IAIScreeningQuestions,
  IAIScreeningQuestionsResponse,
  IAtsCandidateFile,
  IAtsCandidateHighlights,
  IBhJobOrderInfo,
  ICandidateHighlights,
  ICandidateInterviewQuestions,
  IInterviewQuestionsParams,
  IInterviewQuestionsPayload,
  IResumeTipsAndSuggestions,
  SuperSubject,
} from '../models'
import {
  concatAndFormatAiOutput,
  concatAndFormatAIOutputWithExtraSpace,
  concatAndFormatTipsOutput,
  errorToCodeAndMessage,
  errorToString,
  regexToExtractCode,
} from '../util'
import { SignalrService } from './signalr.service'

export class JobAiService extends SignalrService {
  environment: any

  axios: Axios

  private readonly streamUrl: string

  private isJobLoadingSubject = new SuperSubject<boolean>(false)

  isJobLoading$ = this.isJobLoadingSubject.observable$

  private jobInformation = new SuperSubject<IAiJobPromptPayload | null>(null)

  jobInformation$ = this.jobInformation.observable$

  private jobDescriptionStream = new Subject<string>()

  jobDescriptionDeltas$ = this.jobDescriptionStream.asObservable()

  private jobDescriptionStr = ''

  private isJobDescriptionReceived = false

  private candidateHighlightStream = new Subject<string>()

  candidateHighlightDeltas$ = this.candidateHighlightStream.asObservable()

  private atsCandidateHighlightStream = new Subject<string>()

  atsCandidateHighlightDelta$ = this.atsCandidateHighlightStream.asObservable()

  private isInterviewQuestionsLoadingSubject = new SuperSubject<boolean>(false)

  isInterviewQuestionsLoading$ =
    this.isInterviewQuestionsLoadingSubject.observable$

  private interviewQuestionsStream = new Subject<string>()

  interviewQuestionsDeltas$ = this.interviewQuestionsStream.asObservable()

  private interviewQuestionsStr = ''

  private isResumeTipsAndSuggestionLoadingSubject = new SuperSubject<boolean>(
    false
  )

  isResumeTipsAndSuggestionLoading$ =
    this.isResumeTipsAndSuggestionLoadingSubject.observable$

  private isScreeningQuestionsLoadingSubject = new SuperSubject<boolean>(false)

  isScreeningQuestionsLoading$ =
    this.isScreeningQuestionsLoadingSubject.observable$

  private resumeTipsAndSuggestionsStream = new Subject<string>()

  resumeTipsAndSuggestionsStreamDeltas$ =
    this.resumeTipsAndSuggestionsStream.asObservable()

  private candidateAtsFilesSubject = new Subject<IAtsCandidateFile[]>()

  candidateAtsFiles$ = this.candidateAtsFilesSubject.asObservable()

  private aiScreeningQuestionsSubject = new Subject<
    IAIScreeningQuestionsResponse | undefined
  >()

  aiScreeningQuestions$ = this.aiScreeningQuestionsSubject.asObservable()

  private resumeTipsAndSuggestionsStr = ''

  private isAiAssistedResumeLoadingSubject = new SuperSubject<boolean>(false)

  isAiAssistedResumeLoading$ = this.isAiAssistedResumeLoadingSubject.observable$

  private aiAssistedResumeStr = ''

  private aiAssistedResumeStream = new Subject<string>()

  aiAssistedResumeDeltas$ = this.aiAssistedResumeStream.asObservable()

  constructor(environment: any, axios: any, tokenRefreshed: Observable<any>) {
    super(
      environment.PROCOM_VENDOR_API_KEYS[
        (new URLSearchParams(window.location.search).get(
          'vendorCode'
        ) as VendorCodes) || VendorCodes.PCGL
      ],
      tokenRefreshed
    )
    this.environment = environment
    this.axios = axios
    // JobDataStream is the name of the signalr hub on the backend, which will be shared by all job related signalr streams
    this.streamUrl = `${this.environment.JOB_API_URL}/JobDataStream`
  }

  getJobInformation(
    jobId: string | null,
    params?: { requestBy?: string; includeNotes?: boolean }
  ): Observable<IBhJobOrderInfo> {
    return this.axios
      .get<IBhJobOrderInfo>(
        `${this.environment.JOB_API_URL}/ClientJob/ats/job-info/${jobId}`,
        {
          params,
        }
      )
      .pipe(map(({ data }) => data))
  }

  generateClientJobDescription = (
    aiJobPromptPayload: IAiJobPromptPayload
  ): Observable<string> => {
    this.isJobLoadingSubject.value = true
    this.isJobDescriptionReceived = false
    this.jobDescriptionStream.next('')
    this.jobDescriptionStr = ''
    const payload: IAiJobPromptPayload = {
      ...aiJobPromptPayload,
      clientSocketConnectionId: this.signalRConnectionId,
    }
    // Note that this endpoint returns the full job description as traditional http response. Plus it also sends the delta stream through signalr.
    // See the base class SignalrService for the implementation of the signalr connection.
    // In case something went wrong with the signalr connection, the http response will still be returned and displayed to user
    return this.axios
      .post<string>(
        `${this.environment.JOB_API_URL}/ClientJob/ai-job-description`,
        payload
      )
      .pipe(
        map(({ data }) => {
          const jobResponse = concatAndFormatAiOutput('', data)
          this.jobDescriptionStream.next(jobResponse)
          this.isJobDescriptionReceived = true
          return jobResponse
        }),
        finalize(() => {
          this.isJobLoadingSubject.value = false
        })
      )
  }

  generateEmployerJobDescription = (
    aiEmployerJobPromptPayload: IAiEmployerJobPromptPayload
  ): Observable<string> => {
    this.isJobLoadingSubject.value = true
    this.isJobDescriptionReceived = false
    this.jobDescriptionStream.next('')
    this.jobDescriptionStr = ''
    const payload: IAiEmployerJobPromptPayload = {
      ...aiEmployerJobPromptPayload,
      clientSocketConnectionId: this.signalRConnectionId,
    }
    return this.axios
      .post<string>(
        `${this.environment.JOB_API_URL}/EmployerJob/ai-assisted-stream`,
        payload
      )
      .pipe(
        map(({ data }) => {
          const jobResponse = concatAndFormatAiOutput('', data)
          this.jobDescriptionStream.next(jobResponse)
          this.isJobDescriptionReceived = true
          return jobResponse
        }),
        finalize(() => {
          this.isJobLoadingSubject.value = false
        })
      )
  }

  getJobDescriptionStream(debounceMillis?: number): Observable<string> {
    return super
      .getEventStream(this.streamUrl, SignalrEventNames.JobDescriptionDelta)
      .pipe(
        tap((message) => {
          this.jobDescriptionStr = concatAndFormatAiOutput(
            this.jobDescriptionStr,
            message
          )
        }),
        debounceTime(debounceMillis || 50),
        tap(() => {
          // This is a race around condition with HTTP call. Therefore, stream will be listened until payload is received,
          // and after that, apply the formatting to win the race around and get the results in either case
          if (!this.isJobDescriptionReceived) {
            this.jobDescriptionStream.next(this.jobDescriptionStr)
          }
        })
      )
  }

  generateCandidateHighlights = (
    candidateHighlights: ICandidateHighlights
  ): Observable<string> => {
    this.isJobLoadingSubject.value = true
    this.candidateHighlightStream.next('')
    this.jobDescriptionStr = ''
    const payload: ICandidateHighlights = {
      ...candidateHighlights,
      clientSocketConnectionId: this.signalRConnectionId,
    }
    return this.axios
      .post(
        `${this.environment.JOB_API_URL}/Candidate/candidate-highlights`,
        payload
      )
      .pipe(
        map(({ data }) => {
          const result = concatAndFormatAIOutputWithExtraSpace('', data)
          this.candidateHighlightStream.next(result)
          return result
        }),
        catchError((error: any) => {
          return this.getAiResponseError(error)
        }),
        finalize(() => {
          this.isJobLoadingSubject.value = false
        })
      )
  }

  getCandidateHighlightsStream(
    debounceMillis?: number,
    start?: ObservableInput<any>,
    pause?: ObservableInput<any>
  ): Observable<string> {
    let subscription = super.getEventStream(
      this.streamUrl,
      SignalrEventNames.CandidateHighlightsDelta
    )
    if (start && pause) {
      subscription = subscription.pipe(
        windowToggle(start, () => pause),
        mergeAll()
      )
    }
    return subscription.pipe(
      tap((message) => {
        this.jobDescriptionStr = concatAndFormatAIOutputWithExtraSpace(
          this.jobDescriptionStr,
          message
        )
      }),
      debounceTime(debounceMillis || 50),
      tap(() => {
        this.candidateHighlightStream.next(this.jobDescriptionStr)
      })
    )
  }

  generateAiAssistedResume = (
    aiAssistedResume: IAiAssistedResume
  ): Observable<string> => {
    this.isAiAssistedResumeLoadingSubject.value = true
    this.aiAssistedResumeStream.next('')
    this.aiAssistedResumeStr = ''
    const payload: IAiAssistedResume = {
      ...aiAssistedResume,
      clientSocketConnectionId: this.signalRConnectionId,
    }
    return this.axios
      .post(
        `${this.environment.JOB_API_URL}/Copilot/ats/ai-assisted-resume`,
        payload
      )
      .pipe(map(({ data }) => concatAndFormatAIOutputWithExtraSpace('', data)))
  }

  getAiAssistedResumeStream(
    debounceMillis?: number,
    start?: ObservableInput<any>,
    pause?: ObservableInput<any>
  ): Observable<string> {
    let subscription = super.getEventStream(
      this.streamUrl,
      SignalrEventNames.AiAssistedResumeDelta
    )
    if (start && pause) {
      subscription = subscription.pipe(
        windowToggle(start, () => pause),
        mergeAll()
      )
    }
    return subscription.pipe(
      tap((message) => {
        this.aiAssistedResumeStr = concatAndFormatAIOutputWithExtraSpace(
          this.aiAssistedResumeStr,
          message
        )
      }),
      debounceTime(debounceMillis || 50),
      tap(() => {
        this.aiAssistedResumeStream.next(this.aiAssistedResumeStr)
      })
    )
  }

  generateInterviewQuestions = ({
    jobId,
    behavioralQuestions,
    technicalQuestions,
    includeAnswers,
    language,
    atsJobId,
    notes,
  }: IInterviewQuestionsParams): Observable<string> => {
    this.isInterviewQuestionsLoadingSubject.value = true
    this.interviewQuestionsStream.next('')
    this.interviewQuestionsStr = ''

    let url = `${this.environment.JOB_API_URL}/Copilot/`
    if (atsJobId) {
      url += `ats/ai-assisted-interview?${new URLSearchParams({
        atsJobId,
      })}`
    } else {
      url += 'ai-assisted-interview'
    }

    const payload: IInterviewQuestionsPayload = {
      jobId,
      behavioralQuestions,
      technicalQuestions,
      includeAnswers,
      language,
      clientSocketConnectionId: this.signalRConnectionId,
      notes,
      ...(atsJobId
        ? {
            atsJobId: +atsJobId,
          }
        : {}),
    }

    return this.axios.post<string>(url, payload).pipe(
      map(({ data }) => concatAndFormatAIOutputWithExtraSpace('', data)),
      finalize(() => {
        this.isInterviewQuestionsLoadingSubject.value = false
      })
    )
  }

  getInterviewQuestionsStream(debounceMillis?: number): Observable<string> {
    return super
      .getEventStream(
        this.streamUrl,
        SignalrEventNames.InterviewPreparationDelta
      )
      .pipe(
        tap((message) => {
          this.interviewQuestionsStr = concatAndFormatAIOutputWithExtraSpace(
            this.interviewQuestionsStr,
            message
          )
        }),
        debounceTime(debounceMillis || 50),
        tap(() => {
          this.interviewQuestionsStream.next(this.interviewQuestionsStr)
        })
      )
  }

  getInterviewMetadata = (
    atsJobId: number
  ): Observable<ICandidateInterviewQuestions> => {
    return this.axios
      .get<ICandidateInterviewQuestions>(
        `${this.environment.JOB_API_URL}/ClientJob/interview-metadata/${atsJobId}`
      )
      .pipe(map(({ data }) => data))
  }

  generateResumeTipsAndSuggestions = (
    tipsAndSuggestionsPayload: IResumeTipsAndSuggestions
  ): Observable<string> => {
    this.isResumeTipsAndSuggestionLoadingSubject.value = true
    this.resumeTipsAndSuggestionsStream.next('')
    this.resumeTipsAndSuggestionsStr = ''
    const payload: IResumeTipsAndSuggestions = {
      ...tipsAndSuggestionsPayload,
      clientSocketConnectionId: this.signalRConnectionId,
    }
    return this.axios
      .post(`${this.environment.JOB_API_URL}/Copilot/job-tips`, payload)
      .pipe(
        map(({ data }) => concatAndFormatTipsOutput('', data)),
        catchError((error: any) => {
          return this.getAiResponseError(error)
        }),
        finalize(() => {
          this.isResumeTipsAndSuggestionLoadingSubject.value = false
        })
      )
  }

  getResumeTipsAndSuggestions(debounceMillis?: number): Observable<string> {
    return super
      .getEventStream(this.streamUrl, SignalrEventNames.ResumeCopilotDelta)
      .pipe(
        tap((message) => {
          this.resumeTipsAndSuggestionsStr = concatAndFormatTipsOutput(
            this.resumeTipsAndSuggestionsStr,
            message
          )
        }),
        debounceTime(debounceMillis || 50),
        tap(() => {
          this.resumeTipsAndSuggestionsStream.next(
            this.resumeTipsAndSuggestionsStr
          )
        })
      )
  }

  getAtsCandidateFiles = (
    candidateId: string
  ): Observable<IAtsCandidateFile[]> => {
    return this.axios
      .get<IAtsCandidateFile[]>(
        `${this.environment.JOB_API_URL}/Candidate/ats/${candidateId}/files`
      )
      .pipe(
        map(({ data }) => {
          this.candidateAtsFilesSubject.next(data)
          return data
        })
      )
  }

  getScreeningQuestionTranslation(
    text: string,
    language: string
  ): Observable<string> {
    return this.axios
      .post<string>(`${this.environment.JOB_API_URL}/Copilot/translate`, {
        Text: text,
        TextLanguage: language,
        RecaptchaToken: '',
      })
      .pipe(map(({ data }) => concatAndFormatAiOutput('', data)))
  }

  getAIScreeningQuestions = (
    index: number,
    aiScreeningQuestionsPayload: IAIScreeningQuestions
  ): Observable<IAIScreeningQuestionsResponse> => {
    this.isScreeningQuestionsLoadingSubject.value = true
    this.aiScreeningQuestionsSubject.next(undefined)
    const payload: IAIScreeningQuestions = {
      ...aiScreeningQuestionsPayload,
    }
    return this.axios
      .post(
        `${this.environment.JOB_API_URL}/Copilot/screening-questions`,
        payload
      )
      .pipe(
        map(({ data }) => {
          const formattedOutput = concatAndFormatTipsOutput('', data)
          const response: IAIScreeningQuestionsResponse = {
            startingIndex: index,
            response: formattedOutput,
          }
          this.aiScreeningQuestionsSubject.next(response)
          return data
        }),
        catchError((error: any) => {
          return this.getAiResponseError(error)
        }),
        finalize(() => {
          this.isScreeningQuestionsLoadingSubject.value = false
        })
      )
  }

  generateAtsCandidateHighlights = (
    atsCandidateHighlights: IAtsCandidateHighlights
  ): Observable<string> => {
    this.isJobLoadingSubject.value = true
    this.atsCandidateHighlightStream.next('')
    this.jobDescriptionStr = ''
    const payload: IAtsCandidateHighlights = {
      ...atsCandidateHighlights,
      clientSocketConnectionId: this.signalRConnectionId,
    }
    return this.axios
      .post(
        `${this.environment.JOB_API_URL}/Candidate/ats/candidate-highlight`,
        payload
      )
      .pipe(
        map(({ data }) => concatAndFormatAIOutputWithExtraSpace('', data)),
        finalize(() => {
          this.isJobLoadingSubject.value = false
        })
      )
  }

  saveAtsCandidateHighlights = (
    highlights: string,
    candidateId: string,
    params?: {
      entityType: string
    }
  ): Observable<any> => {
    return this.axios
      .post(
        `${this.environment.JOB_API_URL}/Candidate/${candidateId}/save-highlights`,
        highlights,
        {
          params,
        }
      )
      .pipe()
  }

  getAtsCandidateHighlightsStream(
    debounceMillis?: number,
    start?: ObservableInput<any>,
    pause?: ObservableInput<any>
  ): Observable<string> {
    let subscription = super.getEventStream(
      this.streamUrl,
      SignalrEventNames.BullhornCandidateHighlightsDelta
    )
    if (start && pause) {
      subscription = subscription.pipe(
        windowToggle(start, () => pause),
        mergeAll()
      )
    }
    return subscription.pipe(
      tap((message) => {
        this.jobDescriptionStr = concatAndFormatAIOutputWithExtraSpace(
          this.jobDescriptionStr,
          message
        )
      }),
      debounceTime(debounceMillis || 50),
      tap(() => {
        this.atsCandidateHighlightStream.next(this.jobDescriptionStr)
      })
    )
  }

  setLoadingState = (value: boolean): void => {
    this.isJobLoadingSubject.value = value
  }

  setCandidateHighlightStream = (value: string): void => {
    this.candidateHighlightStream.next(value)
    this.jobDescriptionStr = value
  }

  setAtsCandidateHighlightStream = (value: string): void => {
    this.atsCandidateHighlightStream.next(value)
    this.jobDescriptionStr = value
  }

  setAiAssistedResumeLoading = (isLoading: boolean): void => {
    this.isAiAssistedResumeLoadingSubject.value = isLoading
  }

  getAiResponseError = (error: any): Observable<never> => {
    const match = errorToString(errorToCodeAndMessage(error).message)?.match(
      regexToExtractCode
    )

    const responseErrorCode = match ? match[1] : null

    if (
      responseErrorCode &&
      responseErrorCode === ResponseErrorCodes.ContextLengthExceeded
    ) {
      return throwError({
        message: i18n.t(
          'employer.candidateDetail.detailForm.contextLimitExceededError'
        ),
        severity: 'error',
      })
    }
    return throwError({
      message: errorToString(error),
      severity: 'error',
    })
  }
}
