import React, { useMemo } from 'react'

import { DateValueFormat, formatDates, isValidDate } from '@procom-labs/common'

interface Props {
  date: string | null | undefined
  combinedDate?: string
  dateFormat?: DateValueFormat | string
  normalize?: boolean
  localize?: boolean
}

export const DateFormatter: React.FC<Props> = ({
  date,
  dateFormat = DateValueFormat.DayMonthYearMedium,
  combinedDate,
  normalize,
  localize,
}) => {
  const isDateValid = useMemo(() => {
    return isValidDate(date ?? '')
  }, [date])

  const memoizedDate = useMemo(() => {
    if (date && isDateValid) {
      return formatDates(dateFormat, date, '', { normalize, localize })
    }
    return ''
  }, [date, dateFormat, isDateValid, normalize, localize])

  const memoizedCombinedDate = useMemo(() => {
    if (combinedDate && isDateValid) {
      return formatDates(dateFormat, combinedDate, '', { normalize, localize })
    }
    return ''
  }, [combinedDate, dateFormat, isDateValid, normalize, localize])

  if (memoizedCombinedDate) {
    return (
      <>
        {memoizedDate} - {memoizedCombinedDate}
      </>
    )
  }

  return <>{memoizedDate}</>
}
