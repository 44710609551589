import React from 'react'
import { Typography, TypographyProps } from '@mui/material'

export const FieldTitle: React.FC = ({ children }: TypographyProps) => (
  <Typography
    fontWeight={500}
    color="text.secondary"
    sx={{ fontSize: 14, lineHeight: '48px' }}
  >
    {children}
  </Typography>
)
