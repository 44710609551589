import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TFuncKey, useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from '@mui/material'
import { useSubjectSelector } from '@procom-labs/common'
import {
  DateRangeValidationInput,
  IsoDateStringSchema,
  ModalFilterDialog,
  SelectHookField,
} from '@procom-labs/molecules'

import { yupResolver } from '@hookform/resolvers/yup'
import { useGetClientActivityLabel } from '@submission-portal/components/submission-view/hooks'
import { submissionStore } from '@submission-portal/stores/submission-store'
import {
  DefaultSubmissionSearchFilter,
  ReviewAndSubmitKeys,
} from '@submission-portal/types'

const validationSchema = Yup.object().shape({
  dateLastModifiedRange: Yup.object().shape({
    startDate: IsoDateStringSchema.nullable(),
    endDate: IsoDateStringSchema.nullable(),
  }),
  dateAddedRange: Yup.object().shape({
    startDate: IsoDateStringSchema.nullable(),
    endDate: IsoDateStringSchema.nullable(),
  }),
})

const reviewAndSubmitOptions = [
  ReviewAndSubmitKeys.Submitted,
  ReviewAndSubmitKeys.ReadyForSubmission,
  ReviewAndSubmitKeys.SubmittedViaBh,
]

export const SubmissionDataFilterModal: React.FC = () => {
  const { t } = useTranslation('main')
  const getClientActivityLabel = useGetClientActivityLabel()

  const { isFilterModalOpen, searchFilter, clientActivities, submissions } =
    useSubjectSelector(submissionStore, [
      'isFilterModalOpen',
      'searchFilter',
      'clientActivities',
      'submissions',
    ])

  useEffect(() => {
    submissionStore.setClientActivities(submissions)
  }, [submissions])

  const handleClose = useCallback(() => {
    submissionStore.dispatch({
      isFilterModalOpen: false,
    })
  }, [])

  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: searchFilter,
    resolver: yupResolver(validationSchema),
  })

  const clientActivityValues = watch('clientActivity')
  const reviewAndSubmitValues = watch('reviewAndSubmit')

  const onSubmit = useCallback(
    (data: any): void => {
      const {
        dateLastModifiedRange,
        dateAddedRange,
        clientActivity,
        reviewAndSubmit,
      } = data

      submissionStore.dispatch({
        searchFilter: {
          ...searchFilter,
          reviewAndSubmit,
          clientActivity,
          dateLastModifiedRange,
          dateAddedRange,
        },
      })

      handleClose()
    },
    [handleClose, searchFilter]
  )

  const handleClearFilters = useCallback(() => {
    submissionStore.dispatch({
      searchFilter: DefaultSubmissionSearchFilter,
    })
    reset()
  }, [reset])

  const maxDate = new Date()
  return (
    <ModalFilterDialog
      isModalOpen={isFilterModalOpen}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      onClearFilters={handleClearFilters}
    >
      <DateRangeValidationInput
        control={control}
        name="dateAddedRange"
        value={searchFilter.dateAddedRange}
        label={t('submissionList.dateAdded')}
        maxDate={maxDate}
      />
      <Grid item xs={12} pt={2}>
        <DateRangeValidationInput
          control={control}
          name="dateLastModifiedRange"
          value={searchFilter.dateLastModifiedRange}
          label={t('submissionList.lastModified')}
          maxDate={maxDate}
          slotProps={{
            popper: {
              placement: 'top',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} pt={2}>
        <SelectHookField
          control={control}
          name="clientActivity"
          label={t('submissionList.clientActivity')}
          showPleaseSelect={false}
          multiple
          input={<OutlinedInput />}
          renderValue={(selected) =>
            (selected as string[])
              .map((s) => getClientActivityLabel(s))
              .join(', ')
          }
          customLabelProps={{
            sx: {
              fontWeight: '700',
              color: '#000',
              fontSize: '2rem',
              lineHeight: 1.6,
              mb: '8px',
            },
          }}
        >
          {clientActivities?.map((val) => (
            <MenuItem key={val} value={val}>
              <Checkbox
                checked={
                  clientActivityValues &&
                  clientActivityValues?.indexOf(val) > -1
                }
              />
              <ListItemText primary={getClientActivityLabel(val)} />
            </MenuItem>
          ))}
        </SelectHookField>
      </Grid>
      <Grid item xs={12} pt={2}>
        <SelectHookField
          control={control}
          name="reviewAndSubmit"
          label={t('submissionList.reviewAndSubmit')}
          showPleaseSelect={false}
          multiple
          renderValue={(selected) =>
            (selected as string[])
              .map((s) =>
                t(`submissionList.reviewAndSubmitStatuses.${s}` as TFuncKey)
              )
              .join(', ')
          }
          input={<OutlinedInput />}
          customLabelProps={{
            sx: {
              fontWeight: '700',
              color: '#000',
              fontSize: '2rem',
              lineHeight: 1.6,
              mb: '8px',
            },
          }}
        >
          {reviewAndSubmitOptions.map((val) => (
            <MenuItem key={val} value={val}>
              <Checkbox
                checked={
                  reviewAndSubmitValues &&
                  reviewAndSubmitValues?.indexOf(val) > -1
                }
              />
              <ListItemText
                primary={t(
                  `submissionList.reviewAndSubmitStatuses.${val}` as TFuncKey
                )}
              />
            </MenuItem>
          ))}
        </SelectHookField>
      </Grid>
    </ModalFilterDialog>
  )
}
