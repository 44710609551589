import { useCallback } from 'react'
import { AlertState } from '../contexts'

export const useDefaultErrorHandler = (
  addAlert: (payload: Partial<AlertState>) => void,
  message: string
): (() => void) => {
  return useCallback(() => {
    addAlert({
      message,
      severity: 'error',
    })
  }, [addAlert, message])
}
