import React, { ReactElement, useCallback, useMemo } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  BoxProps,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material'

export interface Option {
  id: string
  title: string
}

interface SelectWithOptionsProps
  extends Omit<SelectProps<any>, 'onChange' | 'MenuProps'> {
  options: Option[]
  placeholder: string
  onChange: (value: string, isRootValue?: boolean) => void
  MenuProps?: Partial<SelectProps<any>['MenuProps']>
  boxProps?: Partial<BoxProps>
  clearable?: boolean
  rootOption?: Option | null
}

export const SelectWithOptions = ({
  options,
  placeholder,
  value,
  onChange,
  clearable = false,
  MenuProps,
  boxProps,
  rootOption = null,
  ...props
}: SelectWithOptionsProps): ReactElement => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const isRootValue =
        (rootOption && event.target.value === rootOption.id) ?? false
      onChange(event.target.value, isRootValue)
    },
    [onChange, rootOption]
  )

  const handleClear = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      if (rootOption) {
        onChange(rootOption.id, true)
      } else {
        onChange('')
      }
    },
    [onChange, rootOption]
  )

  const renderedValue = useCallback(
    (selectedValue: string): ReactElement | string => {
      if (selectedValue === '') {
        return rootOption ? (
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {rootOption.title}
          </Box>
        ) : (
          <Box
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            color="text.secondary"
          >
            {placeholder}
          </Box>
        )
      }

      const matchedOption = options.find(
        (option) => option.id === selectedValue
      )

      if (matchedOption) {
        return matchedOption.title
      }

      if (rootOption && rootOption.id === selectedValue) {
        return <Box>{rootOption.title}</Box> // Removed color prop to use default color
      }

      return ''
    },
    [options, placeholder, rootOption]
  )

  const validValue = useMemo(() => {
    if (!value || options.some((option) => option.id === value)) {
      return value
    }
    // If the rootOption exists, default to its ID; otherwise, default to an empty string
    return rootOption ? rootOption.id : ''
  }, [value, options, rootOption])

  return (
    <Box display="flex" alignItems="center" {...boxProps}>
      <Select
        value={validValue}
        onChange={handleChange}
        displayEmpty
        renderValue={renderedValue}
        endAdornment={
          (clearable || rootOption) && value && value !== rootOption?.id ? (
            <InputAdornment position="end" sx={{ marginX: '12px' }}>
              <IconButton size="small" onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: '#fff',
            },
          },
          ...MenuProps,
        }}
        {...props}
      >
        {rootOption && (
          <MenuItem sx={{ display: 'none' }} value={rootOption.id}>
            {rootOption.title}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
