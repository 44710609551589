import { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { CloudUpload } from '@mui/icons-material'
import { styled, Typography } from '@mui/material'
import { GorillaBox, GorillaStack } from '@procom-labs/atoms'

import { ResumeCopilotProButton } from '@submission-portal/components/resume-copilot-pro/components/ui/resume-copilot-pro-button'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const baseStyle = {}

const focusedStyle = {}

const acceptStyle = {
  backgroundColor: '#c2fde3',
}

const rejectStyle = {
  backgroundColor: '#ffcbd4',
}

export type ResumeCopilotProFileUploadProps = {
  onChange: (file: File) => void
}

export const ResumeCopilotProFileUpload: FC<
  ResumeCopilotProFileUploadProps
> = ({ onChange }) => {
  const { t } = useTranslation('resume-copilot-pro')

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onChange(acceptedFiles[0])
      }
    },
    [onChange]
  )

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        'application/pdf': [],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          [],
      },
    })

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      onChange(event.target.files[0])
    }
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <GorillaBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: '50px',
        paddingX: '50px',
        border: '1px dashed gray',
        borderRadius: '24px',
      }}
      {...getRootProps({ style, className: 'dropzone' })}
    >
      <GorillaStack direction="row" alignItems="center" spacing={10}>
        <CloudUpload color="primary" sx={{ fontSize: 60 }} />
        <GorillaStack alignItems="center">
          <Typography variant="h6">{t('upload.heading')}</Typography>
          <Typography variant="body2">{t('upload.subheading')}</Typography>
        </GorillaStack>
        <ResumeCopilotProButton
          // component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          size="large"
        >
          {t('upload.button')}
          <VisuallyHiddenInput
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleFileChange}
            {...getInputProps()}
          />
        </ResumeCopilotProButton>
      </GorillaStack>
    </GorillaBox>
  )
}
