import { FC, ReactElement } from 'react'

import { Box, StackProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { GorillaStack } from '@procom-labs/atoms'

interface SectionTitleProps extends StackProps {
  icon: ReactElement
  title: string
}
export const SectionTitle: FC<SectionTitleProps> = ({ icon, title, sx }) => {
  const theme = useTheme()
  return (
    <GorillaStack direction="row" sx={{ alignItems: 'center', ...sx }} gap={2}>
      <Box
        sx={{
          height: '40px',
          width: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        {icon}
      </Box>
      <Typography variant="h6" sx={{ opacity: 0.6 }}>
        {title}
      </Typography>
    </GorillaStack>
  )
}
