import { forwardRef, memo } from 'react'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, IconButton, IconButtonProps } from '@mui/material'

interface ArrowButtonComponentProps {
  scrollList: () => void
  iconButtonSX?: IconButtonProps
  direction: 'top' | 'down'
}
export const ArrowButtonComponent = memo(
  forwardRef<HTMLElement, ArrowButtonComponentProps>(
    ({ scrollList, iconButtonSX, direction }, ref) => (
      <Box
        ref={ref}
        display="flex"
        justifyContent="center"
        sx={{
          background: 'background.default',
          zIndex: 100,
          ...iconButtonSX,
        }}
      >
        <IconButton onClick={scrollList}>
          {direction === 'top' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Box>
    )
  )
)
