import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography } from '@mui/material'
import { useDebouncedSubjectSelector } from '@procom-labs/common'
import {
  SessionCloseableAlert,
  useDataPaginationProps,
  useDataSortHandler,
} from '@procom-labs/molecules'
import { AppDataGrid } from '@procom-labs/organisms'

import { BullhornDataSearchBar } from '@submission-portal/components/candidate-prep/bullhorn-candidate-prep/bullhorn-data-search-bar'
import { SubmissionDataFilterModal } from '@submission-portal/components/submission-view'
import { submissionService } from '@submission-portal/services'
import { candidatePrepSelectStore } from '@submission-portal/stores'
import { DefaultSubmissionSearchFilter } from '@submission-portal/types'

import {
  useBullhornCandidatePrepDataColumns,
  useBullhornCandidatesDataRecords,
} from './bullhorn-candidate-data-hooks'

export const BullhornCandidateForm: React.FC<{
  hideAlert?: boolean
  searchBarLabel?: string
}> = ({ hideAlert, searchBarLabel }) => {
  const { t } = useTranslation('main')
  const [searchParams] = useSearchParams()
  const jobId = searchParams.get('EntityID')
  const userId = searchParams.get('UserID')

  useEffect(() => {
    sessionStorage.setItem('rootPath', '/data')
  }, [])

  // Use debounce to apply delay to the search query
  const { searchFilter } = useDebouncedSubjectSelector(
    candidatePrepSelectStore,
    ['searchFilter'],
    300
  )
  const { keyword } = searchFilter

  const { rows, rowCount, loading } =
    useBullhornCandidatesDataRecords(searchFilter)

  const handleChangeKeyword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      candidatePrepSelectStore.dispatch({
        loading: true,
        searchFilter: {
          ...searchFilter,
          keyword: e.target.value,
        },
      })
    },
    [searchFilter]
  )

  useEffect(() => {
    return () => {
      candidatePrepSelectStore.dispatch({
        loading: false,
        searchFilter: DefaultSubmissionSearchFilter,
      })
    }
  }, [])

  useEffect(() => {
    if (jobId && userId) {
      submissionService.setIFrameIds({
        atsCandidateId: jobId,
        atsUserId: userId,
      })
    }
  }, [jobId, userId])

  const columns = useBullhornCandidatePrepDataColumns()

  const paginationProps = useDataPaginationProps(
    candidatePrepSelectStore,
    searchFilter
  )
  const onSortModelChange = useDataSortHandler({
    searchFilter,
    store: candidatePrepSelectStore,
  })

  return (
    <Box sx={{ m: 4 }}>
      <BullhornDataSearchBar
        handleChangeKeyword={handleChangeKeyword}
        searchBarLabel={searchBarLabel}
      />
      {!hideAlert && (
        <SessionCloseableAlert
          sx={{ borderRadius: '4px', mt: '1rem' }}
          trackKey="showSearchCandidateInfo"
          severity="info"
          icon={<InfoIcon fontSize="inherit" />}
        >
          <Typography variant="body2">
            {t('candidatePreb.searchCandidateInfo')}
          </Typography>
        </SessionCloseableAlert>
      )}

      <AppDataGrid
        rows={rows}
        rowCount={rowCount}
        columns={columns}
        loading={loading}
        idField="id"
        slotLabel={t(
          keyword
            ? 'candidatePreb.notCandidatesFound'
            : 'candidatePreb.searchCandidateDescription'
        )}
        sx={{ mt: '2rem' }}
        onSortModelChange={onSortModelChange}
        {...paginationProps}
      />
      <SubmissionDataFilterModal />
    </Box>
  )
}
