import Axios from 'axios-observable'
import { catchError, finalize, map, Observable, of } from 'rxjs'
import { AuthService } from './auth.service'

import {
  IContractorProfileResume,
  IContractorResumeUpload,
  IUserContractorProfile,
  IUserContractorProfilePayload,
  SuperSubject,
} from '../models'

interface ContractorProfileServiceConfig {
  authService?: AuthService
  authApiUrl: string
  jobApiUrl: string
  axios: Axios
  apiKey?: string
}

export class ContractorProfileService {
  private contractorProfileEndpoint

  private getContractorProfileEndpoint

  private contractorProfileSubject =
    new SuperSubject<IUserContractorProfile | null>(null)

  contractorProfile$ = this.contractorProfileSubject.observable$

  private isLoadingSubject = new SuperSubject<boolean>(false)

  isLoading$ = this.isLoadingSubject.observable$

  private isSavingSubject = new SuperSubject<boolean>(false)

  isSaving$ = this.isSavingSubject.observable$

  private axios: Axios

  private authService: AuthService | undefined

  private authApiUrl: string

  private jobApiUrl: string

  apiKey: string | undefined

  constructor(config: ContractorProfileServiceConfig) {
    this.axios = config.axios
    this.authService = config.authService
    this.authApiUrl = config.authApiUrl
    this.jobApiUrl = config.jobApiUrl
    this.apiKey = config.apiKey

    this.contractorProfileEndpoint = `${this.authApiUrl}/ContractorProfile`
    this.getContractorProfileEndpoint = `${this.authApiUrl}/ContractorProfile/current-user`
  }

  getContractorProfile(cache = true): Observable<IUserContractorProfile> {
    if (this.contractorProfileSubject.value && cache) {
      return of(this.contractorProfileSubject.value)
    }
    this.isLoadingSubject.value = true
    return this.axios
      .get<IUserContractorProfile>(`${this.getContractorProfileEndpoint}`)
      .pipe(
        map(({ data }) => {
          this.contractorProfileSubject.value = data
          return data
        }),
        finalize(() => {
          this.isLoadingSubject.value = false
        }),
        catchError(() => {
          this.contractorProfileSubject.value = null
          return of()
        })
      )
  }

  createContractorProfile(
    payload: IUserContractorProfilePayload
  ): Observable<IUserContractorProfile> {
    this.isSavingSubject.value = true
    return this.axios
      .post<IUserContractorProfile>(
        `${this.contractorProfileEndpoint}`,
        payload
      )
      .pipe(
        map(({ data }) => {
          this.contractorProfileSubject.value = data
          return data
        }),
        finalize(() => {
          this.isSavingSubject.value = false
        })
      )
  }

  updateContractorDetail(
    payload: IUserContractorProfilePayload
  ): Observable<IUserContractorProfile> {
    this.isLoadingSubject.value = true
    return this.axios
      .put<IUserContractorProfile>(
        `${this.contractorProfileEndpoint}/${payload?.contractorProfile?.id}`,
        payload
      )
      .pipe(
        map(({ data }) => {
          this.contractorProfileSubject.value = data
          return data
        }),
        finalize(() => {
          this.isLoadingSubject.value = false
        })
      )
  }

  sendSmsVerificationCode(id: string): Observable<void> {
    return this.axios
      .post(`${this.contractorProfileEndpoint}/${id}/sms-code`, {
        siteConfigurationId: this.authService?.siteConfigurationId,
      })
      .pipe(map(() => {}))
  }

  verifySmsCode(id: string, code: string): Observable<boolean> {
    return this.axios
      .post(`${this.contractorProfileEndpoint}/${id}/verify-sms-code`, {
        code,
      })
      .pipe(map(({ data }) => !!data))
  }

  uploadResume(
    formData: FormData,
    onUploadProgress?: (event: ProgressEvent) => void
  ): Observable<IContractorResumeUpload> {
    this.isSavingSubject.value = true
    return this.axios
      .post<IContractorResumeUpload>(
        `${this.contractorProfileEndpoint}/upload-resume`,
        formData,
        {
          onUploadProgress,
        }
      )
      .pipe(
        map(({ data }) => data),
        finalize(() => {
          this.isSavingSubject.value = false
        })
      )
  }

  saveToProfile(formData: FormData): Observable<IContractorResumeUpload> {
    this.isSavingSubject.value = true
    return this.axios
      .post<IContractorResumeUpload>(
        `${this.contractorProfileEndpoint}/save-resume`,
        formData
      )
      .pipe(
        map(({ data }) => {
          this.updateUserResume(data.contractorResume)
          return data
        }),
        finalize(() => {
          this.isSavingSubject.value = false
        })
      )
  }

  downloadResume(
    fileStorageId: string,
    atsUserId?: string
  ): Observable<ArrayBuffer> {
    this.isLoadingSubject.value = true
    const urlParams = new URLSearchParams({
      fileId: `${fileStorageId}`,
    })
    if (atsUserId) {
      urlParams.append('atsUserId', atsUserId)
    }
    const url = `${this.jobApiUrl}/File/resume?${urlParams.toString()}`
    const headers =
      atsUserId && this.apiKey ? { 'api-key': this.apiKey } : undefined
    return this.axios
      .get<ArrayBuffer>(url, {
        responseType: 'arraybuffer',
        headers,
      })
      .pipe(
        map(({ data }) => data),
        finalize(() => {
          this.isLoadingSubject.value = false
        })
      )
  }

  updateUserResume(resume: IContractorProfileResume | null): void {
    if (this.contractorProfileSubject.value) {
      this.contractorProfileSubject.value = {
        ...this.contractorProfileSubject.value,
        resume,
      }
    }
  }
}
