import SwiperCore from 'swiper'

import {
  JobFilterFields,
  JobSearchType,
  JobStatus,
  TalentPoolsSortOptions,
} from '../../enums'
import {
  ClientListItem,
  IBillRate,
  IJobFilter,
  IJobSearchType,
  IJobStatusCount,
} from '../../interfaces'
import { IJob, IJobDetail } from '../../models'
import { BaseSubjectStore } from '../subject-store'

interface ClientJobStoreState {
  isLoading: boolean
  jobList: IJob[] | null
  activeJob: IJobDetail | null
  jobStatus: JobStatus
  jobStatusCount: IJobStatusCount[] | null
  searchType: IJobSearchType
  searchFilter: IJobFilter
  clientList?: ClientListItem[]
  carouselFunnelInstance: SwiperCore | null
  billRates?: IBillRate[] | null
  isDifferentBillRate?: boolean
  isScreeningQuestionBeingTranslated?: boolean
}

const defaultSearchType: IJobSearchType = {
  title: JobSearchType.Jobs,
  index: 0,
}
export const defaultJobSearchFilter: IJobFilter = {
  keyword: '',
  clientCompany: [],
  period: {},
  portalJobStatus: JobStatus.Active,
  sortField: JobFilterFields.CreatedDateTime,
  sortOrder: TalentPoolsSortOptions.Newest,
}

export class ClientJobStore extends BaseSubjectStore<ClientJobStoreState> {
  getDefaultValue(): Required<ClientJobStoreState> {
    return {
      isLoading: false,
      jobList: null,
      activeJob: null,
      jobStatus: JobStatus.Active,
      jobStatusCount: null,
      searchType: defaultSearchType,
      searchFilter: defaultJobSearchFilter,
      clientList: [],
      carouselFunnelInstance: null,
      billRates: [],
      isDifferentBillRate: false,
      isScreeningQuestionBeingTranslated: false,
    }
  }

  get searchType(): IJobSearchType {
    return this.state.searchType.value
  }

  get searchFilter(): IJobFilter {
    return this.state.searchFilter.value
  }

  findJobIndex = (jobs: IJob[], selectedJobId: string): number => {
    return jobs.findIndex((job) => job.id === selectedJobId)
  }

  setSearchType = (value: IJobSearchType): void => {
    this.dispatch({
      searchType: value,
    })
  }

  setJobList(jobList: IJob[], selectedJobId?: string | null): void {
    const selectedJobIndex = selectedJobId
      ? this.findJobIndex(jobList, selectedJobId)
      : 0

    if (jobList.length) {
      this.dispatch({
        activeJob: jobList[selectedJobIndex] as IJobDetail,
      })
    }
    this.dispatch({
      jobList,
    })
  }

  updateJob(job: IJob): void {
    const jobsList = this.state.jobList.value
    if (jobsList) {
      const jobIndex = this.findJobIndex(jobsList, job.id)
      if (jobIndex === -1) throw new Error('No job found')

      const jobListCopy = JSON.parse(JSON.stringify(jobsList))
      jobListCopy[jobIndex] = job

      this.dispatch({
        activeJob: job,
        jobList: jobListCopy,
      })
    }
  }

  setJobDetail(jobId: string | null): IJobDetail | null {
    const activeJob =
      this.state.jobList.value?.find((job: IJobDetail) => job.id === jobId) ||
      null
    this.dispatch({
      activeJob,
    })
    return activeJob
  }

  setJobStatus(status: JobStatus): void {
    this.dispatch({
      isLoading: true,
      jobStatus: status,
    })
  }

  updateSearchFilters(payload: Partial<IJobFilter>): void {
    this.dispatch({
      searchFilter: {
        ...this.state.searchFilter.value,
        ...payload,
      },
    })
    if (!this.state.isLoading.value) {
      this.dispatch({
        isLoading: true,
      })
    }
  }

  clearSearchFilters(): void {
    this.dispatch({
      searchFilter: {
        ...defaultJobSearchFilter,
        portalJobStatus: this.state.jobStatus.value,
      },
    })
  }

  setCarouselFunnelInstance(carouselInstance: any): void {
    this.dispatch({
      carouselFunnelInstance: carouselInstance,
    })
  }

  setIsDifferentBillRate(isDifferentBillRate: boolean): void {
    this.dispatch({
      isDifferentBillRate,
    })
  }
}
