export enum EmploymentTypes {
  Contract = 'Contract',
  Permanent = 'Permanent',
  ContractToHire = 'ContractToHire',
  Both = 'Both',
}

export enum CurrencyTypes {
  CAD = 'CAD',
  USD = 'USD',
}

export enum CandidateStatusType {
  ClientSubmission = 'Client Submission',
  Shortlist = 'Client Shortlisted',
  Interview = 'Client Interview',
  Offer = 'Offer Extended',
  Rejected = 'Client Rejected',
}

export enum DateAvailableDataTypes {
  Custom = 'Custom',
  Immediate = 'Immediate',
  TwoWeeksNotice = 'TwoWeeksNotice',
  Date = 'Date',
}

export enum DurationsEnum {
  Months = 'Months',
  Years = 'Years',
  Days = 'Days',
  Weeks = 'Weeks',
}

export enum AvailabilityDefaults {
  IMMEDIATE = 'Immediate',
  TWO_WEEKS = '2 weeks notice',
  OTHER = 'Other',
  DATE = 'Date',
}

export enum RatePeriod {
  Hour = 'hour',
  Day = 'day',
  Month = 'month',
}

export enum FileSource {
  Bullhorn = 'Bullhorn',
  FileUpload = 'FileUpload',
}

export enum CandidateViewedFrom {
  QuickView = 'Quick View',
  CandidateDetails = 'Candidate Details',
}
