import Axios from 'axios-observable'
import { catchError, map, Observable, tap, throwError } from 'rxjs'

import {
  CandidateOnePagerStateEnum,
  FileSource,
  HTTPResponseStatus,
} from '../enums'
import { ICandidateOnePager, IEntityType } from '../models'
import { candidateOnePagerStore } from '../store'

export class CandidateOnePagerService {
  private readonly baseEndpoint: string

  private readonly axios: Axios

  constructor(baseEndpoint: string, axios: Axios) {
    this.baseEndpoint = baseEndpoint
    this.axios = axios
  }

  getParsedResumeStatus(
    taskId: string,
    jobSubmissionId: string
  ): Observable<ICandidateOnePager> {
    return this.axios
      .get(`${this.baseEndpoint}/OnePager/status?taskId=${taskId}`, {
        params: {
          entityType: IEntityType.JobSubmission,
          entityId: jobSubmissionId,
        },
      })
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }

  private parseResume(
    formData: FormData,
    jobSubmissionId: string,
    fileStorageId: string,
    fileSource?: {
      fileSourceType: FileSource
      fileSourceId: string
    }
  ): Observable<{
    taskId: string
    parsedResumeId: string
    onePagerId: string
  }> {
    formData.append('entityType', IEntityType.JobSubmission)
    formData.append('entityId', jobSubmissionId)
    formData.append('fileStorageId', fileStorageId)
    if (fileSource) {
      formData.append('fileSourceType', fileSource.fileSourceType)
      formData.append('fileSourceId', fileSource.fileSourceId)
    }
    return this.axios
      .post(`${this.baseEndpoint}/OnePager/parse`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }

  startResumeParse(
    formData: FormData,
    jobSubmissionId: string,
    fileStorageId: string,
    fileSource?: {
      fileSourceType: FileSource
      fileSourceId: string
    }
  ): Observable<{
    taskId: string
    parsedResumeId: string
    onePagerId: string
  }> {
    return this.parseResume(
      formData,
      jobSubmissionId,
      fileStorageId,
      fileSource
    ).pipe(
      tap(({ taskId }) => {
        candidateOnePagerStore.dispatch({
          taskId,
          state: CandidateOnePagerStateEnum.PARSING,
        })
      })
    )
  }

  getCandidateOnePager(id: string): Observable<ICandidateOnePager> {
    return this.axios
      .get<ICandidateOnePager>(
        `${this.baseEndpoint}/OnePager?${new URLSearchParams({
          id,
        })}`
      )
      .pipe(map(({ data }) => data))
  }

  createNewOnePager(
    parsedResumeId: string,
    fileStorageId: string,
    entityType: string,
    entityId: string
  ): Observable<ICandidateOnePager> {
    return this.axios
      .post(`${this.baseEndpoint}/OnePager`, {
        parsedResumeId,
        fileStorageId,
        entityType,
        entityId,
      })
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }

  getOnePagerByParsedResumeId(
    parsedResumeId: string,
    fileStorageId: string,
    entityType: string,
    entityId: string
  ): Observable<ICandidateOnePager> {
    return this.axios
      .get(`${this.baseEndpoint}/OnePager/parsed-resume/${parsedResumeId}`, {
        params: {
          entityType,
          entityId,
        },
      })
      .pipe(
        map(({ data }) => {
          return data
        }),
        catchError((error) => {
          const { status } = error.toJSON()

          if (status === HTTPResponseStatus.NotFound) {
            return this.createNewOnePager(
              parsedResumeId,
              fileStorageId,
              entityType,
              entityId
            )
          }
          return throwError(() => new Error(error))
        })
      )
  }

  saveOnePager(payload: ICandidateOnePager): Observable<ICandidateOnePager> {
    return this.axios.put(`${this.baseEndpoint}/OnePager`, payload).pipe(
      map(({ data }) => {
        return data
      })
    )
  }
}
