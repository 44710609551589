import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Preloader3D } from '@procom-labs/organisms'
import { useTranslation } from 'react-i18next'

export const AiLoader: FC = () => {
  const { t } = useTranslation('main')

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      mt="4px"
      justifyContent="center"
      alignContent="center"
    >
      <Grid item>
        <Typography variant="body1" align="center" sx={{ fontSize: 16 }}>
          {t('common.jobGenerator.aiPlaceholder')}
        </Typography>
      </Grid>
      <Grid item>
        <Preloader3D />
      </Grid>
    </Grid>
  )
}
