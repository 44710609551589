import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import CloseIcon from '@mui/icons-material/Close'
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  StepLabel,
} from '@mui/material'
import Step from '@mui/material/Step'
import Stepper from '@mui/material/Stepper'
import { useTheme } from '@mui/material/styles'
import { GorillaDialog } from '@procom-labs/atoms'
import {
  CartCandidateStatuses,
  FeatureFlagNames,
  ICart,
  ICartCandidate,
  ICartEntityStatus,
  IJobSubmission,
  JobSubmissionAtsStatusLabels,
  useIsFeatureFlagEnabled,
  useSubjectSelector,
  useSubscriptionRef,
  VendorCodes,
} from '@procom-labs/common'

import { useParseMultipleOnePagers } from '@submission-portal/hooks'
import { vendorStorage } from '@submission-portal/lib'
import { cartService } from '@submission-portal/services'
import { avatarService } from '@submission-portal/services/avatar.service'
import { submissionStore } from '@submission-portal/stores'

import { CandidateCartModalContent } from './candidate-cart-modal-content'

export const CandidateCartModal: FC<{}> = () => {
  const { t } = useTranslation('main')
  const modalRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const [showEmail, setShowEmail] = useState(false)
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)

  const [selectedCandidate, setSelectedCandidate] = useState<ICartCandidate>()
  const avatarColors = useObservable(() => avatarService.avatarsColors$)
  const refreshCartSubRef = useSubscriptionRef()

  const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
    FeatureFlagNames.ResumeOnePager
  )
  const isVendorVanderHouwen =
    vendorStorage.get()?.vendorCode === VendorCodes.VNDRHWN

  const {
    isCandidateCartOpen,
    cart,
    submissions,
    unsavedChanges,
    pendingOnePagers,
    fetchedSubmissions,
  } = useSubjectSelector(submissionStore, [
    'isCandidateCartOpen',
    'cart',
    'submissions',
    'unsavedChanges',
    'pendingOnePagers',
    'fetchedSubmissions',
  ])

  const title = useMemo(() => {
    if (showEmail && cart) {
      return t('submissionDetail.reviewAndSubmit.stepperLabel')
    }
    if (selectedCandidate) {
      return selectedCandidate.entityStatus === CartCandidateStatuses.Ready
        ? t('submissionList.multipleCandidates.candidateDetails')
        : t('submissionList.multipleCandidates.addCandidateDetails')
    }

    return t('submissionList.multipleCandidates.modalTitle')
  }, [t, showEmail, cart, selectedCandidate])

  const selectedCandidates = useMemo(
    () =>
      !cart
        ? []
        : cart.entities.reduce((acc, current) => {
            const found = submissions.find(
              (submission) => submission.jobSubmissionId === current.id
            )
            if (found) {
              const backgroundColor = avatarColors?.find(
                (c) => c.avatarId === found.atsJobSubmissionId
              )?.color

              acc.push({
                backgroundColor: backgroundColor ?? '',
                firstName: found.firstName,
                lastName: found.lastName,
                email: found.email,
                jobSubmissionId: found.jobSubmissionId,
                atsJobSubmissionId: found.atsJobSubmissionId,
                entityStatus: current.status,
                status: found.status,
                submissionSource: found.submissionSource,
                atsSubmissionStatus: found.atsSubmissionStatus,
              })
            }
            return acc
          }, [] as ICartCandidate[]),
    [cart, submissions, avatarColors]
  )

  const unpreppedCandidates = useMemo(
    () =>
      selectedCandidates.reduce((acc, current) => {
        // If the one pager is enabled, check if this candidate has a one pager pending
        const parsingOnePager =
          IS_ONE_PAGER_ENABLED &&
          !!fetchedSubmissions?.find((p) => p.id === current.jobSubmissionId)
            ?.candidate.isOnePagerEnabled &&
          !!pendingOnePagers.find(
            (p) => p.jobSubmissionId === current.jobSubmissionId
          )
        if (
          current.entityStatus !== CartCandidateStatuses.Ready ||
          parsingOnePager
        ) {
          acc.push({
            ...current,
            parsingOnePager,
          })
        }
        return acc
      }, [] as ICartCandidate[]),
    [
      selectedCandidates,
      pendingOnePagers,
      fetchedSubmissions,
      IS_ONE_PAGER_ENABLED,
    ]
  )

  const preppedCandidates = useMemo(
    () =>
      selectedCandidates.filter(
        (candidate) =>
          !unpreppedCandidates.some(
            (unprepped) =>
              unprepped.jobSubmissionId === candidate.jobSubmissionId
          )
      ),
    [selectedCandidates, unpreppedCandidates]
  )

  const findStatusDifferences = useCallback(
    (fetchedCart: ICart): ICartEntityStatus[] | undefined => {
      return cart?.entities.filter((currentCartItem) => {
        const matchingItem = fetchedCart.entities.find(
          (fetchedCartItem) => fetchedCartItem.id === currentCartItem.id
        )
        return matchingItem && currentCartItem.status !== matchingItem.status
      })
    },
    [cart]
  )

  const handleCartUpdate = useCallback(
    (c: ICart, statusDifferences: ICartEntityStatus[]) => {
      const fetched: IJobSubmission[] = IS_ONE_PAGER_ENABLED
        ? fetchedSubmissions.filter(
            (sub) =>
              !statusDifferences.find((diff) => diff.id === sub.id) &&
              !pendingOnePagers.find((p) => p.jobSubmissionId === sub.id)
          )
        : []

      submissionStore.dispatch({
        cart: c,
        ...(IS_ONE_PAGER_ENABLED ? { fetchedSubmissions: fetched } : {}),
      })
    },
    [IS_ONE_PAGER_ENABLED, fetchedSubmissions, pendingOnePagers]
  )

  // Get new cart data and update the store
  const refreshCart = useCallback(() => {
    if (cart) {
      refreshCartSubRef.current = cartService
        .getCartById(cart.id, true)
        .subscribe({
          next: (c) => {
            // Check if there are any status differences between the fetched cart and the current cart
            const statusDifferences = findStatusDifferences(c)

            if (statusDifferences) {
              handleCartUpdate(c, statusDifferences)
            }
          },
        })
    }
  }, [cart, refreshCartSubRef, handleCartUpdate, findStatusDifferences])

  const handleNextToSuccessScreen = useCallback(() => {
    setShowSuccessScreen(true)
  }, [])

  const handleClose = useCallback(() => {
    if (selectedCandidate) {
      refreshCart()

      if (unsavedChanges) {
        submissionStore.dispatch({
          isSaveConfirmationModalOpen: true,
        })
        return
      }
    }

    if (showSuccessScreen) {
      // If we are currently on a success screen (i.e we successfully submitted a group of candidates),
      // upon close we reset the cart and change the screen back to the first step
      submissionStore.dispatch({ isCandidateCartOpen: false, cart: null })
      setShowSuccessScreen(false)
    } else {
      submissionStore.dispatch({ isCandidateCartOpen: false })
    }
    setSelectedCandidate(undefined)
    setShowEmail(false)
  }, [unsavedChanges, showSuccessScreen, selectedCandidate, refreshCart])

  // Hook that handles parsing of multiple one pagers
  useParseMultipleOnePagers(selectedCandidates)

  // Automatically remove submitted candidates from the cart
  // Possible scenarios:
  // 1. Candidate was in the cart but then the user submitted them individually
  // 2. Candidate was in the cart but then the user submitted them via bullhorn
  // In both of these cases the submissionSource will not be null so we remove them
  useEffect(() => {
    const filteredCandidates = selectedCandidates.reduce((acc, candidate) => {
      if (isVendorVanderHouwen) {
        // Prevent the VH's candidates that have been submitted via BH from being removed from cart so it can be submitted again
        if (
          candidate.submissionSource !== null &&
          candidate.atsSubmissionStatus !== JobSubmissionAtsStatusLabels.Handoff
        ) {
          acc.push(candidate.jobSubmissionId)
        }
      } else if (candidate.submissionSource !== null) {
        acc.push(candidate.jobSubmissionId)
      }
      return acc
    }, [] as string[])

    let subscription: Subscription | null = null
    if (filteredCandidates.length && cart && !isCandidateCartOpen) {
      subscription = cartService
        .removeCandidatesFromCart(cart.id, filteredCandidates)
        .subscribe({
          next: () => {
            submissionStore.dispatch({
              cart: {
                ...cart,
                entities: [
                  ...cart.entities.filter(
                    (c) => !filteredCandidates.includes(c.id)
                  ),
                ],
              },
              isCandidateRemoveModalOpen: false,
            })
          },
        })
    }
    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [selectedCandidates, cart, isCandidateCartOpen, isVendorVanderHouwen])

  const activeStep = useMemo(() => {
    if (showSuccessScreen) return 2
    return showEmail ? 1 : 0
  }, [showSuccessScreen, showEmail])

  return (
    <GorillaDialog open={isCandidateCartOpen} fullScreen fullWidth>
      <IconButton
        sx={{ position: 'absolute', top: '4px', right: '4px' }}
        aria-label={t('common.aria.close')}
        onClick={handleClose}
      >
        <CloseIcon color="primary" />
      </IconButton>

      <DialogContent ref={modalRef}>
        <Grid
          container
          justifyContent="space-between"
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            mx: 0,
            py: 2,
            mb: 2,
            width: '100%',
          }}
        >
          <Grid
            xs={5}
            sx={{ minWidth: 'fit-content', pl: 0 }}
            item
            flexDirection="row"
            alignItems="baseline"
          >
            <DialogTitle
              variant="h5"
              sx={{ p: 0, flex: 'unset', whiteSpace: 'nowrap' }}
            >
              {title}
            </DialogTitle>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{ minWidth: '300px', marginLeft: 'auto', mr: '-24px' }}
          >
            <Stepper alternativeLabel activeStep={activeStep}>
              <Step
                key="prep"
                completed={
                  (selectedCandidates.length &&
                    preppedCandidates.length === selectedCandidates.length) ||
                  false
                }
              >
                <StepLabel>
                  {t('submissionList.multipleCandidates.prepCandidates')}
                </StepLabel>
              </Step>
              <Step key="review" completed={showSuccessScreen}>
                <StepLabel>{t('submissionList.reviewAndSubmit')}</StepLabel>
              </Step>
              <Step key="success" completed={showSuccessScreen}>
                <StepLabel>
                  {t('submissionDetail.submissionSuccess.stepperLabel2')}
                </StepLabel>
              </Step>
            </Stepper>
          </Grid>
        </Grid>
        <CandidateCartModalContent
          showSuccessScreen={showSuccessScreen}
          showEmail={showEmail}
          setShowEmail={setShowEmail}
          preppedCandidates={preppedCandidates}
          selectedCandidates={selectedCandidates}
          unpreppedCandidates={unpreppedCandidates}
          selectedCandidate={selectedCandidate}
          setSelectedCandidate={setSelectedCandidate}
          handleNextToSuccessScreen={handleNextToSuccessScreen}
          handleScrollToTop={() => {
            if (modalRef?.current?.firstChild) {
              modalRef.current.scrollTop = 0
            }
          }}
          refreshCart={refreshCart}
        />
      </DialogContent>
    </GorillaDialog>
  )
}
