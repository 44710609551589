import React, { FC, useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { Typography } from '@mui/material'
import { useObservable } from 'rxjs-hooks'
import { submissionService } from '@submission-portal/services'

const PIN_LIVE_DURATION = 2 * 60 * 1000 // 2 minutes

export const MapComponent: FC = () => {
  const [pinData, setPinData] = useState<any>()

  const isSecondLoad = useObservable(
    () => submissionService.isSecondLoad$,
    false
  )

  const jobSubmissionReport = useObservable(
    () => submissionService.jobSubmissionReport$,
    null
  )

  const currentIndex = useObservable(() => submissionService.currentIndex$, 0)

  useEffect(() => {
    const addDataWithDelay = (): void => {
      if (jobSubmissionReport && currentIndex < jobSubmissionReport?.length) {
        setTimeout(() => {
          setPinData((prevData: any) => {
            const updatedPins = prevData ? [...prevData] : []
            return [...updatedPins, jobSubmissionReport[currentIndex]]
          })
          submissionService.updateCurrentIndex()
        }, 200)
      }
    }
    if (isSecondLoad) addDataWithDelay()
  }, [currentIndex, isSecondLoad, jobSubmissionReport])

  useEffect(() => {
    const removeDataWithDelay = (): void => {
      if (pinData?.length > 0) {
        setTimeout(() => {
          setPinData((prevData: any) => prevData?.slice(1))
        }, PIN_LIVE_DURATION)
      }
    }
    removeDataWithDelay()
  }, [pinData])

  const getMarkerIcon = (condition: number): L.Icon<L.IconOptions> => {
    let iconUrl = ''
    // Set icon URL based on the condition
    if (condition === 1) {
      iconUrl =
        'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png'
    } else if (condition === 2) {
      iconUrl =
        'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png'
    } else {
      iconUrl =
        'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png'
    }

    return L.icon({
      iconUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    })
  }

  return (
    <MapContainer
      center={[51, -114]}
      zoom={3}
      style={{ height: '400px', width: '100%' }}
    >
      <TileLayer url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png" />
      {pinData?.map((event: any, index: number) =>
        event?.latitude && event?.longitude ? (
          <Marker
            key={`${event?.atsJobSubmissionId}-${index}`}
            position={[event?.latitude, event?.longitude]}
            icon={getMarkerIcon(event?.submissionType)}
          >
            <Tooltip>
              <Typography>{event?.accountManagerName}</Typography>
            </Tooltip>
          </Marker>
        ) : (
          <React.Fragment key={event?.atsJobSubmissionId} />
        )
      )}
    </MapContainer>
  )
}
