import { useCallback } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { DateAvailableDataTypes } from '../enums'
import { detectDateFormat, getNormalizedDateString, isADate } from '../util'

export const useGetDateAvailable = (): ((
  dateAvailable: string | undefined,
  dateAvailableType: string,
  dateAvailableUserInput: string | undefined
) => string) => {
  const { t } = useTranslation('main')

  return useCallback(
    (
      dateAvailable: string | undefined,
      dateAvailableType: string,
      dateAvailableUserInput: string | undefined
    ) => {
      switch (dateAvailableType) {
        case DateAvailableDataTypes.Custom:
          return dateAvailableUserInput ?? ''
        case DateAvailableDataTypes.Immediate:
          return t('common.candidates.availability.immediate')
        case DateAvailableDataTypes.TwoWeeksNotice:
          return t('common.candidates.availability.twoWeeks')
        default: {
          if (dateAvailable) {
            const detectedFormat = detectDateFormat(dateAvailable)
            if (detectedFormat) {
              return getNormalizedDateString(
                DateTime.fromFormat(
                  dateAvailable,
                  detectedFormat
                ).toISODate() || ''
              )
            }

            return isADate(dateAvailable)
              ? getNormalizedDateString(dateAvailable)
              : dateAvailable
          }
          return ''
        }
      }
    },
    [t]
  )
}
