import React, { FC } from 'react'
import { useField } from 'formik'

import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, IconButtonProps } from '@mui/material'

interface IClearIconProps extends IconButtonProps {
  value?: string | string[]
  hide?: boolean
}
export const ClearIconBtn: FC<IClearIconProps> = ({
  hide = false,
  value,
  sx,
  ...props
}) => {
  return hide || !value ? null : (
    <IconButton
      tabIndex={-1}
      sx={{
        visibility: value ? 'visible' : 'hidden',
        position: 'absolute',
        right: '6px',
        marginRight: 2.5,
        p: 0.5,
        ...sx,
      }}
      {...props}
    >
      <ClearIcon />
    </IconButton>
  )
}

export const ClearFormikBtn: FC<
  IClearIconProps & {
    name: string
  }
> = ({ name, onClick, ...rest }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<string>(name)
  return (
    <ClearIconBtn
      value={field.value}
      {...rest}
      onClick={(e) => {
        helpers.setValue('')
        onClick?.(e)
      }}
    />
  )
}
