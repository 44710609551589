import { NodeEventHandler } from 'rxjs/internal/observable/fromEvent'

import { DeltaResponse } from '@common/models'

export class SequencedDataEmitter {
  private buffer: DeltaResponse[] = []

  private readonly handler: NodeEventHandler

  private nextIndex = 0

  private requestId: string | null = null

  constructor(private readonly props: { handler: NodeEventHandler }) {
    this.handler = props.handler
  }

  // Method to receive data packets
  receiveDataPacket(packet: DeltaResponse): void {
    // Check if the packet is part of a new request
    if (this.requestId !== packet.requestId) {
      // If it's a new request, reset the emitter
      this.requestId = packet.requestId
      this.reset()
    }
    if (packet.index === this.nextIndex) {
      // Emit the packet immediately if it's in the correct sequence
      this.emitData(packet.value)
      this.nextIndex += 1
      this.checkBuffer()
    } else {
      // Otherwise, buffer the packet
      this.buffer.push(packet)
    }
  }

  // Method to emit data in the correct sequence
  emitData(value: string): void {
    this.handler(value)
  }

  private reset(): void {
    this.nextIndex = 0
    this.buffer = []
  }

  // Check if any buffered packets can be emitted
  private checkBuffer(): void {
    while (this.buffer.length > 0) {
      const nextPacket = this.buffer.find(
        (packet) => packet.index === this.nextIndex
      )
      if (nextPacket) {
        this.emitData(nextPacket.value)
        this.nextIndex += 1
        this.buffer.splice(this.buffer.indexOf(nextPacket), 1)
      } else {
        break // Stop if no more packets can be emitted
      }
    }
  }
}
