import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PersonIcon from '@mui/icons-material/Person'
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { ButtonProps } from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import {
  CartCandidateStatuses,
  getInitials,
  ICartCandidate,
} from '@procom-labs/common'

interface StatusButtonProps extends ButtonProps {
  entityStatus?: CartCandidateStatuses
}

const StatusButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'entityStatus',
})<StatusButtonProps>(({ theme, entityStatus }) => ({
  width: '172px',
  color: theme.palette.common.black,
  fontSize: `${theme.typography.body1.fontSize} !important`,
  fontWeight: theme.typography.body1.fontWeight,
  textTransform: 'none',
  backgroundColor:
    entityStatus === CartCandidateStatuses.Ready
      ? alpha(theme.palette.success.light, 0.6)
      : alpha(theme.palette.warning.light, 0.6),
  flex: '0 0 auto',
  '&:hover': {
    backgroundColor:
      entityStatus === CartCandidateStatuses.Ready
        ? alpha(theme.palette.success.light, 0.4)
        : alpha(theme.palette.warning.light, 0.4),
  },
}))

export const CandidateCartList: FC<{
  candidates: ICartCandidate[]
  label: string
  labelBackgroundColor: string
  setSelectedCandidate: (candidate: ICartCandidate) => void
  removeCandidate: (jobSubmissionId: string) => void
  status?: CartCandidateStatuses
}> = React.memo(
  ({
    candidates,
    setSelectedCandidate,
    removeCandidate,
    label,
    labelBackgroundColor,
    status,
  }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    return (
      <>
        <Box
          key={label}
          sx={{
            width: 'fit-content',
            px: 2,
            mb: 1,
            borderRadius: '4px',
            backgroundColor: labelBackgroundColor,
          }}
        >
          <Typography>{label}</Typography>
        </Box>

        <List
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '15px',
            padding: '0px',
            mb: 2,
          }}
        >
          {candidates?.map((candidate, index) => (
            <div key={candidate.atsJobSubmissionId ?? candidate.email}>
              <Grid
                container
                component="li"
                columnSpacing={1}
                rowSpacing={2}
                sx={{ p: '10px 18px', justifyContent: 'space-between' }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src=""
                    alt={`${candidate.firstName}-${candidate.lastName}`}
                    sx={{
                      backgroundColor: candidate.backgroundColor
                        ? `#${candidate.backgroundColor}`
                        : '000000',
                      width: 44,
                      height: 44,
                      fontWeight: 400,
                      fontSize: 20,
                      lineHeight: 20,
                      color: (innerTheme) => innerTheme.palette.common.white,
                    }}
                  >
                    {getInitials(
                      `${candidate.firstName} ${candidate.lastName}`,
                      2
                    ) || <PersonIcon sx={{ width: 35, height: 35 }} />}
                  </Avatar>
                  <Typography sx={{ ml: '12px' }}>
                    {`${candidate.firstName} ${candidate.lastName}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 'fit-content',
                  }}
                >
                  <EmailOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, mt: '2px' }}
                  />
                  <Typography>{candidate.email}</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    minWidth: 'fit-content',
                    marginLeft: 'auto',
                  }}
                >
                  {candidate.parsingOnePager && (
                    <Tooltip title={t('candidateOnePagerParser.loading')}>
                      <CircularProgress size={20} sx={{ mr: 2 }} />
                    </Tooltip>
                  )}

                  <StatusButton
                    entityStatus={status}
                    onClick={() => setSelectedCandidate(candidate)}
                  >
                    {status === CartCandidateStatuses.Ready
                      ? t('submissionList.multipleCandidates.ready')
                      : t('submissionList.multipleCandidates.prepCandidate')}
                  </StatusButton>
                  <Tooltip
                    title={t(
                      'submissionList.multipleCandidates.removeCandidate'
                    )}
                  >
                    <IconButton
                      onClick={() => removeCandidate(candidate.jobSubmissionId)}
                      edge="end"
                      aria-label="delete"
                      sx={{ ml: '8px' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              {index < candidates.length - 1 && (
                <Divider key={`divider-${candidate.atsJobSubmissionId}`} />
              )}
            </div>
          ))}
        </List>
      </>
    )
  }
)
