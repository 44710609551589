import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox, FormControlLabel } from '@mui/material'

export interface CheckboxWithLabelProps {
  name: string
  label: string
}

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  name,
  label,
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          }
          label={label}
        />
      )}
    />
  )
}
