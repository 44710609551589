import React, { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FileCopyOutlined, HighlightOutlined } from '@mui/icons-material'

import { ListField, ListFieldProps } from './list-field'

export enum AdditionalInfoType {
  Certifications = 'certifications',
  AdditionalHighlights = 'additionalHighlights',
}

const certificationProps: Omit<ListFieldProps, 'title' | 'removePrompt'> = {
  name: AdditionalInfoType.Certifications,
  switchName: 'visibility.Certifications',
  textFieldName: 'name',
  icon: FileCopyOutlined,
  showDate: true,
  emptyValue: { name: '', date: null },
}

const additionalHighlightsProps: Omit<
  ListFieldProps,
  'title' | 'removePrompt'
> = {
  name: AdditionalInfoType.AdditionalHighlights,
  switchName: 'visibility.AdditionalHighlights',
  textFieldName: 'value',
  icon: HighlightOutlined,
  emptyValue: { value: '', key: null },
}

export const AdditionalInfoEdit: FC<{ name: AdditionalInfoType }> = memo(
  ({ name }) => {
    const { t } = useTranslation('main')

    const listFieldProps: ListFieldProps = useMemo(
      () =>
        name === AdditionalInfoType.Certifications
          ? {
              ...certificationProps,
              title: t('organisms.candidateOnePager.certifications'),
              removePrompt: t(
                'organisms.candidateOnePager.certificationsRemove'
              ),
            }
          : {
              ...additionalHighlightsProps,
              title: t('organisms.candidateOnePager.additionalHighlights'),
              removePrompt: t(
                'organisms.candidateOnePager.additionalHighlightsRemove'
              ),
            },
      [name, t]
    )

    return <ListField {...listFieldProps} />
  }
)
