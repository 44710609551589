import { useCallback, useEffect, useMemo } from 'react'

import {
  colorPallete,
  getRandomColor,
  IAvatarColor,
  useSubjectSelector,
} from '@procom-labs/common'

import { AvatarStore } from '@molecules/stores'

/**
 * useAvatarColor - This hook is utilized to obtain a color for a given avatar ID.
 *
 * It guarantees an immediate value by either retrieving it from the store or generating
 * a temporary one if it doesn't exist yet. The primary purpose of this hook is to ensure
 * that components relying on avatar colors don't have to deal with undefined or null values.
 * Any new colors that are generated on-the-fly are dispatched to the store for consistency.
 *
 * @param {string} id - The ID of the avatar for which the color is needed.
 * @returns {IAvatarColor} - The color associated with the avatar ID.
 */
export const useAvatarColor = (
  id: string,
  avatarStore: AvatarStore
): IAvatarColor => {
  const { colors } = useSubjectSelector(avatarStore, ['colors'])

  const [color, shouldUpdate] = useMemo(() => {
    const avatarColor = colors.find((current) => current.avatarId === id)
    if (avatarColor) {
      return [avatarColor, false]
    }
    return [
      {
        avatarId: id,
        color: getRandomColor(colorPallete),
      },
      true,
    ]
  }, [id, colors])

  useEffect(() => {
    if (shouldUpdate) {
      const updatedColors = colors.filter((current) => current.avatarId !== id) // Removing any existing color with this ID.
      avatarStore.dispatch({
        colors: [...updatedColors, color],
      })
    }
  }, [id, colors, color, shouldUpdate, avatarStore])

  return color
}

/**
 * useFetchAvatarColors - This hook is designed to optimize and batch retrieve colors
 * for a list of avatar IDs.
 *
 * It primarily serves scenarios where multiple avatar colors are required at once, thereby
 * reducing redundant individual calls and ensuring that all avatar colors are fetched or generated
 * in a single batch. This is particularly useful for lists or grids of avatars to maintain performance.
 *
 * @returns {function} - A callback function that takes an array of avatar IDs and returns
 * their associated colors.
 */
export const useFetchAvatarColors = (
  avatarStore: AvatarStore
): ((ids: string[]) => IAvatarColor[]) => {
  const { colors } = useSubjectSelector(avatarStore, ['colors'])

  return useCallback(
    (ids: string[]): IAvatarColor[] => {
      // Extract the colors that already exist in the store
      const existingColors = ids
        .map((id) => colors.find((current) => current.avatarId === id))
        .filter(Boolean) as IAvatarColor[]

      // Identify the ids that do not have a corresponding color
      const missingIds = ids.filter(
        (id) => !existingColors.some((color) => color.avatarId === id)
      )

      // Generate colors for the missing ids
      const newColors = missingIds.map((id) => ({
        avatarId: id,
        color: getRandomColor(colorPallete),
      }))

      // Dispatch the new colors to the store
      if (newColors.length > 0) {
        avatarStore.dispatch({
          colors: [...colors, ...newColors],
        })
      }

      return [...existingColors, ...newColors]
    },
    [avatarStore, colors]
  )
}
