import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useObservable } from 'rxjs-hooks'

import {
  IAvailabilityPayload,
  JobSubmissionAtsStatusLabels,
  useSubjectSelector,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'
import { CandidateRequestInterviewModalContent } from '@procom-labs/organisms'

import { useUpdateAtsStatus } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-info-hooks'
import { authService } from '@submission-portal/services'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

export const CandidateRequestInterviewModal: React.FC = () => {
  const { t } = useTranslation('main')
  const { addAlert } = useAlert()

  const {
    isModalRequestInterviewOpen: open,
    modalCandidateData: candidate,
    accountManagerName,
  } = useSubjectSelector(candidatesPrepStore, [
    'isModalRequestInterviewOpen',
    'modalCandidateData',
    'accountManagerName',
  ])

  const [isAvailabilitySent, setIsAvailabilitySent] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const currentUser = useObservable(() => authService.authData$)

  const updateAtsStatus = useUpdateAtsStatus()

  const onSubmit = useCallback(
    (
      payloadFormatted: IAvailabilityPayload[],
      interviewRequestAdditionalNotes: string
    ): void => {
      if (!candidate) return

      if (candidate.atsUserId && candidate.jobSubmissionId) {
        updateAtsStatus(
          {
            atsUserId: candidate.atsUserId,
            jobSubmissionId: candidate.jobSubmissionId,
            additionalData: {
              interviewRequest: {
                interviewRequesterName: currentUser?.user?.displayName ?? '',
                interviewRequesterEmail: currentUser?.user?.email ?? '',
                interviewRequestAdditionalNotes,
                interviewDateTimes: payloadFormatted,
              },
            },
          },
          JobSubmissionAtsStatusLabels.InterviewRequested,
          {
            complete: () => {
              setIsAvailabilitySent(true)
              setIsEditing(true)
              addAlert({
                severity: 'success',
                message: t('common.alert.itemSaved'),
              })
              candidatesPrepStore.dispatch({
                candidateStatus:
                  JobSubmissionAtsStatusLabels.InterviewRequested,
              })
            },
            error: () => {
              candidatesPrepStore.dispatch({
                isModalRequestInterviewOpen: false,
              })
              addAlert({
                message: t('common.alert.somethingWrong'),
                severity: 'error',
              })
            },
          }
        )
      } else {
        addAlert({
          message: t('common.alert.somethingWrong'),
          severity: 'error',
        })
      }
    },
    [
      updateAtsStatus,
      addAlert,
      candidate,
      currentUser?.user?.displayName,
      currentUser?.user?.email,
      t,
    ]
  )

  const handleClose = useCallback(() => {
    candidatesPrepStore.dispatch({
      isModalRequestInterviewOpen: false,
      modalCandidateData: {},
      modalAtsStatus: '',
    })
    setIsAvailabilitySent(false)
    setIsEditing(false)
  }, [])

  return (
    <CandidateRequestInterviewModalContent
      open={open}
      handleClose={handleClose}
      isAvailabilitySent={isAvailabilitySent}
      candidate={candidate}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      accountManagerName={accountManagerName}
      onSubmit={onSubmit}
    />
  )
}
