import {
  DateRangeFilter,
  ILocation,
  JobSubmissionAtsStatusLabels,
  SortOrder,
} from '@procom-labs/common'

const DefaultPageSize = 20

export interface ClientPortalVisibility {
  isRateVisible: boolean
  isAvailabilityVisible: boolean
  isLegalStatusVisible: boolean
  isLocationVisible: boolean
}

export interface CandidateResult {
  count: number
  candidates: Candidate[]
}

export interface Candidate {
  id: number
  name: string
  dateAvailable: number
  dateAdded: number | null
  email: string | null
  phone: string | null
  mobile: string | null
  hourlyRate: number | null
  salary: number
  address: Address
  employeeType: string | null
  firstName: string
  lastName: string
  customText23: string | null
  customText24: string | null
  status: string
  dateLastModified: number
  source: string
  customText12: string | null
  owner: Owner
}

export interface Address {
  address1: string
  address2: string
  city: string
  countryCode: string
  countryID: number
  countryName: string
  state: string
  zip: string
}

export interface Owner {
  id: number
  firstName: string
  lastName: string
  email: string | null
  name: string | null
  username: string | null
}

export interface SubmissionSearchFilter {
  dateAddedRange: DateRangeFilter
  dateLastModifiedRange: DateRangeFilter
  clientActivity: string[]
  reviewAndSubmit: string[]
  pageSize: number
  sortOrder: SortOrder
  sortField: string
  currentPage: number
  keyword: string
}

export interface SubmissionPrepSearchFilter {
  dateAddedRange: DateRangeFilter
  dateLastModifiedRange: DateRangeFilter
  clientActivity: string[]
  reviewAndSubmit: string[]
  pageSize: number
  sortOrder: SortOrder
  sortField: string
  currentPage: number
  keyword: string
}

export const DefaultSubmissionSearchFilter: SubmissionSearchFilter = {
  pageSize: DefaultPageSize,
  sortOrder: SortOrder.desc,
  sortField: '',
  currentPage: 0,
  keyword: '',
  dateAddedRange: {},
  dateLastModifiedRange: {},
  clientActivity: [],
  reviewAndSubmit: [],
}
export const DefaultCandidatesPrepSearchFilter: CandidatesPrepSearchFilter = {
  pageSize: DefaultPageSize,
  sortOrder: SortOrder.desc,
  sortField: '',
  currentPage: 0,
  keyword: '',
  readyToPrep: false,
  submittedToManager: false,
  submittedToClient: false,
  aMHoldShowMore: false,
  aMHoldNeedInfo: false,
  aMHoldRTR: false,
  aMHoldTentative: false,
  aMHoldWillSubmit: false,
  aMLockUp: false,
  confirmed: false,
  placement: false,
  interviewRequested: false,
  interviewing: false,
  shortlistedByMSP: false,
  clientFeedback: false,
  rejected: false,
}

export interface ICandidatesPrepSearchableFields {
  rejected: boolean
  aMHoldNeedInfo: boolean
  aMHoldRTR: boolean
  aMHoldShowMore: boolean
  aMHoldTentative: boolean
  aMHoldWillSubmit: boolean
  aMLockUp: boolean
  clientFeedback: boolean
  confirmed: boolean
  interviewRequested: boolean
  interviewing: boolean
  placement: boolean
  shortlistedByMSP: boolean
  readyToPrep: boolean
  submittedToManager: boolean
  submittedToClient: boolean
}

export interface CandidatesPrepSearchFilter
  extends ICandidatesPrepSearchableFields {
  pageSize: number
  sortOrder: SortOrder
  sortField: string
  currentPage: number
  keyword: string
}

export enum CandidatesPrepStep {
  Upload = 0,
  Prep,
}

export enum FileModes {
  Select = 'select',
  Upload = 'upload',
}

export interface CandidatePrepInfoRecord {
  firstName: string
  lastName: string
  atsSubmissionStatus: JobSubmissionAtsStatusLabels | null
  atsJobSubmissionId: number
  dateAddedOnAts: string
  hiringManagerName: string
  atsUserId: number
  jobSubmissionId: string
}

export interface ICandidatePrepProfileData {
  dateAvailable: string
  billRate: number
  employmentType: string
  location: ILocation
}
export interface ICandidatePrepFormValues {
  clientPortalFieldsVisibility: ClientPortalVisibility
  profile: ICandidatePrepProfileData
  highlights: string
}

export interface IEmailForm {
  language: string
  emailContent: string
  emailSubject: string
  emailFrom: string[]
  emailTo: string[]
  emailToCc: string[]
  emailToBcc: string[]
  sendEmailCopyToSender: boolean
  includeHighlights?: boolean
}
