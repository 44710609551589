import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useSubjectSelector } from '@procom-labs/common'
import { SearchBar } from '@procom-labs/organisms'

import { candidatePrepSelectStore } from '@submission-portal/stores/candidate-prep-select-store'

export const BullhornDataSearchBar: FC<{
  handleChangeKeyword: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchBarLabel?: string
}> = ({ handleChangeKeyword, searchBarLabel }) => {
  const { t } = useTranslation('main')

  const { searchFilter } = useSubjectSelector(candidatePrepSelectStore, [
    'searchFilter',
  ])

  return (
    <SearchBar
      handleChangeKeyword={handleChangeKeyword}
      keyword={searchFilter.keyword}
      searchLabel={
        searchBarLabel ?? t('candidatePreb.searchCandidatePlaceholder')
      }
    />
  )
}
