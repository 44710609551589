import { BaseSubjectStore, IAvatarColor } from '@procom-labs/common'

export interface IAvatarStoreState {
  colors: IAvatarColor[]
}

export class AvatarStore extends BaseSubjectStore<IAvatarStoreState> {
  getDefaultValue(): IAvatarStoreState {
    return {
      colors: [],
    }
  }
}
