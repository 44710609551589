import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useObservable } from 'rxjs-hooks'

import { Grid, Typography, useTheme } from '@mui/material'
import { ClientPortalBackground, GorillaGrid } from '@procom-labs/atoms'
import {
  clientService,
  DateValueFormat,
  formatDates,
  useSubscriptionRef,
} from '@procom-labs/common'
import { BackdropLoader } from '@procom-labs/molecules'

import { IChartData, JobSubmissionReport } from '@submission-portal/models'
import { submissionService } from '@submission-portal/services'

import { ChartComponent } from './chart-component'
import { LeaderBoardComponent } from './leader-board-component'
import { MapComponent } from './map-component'

let COUNTER = -1

const FETCH_DATA_INTERVAL = 5 * 60 * 1000

const SubmissionTypes: Record<number, string> = {
  1: 'VMS/MSP Submission',
  2: 'Email Submission',
  3: 'Portal Submission',
}

export const EventsTracking: FC<{}> = () => {
  const [chartData, setChartData] = useState<IChartData[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [eventsData, setEventsData] = useState<JobSubmissionReport[]>([])
  const theme = useTheme()
  const { t } = useTranslation()
  const subscriptionRef = useSubscriptionRef()

  const [searchParams] = useSearchParams()
  const vendorCode = searchParams.get('vendorCode') ?? ''
  const apiKey = searchParams.get('apiKey') ?? ''

  const jobSubmissionReport = useObservable(
    () => submissionService.jobSubmissionReport$,
    null
  )

  const isSecondLoad = useObservable(
    () => submissionService.isSecondLoad$,
    false
  )

  const [date, time] = useMemo(() => {
    return String(
      formatDates(
        DateValueFormat.DateTimeFullTimeSimple,
        new Date().toISOString()
      )
    ).split(' at ')
  }, [])

  const currentWeekMonday = useMemo(() => {
    const today = new Date()
    const dayOfWeek = today.getDay()
    // Calculate the difference between today and Monday
    const difference = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
    // Create a new Date object for Monday of the current week at midnight
    const mondayDate = new Date(today.setDate(difference))
    mondayDate.setHours(0, 0, 0, 0)
    return mondayDate.toISOString()
  }, [])

  useEffect(() => {
    let index = 0
    const today = new Date()
    const dayOfWeek = today.getDay()
    if (dayOfWeek === 1) {
      // today is Monday, refresh the data from current weeks monday to onward
      setEventsData([])
    }
    const addToEventData = (): void => {
      if (jobSubmissionReport && index < jobSubmissionReport?.length) {
        setTimeout(() => {
          setEventsData((prevData) => [...prevData, jobSubmissionReport[index]])
          index += 1
          addToEventData()
        }, 200)
        clientService.backDropLoading = false
      }
    }

    addToEventData()
  }, [jobSubmissionReport])

  useEffect(() => {
    // for checking 2nd fetch call
    const fetchJobSubmissionReport = (): void => {
      COUNTER += 1
      // show backdrop only on initial load
      if (!isSecondLoad) clientService.backDropLoading = true
      // get past data fetch interval from current date
      const currentDate = new Date()
      const pastIntervalTime = new Date(
        currentDate.getTime() - FETCH_DATA_INTERVAL
      )
      const isoStringPastIntervalTime = pastIntervalTime.toISOString()

      subscriptionRef.current = submissionService
        .getJobSubmissionReport({
          apiKey,
          fromDate:
            COUNTER >= 1
              ? isoStringPastIntervalTime ?? ''
              : currentWeekMonday ?? '',
          toDate: currentDate.toISOString(),
          vendor: vendorCode,
        })
        .subscribe({
          complete: () => {
            if (COUNTER) submissionService.setIsSecondLoad(true)
          },
        })
    }
    // Fetch data on initial visit
    if (!isSecondLoad) fetchJobSubmissionReport()
    // Set interval to fetch data every 5 minutes
    const interval = setInterval(fetchJobSubmissionReport, FETCH_DATA_INTERVAL)

    return () => clearInterval(interval)
  }, [subscriptionRef, currentWeekMonday, apiKey, vendorCode, isSecondLoad])

  useEffect(() => {
    if (eventsData && currentIndex < eventsData.length) {
      setChartData((prevChartData) => [
        ...prevChartData,
        {
          label: SubmissionTypes[eventsData[currentIndex].submissionType],
          value: eventsData[currentIndex].submissionType,
        },
      ])
      setCurrentIndex((prevIndex) => prevIndex + 1)
    }
  }, [currentIndex, eventsData])

  return eventsData.length ? (
    <GorillaGrid p={6}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography
          variant="h4"
          gutterBottom
          mb={3}
          color={theme.palette.common.white}
        >
          {t('common.eventTracking.title')}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          mb={2}
          color={theme.palette.common.white}
          mr={6}
        >
          {t(`common.eventTracking.lastUpdatedAt`, {
            date,
            time,
          })}
        </Typography>
      </Grid>
      <>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <MapComponent />
          </Grid>
          <Grid item xs={6}>
            <ChartComponent eventData={chartData} />
          </Grid>
        </Grid>
        <LeaderBoardComponent eventData={eventsData} />
      </>
      <ClientPortalBackground />
    </GorillaGrid>
  ) : (
    <BackdropLoader />
  )
}
