import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { groupBy } from '@procom-labs/common'
import { IChartData } from '@submission-portal/models'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const colors = [
  'rgba(31, 138, 79,255)',
  'rgba(203,38,58,255)',
  'rgba(69,150,207,255)',
]

const options = {
  plugins: {
    legend: {
      display: false, // Hide the legend (graph label)
    },
  },
}

export const ChartComponent: FC<{ eventData: IChartData[] }> = ({
  eventData,
}) => {
  const dict = groupBy(eventData, 'value')
  const data = {
    labels: Object.entries(dict).map(
      ([, value]: any) => `${value[0].label} (${value.length})`
    ),
    datasets: [
      {
        label: 'Submissions',
        data: Object.entries(dict).map(([, value]: any) => value?.length),
        backgroundColor: () =>
          Object.entries(dict).map(([key]: any) => colors[key]),
      },
    ],
  }

  return <Bar data={data as any} options={options} />
}
