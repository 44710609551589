import React, { FC } from 'react'
import { FieldInputProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { MenuItem, Select } from '@mui/material'
import { RatePeriod } from '@procom-labs/common'

export const RatePeriodSelect: FC<{
  field: FieldInputProps<any>
  labelId?: string
}> = ({ field, labelId }) => {
  const { t } = useTranslation('main')

  return (
    <Select
      size="small"
      labelId={labelId}
      defaultValue={RatePeriod.Hour}
      variant="standard"
      SelectDisplayProps={{
        style: {
          paddingBottom: '2px',
        },
      }}
      sx={{
        '& .MuiSelect-select': {
          width: '47px',
        },
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        backgroundColor: 'common.white',
      }}
      {...field}
    >
      <MenuItem key={0} value={RatePeriod.Hour}>
        {t('common.compensation.hour')}
      </MenuItem>
      <MenuItem key={1} value={RatePeriod.Day}>
        {t('common.compensation.day')}
      </MenuItem>
      <MenuItem key={2} value={RatePeriod.Month}>
        {t('common.compensation.month')}
      </MenuItem>
    </Select>
  )
}
