import React, { FC, ReactNode } from 'react'
import { alpha, Theme } from '@mui/material'
import {
  ChipDropdown,
  ChipDropdownProps,
  DropdownItem,
  ExtendedSxProps,
} from './chip-dropdown'

interface LegendChipDropdownProps extends ChipDropdownProps {
  legendColor: string
  renderMenuItem: (props: {
    item: DropdownItem
    selectedItemLabel: string
    handleItemSelect: (event: React.MouseEvent<HTMLElement>) => void
    sx?: ExtendedSxProps<Theme>
  }) => ReactNode
}

const commonSx = {
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '14px',
  letterSpacing: '0px',
  deleteIconColor: null,
  borderRadius: '4px',
  p: '4px',
  '& .MuiChip-label': {
    padding: '0px',
  },
}
export const LegendChipDropdown: FC<LegendChipDropdownProps> = ({
  legendColor,
  ...rest
}) => (
  <ChipDropdown
    sx={{
      ...commonSx,
      height: '22px',
      backgroundColor: alpha(legendColor, 0.12),
      '&:hover': {
        backgroundColor: alpha(legendColor, 0.12),
        borderColor: alpha('#000', 0.26),
      },
      border: `1px solid ${alpha(legendColor, 0.5)}`,
    }}
    menuSx={{ '& .MuiList-padding': { padding: '4px 8px 8px 8px' } }}
    menuItemsSx={{
      ...commonSx,
      mt: '4px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
    }}
    {...rest}
  />
)
