import Axios from 'axios-observable'
import { finalize, map, Observable } from 'rxjs'

import { SuperSubject } from '../models'

interface FileActionsServiceConfig {
  baseEndpoint: string
  axios: Axios
}

export class FileActionsService {
  private isGetFileTextLoadingSubject = new SuperSubject<boolean>(false)

  isGetFileTextLoading$ = this.isGetFileTextLoadingSubject.observable$

  private readonly baseEndpoint: string

  private readonly axios: Axios

  constructor(config: FileActionsServiceConfig) {
    this.baseEndpoint = config.baseEndpoint
    this.axios = config.axios
  }

  getFileText = (
    formData: FormData,
    fileExtension: string,
    formatDocToHtml = true
  ): Observable<string> => {
    this.isGetFileTextLoadingSubject.value = true
    const isFileDoc = fileExtension === 'doc' || fileExtension === 'docx'
    return this.axios
      .post(
        `${this.baseEndpoint}/File/content?${
          isFileDoc && formatDocToHtml
            ? new URLSearchParams({
                format: 'HTML',
              })
            : ''
        }`,
        formData
      )
      .pipe(
        map(({ data }) => data ?? ''),
        finalize(() => {
          this.isGetFileTextLoadingSubject.value = false
        })
      )
  }
}
