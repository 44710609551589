import React, { ReactElement } from 'react'

import Parse from 'html-react-parser'

import { styled } from '@mui/material/styles'

const Container = styled(
  'div',
  {}
)(({ theme }) => ({
  lineHeight: '1.5',
  '& ul, ol': {
    marginLeft: theme.spacing(2),
  },
}))

export const RichTextEditor: React.FC<{
  rawHTML: string
}> = ({ rawHTML }) => (<Container>{Parse(rawHTML)}</Container>) as ReactElement
