import React, { FC, ReactElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  SvgIconTypeMap,
  Switch,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useTheme } from '@mui/material/styles'
import { GorillaStack } from '@procom-labs/atoms'

interface FormToggleFieldProps {
  label: string
  switchName: string
  name: string
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }
  iconSx?: SxProps<Theme>
  render: (props: any) => ReactElement
}

export const FormToggleField: FC<FormToggleFieldProps> = ({
  label,
  switchName,
  name,
  icon: IconComponent,
  iconSx,
  render,
}) => {
  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))
  const { control } = useFormContext()

  return (
    <GorillaStack
      gap={isMobileOrTab ? 1 : 2}
      sx={{ width: '100%' }}
      direction={isMobileOrTab ? 'column' : 'row'}
      alignItems={isMobileOrTab ? 'start' : 'center'}
    >
      <GorillaStack
        gap={isMobileOrTab ? 1 : 2}
        direction="row"
        alignItems="center"
      >
        <Controller
          name={switchName}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Switch
              {...field}
              color="primary"
              checked={Boolean(field.value)}
              sx={{ ml: '-12px' }}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
        <GorillaStack gap={1} direction="row" alignItems="center">
          <IconComponent sx={iconSx} fontSize="small" />
          <Typography
            variant="body1"
            component="span"
            sx={{ minWidth: '140px', color: theme.palette.text.secondary }}
          >
            {label}
          </Typography>
        </GorillaStack>
      </GorillaStack>

      <Controller
        name={name}
        aria-label={label}
        control={control}
        render={({ field }) => render(field)}
      />
    </GorillaStack>
  )
}
