import { PaletteMode } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'

const SPACING_BASE = 8

// https://uxplanet.org/responsive-design-best-practices-c6d3f5fd163b
const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    k2: 1900,
    k4: 3000,
  },
}

const PALETTE = {
  mode: 'light' as PaletteMode,
  common: {
    black: '#000',
    white: '#fff',
    glass: '#F4F5F7',
  },
  primary: {
    main: '#008BCC',
    dark: '#006391',
    light: '#54B1DD',
    contrastText: '#fff',
  },
  secondary: {
    main: '#8a325f',
    dark: '#622443',
    light: '#b17694',
    contrastText: '#fff',
  },
  error: {
    dark: '#621B16',
    main: '#F44336',
    light: '#F88078',
    contrastText: '#fff',
  },
  warning: {
    main: '#ED6C02',
    light: '#FFB547',
    dark: '#C77700',
    contrastText: '#fff',
  },
  info: {
    dark: '#0B79D0',
    main: '#2196F3',
    contrastText: '#fff',
  },
  success: {
    main: '#4CAF50',
    light: '#7BC67E',
    dark: '#3B873E',
    contrastText: '#fff',
  },
  text: {
    primary: '#000000DE',
    secondary: '#00000099',
    disabled: '#00000061',
  },
  divider: 'rgba(0, 0, 0, 0.20)',
  background: {
    default: '#fff',
    paper: '#f6f6fa',
    offWhite: '#FAFAFA',
  },
  candidatesFunnel: {
    new: '#0288d1',
    shortlist: '#1565C0',
    interview: '#1A237E',
    offer: '#3B873E',
    rejected: '#3A1528',
  },
  jobsFunnel: {
    active: '#3B873E',
    paused: '#ED6C02',
    closed: '#3A1528',
    draft: '#000000',
  },
  alertOutlined: {
    bgSuccess: '#EDF7ED',
    bgWarning: '#FDF0E6',
    bgError: '#FEECEB',
  },
  onePager: {
    skillsProgress: '#978CB6',
    skillsProgressBg: '#D6D6ED',
    jobsProgress: '#8CB6A7',
    jobsProgressBg: '#CEE4DC',
    boxBg: '#F4F5F7',
  },
}

const SHAPE = {
  borderRadius: 4, // from default
  radius: {
    small: '2px',
    normal: '4px',
    larger: '6px',
    large: '8px',
  },
}

const MIXINS = {
  toolbar: {
    height: 64,
  },
  menuButton: {
    display: 'block',
    color: 'inherit',

    '&.button-variant-text': {
      borderRadius: '0px',
      paddingBottom: 0,

      '&:hover': {
        backgroundColor: 'inherit',
      },
      '& > .MuiButton-label': {},
    },

    '&.button-variant-contained': {
      paddingLeft: '12px',
      paddingRight: '12px',
      color: PALETTE.common.white,
      '&:hover': {
        color: PALETTE.text.secondary,
      },
    },

    '&.link-button': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '&:hover': {
        textDecoration: 'none',
        '& *': {
          textDecoration: 'inherit',
        },
      },
    },

    paddingLeft: '6px',
    paddingRight: '6px',
    marginRight: SPACING_BASE,
    '&:last-child': {
      marginRight: 0,
    },
    minWidth: '80px',

    '& .MuiButton-endIcon': {
      marginLeft: '1.5px',
    },
    '& .MuiSvgIcon-root': {
      marginRight: '-3px',
      transition: '0.5s transform ease',
    },

    '&.selected': {
      '& .MuiSvgIcon-root': {
        transform: 'rotate(180deg)',
      },
    },
  },

  menuButtonLabel: {
    width: 'auto',
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '.button-variant-text &': {
      borderBottom: '3.5px transparent solid',
      '&:hover': {
        borderBottom: `3.5px ${PALETTE.primary.main} solid`,
      },
    },
  },
}

export const muiTheme = createTheme({
  breakpoints: BREAKPOINTS,

  spacing: SPACING_BASE,
  shape: SHAPE,
  mixins: MIXINS,
  palette: PALETTE,

  typography: {
    fontFamily: 'Roboto, sans-serif',
    htmlFontSize: 10,
    h2: {
      fontWeight: 500,
    },
    h2Bold800: {
      fontSize: '6rem',
      fontWeight: 800,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '3.4rem',
      fontWeight: 600,
      lineHeight: 1.23,
    },
    h5Bold700: {
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: 1.334,
    },
    h6: {
      fontWeight: 700,
    },
    h6Bold800: {
      fontSize: '2rem',
      fontWeight: 800,
      lineHeight: 1.334,
    },
    h22: {
      fontSize: '2.2rem',
      fontWeight: 400,
      lineHeight: 1.334,
    },
    h22Bold800: {
      fontSize: '2.2rem',
      fontWeight: 800,
      lineHeight: 1.18,
    },
    h30: {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: 0.86,
    },
    h30Bold800: {
      fontSize: '3rem',
      fontWeight: 800,
      lineHeight: 0.86,
    },
    subtitle1: {
      lineHeight: 1.25,
    },
    subtitle1Bold: {
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: 1.25,
    },
    subtitle1Label: {
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: 1.25,
    },
    body1Bold: {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: '1.5',
    },
    body1Bold500: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: '1.5',
    },
    body1Bold700: {
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: '0.75',
    },

    body2Bold: {
      fontSize: '1.4rem',
      fontWeight: 500,
      lineHeight: '1.57',
    },
    body2Bolder: {
      fontSize: '1.4rem',
      fontWeight: 700,
      lineHeight: '1.57',
    },
    body3: {
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '1.57',
    },
  } as TypographyOptions,

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
        '*': {
          scrollbarColor: 'rgba(196, 196, 196, 1), rgba(229, 229, 229, 1)',
          scrollbarWidth: 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '0.5rem',
          height: '0.5rem',
        },
        '*::-webkit-scrollbar-track': {
          background: 'rgba(229, 229, 229, 1)',
          borderRadius: '100vw',
        },
        '*::-webkit-scrollbar-thumb': {
          background: 'rgba(196, 196, 196, 1)',
          borderRadius: '100vh',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: 'rgba(147, 147, 147, 1)',
        },

        ':root': {
          fontSize: '62.5%',
        },

        body: {
          fontSize: '1.6rem',
        },

        'h1 ~ p,h2 ~ p,h3 ~ p,h4 ~ p,h5 ~ p,h6 ~ p': {
          marginTop: 0,
        },
        'ul,ol': {
          margin: 0,
          padding: 0,
        },
        '.MuiCalendarPicker-root': {
          backgroundColor: '#fff',
        },
        '.remove-focus.Mui-focused': {
          color: `${PALETTE.common.black} !important`,
        },
        '.MuiFormLabel-asterisk': {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap', // The design has decided that we should not let text inside the button to wrap
        },
        sizeSmall: {
          [`@media (min-width: ${BREAKPOINTS.values.xs})`]: {
            fontSize: '1.3rem',
          },
        },
        sizeMedium: {
          [`@media (min-width: ${BREAKPOINTS.values.xs})`]: {
            fontSize: '1.4rem',
          },
        },
        sizeLarge: {
          [`@media (min-width: ${BREAKPOINTS.values.xs})`]: {
            fontSize: '1.5rem',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiButtonBase-root': {
            paddingInline: 16,
          },
          '.MuiToggleButton-primary': {
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
          },
          '&.Mui-selected,&.Mui-selected:hover &:focus': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: theme.palette.common.white,
          },
          '&:hover': {
            backgroundColor: `${theme.palette.primary.main}0a`,
          },
        }),
      },
    },

    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          boxShadow: 'none',
          ...(ownerState.color === 'transparent' && {
            backgroundColor: theme.palette.common.white,
          }),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowX: 'hidden',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 20px 20px',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          borderRadius: 0,
          boxShadow: 'none',
          padding: '3.2rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: PALETTE.error.main,
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          background: PALETTE.common.white,
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline':
            {
              borderColor: PALETTE.error.main,
            },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: PALETTE.success.main,
          color: PALETTE.common.white,
          '& .MuiAlert-icon': {
            color: PALETTE.common.white,
          },
        },
        standardInfo: {
          backgroundColor: '#E9F5FE',
          '& .MuiAlert-icon': {
            color: PALETTE.info.main,
          },
        },
        outlinedError: {
          background: PALETTE.background.default,
        },
        filledSuccess: {
          color: PALETTE.common.white,
        },
      },
    },
  },
})
