import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { WorkOutlineRounded } from '@mui/icons-material'

import { MultiField } from './multi-field'

export const ExperienceEdit: FC = memo(() => {
  const { t } = useTranslation('main')

  return (
    <MultiField
      name="workExperience"
      removePrompt={t('organisms.candidateOnePager.workExperience.remove')}
      title={t('organisms.candidateOnePager.workExperience.title')}
      icon={WorkOutlineRounded}
      fieldKeys={[
        { key: 'startDate', label: t('organisms.candidateOnePager.from') },
        { key: 'endDate', label: t('organisms.candidateOnePager.to') },
        {
          key: 'title',
          label: t('organisms.candidateOnePager.workExperience.jobTitle'),
        },
        {
          key: 'industryName',
          label: t('organisms.candidateOnePager.industry'),
        },
        {
          key: 'description',
          label: t('organisms.candidateOnePager.summary'),
        },
      ]}
    />
  )
})
