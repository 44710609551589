import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BaseSubjectStore } from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

type ObserverActions<T> = {
  next?: (value: T) => void
  error?: (error: any) => void
  complete?: () => void
}

interface LoadingStoreProperties {
  loading: boolean
}

type UseErrorAndCompletionStoreObserverProps<
  T extends LoadingStoreProperties,
  U
> = {
  store: BaseSubjectStore<T>
} & ObserverActions<U>

// Note: This hook is still under implementation. A context will be provided to allow
// having a single error handler
export const useErrorAndCompletionStoreObserver = <
  T extends LoadingStoreProperties,
  U
>({
  store,
  next,
  error: additionalError,
  complete: additionalComplete,
}: UseErrorAndCompletionStoreObserverProps<T, U>): ObserverActions<U> => {
  const { addAlert } = useAlert()
  const { t } = useTranslation()

  return useMemo(
    () => ({
      next: (value: U) => {
        next?.(value)
      },
      error: (err?: any) => {
        addAlert({
          severity: 'error',
          message: t('common.alert.somethingWrong'),
        })
        store.dispatch({
          loading: false,
        } as Partial<T>)
        additionalError?.(err)
      },
      complete: () => {
        store.dispatch({
          loading: false,
        } as Partial<T>)
        additionalComplete?.()
      },
    }),
    [store, next, additionalError, additionalComplete, addAlert, t]
  )
}
