import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  JobSubmissionAtsStatusTypes,
  JobSubmissionPortalStatusTypes,
  useSubjectSelector,
} from '@procom-labs/common'
import { CheckboxGroup, ModalFilterDialog } from '@procom-labs/molecules'

import { yupResolver } from '@hookform/resolvers/yup'
import { CandidatePrepFilterValidationSchema } from '@submission-portal/components/candidate-prep/candidate-prep-utils'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import { DefaultCandidatesPrepSearchFilter } from '@submission-portal/types'

export const CandidatesPrepDataFilterModal: React.FC = () => {
  const { t } = useTranslation('main')

  const { isFilterModalOpen, searchFilter } = useSubjectSelector(
    candidatesPrepStore,
    ['isFilterModalOpen', 'searchFilter']
  )

  const handleClose = useCallback(() => {
    candidatesPrepStore.dispatch({
      isFilterModalOpen: false,
    })
  }, [])

  const methods = useForm({
    defaultValues: searchFilter,
    resolver: yupResolver(CandidatePrepFilterValidationSchema),
  })

  const { handleSubmit } = methods

  const onSubmit = useCallback(
    (data: any): void => {
      candidatesPrepStore.dispatch({
        searchFilter: {
          ...searchFilter,
          ...data,
          keyword: searchFilter.keyword, // Keeping the search keyword
        },
      })

      handleClose()
    },
    [handleClose, searchFilter]
  )

  const handleClearFilters = useCallback(() => {
    methods.reset()
    candidatesPrepStore.dispatch({
      searchFilter: DefaultCandidatesPrepSearchFilter,
    })
  }, [methods])

  const stageNames = useMemo(
    () =>
      Object.values(JobSubmissionAtsStatusTypes).map((current) => ({
        name: current,
        label: t(`candidatePreb.atsStatus.${current}`),
      })),
    [t]
  )

  const statusNames = useMemo(
    () =>
      Object.values(JobSubmissionPortalStatusTypes).map((current) => ({
        name: current,
        label: t(`candidatePreb.portalStatus.${current}`),
      })),
    [t]
  )

  return (
    <FormProvider {...methods}>
      <ModalFilterDialog
        isModalOpen={isFilterModalOpen}
        onClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
        onClearFilters={handleClearFilters}
      >
        <CheckboxGroup label={t('candidatePreb.stage')} options={stageNames} />

        <CheckboxGroup
          label={t('candidatePreb.status')}
          options={statusNames}
        />
      </ModalFilterDialog>
    </FormProvider>
  )
}
