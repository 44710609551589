import { SuperSubject } from '@procom-labs/common'
import { environment } from '@submission-portal/environment'
import { catchError, finalize, map, Observable, throwError } from 'rxjs'
import { axios } from '@submission-portal/lib'

class JobDescriptionService {
  private isJobSavedLoadingSubject = new SuperSubject<boolean>(false)

  isJobSavedLoading$ = this.isJobSavedLoadingSubject.observable$

  saveJobDescriptionToBullhorn(
    jobId: string | null,
    atsJobDescription?: string
  ): Observable<string> {
    this.isJobSavedLoadingSubject.value = true
    return axios
      .post<string>(
        `${environment.JOB_API_URL}/ClientJob/ats/job-description/${jobId}`,
        { jobDescription: atsJobDescription }
      )
      .pipe(
        map(({ data }) => {
          return data
        }),
        catchError((error) => {
          this.isJobSavedLoadingSubject.value = false
          return throwError(() => new Error(error?.response?.data?.message))
        }),
        finalize(() => {
          this.isJobSavedLoadingSubject.value = false
        })
      )
  }
}

export const jobDescriptionService = new JobDescriptionService()
