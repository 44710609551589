export const getInitials = (name: string, count = 1): string => {
  if (name) {
    const initialList: string[] = name
      .trim()
      .split(' ')
      .map((i) => {
        const j = i.trim().replace(/[^\w\s]/gi, '')
        return j ? j[0] : ''
      })
      .filter((i) => !!i)

    if (initialList.length) {
      return initialList.slice(0, count).join('').toUpperCase()
    }
  }
  return ''
}

export const colorPallete = [
  '001791',
  '003A56',
  '006391',
  '00845C',
  '008ACA',
  '026446',
  '112E51',
  '1E4620',
  '2B1E46',
  '2D5D6C',
  '301863',
  '357B38',
  '3D4B92',
  '461E1E',
  '461E42',
  '4B3778',
  '4F5E15',
  '560000',
  '622443',
  '625124',
  '63308A',
  '6A3F17',
  '731C36',
  '865DA6',
  '8A3232',
  '8A325F',
  'A95B12',
  'AA517E',
  'AE0000',
  'B92D2D',
]

export const getRandomColor = (colors: string[] = colorPallete): string => {
  const randomNumber = Math.floor(Math.random() * colors.length)
  return colors[randomNumber]
}
