import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DialogActions, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'
import { ButtonClose, GorillaDialog } from '@procom-labs/atoms'

export const RemoveDialog: FC<{
  title: string
  open: boolean
  onClose: () => void
  onDelete: () => void
  cancelId?: string
  deleteId?: string
}> = ({ title, open, onClose, onDelete, cancelId, deleteId }) => {
  const { t } = useTranslation()
  return (
    <GorillaDialog open={open}>
      <DialogTitle sx={{ mt: 3, pb: 1 }}>{title}</DialogTitle>
      <ButtonClose handleClose={onClose} />
      <DialogActions>
        <Button id={cancelId} variant="outlined" autoFocus onClick={onClose}>
          {t('common.btn.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onDelete}
          id={deleteId}
          color="secondary"
        >
          {t('common.btn.delete')}
        </Button>
      </DialogActions>
    </GorillaDialog>
  )
}
