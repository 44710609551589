import { Subject } from 'rxjs'

import { BaseSubjectStore, IContractorProfileResume } from '@procom-labs/common'

type PendingHandlers = {
  [key: string]: Subject<IContractorProfileResume>[]
}

export interface IResourceStoreState {
  resumes: { [key: string]: IContractorProfileResume }
  pending: string[]
  pendingHandlers: PendingHandlers
}

export class ResourceStore extends BaseSubjectStore<IResourceStoreState> {
  getDefaultValue(): IResourceStoreState {
    return {
      resumes: {},
      pending: [],
      pendingHandlers: {},
    }
  }
}

export const resourceStore = new ResourceStore()
