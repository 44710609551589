import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'
import { useSubjectSelector } from '@procom-labs/common'
import { ProgressStepper } from '@procom-labs/molecules'

import { SubmissionPrepDocumentUploader } from '@submission-portal/components/candidate-prep/components/submission-prep-document-uploader'
import { SubmissionPrepResume } from '@submission-portal/components/candidate-prep/components/submission-prep-resume'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import { CandidatesPrepStep } from '@submission-portal/types'

export const SubmissionPrepMainTab: React.FC = () => {
  const { t } = useTranslation('main')

  const { prepStep, candidate: submission } = useSubjectSelector(
    candidatesPrepStore,
    ['prepStep', 'candidate']
  )
  const currentFile = submission?.candidate?.formattedSubmissionFile

  const setActiveStep = useCallback(
    (newPrepStep) => {
      const castNewPrepStep = newPrepStep as CandidatesPrepStep

      if (currentFile || castNewPrepStep !== CandidatesPrepStep.Prep) {
        candidatesPrepStore.dispatch({
          prepStep: castNewPrepStep,
        })
      }
    },
    [currentFile]
  )

  const steps = useMemo(
    () => [
      t('candidatePreb.stepper.uploadDocuments'),
      t('candidatePreb.stepper.prepSubmission'),
    ],
    [t]
  )

  const stepContents = useMemo(() => {
    const contents = []
    if (prepStep === 0) {
      contents.push({
        id: 'uploader',
        content: <SubmissionPrepDocumentUploader />,
      })
    }
    if (prepStep === 1) {
      contents.push({ id: 'prep-step', content: <SubmissionPrepResume /> })
    }
    return contents
  }, [prepStep])

  return (
    <Box>
      <ProgressStepper
        sx={{ pt: '3rem', position: 'relative', zIndex: '1' }}
        steps={steps}
        activeStep={prepStep}
        setActiveStep={setActiveStep}
      />
      <Box>
        {stepContents.map(({ id, content }) => (
          <Box key={id}>{content}</Box>
        ))}
      </Box>
    </Box>
  )
}
