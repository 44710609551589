import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import {
  alpha,
  Box,
  Button,
  DialogContent,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import {
  IJobSubmission,
  IResume,
  useSubjectSelector,
} from '@procom-labs/common'
import { PDFViewer } from '@procom-labs/molecules'
import { ResumeUploader } from '@procom-labs/organisms'

import {
  useDeleteAdditionalDocument,
  useUploadAdditionalDocumentsChange,
} from '@submission-portal/components/candidate-prep/hooks/candidate-prep-core-hooks'
import {
  useGetUserId,
  useUploadResource,
  useViewOrDownloadFile,
} from '@submission-portal/hooks'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import { FileActions } from '@submission-portal/types'

interface SubmissionPrepAdditionalDocsProps {}
export const SubmissionPrepAdditionalDocs: React.FC<
  SubmissionPrepAdditionalDocsProps
> = () => {
  const { t } = useTranslation('main')
  const { additionalDocuments, candidate: submission } = useSubjectSelector(
    candidatesPrepStore,
    ['additionalDocuments', 'candidate']
  )

  const uploadResource = useUploadResource()
  const handleDeleteResource = useDeleteAdditionalDocument()
  const userId = useGetUserId()
  const [processing, setProcessing] = useState(false)
  const [blobUrl, setBlobUrl] = useState('')
  const [showingFile, setShowingFile] = useState(false)

  const onChangeResource = useUploadAdditionalDocumentsChange(userId)

  const attachToSubmissionValue =
    submission?.candidate?.isAdditionalDocumentsUploadEnabled

  const addDocument = useCallback(() => {
    candidatesPrepStore.dispatch({
      additionalDocuments: [
        ...additionalDocuments,
        { file: null, fileBlob: '' },
      ],
    })
  }, [additionalDocuments])

  const handleResumes = useViewOrDownloadFile(
    userId,
    setBlobUrl,
    setShowingFile,
    setProcessing
  )

  const handleDocuments = useCallback(
    (resumes: IResume[], actionType: string, fileBlob?: string) => {
      if (actionType === FileActions.View && fileBlob && fileBlob !== '') {
        setBlobUrl(fileBlob)
        setShowingFile(true)
      } else {
        handleResumes(resumes, actionType)
      }
    },
    [handleResumes]
  )

  const handleToggleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      candidatesPrepStore.dispatch({
        candidate: {
          ...submission,
          ...(submission?.candidate && {
            candidate: {
              ...submission?.candidate,
              isAdditionalDocumentsUploadEnabled: event.target.checked,
            },
          }),
        } as IJobSubmission,
      })
    },
    [submission]
  )

  return (
    <Box sx={{ mt: 2 }}>
      {blobUrl && showingFile && (
        <Dialog
          open={!!blobUrl}
          onClose={() => setShowingFile(false)}
          sx={{
            '.MuiPaper-root': {
              minHeight: 'calc(100vh - 200px)',
              minWidth: '800px',
              overflow: 'auto',
            },
          }}
        >
          <DialogContent>
            <PDFViewer file={blobUrl} />
          </DialogContent>
        </Dialog>
      )}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: '2rem',
              fontWeight: 500,
              lineHeight: '32px',
              letterSpacing: '0.15px',
              color: '#00000099',
            }}
          >
            {t('candidatePreb.additionalDocumentsOptional')}
          </Typography>
        </Grid>
        {additionalDocuments?.[0]?.file && (
          <Grid item sx={{ marginLeft: '188.2px' }}>
            <FormControlLabel
              control={
                <Switch
                  name="checkedSwitch"
                  color="primary"
                  checked={attachToSubmissionValue}
                  onChange={handleToggleChange}
                />
              }
              label={t('candidatePreb.attachToSubmission')}
            />
          </Grid>
        )}
      </Grid>

      <Stack sx={{ mt: '1rem' }} spacing={2} useFlexGap>
        {additionalDocuments.map((document) => (
          <Grid
            container
            spacing={2}
            key={`document-${document?.file?.fileStorageId}`}
          >
            <Grid item xs={6}>
              <ResumeUploader
                direction="column"
                uploadResume={uploadResource}
                deleteResume={handleDeleteResource}
                onChange={onChangeResource}
                value={document?.file ? (document?.file as IResume) : null}
                fileStyles={{
                  width: '100%',
                  border: `1px solid ${alpha('#000', 0.23)}`,
                  borderRadius: '4px',
                  padding: '0px 8px 5px 8px',
                  margin: '0px',
                }}
              />
            </Grid>
            {document?.file && (
              <Grid
                item
                xs={6}
                container
                alignItems="center"
                spacing={2}
                justifyContent="flex-start"
              >
                <Grid item>
                  <Button
                    id={`view-doc-${document?.file?.fileStorageId}`}
                    size="medium"
                    onClick={() =>
                      handleDocuments(
                        [
                          {
                            extension: (document?.file as IResume).extension,
                            fileStorageId: (document?.file as IResume)
                              .fileStorageId,
                            name: (document?.file as IResume).name,
                            size: (document?.file as IResume).size,
                          },
                        ],
                        'view',
                        document.fileBlob
                      )
                    }
                    disabled={processing}
                  >
                    {t('common.btn.view')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id={`download-doc-${document?.file?.fileStorageId}`}
                    size="medium"
                    onClick={() =>
                      handleDocuments(
                        [
                          {
                            extension: (document?.file as IResume).extension,
                            fileStorageId: (document?.file as IResume)
                              .fileStorageId,
                            name: (document?.file as IResume).name,
                            size: (document?.file as IResume).size,
                          },
                        ],
                        'download'
                      )
                    }
                    disabled={processing}
                  >
                    {t('common.btn.download')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Stack>
      {additionalDocuments[additionalDocuments.length - 1].file && (
        <Button
          id="add-document"
          size="medium"
          onClick={addDocument}
          disabled={processing}
          sx={{ mt: '4px' }}
        >
          <AddIcon /> {t('common.btn.addDocument')}
        </Button>
      )}
    </Box>
  )
}
