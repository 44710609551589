import React, { FC, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  TitledPanel,
  ViewportResizer,
  GeneratorButton,
} from '@procom-labs/molecules'
import { environment } from '@submission-portal/environment'
import { SubmissionPrepTipsDrawer } from '@submission-portal/components/candidate-prep/components/submission-prep-tips-drawer'
import { SubmissionPrepFormattedResumeTab } from '../tabs/submission-prep-formatted-resume-tab'
import { SubmissionPrepOriginalResumeTab } from '../tabs/submission-prep-original-resume-tab'

export const SubmissionPrepResume: FC = () => {
  const { t } = useTranslation('main')
  const [openTipsDrawer, setOpenTipsDrawer] = useState(false)
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="end"
    >
      {environment.ENABLE_TIPS_AND_SUGGESTIONS && (
        <>
          <GeneratorButton
            sx={{
              position: 'absolute',
            }}
            id="cta-generate-candidate-highlights"
            size="medium"
            type="submit"
            alt="View tips"
            onClick={() => setOpenTipsDrawer(!openTipsDrawer)}
          >
            {t('candidatePreb.generateTipsSuggestion')}
          </GeneratorButton>

          <SubmissionPrepTipsDrawer
            open={openTipsDrawer}
            setOpen={setOpenTipsDrawer}
          />
        </>
      )}

      <Grid container spacing={4} sx={{ pt: '44px' }}>
        <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
          <TitledPanel
            label={t('candidatePreb.original')}
            sx={{ height: '100%' }}
          >
            <ViewportResizer>
              <SubmissionPrepOriginalResumeTab />
            </ViewportResizer>
          </TitledPanel>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
          <TitledPanel
            label={t('candidatePreb.formatted')}
            sx={{ height: '100%' }}
          >
            <ViewportResizer>
              <SubmissionPrepFormattedResumeTab />
            </ViewportResizer>
          </TitledPanel>
        </Grid>
      </Grid>
    </Box>
  )
}
