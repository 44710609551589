import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  JobSubmissionAtsStatusLabels,
  useSubjectSelector,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'
import {
  CandidateRejectedModalContent,
  RejectedFormPayload,
} from '@procom-labs/organisms'

import { useUpdateAtsStatus } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-info-hooks'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

export const CandidateRejectedModal: React.FC = () => {
  const { t } = useTranslation('main')

  const {
    isModalRejectedOpen: open,
    modalCandidateData: candidate,
    modalAtsStatus,
  } = useSubjectSelector(candidatesPrepStore, [
    'isModalRejectedOpen',
    'modalCandidateData',
    'modalAtsStatus',
  ])

  const updateAtsStatus = useUpdateAtsStatus()

  const { addAlert } = useAlert()

  const handleRejectCandidate = useCallback(
    (values: RejectedFormPayload): void => {
      if (!candidate) return

      if (candidate.atsUserId && candidate.jobSubmissionId) {
        updateAtsStatus(
          {
            atsUserId: candidate.atsUserId,
            jobSubmissionId: candidate.jobSubmissionId,
            additionalData: {
              rejectionReason: values.rejectionReason,
              rejectionDateTime: new Date(
                new Date(Date.now()).toUTCString()
              ).toISOString(),
            },
          },
          modalAtsStatus,
          {
            complete: () => {
              candidatesPrepStore.dispatch({
                isModalRejectedOpen: false,
                candidateStatus: modalAtsStatus as JobSubmissionAtsStatusLabels,
              })
              addAlert({
                severity: 'success',
                message: t('common.alert.itemSaved'),
              })
            },
            error: () => {
              candidatesPrepStore.dispatch({
                isModalRejectedOpen: false,
              })
              addAlert({
                message: t('common.alert.somethingWrong'),
                severity: 'error',
              })
            },
          }
        )
      } else {
        addAlert({
          message: t('common.alert.somethingWrong'),
          severity: 'error',
        })
      }
    },
    [addAlert, candidate, t, updateAtsStatus, modalAtsStatus]
  )

  const handleClose = useCallback(() => {
    candidatesPrepStore.dispatch({
      isModalRejectedOpen: false,
    })
  }, [])

  return (
    <CandidateRejectedModalContent
      open={open}
      handleClose={handleClose}
      handleRejectCandidate={handleRejectCandidate}
      candidate={candidate}
    />
  )
}
