import React from 'react'

import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { SubmissionDataView } from '@submission-portal/components/submission-data'

export const SubmissionDataList: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))

  return (
    <Box sx={{ p: isMobile ? 2 : 4, height: '100vh', overflow: 'auto' }}>
      <SubmissionDataView />
    </Box>
  )
}
