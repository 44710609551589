import React, { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import GroupsIcon from '@mui/icons-material/Groups'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import MessageIcon from '@mui/icons-material/Message'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PersonIcon from '@mui/icons-material/Person'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  CandidateStatusType,
  FeatureFlagNames,
  gtmEvents,
  useIsFeatureFlagEnabled,
} from '@procom-labs/common'

export const CandidateActionsMenu: React.FC<{
  jobSubmissionStatus: string
  zIndexIconButton: number
  isAvailabilitySent: boolean
  handleContactAM?: () => void
  handleCandidateRequestInterview?: () => void
  handleAddInterviewNotes?: (event: React.SyntheticEvent) => void
  handleCandidateRejected?: () => void
  handleOfferExtended?: () => void
  handleSendDataToGTM?: (eventName: string) => void
  handleViewProfile?: () => void
  handleClick?: () => void
  handleSetCandidate?: () => void
  isLoading?: boolean
  disableButtons?: boolean
  isSubmissionPortal?: boolean
  isModalProfileOpened?: boolean
  hasContractorProfileId?: boolean
  handleAddCandidateToJob?: () => void
  handleAddCandidateToPayroll?: () => void
  handleAddCandidateToTalentList?: () => void
}> = ({
  handleContactAM,
  handleViewProfile,
  handleCandidateRequestInterview,
  handleAddInterviewNotes,
  handleCandidateRejected,
  handleOfferExtended,
  jobSubmissionStatus,
  zIndexIconButton,
  handleClick,
  isAvailabilitySent,
  handleSendDataToGTM,
  handleSetCandidate,
  isLoading,
  disableButtons,
  isSubmissionPortal,
  isModalProfileOpened = false,
  handleAddCandidateToJob,
  handleAddCandidateToTalentList,
  hasContractorProfileId = false,
}) => {
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  const IS_SELF_SERVE_RECRUITING_ENABLED = useIsFeatureFlagEnabled(
    FeatureFlagNames.SelfServeRecruiting
  )

  const { t } = useTranslation('main')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [interviewEl, setInterviewEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const openInterviewMenu = Boolean(interviewEl)

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleCloseMenu = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const handleOpenInterviewMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      setInterviewEl(event.currentTarget)
    },
    []
  )

  const handleCloseInterviewMenu = useCallback((): void => {
    setInterviewEl(null)
  }, [])

  const enableAddToJobAndTalentListOptions =
    IS_SELF_SERVE_RECRUITING_ENABLED &&
    isModalProfileOpened &&
    hasContractorProfileId

  return (
    <>
      <IconButton
        aria-label={t('common.aria.more')}
        id="long-button"
        aria-controls="long-menu"
        aria-expanded="true"
        aria-haspopup="true"
        sx={{ p: 0.5, zIndex: zIndexIconButton }}
        onClick={(event) => {
          handleOpenMenu(event)
          handleSetCandidate?.()
          handleClick?.()
        }}
      >
        <MoreVertIcon sx={{ color: theme.palette.common.black }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        onMouseLeave={handleCloseInterviewMenu}
      >
        {handleViewProfile && (
          <MenuItem
            onMouseOver={handleCloseInterviewMenu}
            onClick={() => {
              handleSendDataToGTM?.(gtmEvents.CandidateViewViewProfile)
              handleViewProfile()
              handleCloseMenu()
            }}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText sx={{ textTransform: 'capitalize' }}>
              {t('common.candidates.list.viewBtn')}
            </ListItemText>
          </MenuItem>
        )}
        {handleCandidateRejected && (
          <MenuItem
            onMouseOver={handleCloseInterviewMenu}
            onClick={() => {
              handleCandidateRejected()
              handleCloseMenu()
            }}
            disabled={isLoading || disableButtons}
          >
            <ListItemIcon>
              {jobSubmissionStatus === CandidateStatusType.Rejected ? (
                <ThumbDownAltIcon sx={{ color: theme.palette.error.main }} />
              ) : (
                <ThumbDownOffAltIcon sx={{ color: theme.palette.error.main }} />
              )}
            </ListItemIcon>
            <ListItemText>
              {jobSubmissionStatus === CandidateStatusType.Rejected
                ? t('common.candidates.status.rejected')
                : t('organisms.candidateDetails.btn.reject')}
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem
          disabled={isSubmissionPortal}
          onMouseOver={
            mobileView ? () => {} : (event) => handleOpenInterviewMenu(event)
          }
          onClick={
            mobileView ? (event) => handleOpenInterviewMenu(event) : () => {}
          }
        >
          <ul style={{ width: '100%' }}>
            <ListItem sx={{ p: 0 }}>
              <ListItemIcon>
                <CalendarTodayIcon />
              </ListItemIcon>
              <ListItemText>
                {t('common.candidates.funnels.interview')}
              </ListItemText>
              <ChevronRightIcon color="action" />
            </ListItem>
          </ul>
        </MenuItem>

        {(handleAddInterviewNotes || handleCandidateRequestInterview) && (
          <Menu
            anchorEl={interviewEl}
            MenuListProps={{
              onMouseLeave: mobileView
                ? () => {}
                : () => {
                    handleCloseInterviewMenu()
                  },
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPaper-root': {
                ml: 2,
              },
              pointerEvents: 'none',
            }}
            open={openInterviewMenu}
            onClose={handleCloseInterviewMenu}
          >
            <div style={{ pointerEvents: 'auto' }}>
              {handleCandidateRequestInterview && (
                <MenuItem
                  id="btn-interview-send-request"
                  onClick={() => {
                    handleCandidateRequestInterview()
                    handleCloseMenu()
                    handleCloseInterviewMenu()
                  }}
                  disabled={isLoading || disableButtons}
                >
                  <ListItemText>
                    {jobSubmissionStatus === CandidateStatusType.Interview &&
                    isAvailabilitySent
                      ? t('common.candidates.list.editRequest')
                      : t('common.candidates.list.sendRequest')}
                  </ListItemText>
                </MenuItem>
              )}

              {handleAddInterviewNotes && (
                <MenuItem
                  id="btn-add-interview-notes"
                  onClick={handleAddInterviewNotes}
                >
                  <ListItemText>
                    {t('common.candidates.list.interviewNotes')}
                  </ListItemText>
                </MenuItem>
              )}
            </div>
          </Menu>
        )}

        {handleOfferExtended && (
          <MenuItem
            id="btn-candidate-extend-offer"
            onMouseOver={handleCloseInterviewMenu}
            onClick={() => {
              handleOfferExtended()
              handleCloseMenu()
            }}
            disabled={isLoading || disableButtons}
          >
            <ListItemIcon>
              <InsertEmoticonIcon />
            </ListItemIcon>
            <ListItemText>{t('common.candidates.list.sendOffer')}</ListItemText>
          </MenuItem>
        )}

        {handleContactAM && (
          <MenuItem
            onMouseOver={handleCloseInterviewMenu}
            id="btn-account-manager-message"
            onClick={() => {
              handleContactAM()
              handleCloseMenu()
            }}
            disabled={disableButtons}
          >
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText>
              {t('common.enums.AuthProfileType.AccountManager')}
            </ListItemText>
          </MenuItem>
        )}
        {enableAddToJobAndTalentListOptions && (
          <MenuItem
            id="btn-add-to-another-job"
            onClick={handleAddCandidateToJob}
            disabled={disableButtons}
          >
            <ListItemIcon>
              <PersonAddAlt1Icon />
            </ListItemIcon>
            <ListItemText>{t('common.candidates.list.addToJob')}</ListItemText>
          </MenuItem>
        )}
        {enableAddToJobAndTalentListOptions && (
          <MenuItem
            id="btn-add-to-payroll"
            onClick={handleAddCandidateToTalentList}
            disabled={disableButtons}
          >
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText>
              {t('common.candidates.list.addToTalentList')}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  ) as ReactElement
}
