import { TFunction } from 'i18next'
import { DateTime, DateTimeFormatOptions, Interval } from 'luxon'

import {
  DateAndTime,
  DateValueFormat,
  INTERVIEW_DATE_FORMAT,
  PreferredLanguageType,
} from '../enums'

export type Duration = {
  minutes: number
  hours: number
  days: number
  weeks: number
}

const checkLangFr = (): boolean | null =>
  localStorage.getItem('i18nextLng') === PreferredLanguageType.FR || null

export const getDuration = (pastDate: any, futureDate?: any): Duration => {
  const now = !pastDate ? DateTime.local() : DateTime.fromISO(pastDate)
  const later = !futureDate ? DateTime.local() : DateTime.fromISO(futureDate)

  // Validate if 'now' and 'later' are valid DateTime objects
  if (!now.isValid || !later.isValid) {
    return {
      minutes: NaN,
      hours: NaN,
      days: NaN,
      weeks: NaN,
    }
  }

  const diff = Interval.fromDateTimes(now, later)

  return {
    minutes: diff.length(DateAndTime.Minutes),
    hours: diff.length(DateAndTime.Hours),
    days: diff.length(DateAndTime.Days),
    weeks: diff.length(DateAndTime.Weeks),
  }
}

export const getDurationExcludeWeekends = (
  pastDate: any,
  futureDate?: any
): Duration => {
  const now = !pastDate ? DateTime.local() : DateTime.fromISO(pastDate)
  const later = !futureDate ? DateTime.local() : DateTime.fromISO(futureDate)
  let totalWeekends = 0

  const interval = Interval.fromDateTimes(now, later)
  interval.splitBy({ days: 1 }).forEach((dayInterval) => {
    const day = dayInterval?.start?.weekday
    if (day && day >= 6 && day <= 7) {
      totalWeekends += 1
    }
  })

  const totalMinutesExcludingWeekends =
    interval.length(DateAndTime.Hours) - totalWeekends * 24 * 60

  const totalHoursExcludingWeekends =
    interval.length(DateAndTime.Hours) - totalWeekends * 24

  const totalDaysExcludingWeekends =
    interval.length(DateAndTime.Days) - totalWeekends

  const totalWeeks = interval.length(DateAndTime.Weeks)

  return {
    minutes: totalMinutesExcludingWeekends,
    hours: totalHoursExcludingWeekends,
    days: totalDaysExcludingWeekends,
    weeks: totalWeeks,
  }
}

export const getDurationAgo = (pastDate: any, futureDate?: any): string => {
  const duration: any = getDuration(pastDate, futureDate)
  // Check for NaN in duration properties and return an empty string if any is NaN
  const { hours, days, minutes } = duration
  if (Number.isNaN(hours) || Number.isNaN(days) || Number.isNaN(minutes)) {
    return ''
  }
  const isLangFr = checkLangFr()
  let output = ''
  if (hours >= 168) {
    output = DateTime.fromISO(pastDate).toFormat(
      'DDD',
      isLangFr ? { locale: 'fr' } : {}
    )
  } else if (hours >= 24) {
    const durationDays = Math.round(days)
    output = isLangFr
      ? `il y a ${durationDays} jour${durationDays > 1 ? 's' : ''}`
      : `${durationDays} day${durationDays > 1 ? 's' : ''} ago`
  } else if (minutes < 60) {
    const durationMinutes = Math.round(minutes)
    output = isLangFr
      ? `il y a ${durationMinutes} minute${durationMinutes > 1 ? 's' : ''}`
      : `${durationMinutes > 0 ? durationMinutes : 1} minute${
          durationMinutes > 1 ? 's' : ''
        } ago`
  } else {
    const durationHours = Math.round(hours)
    output = isLangFr
      ? `il y a ${durationHours} heure${durationHours > 1 ? 's' : ''}`
      : `${durationHours} hour${durationHours > 1 ? 's' : ''} ago`
  }

  return output
}

export const getDurationIn = (pastDate: any, futureDate?: any): string => {
  const duration: any = getDuration(futureDate, pastDate)
  const isLangFr = checkLangFr()
  let output = ''
  if (duration.hours >= 336) {
    output = DateTime.fromISO(pastDate).toFormat(
      'DDD',
      isLangFr ? { locale: 'fr' } : {}
    )
  } else if (duration.hours >= 168) {
    output = DateValueFormat.InTwoWeek
  } else {
    output = DateValueFormat.InOneWeek
  }

  return output
}

function getOffsetStr(input: string): string | null {
  const isoDatePattern =
    /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/
  const groups = input.match(isoDatePattern)
  return groups ? groups[4] : null
}

function hasOffset(input: string): boolean {
  return !!getOffsetStr(input)
}

function parseUTCOffset(offsetStr: string): number | null {
  if (offsetStr.toUpperCase() === 'Z') {
    return 0
  }
  const sign = offsetStr.startsWith('-') ? -1 : 1
  const hour = +offsetStr.slice(1, 3)
  const min = +offsetStr.slice(-2)
  return sign * hour * 60 + min
}

function getUTCOffsetMinutes(input: string): number | null {
  const offset = getOffsetStr(input)
  return offset ? parseUTCOffset(offset) : null
}

const checkIsDateUtc = (date: string): boolean => {
  return hasOffset(date) && getUTCOffsetMinutes(date) === 0
}

export const checkIsDateOnly = (date: string): boolean => {
  const isDateUtc = checkIsDateUtc(date)
  if (isDateUtc) {
    const dateObj = DateTime.fromISO(date, { setZone: true })
    return (
      dateObj.hour === 0 &&
      dateObj.minute === 0 &&
      dateObj.second === 0 &&
      dateObj.millisecond === 0
    )
  }
  return false
}

export const formatDates = (
  type: string,
  dateOne: string,
  dateTwo?: string,
  opts?: { normalize?: boolean; localize?: boolean }
): string => {
  if (!dateOne) return ''

  if (type === DateValueFormat.InThePast) {
    return getDurationAgo(dateOne, dateTwo)
  }
  if (type === DateValueFormat.InTheFuture) {
    return getDurationIn(dateOne, dateTwo)
  }

  let output = ''
  const isLangFr = checkLangFr()
  const isDateOnly =
    !opts?.localize && !opts?.normalize ? checkIsDateOnly(dateOne) : false
  // isDateOnly flag will stop the conversion to local datetime

  let dateObj = DateTime.fromISO(dateOne, {
    setZone: !opts?.localize ? isDateOnly || opts?.normalize : false,
  })
  // For Backward Compatability with old saved dates
  if (!dateObj.isValid) {
    const dt = new Date(dateOne)
    if (opts?.normalize) {
      dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset())
    }
    dateObj = DateTime.fromJSDate(dt)
  }

  if (type === DateValueFormat.DayMonthYear) {
    output = dateObj.toLocaleString(
      { ...DateTime.DATETIME_SHORT_WITH_SECONDS },
      isLangFr ? { locale: 'fr' } : {}
    )
  } else if (type === DateValueFormat.DayMonthYearShort) {
    output = dateObj.toLocaleString(
      {
        ...DateTime.DATE_SHORT,
      },
      isLangFr ? { locale: 'fr' } : {}
    )
  } else if (type === DateValueFormat.DateTimeFullTimeSimple) {
    // E.g., October 6, 2022 at 10:55 AM
    output = dateObj.toLocaleString(
      {
        ...DateTime.DATE_FULL,
        ...DateTime.TIME_SIMPLE,
      },
      isLangFr ? { locale: 'fr' } : {}
    )
  } else if (type === DateValueFormat.DayMonthWithAMPM) {
    // E.g., Oct 6, 2022, 10:55:46 AM
    output = dateObj.toLocaleString(
      {
        ...DateTime.DATE_MED,
        ...DateTime.TIME_WITH_SECONDS,
      },
      isLangFr ? { locale: 'fr' } : {}
    )
  } else if (type === DateValueFormat.DayMonthYearMedium) {
    // E.g., Oct 6, 2022
    output = dateObj.toLocaleString(
      {
        ...DateTime.DATE_MED,
      },
      isLangFr ? { locale: 'fr' } : {}
    )
  } else if (type === DateValueFormat.IsoDate) {
    output = dateObj.toISODate() || ''
  } else if (type === DateValueFormat.Time12Hour) {
    output = dateObj.toLocaleString(
      {
        ...DateTime.TIME_SIMPLE,
      },
      { locale: 'en-US' }
    )
  } else if (type === DateValueFormat.DayMonthYearTimezone) {
    output = DateTime.fromISO(dateOne).toLocaleString(
      { ...DateTime.DATETIME_SHORT_WITH_SECONDS, timeZoneName: 'short' },
      isLangFr ? { locale: 'fr' } : {}
    )
  } else {
    output = dateObj.toFormat('DDD', isLangFr ? { locale: 'fr' } : {})
  }
  return output
}

export const getLuxonDateTime = (
  dateStr: string = '',
  normalize = false
): DateTime => {
  const isDateOnly = dateStr && !normalize ? checkIsDateOnly(dateStr) : false

  let datetime = dateStr
    ? DateTime.fromISO(dateStr, { setZone: normalize || isDateOnly })
    : DateTime.now()

  if (isDateOnly || normalize) {
    datetime = datetime.setZone('local', { keepLocalTime: true })
  }

  if (normalize && !isDateOnly) {
    datetime = datetime.startOf('day')
  }

  return datetime
}

export const getNormalizedDateString = (
  dateStr: string,
  format: DateTimeFormatOptions = DateTime.DATE_MED
): string => {
  if (dateStr) {
    const isLangFr = checkLangFr()
    const dateTime = getLuxonDateTime(dateStr, true)
    return dateTime.toLocaleString(format, isLangFr ? { locale: 'fr' } : {})
  }
  return ''
}

// Returns a date object from a time string (for example: '1:52 PM')
export const getDateFromTime = (timeStr: string): Date => {
  const [time, modifier] = timeStr.split(' ')
  let hours: string | number = time.split(':')[0]
  const minutes = Number(time.split(':')[1])

  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12
  }
  const date = new Date()
  date.setHours(Number(hours))
  date.setMinutes(Number(minutes))

  return date
}

export const isADate = (dateStr: string): boolean => {
  const date = new Date(dateStr)
  return !Number.isNaN(date.getDate())
}

export const getDateDifference = (
  endDate: string,
  startDate: string
): {
  differenceInMilliseconds: number
  differenceInSeconds: number
  differenceInDays: number
  differenceInMinutes: number
} => {
  if (!endDate || !startDate)
    return {
      differenceInMilliseconds: 0,
      differenceInSeconds: 0,
      differenceInDays: 0,
      differenceInMinutes: 0,
    }

  //  Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(
    new Date(endDate).getTime() - new Date(startDate).getTime()
  )
  // Convert milliseconds to seconds
  const differenceInSeconds = differenceInMilliseconds / 1000
  // Convert seconds to minutes
  const differenceInMinutes = differenceInSeconds / 60
  // Convert milliseconds to days
  const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000)

  return {
    differenceInMilliseconds,
    differenceInSeconds,
    differenceInDays,
    differenceInMinutes,
  }
}
export const getLuxonDate = (dateStr: string): string => {
  // date saved is in 4/30/2024 format and when date selected from mui datepicker
  // it's format is in ISO, so to keep it consistent we need to update te value in ISO format
  if (!DateTime.fromISO(dateStr).isValid) {
    const luxonDate = DateTime.fromFormat(dateStr, INTERVIEW_DATE_FORMAT)
    return luxonDate.toISO() ?? ''
  }
  return dateStr
}

export const getNextWeekday = (daysOffset: number = 1): DateTime => {
  const date = DateTime.now().plus({ days: daysOffset })
  const dayOfWeek = date.weekday
  if (dayOfWeek === 6) {
    // skip saturday and sunday
    return date.plus({ days: 2 })
  }
  if (dayOfWeek === 7) {
    // skip sunday
    return date.plus({ days: 1 })
  }
  return date
}

export const formatTimeZone = (timeZone: string): string => {
  // Removing offsets from timeZone string as this is not required for local computation
  const splitTimeZones = timeZone.split(' ')
  if (splitTimeZones.length > 1) return splitTimeZones[1].trim()
  return ''
}

export const isDateEqualGreaterThanToday = (
  date: string | DateTime,
  keepLocalTime = true
): boolean => {
  const dateToCompare =
    typeof date === 'string'
      ? DateTime.fromFormat(date, 'M/d/yyyy', { zone: 'UTC' })
      : date.startOf('day')

  const today = DateTime.now().setZone('UTC', { keepLocalTime }).startOf('day')
  return dateToCompare >= today
}

export const getMonthAndYear = (
  endDate: string,
  startDate: string,
  t: TFunction
): string => {
  if (!endDate || !startDate) return '-'
  const date1 = new Date(endDate).getTime()
  const date2 = new Date(startDate).getTime()
  const diff = Math.floor(date1 - date2)
  const day = 1000 * 60 * 60 * 24

  const days = Math.floor(diff / day)
  const months = Math.floor(days / 31)
  const years = Math.floor(months / 12)

  if (years) {
    return years + t('common.formatDates.years')
  }
  if (months) {
    return months + t('common.formatDates.months')
  }
  return days + t('common.formatDates.days')
}
