export enum HighlightTypes {
  TOOLS = 'Tools',
  LANGUAGES = 'Languages',
  INTERESTS = 'Interests',
  PROJECTS = 'Projects',
  PATENTS = 'Patents',
  PUBLICATIONS = 'Publications',
  REFERENCES = 'References',
}

export enum OnePagerModes {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum VisibilityTypes {
  Location = 'Location',
  Rate = 'Rate',
  WorkplaceType = 'WorkplaceType',
  Contract = 'Contract',
  Availability = 'Availability',
  Skills = 'Skills',
  Jobs = 'Jobs',
  Industry = 'Industry',
  OpenToRoles = 'OpenToRoles',
  Certifications = 'Certifications',
  AdditionalHighlights = 'AdditionalHighlights',
}

export enum CandidateOnePagerStateEnum {
  NO_FILE = 'editor.states.noFile',
  FILE_LOADING = 'editor.states.fileLoading',
  FILE_LOADED = 'editor.states.fileLoaded',
  UPLOADING = 'editor.states.uploading',
  PARSING = 'editor.states.parsing',
  STREAMING = 'editor.states.streaming',
  DONE = 'editor.states.done',
  ERROR = 'editor.states.error',
}

export enum BusinessType {
  TECHNICAL = 'technical',
  SOFT = 'soft skill',
  LEADERSHIP = 'leadership',
}
