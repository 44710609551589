import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const ButtonComponent = styled(Button)(({ size }) => ({
  padding: size === 'small' ? '5px 15px' : '4px 20px',
  borderRadius: '60px',
  fontSize: '14px',
  fontWeight: '800',
}))

export const CapsuleButton = ButtonComponent as typeof Button
