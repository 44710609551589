import React, { useCallback, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Subject } from 'rxjs'

import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import InfoIcon from '@mui/icons-material/Info'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PaymentsIcon from '@mui/icons-material/Payments'
import { Box, Grid, TextField, Typography } from '@mui/material'
import {
  AiFeatureLocations,
  gtmEvents,
  ICandidateHighlights,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  DatePickerHookField,
  FormToggleField,
  LocationAutocompleteHook,
  SessionCloseableAlert,
  useAlert,
  useDefaultErrorHandler,
} from '@procom-labs/molecules'
import { AiSummaryGenerator } from '@procom-labs/organisms'

import { fetchResumeAndCoverPage } from '@submission-portal/components/candidate-prep/candidate-prep-utils'
import { environment } from '@submission-portal/environment'
import {
  useAtsResourcesFetcher,
  useScopedFetchFile,
  useTrackingWrapper,
} from '@submission-portal/hooks'
import { jobAiService } from '@submission-portal/services/job-ai.service'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

export const SubmissionPrepProfileTab: React.FC = () => {
  const { t } = useTranslation('main')

  const { candidate: submission } = useSubjectSelector(candidatesPrepStore, [
    'candidate',
  ])
  const subscriptionRef = useSubscriptionRef()

  const { track } = useTrackingWrapper()

  const candidateHighlightsSubscriptionRef = useSubscriptionRef()

  const pause = useRef(new Subject<string>())
  const start = useRef(new Subject<string>())
  const { setValue } = useFormContext()
  const { addAlert } = useAlert()

  const onFetchFile = useScopedFetchFile()
  const fetchResource = useAtsResourcesFetcher(onFetchFile)

  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  useEffect(() => {
    if (submission?.highlights) {
      jobAiService.setCandidateHighlightStream(submission?.highlights)
    }
  }, [submission?.highlights])

  const handleGenerateCandidateHighlights = useCallback(
    (language) => {
      subscriptionRef.current = fetchResumeAndCoverPage({
        fetchResource,
        candidateStore: candidatesPrepStore,
      }).subscribe({
        next: ({ resume }) => {
          // Highlights require a resume
          if (!resume?.fileStorageId) {
            addAlert({
              severity: 'error',
              message: t('candidatePreb.profileMissingResumeError'),
            })
          } else {
            const payload: ICandidateHighlights = {
              atsJobId: submission?.atsJobId,
              resumeFileStorageId: resume?.fileStorageId,
              language,
            }
            start.current.next('')
            candidateHighlightsSubscriptionRef.current = jobAiService
              .generateCandidateHighlights(payload)
              .subscribe({
                next: (highlights: string) => {
                  // The user will need later ot have the save button to save highlights
                  setValue('highlights', highlights)
                },
                error: (err) => {
                  addAlert({
                    message: err.message,
                    severity: err.severity,
                  })
                },
                complete: () => {
                  track({
                    eventName: gtmEvents.GenerateAiCandidateHighlights,
                    ...payload,
                    jobTitle: submission?.jobTitle,
                    featureLocation: AiFeatureLocations.EmployerPortal,
                    candidateUserId: submission?.candidate?.id,
                  })

                  pause.current.next('')
                },
              })
          }
        },
        error: handleError,
      })
    },
    [
      subscriptionRef,
      fetchResource,
      handleError,
      addAlert,
      t,
      submission?.atsJobId,
      submission?.jobTitle,
      submission?.candidate?.id,
      candidateHighlightsSubscriptionRef,
      setValue,
      track,
    ]
  )

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
      <Grid item xs={4} sm={8} md={8} lg={8}>
        <SessionCloseableAlert
          sx={{ borderRadius: '4px', mt: '1rem' }}
          trackKey="showSearchCandidateInfo"
          severity="info"
          icon={<InfoIcon fontSize="inherit" />}
        >
          <Typography variant="body2">
            {t('candidatePreb.candidateProfileEdit')}
          </Typography>
        </SessionCloseableAlert>
        <FormToggleField
          label={t('common.candidates.details.availability')}
          switchName="clientPortalFieldsVisibility.isAvailabilityVisible"
          name="profile.dateAvailable"
          icon={EventAvailableIcon}
          render={(
            { ...fieldProps } // destructure and ignore ref
          ) => (
            <DatePickerHookField
              {...fieldProps}
              customLabel={false}
              disablePast
              textFieldProps={{
                InputLabelProps: { shrink: true },
                required: true,
                size: 'small',
              }}
            />
          )}
        />
        <FormToggleField
          label={t('common.candidates.details.billRate')}
          switchName="clientPortalFieldsVisibility.isRateVisible"
          name="profile.billRate"
          icon={PaymentsIcon}
          render={(fieldProps) => (
            <TextField
              {...fieldProps}
              InputProps={{
                autoComplete: 'off',
              }}
              variant="outlined"
              size="small"
              type="number"
              sx={{ width: '100%' }}
            />
          )}
        />
        <FormToggleField
          label={t('common.candidates.details.legalStatus')}
          switchName="clientPortalFieldsVisibility.isLegalStatusVisible"
          name="profile.employmentType"
          icon={HowToRegIcon}
          render={(fieldProps) => (
            <TextField
              {...fieldProps}
              InputProps={{
                autoComplete: 'off',
              }}
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
            />
          )}
        />
        <FormToggleField
          label={t('candidatePreb.location')}
          switchName="clientPortalFieldsVisibility.isLocationVisible"
          name="profile.location"
          icon={LocationOnIcon}
          render={(
            { ...fieldProps } // destructure and ignore ref
          ) => (
            <LocationAutocompleteHook
              fullWidth
              size="small"
              apiKey={environment.GOOGLE_MAPS_API_KEY}
              sx={{ width: '100%' }}
              {...fieldProps}
            />
          )}
        />

        <Box sx={{ mt: 6 }}>
          <AiSummaryGenerator
            title={t('organisms.candidateDetails.comments')}
            environment={environment}
            jobAiService={jobAiService}
            isBullhornPortal
            isSubmittedJobCandidateView
            handleGenerateCandidateSummary={handleGenerateCandidateHighlights}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
