import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { SchoolOutlined } from '@mui/icons-material'

import { MultiField } from './multi-field'

export const EducationEdit: FC = memo(() => {
  const { t } = useTranslation('main')
  return (
    <MultiField
      name="education"
      title={t('organisms.candidateOnePager.education.title')}
      removePrompt={t('organisms.candidateOnePager.education.remove')}
      icon={SchoolOutlined}
      fieldKeys={[
        {
          key: 'startDate',
          label: t('organisms.candidateOnePager.from'),
        },
        {
          key: 'endDate',
          label: t('organisms.candidateOnePager.to'),
        },
        {
          key: 'qualification',
          label: t('organisms.candidateOnePager.education.program'),
        },
        {
          key: 'institution',
          label: t('organisms.candidateOnePager.education.institution'),
        },
        {
          key: 'description',
          label: t('organisms.candidateOnePager.summary'),
        },
      ]}
    />
  )
})
