import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react'
import { Menu, MenuProps } from '@mui/material'

interface ActionMenuButtonProps extends Omit<MenuProps, 'open' | 'anchorEl'> {
  id: string
  label: ReactElement
  children: (close: () => void) => ReactNode
}

export const ActionMenuButton: FC<ActionMenuButtonProps> = ({
  id,
  label,
  children,
  ...menuProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      {React.cloneElement(label, {
        'aria-label': 'menu',
        id,
        'aria-controls': open ? `menu-for-${id}` : undefined,
        'aria-expanded': open ? 'true' : undefined,
        'aria-haspopup': 'true',
        onClick: handleClick,
      })}
      <Menu
        id={`menu-for-${id}`}
        MenuListProps={{
          'aria-labelledby': id,
          ...menuProps.MenuListProps,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...menuProps}
      >
        {children(handleClose)}
      </Menu>
    </>
  )
}
