import { useEffect } from 'react'
import { Subscription } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import { useServices } from '@procom-labs/common'

export const getCandidateFiles = (candidateId: string | null) => {
  const { jobAiService } = useServices()
  const candidateFiles = useObservable(() => jobAiService.candidateAtsFiles$)

  useEffect(() => {
    const subscription = new Subscription()

    if (candidateId) {
      subscription.add(
        jobAiService.getAtsCandidateFiles(candidateId).subscribe()
      )
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [jobAiService])

  return candidateFiles
}
