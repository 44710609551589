import { FC, useEffect, useMemo } from 'react'

import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GorillaGrid, GorillaStack } from '@procom-labs/atoms'
import {
  candidateOnePagerStore,
  useExecuteOnUnmountOrIdle,
  useSubjectSelector,
} from '@procom-labs/common'

import { getVisibilityMap } from './one-pager-utils'
import {
  Candidate,
  Certifications,
  Education,
  Experience,
  Highlights,
  Industry,
  Jobs,
  Roles,
  Skills,
} from './preview'

export const OnePagerView: FC<{
  isClientPortal?: boolean
  hideCandidateDescription?: boolean
  handleOnePagerUnmount?: (isSuddenShutDown?: boolean) => void
  handleOnePagerViewEvent?: () => void
}> = ({
  isClientPortal = false,
  hideCandidateDescription,
  handleOnePagerUnmount,
  handleOnePagerViewEvent,
}) => {
  const { onePager } = useSubjectSelector(candidateOnePagerStore, ['onePager'])

  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))

  const visibility = useMemo(
    () =>
      onePager?.fieldsVisibility
        ? getVisibilityMap(onePager.fieldsVisibility)
        : null,
    [onePager]
  )
  useExecuteOnUnmountOrIdle(handleOnePagerUnmount)

  useEffect(() => {
    handleOnePagerViewEvent?.()
  }, [handleOnePagerViewEvent])

  if (!onePager || !visibility) return null

  return isMobileOrTab ? (
    <GorillaGrid container gap={3} sx={{ pt: 4 }}>
      {!hideCandidateDescription && (
        <GorillaGrid item xs={12}>
          <Candidate
            candidate={onePager.candidateDescription}
            visibility={visibility}
            summary={onePager.parsedResumeData.careerSummary?.summary}
          />
        </GorillaGrid>
      )}
      {visibility.Skills && (
        <GorillaGrid item xs={12}>
          <Skills
            skills={onePager.skills}
            parsedResumeSkills={onePager.parsedResumeData.skills}
          />
        </GorillaGrid>
      )}
      <GorillaGrid item xs={12}>
        <Experience employmentHistory={onePager.parsedResumeData.employments} />
      </GorillaGrid>
      {visibility.Jobs && (
        <GorillaGrid item xs={12}>
          <Jobs jobs={onePager.jobs} />
        </GorillaGrid>
      )}
      {visibility.Industry && (
        <GorillaGrid item xs={12}>
          <Industry industries={onePager.industries} />
        </GorillaGrid>
      )}
      {visibility.OpenToRoles && onePager.openToRoles && (
        <GorillaGrid item xs={12}>
          <Roles roles={onePager.openToRoles} />
        </GorillaGrid>
      )}

      <GorillaGrid item xs={12}>
        <Education educationHistory={onePager.parsedResumeData.educations} />
      </GorillaGrid>
      {isMobile ? (
        <>
          {onePager.parsedResumeData.certifications.length !== 0 &&
            visibility.Certifications && (
              <GorillaGrid item xs={12}>
                <Certifications
                  certifications={onePager.parsedResumeData.certifications}
                />
              </GorillaGrid>
            )}
          {onePager.additionalHighlights.length !== 0 &&
            visibility.AdditionalHighlights && (
              <GorillaGrid item xs={12}>
                <Highlights
                  additionalHighlights={onePager.additionalHighlights}
                />
              </GorillaGrid>
            )}
        </>
      ) : (
        <GorillaGrid item xs={12}>
          <GorillaStack gap={3} direction="row">
            {onePager.parsedResumeData.certifications.length !== 0 &&
              visibility.Certifications && (
                <Box flex={1}>
                  <Certifications
                    certifications={onePager.parsedResumeData.certifications}
                  />
                </Box>
              )}
            {onePager.additionalHighlights.length !== 0 &&
              visibility.AdditionalHighlights && (
                <Box flex={1}>
                  <Highlights
                    additionalHighlights={onePager.additionalHighlights}
                  />
                </Box>
              )}
          </GorillaStack>
        </GorillaGrid>
      )}
    </GorillaGrid>
  ) : (
    <GorillaGrid container flexWrap="nowrap" gap={4} sx={{ pt: 4 }}>
      <GorillaGrid
        item
        xs={isClientPortal ? 12 : 8}
        sx={{ display: 'flex', flexDirection: 'column' }}
        gap={6}
      >
        {!hideCandidateDescription && (
          <Candidate
            candidate={onePager.candidateDescription}
            visibility={visibility}
            summary={onePager.parsedResumeData.careerSummary?.summary}
          />
        )}

        {isClientPortal && visibility.Skills && (
          <Skills
            skills={onePager.skills}
            parsedResumeSkills={onePager.parsedResumeData.skills}
          />
        )}

        <Experience employmentHistory={onePager.parsedResumeData.employments} />

        {isClientPortal && (
          <>
            {visibility.Jobs && (
              <GorillaGrid item xs={12}>
                <Jobs jobs={onePager.jobs} />
              </GorillaGrid>
            )}
            {visibility.Industry && (
              <Industry industries={onePager.industries} />
            )}
            {visibility.OpenToRoles && onePager.openToRoles && (
              <Roles roles={onePager.openToRoles} />
            )}
          </>
        )}
        <Education educationHistory={onePager.parsedResumeData.educations} />

        <GorillaStack gap={3} direction="row">
          {onePager.parsedResumeData.certifications.length !== 0 &&
            visibility.Certifications && (
              <Box flex={1}>
                <Certifications
                  certifications={onePager.parsedResumeData.certifications}
                />
              </Box>
            )}
          {onePager.additionalHighlights.length !== 0 &&
            visibility.AdditionalHighlights && (
              <Box flex={1}>
                <Highlights
                  additionalHighlights={onePager.additionalHighlights}
                />
              </Box>
            )}
        </GorillaStack>
      </GorillaGrid>
      {!isClientPortal && (
        <GorillaGrid
          item
          sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          gap={3}
        >
          {visibility.Skills && (
            <Skills
              skills={onePager.skills}
              parsedResumeSkills={onePager.parsedResumeData.skills}
            />
          )}

          {visibility.Jobs && <Jobs jobs={onePager.jobs} />}
          {visibility.Industry && <Industry industries={onePager.industries} />}

          {visibility.OpenToRoles && onePager.openToRoles && (
            <Roles roles={onePager.openToRoles} />
          )}
        </GorillaGrid>
      )}
    </GorillaGrid>
  )
}
