import {
  appService,
  authService,
  clientJobService,
  interviewCopilotService,
  jobAiService,
  utilsService,
} from '@submission-portal/services'

export const services = {
  clientJobService,
  jobAiService,
  interviewCopilotService,
  authService,
  appService,
  utilsService,
}
