import React, { useEffect } from 'react'
import { Paper, Tabs } from '@mui/material'
import NoteIcon from '@mui/icons-material/Note'
import PersonIcon from '@mui/icons-material/Person'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import SubjectIcon from '@mui/icons-material/Subject'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { IconTab, TabPanel, useActiveTab } from '@procom-labs/molecules'
import { useTranslation } from 'react-i18next'
import { SubmissionPrepMainTab } from '@submission-portal/components/candidate-prep/tabs/submission-prep-main-tab'
import { SubmissionPrepProfileTab } from '@submission-portal/components/candidate-prep/tabs/submission-prep-profile-tab'
import { useIsCandidatePrepReadOnly } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-core-hooks'
import { useSubscriptionRef } from '@procom-labs/common'
import { jobAiService } from '@submission-portal/services'

export const CandidatePrepCandidateTabContainer: React.FC = () => {
  const isTabbingDisabled = useIsCandidatePrepReadOnly()

  const candidateHighlightsStreamRef = useSubscriptionRef()
  const aiAssistedResumeStreamRef = useSubscriptionRef()

  const { t } = useTranslation('main')
  const [value, handleChange] = useActiveTab()

  useEffect(() => {
    candidateHighlightsStreamRef.current = jobAiService
      .getCandidateHighlightsStream()
      .subscribe()

    aiAssistedResumeStreamRef.current = jobAiService
      .getAiAssistedResumeStream()
      .subscribe()
  }, [aiAssistedResumeStreamRef, candidateHighlightsStreamRef])

  return (
    <Paper square style={{ backgroundColor: '#fff', boxShadow: 'none' }}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        style={{ borderBottom: '1px solid gray' }}
        variant="fullWidth"
      >
        <IconTab
          disabled={isTabbingDisabled}
          icon={<NoteIcon />}
          label={t('candidatePreb.tabs.submissionPrep')}
        />
        <IconTab
          disabled={isTabbingDisabled}
          icon={<PersonIcon />}
          label={t('candidatePreb.tabs.profile')}
        />
        <IconTab
          disabled
          icon={<FolderOpenIcon />}
          label={`${t('candidatePreb.tabs.documents')} (${t(
            'candidatePreb.tabs.comingSoon'
          )})`}
        />
        <IconTab
          disabled
          icon={<SubjectIcon />}
          label={`${t('candidatePreb.tabs.notes')} (${t(
            'candidatePreb.tabs.comingSoon'
          )})`}
        />
        <IconTab
          disabled
          icon={<AccessTimeIcon />}
          label={`${t('candidatePreb.tabs.timeline')} (${t(
            'candidatePreb.tabs.comingSoon'
          )})`}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <SubmissionPrepMainTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SubmissionPrepProfileTab />
      </TabPanel>
      <TabPanel value={value} index={2} />
      <TabPanel value={value} index={3} />
      <TabPanel value={value} index={4} />
    </Paper>
  )
}
