import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { List, ListItem, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
  BusinessType,
  GorillaResumeSkill,
  GorillaResumeTool,
  IOnePagerSkills,
} from '@procom-labs/common'

import { useCandidateOnePager } from '../one-pager-hooks'
import {
  getFixedNumber,
  getSkillGroups,
  getSkillProgress,
} from '../one-pager-utils'
import { SkillProgress } from './skill-progress'
import { SkillsWrapper } from './skills-wrapper'

const SkillsProgressBarList: FC<{
  skills: GorillaResumeTool[]
  maxYears: number
}> = ({ skills, maxYears }) => {
  const theme = useTheme()

  return (
    <List sx={{ p: 0 }}>
      {skills.map((skill) =>
        skill.name ? (
          <ListItem sx={{ p: 0, pb: 2 }} key={skill.name}>
            <SkillProgress
              label={skill.name}
              years={skill.yearsOfExperience ?? 0}
              value={getSkillProgress(
                +(skill.yearsOfExperience ?? 0),
                maxYears
              )}
              linearProgressColor={theme.palette.onePager.skillsProgress}
              linearProgressColorBg={theme.palette.onePager.skillsProgressBg}
            />
          </ListItem>
        ) : null
      )}
    </List>
  )
}

const SoftSkills: FC<{ skills: GorillaResumeTool[]; maxYears: number }> = ({
  skills,
  maxYears,
}) => {
  const { t } = useTranslation()
  const softSkillsWithYears = useMemo(
    () =>
      skills.filter(
        (skill) =>
          (skill.yearsOfExperience
            ? getFixedNumber(skill.yearsOfExperience)
            : 0) > 0
      ),
    [skills]
  )
  const softSkillsWithoutYears = useMemo(
    () =>
      skills.filter(
        (skill) =>
          (skill.yearsOfExperience
            ? getFixedNumber(skill.yearsOfExperience)
            : 0) === 0
      ),
    [skills]
  )

  return (
    <>
      {softSkillsWithYears.length > 0 && (
        <SkillsProgressBarList
          skills={softSkillsWithYears}
          maxYears={maxYears}
        />
      )}
      {softSkillsWithYears.length > 0 && softSkillsWithoutYears.length > 0 && (
        <Typography>{t('common.preps.other')}</Typography>
      )}
      {softSkillsWithoutYears.length > 1 && (
        <Typography variant="body2">
          {softSkillsWithoutYears.map((skill, index) => (
            <>
              <span>{skill.name}</span>
              {index < softSkillsWithoutYears.length - 1 && (
                <span style={{ marginRight: '8px' }}>,</span>
              )}
            </>
          ))}
        </Typography>
      )}
      {softSkillsWithoutYears.length === 1 && (
        <Typography variant="body2">
          {softSkillsWithoutYears[0].name}
        </Typography>
      )}
    </>
  )
}

export const Skills: FC<{
  skills: IOnePagerSkills
  parsedResumeSkills: GorillaResumeSkill[]
}> = memo(({ skills, parsedResumeSkills }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  // Group the skills by type
  const skillGroups = useMemo(
    () => getSkillGroups(skills.skills, parsedResumeSkills),
    [skills, parsedResumeSkills]
  )

  const { getSkillLabel } = useCandidateOnePager()

  return (
    <SkillsWrapper
      title={t('organisms.candidateOnePager.skills')}
      maxYears={skills.maxYears}
      minYears={skills.minYears}
    >
      {skillGroups.map((skillGroup, index) =>
        skillGroup.skills.length > 0 ? (
          <Stack
            direction="column"
            gap={2}
            key={skillGroup.type}
            sx={{ pt: index === 0 ? 1 : 3 }}
          >
            <Typography
              variant="subtitle1Bold"
              sx={{ color: theme.palette.text.secondary }}
            >
              {getSkillLabel(skillGroup.businessType, skillGroup.type)}
            </Typography>
            {skillGroup.businessType?.toLowerCase() === BusinessType.SOFT ? (
              <SoftSkills
                skills={skillGroup.skills}
                maxYears={skills.maxYears}
              />
            ) : (
              <SkillsProgressBarList
                skills={skillGroup.skills}
                maxYears={skills.maxYears}
              />
            )}
          </Stack>
        ) : null
      )}
    </SkillsWrapper>
  )
})
