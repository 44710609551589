import React, { FC } from 'react'
import { Field, FieldProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { OutlinedInput, OutlinedInputProps } from '@mui/material'

interface AvailabilityCustomProps extends OutlinedInputProps {
  name: string
}
export const AvailabilityCustom: FC<AvailabilityCustomProps> = React.memo(
  ({ name, ...rest }) => {
    const { t } = useTranslation('main')

    return (
      <Field name={name}>
        {({ field }: FieldProps) => (
          <OutlinedInput
            {...field}
            id={name}
            placeholder={t('common.form.availability.otherPlaceholder')}
            {...rest}
          />
        )}
      </Field>
    )
  }
)
