import React, {
  FC,
  useState,
  useEffect,
  memo,
  Dispatch,
  SetStateAction,
} from 'react'
import { Typography, TextField, IconButton, Box, useTheme } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'

interface ITextFieldWithEdit {
  resumeTitle: string
  setResumeFieldValue: Dispatch<SetStateAction<string>>
}

const FONT_SIZE = 9
const DEFAULT_INPUT_WIDTH = 200

export const TextFieldWithEdit: FC<ITextFieldWithEdit> = memo(
  ({ resumeTitle, setResumeFieldValue }) => {
    const theme = useTheme()
    const { t } = useTranslation('main')
    const [isEditing, setIsEditing] = useState(false)
    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH)

    useEffect(() => {
      if (resumeTitle.length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
        setInputWidth((resumeTitle.length + 1) * FONT_SIZE)
      } else {
        setInputWidth(DEFAULT_INPUT_WIDTH)
      }
    }, [resumeTitle])

    const toggleIsEditing = (): void => {
      if (resumeTitle) {
        setIsEditing((prevState) => !prevState)
      }
    }

    if (isEditing) {
      return (
        <>
          <TextField
            value={resumeTitle}
            onChange={(e) => setResumeFieldValue(e.target.value)}
            onBlur={toggleIsEditing}
            inputProps={{
              style: { width: `${inputWidth}px` },
            }}
            variant="standard"
            label={t('resumeCopilot.resumeTitle')}
            autoFocus
          />
          <Typography style={{ display: 'none' }} />
        </>
      )
    }

    return (
      <Box>
        <Typography fontWeight={500} display="inline">
          {resumeTitle}
        </Typography>
        <IconButton onClick={toggleIsEditing} sx={{ ml: 1 }} size="small">
          <EditIcon sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Box>
    )
  }
)
