import Axios from 'axios-observable'
import { finalize, map, Observable } from 'rxjs'

import {
  AuthProfileType,
  ClientCodes,
  JobFilterFields,
  JobKeywordFields,
  JobSearchType,
  JobStatus,
  TalentPoolsSortOptions,
  VendorCodes,
} from '../enums'
import {
  ClientListItem,
  IBillRate,
  IJobFilter,
  IJobStatusCount,
} from '../interfaces'
import {
  IDirectMessageFormPayload,
  IJob,
  IJobDetail,
  SortOrder,
} from '../models'
import { ClientJobStore } from '../store'
import { getClientCode, getVendorCode } from '../util'

interface JobServiceConfig {
  baseEndpoint: string
  axios: Axios
  clientJobStore: ClientJobStore
}

export class JobService {
  private readonly baseEndpoint: string

  private readonly axios: Axios

  clientJobStore: ClientJobStore

  constructor(config: JobServiceConfig) {
    this.baseEndpoint = config.baseEndpoint
    this.axios = config.axios
    this.clientJobStore = config.clientJobStore
  }

  getJobList(
    filter: IJobFilter,
    profileType?: AuthProfileType,
    selectedJobId?: string | null
  ): Observable<IJob[]> {
    const orderBy = `${filter.sortField} ${
      filter.sortOrder === TalentPoolsSortOptions.Newest
        ? SortOrder.desc
        : SortOrder.asc
    }`

    const filterQuery = this.prepareSearchQuery(filter, profileType)
    const query = filter
      ? `${this.baseEndpoint}/ClientJob?${new URLSearchParams({
          query: filterQuery,
          ...(orderBy && { orderBy }),
          ...(this.clientJobStore.searchType.title ===
            JobSearchType.Candidates && {
            searchBy: 'Candidate',
            candidateName: filter.keyword,
          }),
        })}`
      : `${this.baseEndpoint}/ClientJob`
    this.clientJobStore.dispatch({
      isLoading: true,
    })
    return this.axios.get<IJob[]>(query).pipe(
      map(({ data }) => {
        const selectedJobIndex = selectedJobId
          ? this.clientJobStore.findJobIndex(data, selectedJobId)
          : 0

        if (data.length && selectedJobIndex > -1) {
          const activeJob = data[selectedJobIndex] as IJobDetail
          this.clientJobStore.dispatch({
            activeJob: {
              ...activeJob,
              isViewed: true,
            },
          })
        } else {
          this.clientJobStore.dispatch({
            activeJob: null,
          })
        }
        this.clientJobStore.dispatch({
          jobList: data,
        })
        return data
      }),
      finalize(() => {
        this.clientJobStore.dispatch({
          isLoading: false,
        })
      })
    )
  }

  getJob(id: string): Observable<IJobDetail> {
    this.clientJobStore.dispatch({
      isLoading: true,
    })
    return this.axios
      .get<IJobDetail>(`${this.baseEndpoint}/ClientJob/${id}`)
      .pipe(
        map(({ data }) => data),
        finalize(() => {
          this.clientJobStore.dispatch({
            isLoading: false,
          })
        })
      )
  }

  getJobByAtsId(atsJobId: string): Observable<IJobDetail> {
    this.clientJobStore.dispatch({
      isLoading: true,
    })
    return this.axios
      .get<IJobDetail>(`${this.baseEndpoint}/ClientJob/ats/${atsJobId}`)
      .pipe(
        map(({ data }) => data),
        finalize(() => {
          this.clientJobStore.dispatch({
            isLoading: false,
          })
        })
      )
  }

  getJobByAtsIdAndFlag(
    atsJobId: string,
    createJob?: boolean
  ): Observable<IJobDetail> {
    this.clientJobStore.dispatch({
      isLoading: true,
    })
    let url = `${this.baseEndpoint}/JobSubmission/ats/job-order/${atsJobId}`
    if (createJob) {
      url = url.concat(`?createJob=${createJob}`)
    }
    return this.axios.get<IJobDetail>(url).pipe(
      map(({ data }) => data),
      finalize(() => {
        this.clientJobStore.dispatch({
          isLoading: false,
        })
      })
    )
  }

  getJobStatusCount(): Observable<IJobStatusCount[]> {
    const url = `${this.baseEndpoint}/ClientJob/status-count`
    const clientCode = getClientCode(window.location.hostname)
    const query = ![VendorCodes.APLN, VendorCodes.CMPSTF].includes(
      getVendorCode()
    )
      ? clientCode !== null
        ? `${url}?${new URLSearchParams({
            query: `$filter=(IsDirectSourcingJob eq true)`,
          })}`
        : url
      : `${url}?${new URLSearchParams({
          query: `$filter=(NoOfActiveCandidates gt 0 or NoOfRejectedCandidates gt 0)`,
        })}`
    return this.axios.get<IJobStatusCount[]>(query).pipe(
      map(({ data }) => {
        this.clientJobStore.dispatch({
          jobStatusCount: data,
        })
        return data
      })
    )
  }

  directMessageSubmitHandler({
    id,
    messageBody,
  }: IDirectMessageFormPayload): Observable<any> {
    return this.axios.post(
      `${this.baseEndpoint}/ClientJob/${id}/account-manager-direct-message`,
      {
        messageBody,
      }
    )
  }

  getClientNames(): Observable<void> {
    return this.axios
      .get<ClientListItem[]>(`${this.baseEndpoint}/ClientJob/client-name`)
      .pipe(
        map(({ data }) => {
          this.clientJobStore.dispatch({
            clientList: data,
          })
        })
      )
  }

  getBillRates(): Observable<void> {
    return this.axios
      .get<IBillRate[]>(`${this.baseEndpoint}/ClientJob/bill-rates`)
      .pipe(
        map(({ data }) => {
          this.clientJobStore.dispatch({
            billRates: data,
          })
        })
      )
  }

  prepareSearchQuery(
    searchFilter: IJobFilter,
    profileType?: AuthProfileType
  ): string {
    const { keyword, clientCompany, period, portalJobStatus } = searchFilter

    const filterArray = []

    filterArray.push(`$filter=`)

    if (portalJobStatus === JobStatus.Draft) {
      if (profileType === AuthProfileType.AccountManager) {
        filterArray.push(`(PortalJobStatus eq '${JobStatus.PendingReview}')`)
      } else {
        filterArray.push(
          `(PortalJobStatus eq '${portalJobStatus}' or PortalJobStatus eq '${JobStatus.PendingReview}')`
        )
      }
    } else {
      filterArray.push(
        `PortalJobStatus eq '${portalJobStatus || JobStatus.Active}'`
      )
    }

    if (period.startDate) {
      const dateFilter =
        portalJobStatus === JobStatus.Draft
          ? JobFilterFields.LastModifiedDateTime
          : JobFilterFields.CreatedDateTime

      filterArray.push(
        ` and (${dateFilter} ge ${period.startDate}) and (${dateFilter} lt ${period.endDate})`
      )
    }

    if (clientCompany.length) {
      filterArray.push(' and ')
      const clientCompanyQuery: string[] = []
      clientCompany.map((client) =>
        clientCompanyQuery.push(
          `(${JobFilterFields.ClientCode} eq '${client}')`
        )
      )
      filterArray.push(clientCompanyQuery.join(' or '))
    }

    // To only send search.isMatch() in query when JobSearchType is Jobs
    if (
      keyword &&
      this.clientJobStore.searchType.title === JobSearchType.Jobs
    ) {
      const query = ` and search.ismatch('${keyword}*','${Object.values(
        JobKeywordFields
      ).join(',')}')`
      filterArray.push(query)
    }

    const vendorCode = getVendorCode()
    const clientCode = getClientCode()

    if (
      [VendorCodes.APLN, VendorCodes.CMPSTF].includes(vendorCode) &&
      [JobStatus.Active, JobStatus.Closed, JobStatus.Paused].includes(
        portalJobStatus
      )
    ) {
      const query = ` and (NoOfActiveCandidates gt 0 or NoOfRejectedCandidates gt 0)`
      filterArray.push(query)
    }

    // TODO: Refactor to add a generic query for the particular portal
    if (vendorCode === VendorCodes.PCGL && clientCode === ClientCodes.BMO) {
      const query = ` and IsDirectSourcingJob eq true and ClientCode eq 'BMO'`
      filterArray.push(query)
    }

    return filterArray.join('')
  }

  getJobDescriptionTemplates(): Observable<string[]> {
    return this.axios
      .get<string[]>(`${this.baseEndpoint}/ClientJob/job-description-templates`)
      .pipe(map(({ data }) => data))
  }
}
