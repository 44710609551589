import { useMemo } from 'react'

import { FeatureFlagNames } from '../enums'
import { featureFlagStore, useSubjectSelector } from '../store'

export const useIsFeatureFlagEnabled = (
  featureFlagName: FeatureFlagNames
): boolean => {
  const { featureFlags } = useSubjectSelector(featureFlagStore, [
    'featureFlags',
  ])
  return useMemo(() => {
    const feature = featureFlags.find(
      (flag) => flag.featureName === featureFlagName
    )
    return feature ? feature.enabled : false
  }, [featureFlagName, featureFlags])
}
