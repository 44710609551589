import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useObservable } from 'rxjs-hooks'

import FilterAltIcon from '@mui/icons-material/FilterAlt'
import PersonIcon from '@mui/icons-material/Person'
import {
  alpha,
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GridColDef } from '@mui/x-data-grid'
import {
  CandidateStatusType,
  Duration,
  getDurationExcludeWeekends,
  getInitials,
  JobSubmissionStatusTypes,
  useSubjectSelector,
  useSubscriptionRef,
  VendorCodes,
} from '@procom-labs/common'
import { DropDownActionMenuButton } from '@procom-labs/molecules'
import { CellText } from '@procom-labs/organisms'

import { SubmissionActions } from '@submission-portal/components/submission-view/components/submission-actions'
import { useAddCandidateToList } from '@submission-portal/hooks'
import { useNavigateToSubmission } from '@submission-portal/hooks/submission-navigation'
import { vendorStorage } from '@submission-portal/lib'
import { ISubmissionSummary } from '@submission-portal/models'
import { submissionService } from '@submission-portal/services'
import { avatarService } from '@submission-portal/services/avatar.service'
import { submissionStore } from '@submission-portal/stores'
import {
  ReviewAndSubmitKeys,
  SubmissionFields,
  SubmissionSearchFilter,
  SubmissionSource,
} from '@submission-portal/types'

const MAX_DAYS = 30
const MIN_DAYS = 1
const MIN_HOURS = 1

export const useGetClientActivityLabel = (): ((status: string) => string) => {
  const { t } = useTranslation('main')

  return useCallback(
    (status: string) => {
      switch (status) {
        case CandidateStatusType.ClientSubmission:
          return t('submissionList.activityStatuses.clientSubmission')
        case JobSubmissionStatusTypes.SubmittedToManager:
          return t('submissionList.activityStatuses.submittedToManager')
        default:
          return status
      }
    },
    [t]
  )
}

export const useGetSubmissionColumnDescriptors =
  (): GridColDef<ISubmissionSummary>[] => {
    const { t } = useTranslation('main')
    const getClientActivityLabel = useGetClientActivityLabel()

    return [
      {
        field: SubmissionFields.Candidate,
        headerName: t('submissionList.candidates'),
        valueGetter: (params) =>
          `${params.row.firstName} ${params.row.lastName}`,
        minWidth: 200,
      },
      {
        field: SubmissionFields.AddedBy,
        headerName: t('submissionList.addedBy'),
        valueGetter: (params) => params.row.addedBy,
      },
      {
        field: SubmissionFields.Status,
        headerName: t('submissionList.status'),
        valueGetter: (params) => params.row.atsSubmissionStatus,
      },
      {
        field: SubmissionFields.ClientActivity,
        renderHeader: () => (
          <div className="MuiDataGrid-columnHeaderTitle">
            {t('submissionList.clientActivity')}
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation()
                submissionStore.dispatch({
                  isFilterModalOpen: true,
                })
              }}
            >
              <FilterAltIcon />
            </IconButton>
          </div>
        ),
        valueGetter: (params) =>
          getClientActivityLabel(params.row.status ?? ''),
        minWidth: 200,
      },
      {
        field: SubmissionFields.Insights,
        headerName: t('submissionList.insights'),
        valueGetter: (params) =>
          params.row.userActivityInsight?.totalViews ?? 0,
        maxWidth: 180,
      },
      {
        field: SubmissionFields.ReviewAndSubmit,
        renderHeader: () => (
          <div className="MuiDataGrid-columnHeaderTitle">
            {t('submissionList.reviewAndSubmit')}
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation()
                submissionStore.dispatch({
                  isFilterModalOpen: true,
                })
              }}
            >
              <FilterAltIcon />
            </IconButton>
          </div>
        ),
        maxWidth: 300,
        minWidth: 150,
        sortable: false,
      },
    ]
  }

export const useGetStatusColor = (): ((status: string) => string) => {
  const theme = useTheme()

  return useCallback(
    (status: string) => {
      switch (status) {
        case JobSubmissionStatusTypes.OfferRejected:
        case JobSubmissionStatusTypes.OfferWithdrawn:
        case JobSubmissionStatusTypes.ClientRejected:
        case JobSubmissionStatusTypes.WithdrawalByCandidate:
        case JobSubmissionStatusTypes.DeclinedbyClient:
        case JobSubmissionStatusTypes.DeclinedbyClient_DoubleSub:
        case JobSubmissionStatusTypes.Rejected:
          return alpha(theme.palette.error.main, 0.2)
        case JobSubmissionStatusTypes.OfferExtended:
        case JobSubmissionStatusTypes.Offer:
        case JobSubmissionStatusTypes.ClientShortlisted:
        case JobSubmissionStatusTypes.Confirmed:
        case JobSubmissionStatusTypes.Placement:
          return alpha(theme.palette.success.main, 0.2)
        case JobSubmissionStatusTypes.InterviewRequested:
        case JobSubmissionStatusTypes.InterviewScheduled:
        case JobSubmissionStatusTypes.Interviewing:
        case JobSubmissionStatusTypes.ClientInterview:
        case JobSubmissionStatusTypes.ClientFeedback:
          return alpha(theme.palette.info.main, 0.2)
        case JobSubmissionStatusTypes.ClientSubmission: {
          return alpha(theme.palette.warning.main, 0.2)
        }
        default:
          return alpha(theme.palette.info.main, 0.2)
      }
    },
    [theme]
  )
}

export const useGetDateDifference = (): ((duration: Duration) => string) => {
  const { t } = useTranslation('main')

  return useCallback(
    (duration: Duration) => {
      let result = ''

      if (duration.days > MAX_DAYS) {
        result += t('submissionList.moreThan30d')
      } else if (duration.days > MIN_DAYS) {
        result += `${Math.floor(duration.days)}${t('submissionList.day')}`
      } else if (duration.hours > MIN_HOURS) {
        result += `${Math.floor(duration.hours)}${t('submissionList.hour')}`
      } else {
        result += t('submissionList.lessThan1h')
      }
      return result
    },
    [t]
  )
}

export const useSubmissionDataColumns = (): GridColDef<any, any, any>[] => {
  const { t } = useTranslation('main')
  const theme = useTheme()
  const mobileView = useMediaQuery(() => theme.breakpoints.down('md'))

  const avatarColors = useObservable(() => avatarService.avatarsColors$)
  const columnDescriptors = useGetSubmissionColumnDescriptors()
  const getClientActivityLabel = useGetClientActivityLabel()

  const navigateToSubmission = useNavigateToSubmission()
  const addCandidateToList = useAddCandidateToList()
  const getDateDifference = useGetDateDifference()
  const getStatusColor = useGetStatusColor()

  const isVendorVanderHouwen =
    vendorStorage.get()?.vendorCode === VendorCodes.VNDRHWN

  const handleRemoveCandidateFromModal = useCallback(
    (jobSubmissionId: string) => {
      submissionStore.dispatch({
        touchedCandidate: jobSubmissionId,
        isCandidateRemoveModalOpen: true,
      })
    },
    []
  )

  const cellRenderer = useMemo(
    () =>
      (field: string, cell: { row: ISubmissionSummary }): ReactNode => {
        const { row } = cell
        const isSubmittedViaAts =
          row.submissionSource === SubmissionSource.Bullhorn

        const backgroundColor = avatarColors?.find(
          (c) => c.avatarId === row.atsJobSubmissionId
        )?.color

        const durationLastModified = getDurationExcludeWeekends(
          new Date(row.dateLastModified).toISOString(),
          new Date().toISOString()
        )

        let submissionStatus

        if (!row.status) {
          if (isSubmittedViaAts && !isVendorVanderHouwen) {
            submissionStatus = t('submissionList.bhSubmission')
          } else {
            submissionStatus = t(
              'submissionList.activityStatuses.submittedToManager'
            )
          }
        } else {
          submissionStatus = getClientActivityLabel(row.status)
        }
        switch (field) {
          case SubmissionFields.Candidate:
            return (
              <Stack
                direction="row"
                sx={{ alignItems: 'center', marginLeft: '8px' }}
                gap={2}
              >
                <Avatar
                  src=""
                  alt={`${row.firstName}-${row.lastName}`}
                  sx={{
                    backgroundColor: backgroundColor
                      ? `#${backgroundColor}`
                      : '000000',
                    width: 44,
                    height: 44,
                    fontWeight: 400,
                    fontSize: 20,
                    lineHeight: 20,
                    color: (innerTheme) => innerTheme.palette.common.white,
                  }}
                >
                  {getInitials(`${row.firstName} ${row.lastName}`, 2) || (
                    <PersonIcon sx={{ width: 35, height: 35 }} />
                  )}
                </Avatar>
                <Typography
                  variant="body1Bold"
                  display="inline"
                >{`${row.firstName} ${row.lastName}`}</Typography>
              </Stack>
            )
          case SubmissionFields.AddedBy:
            return <CellText value={row.addedBy} />
          case SubmissionFields.Status:
            return <CellText value={row.atsSubmissionStatus} />
          case SubmissionFields.ClientActivity:
            return (
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    maxWidth: '250px',
                    width: '90%',
                    padding: '8px 16px',
                    borderRadius: '4px',
                    backgroundColor: getStatusColor(row.status ?? ''),
                  }}
                >
                  {/*
                  -  If there is no Portal Status, check if candidate is submitted via BullHorn
                     else the Client activity would be "Not sent to client"
                  -  If portal status is SubmittedToManager then status will as per getClientActivityLabel(status)
                  */}

                  <Typography sx={{ textAlign: 'center' }}>
                    {submissionStatus}
                    {row.submissionSource !== null && (
                      <span
                        style={{
                          color: theme.palette.text.secondary,
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          marginLeft: '8px',
                        }}
                      >
                        {getDateDifference(durationLastModified)}
                      </span>
                    )}
                  </Typography>
                </Box>
              </Box>
            )
          case SubmissionFields.Insights:
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pl: 3,
                }}
              >
                <Typography>
                  {row.userActivityInsight?.totalViews ?? 0}{' '}
                  {t('submissionList.views')}
                </Typography>
              </Box>
            )

          case SubmissionFields.ReviewAndSubmit: {
            const handleSubmit = (): void => navigateToSubmission(row)
            /* VH can resubmit the candidate if the candidate has been submitted via BH */
            if (row.submissionSource === SubmissionSource.Bullhorn)
              return (
                <Box sx={{ width: '160px', textAlign: 'center' }}>
                  {t('submissionList.bhSubmission')}
                </Box>
              )

            return (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                {row.submissionSource === SubmissionSource.BullhornUtility &&
                row.jobSubmissionId ? (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={handleSubmit}
                    sx={{ textWrap: 'wrap' }}
                  >
                    {t('submissionList.btn.view')}
                  </Button>
                ) : (
                  <DropDownActionMenuButton
                    buttonProps={{
                      variant: 'contained',
                      color: 'primary',
                      children: t('common.btn.submit'),
                      sx: {
                        width: '160px',
                        boxShadow: 'none !important',
                      },
                    }}
                    id="cta-submit-candidate"
                  >
                    {(close) => (
                      <SubmissionActions
                        close={close}
                        handleSubmit={handleSubmit}
                        handleAddCandidateToList={addCandidateToList}
                        handleRemoveCandidateFromList={
                          handleRemoveCandidateFromModal
                        }
                        submission={row}
                      />
                    )}
                  </DropDownActionMenuButton>
                )}
              </Box>
            )
          }
          default:
            // Should not happen
            return <></>
        }
      },
    [
      avatarColors,
      isVendorVanderHouwen,
      t,
      getClientActivityLabel,
      getStatusColor,
      theme.palette.text.secondary,
      getDateDifference,
      navigateToSubmission,
      addCandidateToList,
      handleRemoveCandidateFromModal,
    ]
  )

  return useMemo(
    () =>
      columnDescriptors.map((columnDescriptor) => ({
        ...columnDescriptor,
        flex: Number(!mobileView),
        renderCell: (cell: any) => cellRenderer(columnDescriptor.field, cell),
      })),
    [cellRenderer, columnDescriptors, mobileView]
  )
}
export const useSubmissionDataRecords = (
  searchFilter: SubmissionSearchFilter
): { rows: ISubmissionSummary[]; rowCount: number; loading: boolean } => {
  const [searchParams] = useSearchParams()
  const subscriptionRef = useSubscriptionRef()
  const jobId = searchParams.get('EntityID')

  const {
    submissions: rows,
    count: rowCount,
    loading,
  } = useSubjectSelector(submissionStore, ['submissions', 'count', 'loading'])

  useEffect(() => {
    if (rowCount < 20 && searchFilter.sortField) return
    subscriptionRef.current = submissionService
      .getSubmissionData(submissionStore, jobId, searchFilter)
      .subscribe({
        next: (items) => {
          avatarService.initAvatarColors(items, 'atsJobSubmissionId')
        },
      })
  }, [jobId, searchFilter, subscriptionRef, rowCount])

  const filteredRows = useMemo(() => {
    let newRows = [...rows]

    newRows = newRows.filter((submission) => {
      let value = true
      if (searchFilter.clientActivity.length > 0) {
        value =
          value && searchFilter.clientActivity.includes(submission.status ?? '')
      }
      if (value && searchFilter.reviewAndSubmit.length > 0) {
        let t = false
        searchFilter.reviewAndSubmit.forEach((v) => {
          switch (v) {
            case ReviewAndSubmitKeys.Submitted:
              t =
                t ||
                (submission.status !==
                  JobSubmissionStatusTypes.SubmittedToManager &&
                  submission.submissionSource !== SubmissionSource.Bullhorn)
              break
            case ReviewAndSubmitKeys.ReadyForSubmission:
              t =
                t ||
                (submission.status ===
                  JobSubmissionStatusTypes.SubmittedToManager &&
                  submission.submissionSource !== SubmissionSource.Bullhorn)
              break
            case ReviewAndSubmitKeys.SubmittedViaBh:
              t = t || submission.submissionSource === SubmissionSource.Bullhorn
              break
            default:
              break
          }
        })
        value = value && t
      }
      return value
    })

    return newRows
  }, [rows, searchFilter.clientActivity, searchFilter.reviewAndSubmit])

  return { rows: filteredRows, rowCount, loading }
}
