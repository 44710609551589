import React, { FC, memo, useCallback, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import {
  Box,
  FormControl,
  IconButton,
  OutlinedInput,
  SvgIconTypeMap,
  Switch,
} from '@mui/material'
import Button from '@mui/material/Button'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useTheme } from '@mui/material/styles'
import { GorillaStack } from '@procom-labs/atoms'
import { DatePickerHookField } from '@procom-labs/molecules'

import { SectionTitle } from '../common'
import { RemoveDialog } from '../dialogs/remove-dialog'

export interface ListFieldProps {
  switchName: string
  removePrompt: string
  name: string
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string }
  title: string
  textFieldName: string
  showDate?: boolean
  emptyValue: any
}
export const ListField: FC<ListFieldProps> = memo(
  ({
    switchName,
    removePrompt,
    textFieldName,
    name,
    icon: IconComponent,
    title,
    showDate,
    emptyValue,
  }) => {
    const { t } = useTranslation('main')
    const theme = useTheme()
    const [openDelete, setOpenDelete] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
    const { fields, remove, append } = useFieldArray({
      name,
    })

    const handleCloseDialog = useCallback(() => {
      setOpenDelete(false)
      setSelectedIndex(undefined)
    }, [])

    const handleOpenDialog = useCallback((index: number) => {
      setSelectedIndex(index)
      setOpenDelete(true)
    }, [])
    const handleAdd = useCallback(
      () => append(emptyValue),
      [append, emptyValue]
    )
    const handleDelete = useCallback(() => {
      if (selectedIndex !== undefined) {
        remove(selectedIndex)
        handleCloseDialog()
      }
    }, [handleCloseDialog, remove, selectedIndex])
    return (
      <>
        <GorillaStack gap={2}>
          <GorillaStack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <SectionTitle
              icon={
                <IconComponent
                  sx={{
                    color: theme.palette.secondary.main,
                    fontSize: '18px',
                  }}
                />
              }
              title={title}
            />
            <Controller
              name={switchName}
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  {...field}
                  color="primary"
                  checked={Boolean(field.value)}
                  sx={{ ml: '-12px' }}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </GorillaStack>
          {fields.map((_field, index) => (
            <GorillaStack
              key={_field.id}
              gap={1}
              direction="row"
              alignItems="end"
            >
              <Controller
                key={_field.id}
                name={`${name}.${index}.${textFieldName}`}
                render={({ field }) => (
                  <FormControl sx={{ flex: 2 }}>
                    <OutlinedInput
                      {...field}
                      size="small"
                      sx={{ pr: 0 }}
                      inputProps={{ sx: { py: '6.5px' } }}
                    />
                  </FormControl>
                )}
              />
              {showDate && (
                <Box sx={{ flex: 1 }}>
                  <DatePickerHookField
                    views={['year', 'month']}
                    format="MM/yyyy"
                    name={`${name}.${index}.dateReceived`}
                    label="Date Received"
                    customLabelProps={{
                      sx: { fontSize: theme.typography.body2.fontSize },
                    }}
                    formatter={(value) => value.toFormat('yyyy-MM')}
                    textFieldProps={{
                      size: 'small',
                      variant: 'outlined',
                      InputProps: {
                        inputProps: {
                          sx: { py: '6.5px' },
                          placeholder: 'MM/YYYY',
                        },
                        sx: { minWidth: '138px' },
                      },
                    }}
                  />
                </Box>
              )}

              <IconButton
                sx={{ p: '6px' }}
                onClick={() => handleOpenDialog(index)}
              >
                <DeleteOutlineRoundedIcon />
              </IconButton>
            </GorillaStack>
          ))}
          <Box>
            <Button
              onClick={handleAdd}
              size="small"
              startIcon={<AddRoundedIcon />}
              sx={{ px: 2, textTransform: 'capitalize' }}
            >
              {t('common.btn.add')}
            </Button>
          </Box>
        </GorillaStack>
        <RemoveDialog
          open={openDelete}
          onClose={handleCloseDialog}
          onDelete={handleDelete}
          title={removePrompt}
          cancelId="btn-cancel-delete"
          deleteId="cta-delete"
        />
      </>
    )
  }
)
