import React, { useCallback } from 'react'
import { Field, FieldArray, FieldArrayRenderProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { Grid, Stack, Switch, Typography } from '@mui/material'
import { ICollaboratorInvitePayload } from '@procom-labs/common'

export const CollaboratorToggle: React.FC<{
  collaborators: ICollaboratorInvitePayload[] | undefined
}> = ({ collaborators }) => {
  const { t } = useTranslation('main')

  const handleCollaboratorsToggle = useCallback(
    (
      arrayHelpers: FieldArrayRenderProps,
      index: number,
      collaborator: ICollaboratorInvitePayload
    ) => {
      arrayHelpers.replace(index, collaborator)
    },
    []
  )

  return (
    <Grid maxHeight={300} overflow="auto">
      <Typography component="h6" variant="h6">
        {t('common.collaboratorToggle.invitees')}
      </Typography>
      <FieldArray
        name="interviewCollaborators"
        render={(arrayHelpers) => (
          <Grid>
            {collaborators &&
              collaborators.map((collaborator, index) => {
                return (
                  <Field
                    name={`interviewCollaborators[${index}]`}
                    key={collaborator.email}
                  >
                    {() => (
                      <>
                        <Stack
                          direction="row"
                          columnGap={3}
                          alignItems="center"
                        >
                          <Typography
                            sx={{ width: '200px' }}
                            textOverflow="ellipsis"
                            overflow="hidden"
                          >
                            {collaborator.email}
                          </Typography>
                          <Switch
                            color="primary"
                            defaultChecked={collaborator.isInvited}
                            onChange={(event, checked) => {
                              handleCollaboratorsToggle(arrayHelpers, index, {
                                email: collaborator.email,
                                firstName: collaborator.firstName,
                                lastName: collaborator.lastName,
                                isInvited: checked,
                                id: collaborator.id ?? '',
                              })
                            }}
                          />
                        </Stack>
                      </>
                    )}
                  </Field>
                )
              })}
          </Grid>
        )}
      />
    </Grid>
  )
}
