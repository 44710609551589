import { FC, useCallback } from 'react'
import { useField } from 'formik'
import i18n from 'i18next'

import { Box } from '@mui/material'
import { PreferredLanguageType, useIsGorillaTheme } from '@procom-labs/common'

import { Editor, IAllProps } from '@tinymce/tinymce-react'

interface TaggableUser {
  id: string
  name: string
  firstName?: string
  lastName?: string
  email?: string
  username?: string
  avatarFileName: string
  avatarFileGuid: string
}

export interface IFromEditor extends IAllProps {
  name: string
  initialValue?: string
  height?: number
  minHeight?: number
  disabled?: boolean
  environment: any
  dropFunc?: any
  width?: string | number
  service?: any
  useCDN?: boolean
  showDisabledMessage?: boolean
}

export const FormEditorToolbar = [
  { name: 'history', items: ['undo', 'redo'] },
  {
    name: 'styles',
    items: [
      'styles',
      'font_family_formats',
      'fontfamily',
      'fontsize',
      'forecolor',
    ],
  },
  {
    name: 'formatting',
    items: ['bold', 'italic', 'underline'],
  },
  {
    name: 'lists',
    items: ['bullist', 'numlist'],
  },
  {
    name: 'alignment',
    items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
  },
  {
    name: 'indentation',
    items: ['outdent', 'indent'],
  },
]

export const FormEditor: FC<IFromEditor> = ({
  name,
  initialValue,
  minHeight,
  environment,
  height = 222,
  disabled = false,
  plugins = ['lists'],
  dropFunc,
  width = '100%',
  service,
  useCDN,
}) => {
  const [field, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const isGorillaTheme = useIsGorillaTheme()

  const language = i18n.language === PreferredLanguageType.FR ? 'fr_FR' : null

  const mentionsFetch = useCallback(
    (query: any, success: any): any => {
      const searchTerm: string = query?.term?.trim()?.toLowerCase() ?? ''
      const taggableUser = service?.getTaggableUsers() as TaggableUser[]

      if (!taggableUser) return

      const filteredUsers = taggableUser?.filter(
        (user) =>
          user?.firstName?.toLowerCase()?.startsWith(searchTerm) ||
          user?.lastName?.toLowerCase()?.startsWith(searchTerm) ||
          user?.email?.toLowerCase()?.includes(searchTerm)
      )

      success(
        filteredUsers.map((user) => ({
          ...user,
          name:
            user?.firstName || user?.lastName
              ? `${user?.firstName} ${user?.lastName}`
              : user.email,
          username:
            user?.firstName || user?.lastName
              ? `${(
                  (user?.firstName ?? '') + (user?.lastName ?? '')
                ).toLowerCase()}`
              : user?.email,
        }))
      )
    },
    [service]
  )

  const mentionsMenuComplete = useCallback(
    (editor: any, userInfo: TaggableUser) => {
      const span = editor.getDoc().createElement('span')
      span.className = 'taggedUser'
      span.setAttribute('data-mention-id', userInfo.id)
      span.setAttribute('style', 'color: #008BCC;')
      span.appendChild(editor.getDoc().createTextNode(`@${userInfo.name}`))
      return span
    },
    [service]
  )

  const requireCdn = useCDN || plugins.includes('mentions')

  const isCDNLoaded =
    window?.tinymce?.baseURL?.includes('cdn') || window?.tinyCdnLoading

  if (!isCDNLoaded && requireCdn) {
    window.tinymce = undefined
    window.tinyCdnLoading = true
  }

  return (
    <Box
      sx={
        isGorillaTheme
          ? (theme) => ({
              '.tox-tinymce': {
                borderColor: 'text.primary',
                '.tox-editor-header': {
                  borderBottom: '2px solid !important',
                  borderColor: 'text.primary',
                  '.tox-tbtn svg': {
                    fill: theme.palette.primary.main,
                  },
                  '.tox-tbtn--disabled svg': {
                    fill: 'rgba(34,47,62,.5) !important',
                  },
                },
              },
              '.tox .tox-edit-area__iframe, .tox-toolbar, .tox-editor-header, .tox-statusbar':
                {
                  backgroundColor: `${theme.palette.common.white} !important`,
                },
            })
          : {}
      }
    >
      <Editor
        value={value}
        disabled={disabled}
        apiKey={environment.TINY_MCE_API_KEY}
        textareaName={name}
        {...(initialValue && { initialValue })}
        init={{
          width,
          height,
          plugins,
          mentions_selector: 'span.taggedUser',
          mentions_menu_complete: mentionsMenuComplete,
          mentions_fetch: mentionsFetch,
          min_height: minHeight,
          branding: false,
          menubar: false,
          statusbar: false,
          elementpath: false,
          toolbar_mode: 'wrap',
          ...{
            ...(language && {
              language,
              language_url: '/assets/locales/fr/tinyMCE.js',
            }),
          },
          toolbar: FormEditorToolbar,
          content_style: `body { 
                          font-family:Roboto,sans-serif; 
                          font-size:14px;
                          border-color: blue;
                        }`,
        }}
        onEditorChange={(newValue) => {
          setValue(newValue)
        }}
        onBlur={field.onBlur}
        onDrop={(e) => {
          e.preventDefault()
          if (dropFunc) {
            dropFunc(e.dataTransfer?.files)
          }
        }}
        tinymceScriptSrc={
          !requireCdn && !isCDNLoaded
            ? `${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`
            : undefined
        }
      />
    </Box>
  )
}
