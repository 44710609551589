import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { MenuItem, Select, SelectProps } from '@mui/material'
import { AvailabilityDefaults } from '@procom-labs/common'

interface AvailabilityProps extends SelectProps {
  name: string
  showDefault?: boolean
}
export const AvailabilityField: FC<AvailabilityProps> = React.forwardRef(
  ({ size, showDefault, ...rest }, ref) => {
    const { t } = useTranslation('main')

    return (
      <Select size={size} ref={ref} {...rest}>
        {showDefault && <MenuItem key={0} value="" sx={{ height: '30px' }} />}
        <MenuItem key={1} value={AvailabilityDefaults.IMMEDIATE}>
          {t('common.form.availability.immediate')}
        </MenuItem>
        <MenuItem key={2} value={AvailabilityDefaults.TWO_WEEKS}>
          {t('common.form.availability.twoWeeks')}
        </MenuItem>
        <MenuItem key={3} value={AvailabilityDefaults.OTHER}>
          {t('common.form.availability.other')}
        </MenuItem>
        <MenuItem key={4} value={AvailabilityDefaults.DATE}>
          {t('common.form.availability.date')}
        </MenuItem>
      </Select>
    )
  }
)
