import React, { MouseEvent, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import {
  Box,
  Grid,
  LinearProgress,
  TablePagination,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { GridColDef } from '@mui/x-data-grid'
import { useDebouncedSubjectSelector } from '@procom-labs/common'
import { AppDataGrid, AppDataGridProps } from '@procom-labs/organisms'

import { ISubmissionSummary } from '@submission-portal/models'
import { submissionService } from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores/submission-store'
import {
  DefaultSubmissionSearchFilter,
  DisplayModes,
} from '@submission-portal/types'

import { CandidateCard } from './candidate-card'

interface AtsJobSubmissionViewProps extends AppDataGridProps {
  columns: GridColDef[]
  rows: ISubmissionSummary[]
  rowCount: number
  loading: boolean
}

export const AtsJobSubmissionView: React.FC<AtsJobSubmissionViewProps> = ({
  columns,
  rows,
  rowCount,
  loading,
  children,
  ...rest
}) => {
  const { t } = useTranslation('main')
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const jobId = searchParams.get('EntityID')
  const userId = searchParams.get('UserID')
  const wideScreen = useMediaQuery(theme.breakpoints.up('xl'))

  const {
    searchFilter,
    displayMode,
    count: totalSubmissions,
  } = useDebouncedSubjectSelector(
    submissionStore,
    ['searchFilter', 'displayMode', 'count'],
    300
  )

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      if (newPage !== searchFilter.currentPage) {
        submissionStore.dispatch({
          searchFilter: {
            ...searchFilter,
            currentPage: newPage,
          },
        })
      }
    },
    [searchFilter]
  )

  useEffect(() => {
    return () => {
      submissionStore.dispatch({
        searchFilter: DefaultSubmissionSearchFilter,
      })
    }
  }, [])

  useEffect(() => {
    if (jobId && userId) {
      submissionService.setIFrameIds({
        atsCandidateId: jobId,
        atsUserId: userId,
      })
    }
  }, [jobId, userId])

  return (
    <>
      {children}
      {displayMode === DisplayModes.List && (
        <AppDataGrid
          rows={rows}
          rowCount={rowCount}
          columns={columns as any}
          loading={loading}
          getRowId={(row) => row.jobSubmissionId || row.atsJobSubmissionId}
          slotLabel={t('submissionList.notFound')}
          boxProps={{
            sx: {
              backgroundColor: theme.palette.background.default,
              zIndex: 0,
              p: '0px',
            },
          }}
          sx={{
            mt: '2rem',
            '.MuiDataGrid-row': {
              padding: '26px 0',
            },
          }}
          {...rest}
        />
      )}
      {displayMode === DisplayModes.Card && (
        <Grid
          container
          spacing={3.5}
          sx={{
            mt: '2rem',
            position: 'relative',
            backgroundColor: theme.palette.background.default,
          }}
        >
          {loading && (
            <Box
              sx={{ width: '100%', mt: 4, position: 'absolute', top: '-8px' }}
            >
              <LinearProgress />
            </Box>
          )}
          {!loading && !rows.length && (
            <Box
              sx={{
                width: '100%',
                mt: 8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography>{t('submissionList.notFound')}</Typography>
            </Box>
          )}
          {rows.map((r) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              xl={3}
              mt={1}
              sx={{
                maxWidth: wideScreen ? '450px !important' : 'auto',
              }}
              key={`${r.jobSubmissionId}-${r.atsJobSubmissionId}`}
            >
              <CandidateCard candidate={r} />
            </Grid>
          ))}
          <Grid item container alignItems="center" justifyContent="end">
            <TablePagination
              component="div"
              count={totalSubmissions}
              page={searchFilter.currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={searchFilter.pageSize}
              rowsPerPageOptions={[]}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}
