import React, { FC } from 'react'

import { LinearProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import { GorillaBox, GorillaStack } from '@procom-labs/atoms'

export type ResumeCopilotProLoaderProps = {
  text?: string
}

export const ResumeCopilotProLoader: FC<ResumeCopilotProLoaderProps> = ({
  text,
}) => {
  return (
    <GorillaBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: 'background.paper',
      }}
    >
      <GorillaStack
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LinearProgress
          color="primary"
          sx={{
            width: '300px',
          }}
        />
        {text && <Typography variant="caption">{text}</Typography>}
      </GorillaStack>
    </GorillaBox>
  )
}
