import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

import { IconButton as MuiIconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

const CloseIconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== 'isAbsolute',
})<{ isAbsolute: boolean }>(({ theme, isAbsolute }) => ({
  ...(isAbsolute
    ? {
        position: 'absolute',
        right: 8,
        top: 8,
      }
    : {
        marginInlineEnd: '-8px',
      }),
  color: theme.palette.action.active,
}))

export const ButtonClose: React.FC<{
  handleClose: () => void
  isAbsolute?: boolean
}> = ({ handleClose, isAbsolute = true }) => {
  const { t } = useTranslation('main')

  return (
    <CloseIconButton
      aria-label={t('common.aria.close')}
      onClick={handleClose}
      isAbsolute={isAbsolute}
    >
      <CloseIcon sx={{ fontSize: 24 }} />
    </CloseIconButton>
  )
}
