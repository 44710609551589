import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Button } from '@mui/material'
import { useSubjectSelector } from '@procom-labs/common'

import { usePrepForSubmission } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-core-hooks'
import { submissionStore } from '@submission-portal/stores'

interface CandidatePrepActionCellProps {
  id: number
}

export const CandidatePrepActionCell: React.FC<
  CandidatePrepActionCellProps
> = ({ id }) => {
  const { t } = useTranslation('main')
  const { submissions } = useSubjectSelector(submissionStore, ['submissions'])

  const isDisabled = !!useMemo(
    () => submissions.find(({ atsUserId }) => atsUserId === id),
    [submissions, id]
  )
  const [processed, setProcessed] = useState(false)

  const [searchParams] = useSearchParams()
  const jobId = searchParams.get('EntityID')

  const prepForSubmission = usePrepForSubmission(Number(jobId))

  const onActionClick = useCallback(() => {
    if (!processed) {
      setProcessed(true)
      prepForSubmission(id)
    }
  }, [id, prepForSubmission, processed])

  return (
    <Button
      disabled={isDisabled || processed}
      fullWidth
      type="button"
      variant="contained"
      onClick={onActionClick}
    >
      {t('candidatePreb.addCandidate')}
    </Button>
  )
}
