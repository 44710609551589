import { FC, forwardRef, memo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material'

import { CurrencyInput } from '../currency-input'

export enum CompensationFieldType {
  Salary = 'salary',
  BillRate = 'billRate',
}
interface SalaryFieldProps extends OutlinedInputProps {
  type: CompensationFieldType.Salary
  renderSelect?: never
}
interface BillRateFieldProps extends OutlinedInputProps {
  type: CompensationFieldType.BillRate
  renderSelect: ReactNode
}
export const CompensationField: FC<SalaryFieldProps | BillRateFieldProps> =
  forwardRef(({ renderSelect, type, name, ...rest }, ref) => {
    const { t } = useTranslation('main')

    return (
      <OutlinedInput
        id={name}
        inputComponent={CurrencyInput}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        endAdornment={
          type === CompensationFieldType.BillRate && renderSelect ? (
            <InputAdornment position="end">{renderSelect}</InputAdornment>
          ) : null
        }
        {...rest}
        ref={ref}
        inputProps={{
          ...rest.inputProps,
          maskOptions: {
            suffix:
              type === CompensationFieldType.BillRate
                ? ''
                : `/${t('common.form.rate.perYear')}`,
          },
        }}
      />
    )
  })
