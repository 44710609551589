import React, { FC, memo, useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TFuncKey, useTranslation } from 'react-i18next'
import { useObservable } from 'rxjs-hooks'

import {
  AttachMoneyRounded,
  CalendarTodayOutlined,
  DocumentScannerOutlined,
  HomeWorkOutlined,
  PersonOutlineRounded,
  PlaceOutlined,
} from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import { LoadingButton } from '@mui/lab'
import {
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { GorillaStack } from '@procom-labs/atoms'
import {
  AvailabilityDefaults,
  candidateOnePagerStore,
  CurrencyTypes,
  IOnePagerForm,
  JobAiService,
  UserWorkplacePreference,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  AvailabilityField,
  CompensationField,
  CompensationFieldType,
  DatePickerHookField,
  FormToggleField,
  LocationAutocompleteHook,
  RatePeriodSelect,
} from '@procom-labs/molecules'

import { FormHookEditor } from '../../form-editor/form-hook-editor'
import { SectionTitle } from '../common'

export const CandidateEdit: FC<{
  jobAiService: JobAiService
  googleMapsApiKey: string
  tinyMceApiKey: string
}> = memo(({ jobAiService, googleMapsApiKey, tinyMceApiKey }) => {
  const { onePager } = useSubjectSelector(candidateOnePagerStore, ['onePager'])

  const theme = useTheme()
  const { t } = useTranslation()
  const { control, setValue, watch } = useFormContext<IOnePagerForm>()
  const subscriptionRef = useSubscriptionRef()

  const isLoading = useObservable(() => jobAiService.isJobLoading$, false)

  const candidateHighlights = useObservable(
    () => jobAiService.candidateHighlightDeltas$,
    ''
  )

  const summary = watch('candidate.summary')
  const availability = watch('candidate.availability')
  const workplaceType = watch('candidate.workplaceType')

  const handleGenerateHighlights = useCallback(() => {
    if (onePager?.fileStorageId) {
      const payload = {
        resumeFileStorageId: onePager.fileStorageId,
        language: 'en', // hardcoded for now
      }
      subscriptionRef.current = jobAiService
        .generateCandidateHighlights(payload)
        .subscribe({
          next: (highlights) => {
            setValue('candidate.summary', highlights)
          },
          complete: () => {
            // clear the candidateHighlights stream after setting it to the editor
            setTimeout(() => {
              jobAiService.setCandidateHighlightStream('')
            }, 100)
          },
        })
    }
  }, [onePager?.fileStorageId, subscriptionRef, setValue, jobAiService])

  useEffect(() => {
    if ((candidateHighlights || summary) && candidateHighlights !== summary) {
      setValue('candidate.summary', candidateHighlights || summary)
    }
  }, [summary, candidateHighlights, setValue])

  return (
    <GorillaStack gap={3}>
      <SectionTitle
        icon={
          <PersonOutlineRounded
            sx={{ color: theme.palette.secondary.main, fontSize: '18px' }}
          />
        }
        title={t('organisms.candidateOnePager.candidate.title')}
      />
      <GorillaStack gap={1}>
        <FormToggleField
          label={`${t('common.candidates.details.location')}:`}
          switchName="visibility.Location"
          name="candidate.location"
          icon={PlaceOutlined}
          iconSx={{ color: theme.palette.secondary.main }}
          render={({ ref, ...fieldProps }) => (
            <LocationAutocompleteHook
              fullWidth
              size="small"
              apiKey={googleMapsApiKey}
              sx={{
                backgroundColor: 'background.default',
                '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                  py: '4px',
                },
              }}
              {...fieldProps}
            />
          )}
        />
        <FormToggleField
          label={`${t('common.candidates.details.rate')}:`}
          switchName="visibility.Rate"
          name="candidate.rate"
          icon={AttachMoneyRounded}
          iconSx={{ color: theme.palette.secondary.main }}
          render={({ ...fieldProps }) => (
            <Grid container gap={1}>
              <Grid item xs>
                <CompensationField
                  {...fieldProps}
                  type={CompensationFieldType.BillRate}
                  sx={{
                    backgroundColor: 'background.default',
                    width: '100%',
                  }}
                  size="small"
                  inputProps={{ sx: { py: '6.5px' } }}
                  renderSelect={
                    <Controller
                      name="candidate.rateType"
                      render={({ field }) => <RatePeriodSelect field={field} />}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="candidate.currency"
                  render={({ field }) => (
                    <Select
                      size="small"
                      fullWidth
                      {...field}
                      inputProps={{ sx: { py: '6.5px' } }}
                      sx={{ backgroundColor: 'background.default' }}
                    >
                      {Object.values(CurrencyTypes).map((v) => (
                        <MenuItem key={v} value={v}>
                          {v}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          )}
        />
        <FormToggleField
          label={`${t(
            'organisms.candidateOnePager.candidate.fields.workplace'
          )}:`}
          switchName="visibility.WorkplaceType"
          name="candidate.workplaceType"
          icon={HomeWorkOutlined}
          iconSx={{ color: theme.palette.secondary.main }}
          render={({ ...fieldProps }) => (
            <Select
              {...fieldProps}
              size="small"
              fullWidth
              multiple
              inputProps={{ sx: { py: '6.5px' } }}
              sx={{ backgroundColor: 'background.default' }}
              renderValue={(selectedValues: string[]) =>
                selectedValues.length ? (
                  <Stack spacing={1} direction="row">
                    {selectedValues.map((sel) => (
                      <Chip
                        key={sel}
                        label={t(`common.enums.jobSite.${sel}` as TFuncKey)}
                        variant="outlined"
                        size="small"
                        clickable
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) => {
                          e.preventDefault()
                          fieldProps.onChange(
                            selectedValues.filter((val) => val !== sel)
                          )
                        }}
                      />
                    ))}
                  </Stack>
                ) : (
                  ''
                )
              }
            >
              {Object.values(UserWorkplacePreference).map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox
                    size="small"
                    sx={{ py: 0 }}
                    checked={workplaceType.includes(name) ?? false}
                  />
                  <ListItemText
                    primary={t(`common.enums.jobSite.${name}` as TFuncKey)}
                  />
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormToggleField
          label={`${t(
            'organisms.candidateOnePager.candidate.fields.contract'
          )}:`}
          switchName="visibility.Contract"
          name="candidate.contract"
          icon={DocumentScannerOutlined}
          iconSx={{ color: theme.palette.secondary.main }}
          render={({ ...fieldProps }) => (
            <Select
              {...fieldProps}
              size="small"
              sx={{ width: '100%', backgroundColor: 'background.default' }}
              inputProps={{ sx: { py: '6.5px' } }}
            >
              <MenuItem key="Contract" value="contract">
                {t('organisms.candidateOnePager.candidate.fields.contract')}
              </MenuItem>
              <MenuItem key="Permanent" value="permanent">
                {t('organisms.candidateOnePager.candidate.fields.permanent')}
              </MenuItem>
            </Select>
          )}
        />
        <FormToggleField
          label={`${t('common.form.availability.label')}:`}
          switchName="visibility.Availability"
          name="candidate.availability"
          icon={CalendarTodayOutlined}
          iconSx={{ color: theme.palette.secondary.main }}
          render={({ ...fieldProps }) => (
            <Grid container gap={1}>
              <Grid item xs>
                <AvailabilityField
                  {...fieldProps}
                  size="small"
                  sx={{
                    backgroundColor: 'background.default',
                    width: '100%',
                  }}
                  inputProps={{
                    sx: { py: '6.5px' },
                  }}
                />
              </Grid>
              {availability === AvailabilityDefaults.DATE && (
                <Grid item xs={12} md={6}>
                  <DatePickerHookField
                    control={control}
                    name="candidate.dateAvailable"
                    disablePast
                    textFieldProps={{
                      size: 'small',
                      inputProps: {
                        sx: { py: '6.5px' },
                      },
                    }}
                  />
                </Grid>
              )}
              {availability === AvailabilityDefaults.OTHER && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: 'flex', alignItems: 'end' }}
                >
                  <Controller
                    name="candidate.customAvailability"
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id={field.name}
                        placeholder={t(
                          'common.form.availability.otherPlaceholder'
                        )}
                        sx={{
                          backgroundColor: 'background.default',
                          width: '100%',
                        }}
                        inputProps={{ sx: { py: '6.5px' } }}
                        size="small"
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          )}
        />
      </GorillaStack>
      <GorillaStack gap={6} direction="row">
        <Typography variant="h6" sx={{ opacity: 0.6 }}>
          {t('organisms.summaryGenerator.placeholder1')}
        </Typography>
        <LoadingButton
          id="cta-generate-candidate-highlights"
          color="secondary"
          variant="contained"
          type="button"
          size="small"
          loading={isLoading}
          sx={{ px: 3, textTransform: 'capitalize' }}
          startIcon={
            <img
              src="/assets/images/ai-generator-white.svg"
              alt="Resume AI Generator"
            />
          }
          onClick={handleGenerateHighlights}
        >
          {t('organisms.summaryGenerator.btn.generate')}
        </LoadingButton>
      </GorillaStack>

      <FormHookEditor
        control={control}
        name="candidate.summary"
        environment={{
          TINY_MCE_API_KEY: tinyMceApiKey,
        }}
      />
    </GorillaStack>
  )
})
