import { SessionStorage } from '@procom-labs/common'

const storageKey = 'JOB_VENDOR_CREDS'

interface IVendorJob {
  vendorCode: string
  entityId: string
  entityType: string
}

export const vendorStorage = new SessionStorage<IVendorJob>(storageKey)
