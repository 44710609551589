import { FC } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { Grid, List, ListItem, ListItemText } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useSubjectSelector } from '@procom-labs/common'
import OnboardingSuccess from '@procom-labs/common/src/assets/svg/onboarding-success.svg'

import { submissionStore } from '@submission-portal/stores'

export const MultipleCandidateSuccess: FC<{
  totalCandidates: number
}> = ({ totalCandidates }) => {
  const theme = useTheme()
  const { t } = useTranslation('main')
  const { emailObject } = useSubjectSelector(submissionStore, ['emailObject'])

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        py: 6,
      }}
      columnGap={6}
    >
      <Grid item ml={8}>
        <img src={OnboardingSuccess} alt="Girl showing thumbs up" />
      </Grid>

      <Grid item>
        <Typography variant="h5" mb={1} component="h5">
          {t('submissionDetail.submissionSuccess.heading')}
        </Typography>
        <Typography mb={1}>
          {totalCandidates <= 1
            ? t('submissionDetail.submissionSuccess.body2', {
                name: `${totalCandidates}`,
                hm: `${emailObject?.emailTo}`,
              })
            : t('submissionDetail.submissionSuccess.body3', {
                name: `${totalCandidates}`,
                hm: `${emailObject?.emailTo}`,
              })}
        </Typography>
        <Typography mb={1}>
          {t('submissionDetail.submissionSuccess.submittedOnMultiCandidate')}
          {`${DateTime.now().toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS
          )}`}
        </Typography>

        {emailObject?.emailToCc && emailObject?.emailToCc?.length > 0 && (
          <>
            <Typography mb={1} mt={3}>
              {t('submissionDetail.submissionSuccess.collaborators')}
            </Typography>
            <List sx={{ listStyle: 'inside' }}>
              {emailObject?.emailToCc?.map((collaborator, index) => (
                <ListItem key={index} sx={{ py: 0, display: 'list-item' }}>
                  <ListItemText sx={{ display: 'inline-block' }}>
                    {collaborator}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Grid>
    </Grid>
  )
}
