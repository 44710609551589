import React, { FC, useState } from 'react'
import { Field, FieldProps } from 'formik'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { BaseTextFieldProps, InputProps } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export const AvailabilityDateField: FC<{
  name: string
  size?: BaseTextFieldProps['size']
  inputSx?: InputProps['sx']
}> = React.memo(({ name, size = 'medium', inputSx }) => {
  const { i18n } = useTranslation('main')
  const [calendarIsOpen, setCalendarIsOpen] = useState(false)

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <LocalizationProvider
          dateAdapter={AdapterLuxon}
          adapterLocale={i18n.language}
        >
          <DatePicker
            disablePast
            value={
              field.value
                ? DateTime.fromISO(field.value ?? '', {
                    setZone: true,
                  })
                : null
            }
            open={calendarIsOpen}
            onClose={() => {
              setCalendarIsOpen(false)
              form.setFieldTouched(name, true)
            }}
            onChange={(newValue: DateTime | null) => {
              form.setFieldValue(
                name,
                newValue
                  ? newValue.setZone('UTC', { keepLocalTime: true }).toISO()
                  : ''
              )
              form.setFieldTouched(name, true)
            }}
            slots={{
              openPickerIcon: CalendarTodayIcon,
            }}
            slotProps={{
              textField: {
                size,
                inputProps: {
                  readOnly: true,
                  sx: inputSx,
                  style: {
                    cursor: 'pointer',
                  },
                },
                error: !!(meta.touched && meta.error && !field.value),
                onClick: () => setCalendarIsOpen(true),
              },
            }}
          />
        </LocalizationProvider>
      )}
    </Field>
  )
})
