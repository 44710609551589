import { FC } from 'react'

import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GorillaGrid, GorillaStack } from '@procom-labs/atoms'
import { JobAiService } from '@procom-labs/common'

import {
  AdditionalInfoEdit,
  AdditionalInfoType,
  CandidateEdit,
  EducationEdit,
  ExperienceEdit,
  IndustryEdit,
  JobsEdit,
  RolesEdit,
  SkillsEdit,
} from './form'

export const OnePagerEdit: FC<{
  jobAiService: JobAiService
  googleMapsApiKey: string
  tinyMceApiKey: string
  hideCandidateDescription?: boolean
  isClientPortal?: boolean
}> = ({
  jobAiService,
  googleMapsApiKey,
  tinyMceApiKey,
  hideCandidateDescription,
  isClientPortal,
}) => {
  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))

  return isMobileOrTab ? (
    <GorillaGrid container gap={3} sx={{ pt: 4 }}>
      {!hideCandidateDescription && (
        <GorillaGrid item xs={12}>
          <CandidateEdit
            jobAiService={jobAiService}
            googleMapsApiKey={googleMapsApiKey}
            tinyMceApiKey={tinyMceApiKey}
          />
        </GorillaGrid>
      )}
      <GorillaGrid item xs={12}>
        <SkillsEdit />
      </GorillaGrid>
      <GorillaGrid item xs={12}>
        <ExperienceEdit />
      </GorillaGrid>
      <GorillaGrid item xs={12}>
        <JobsEdit />
      </GorillaGrid>
      <GorillaGrid item xs={12}>
        <IndustryEdit />
      </GorillaGrid>
      <GorillaGrid item xs={12}>
        <RolesEdit />
      </GorillaGrid>
      <GorillaGrid item xs={12}>
        <EducationEdit />
      </GorillaGrid>
      {isMobile ? (
        <>
          <GorillaGrid item xs={12}>
            <AdditionalInfoEdit name={AdditionalInfoType.Certifications} />
          </GorillaGrid>
          <GorillaGrid item xs={12}>
            <AdditionalInfoEdit
              name={AdditionalInfoType.AdditionalHighlights}
            />
          </GorillaGrid>
        </>
      ) : (
        <GorillaGrid item xs={12}>
          <GorillaStack gap={3} direction="row">
            <Box flex={1}>
              <AdditionalInfoEdit name={AdditionalInfoType.Certifications} />
            </Box>
            <Box flex={1}>
              <AdditionalInfoEdit
                name={AdditionalInfoType.AdditionalHighlights}
              />
            </Box>
          </GorillaStack>
        </GorillaGrid>
      )}
    </GorillaGrid>
  ) : (
    <GorillaGrid container flexWrap="nowrap" gap={4} sx={{ pt: 4 }}>
      <GorillaGrid
        item
        xs={isClientPortal ? 12 : 8}
        sx={{ display: 'flex', flexDirection: 'column' }}
        gap={6}
      >
        {!hideCandidateDescription && (
          <CandidateEdit
            jobAiService={jobAiService}
            googleMapsApiKey={googleMapsApiKey}
            tinyMceApiKey={tinyMceApiKey}
          />
        )}

        {isClientPortal && <SkillsEdit />}

        <ExperienceEdit />

        {isClientPortal && (
          <>
            <GorillaGrid item xs={12}>
              <JobsEdit />
            </GorillaGrid>
            <GorillaGrid item xs={12}>
              <IndustryEdit />
            </GorillaGrid>
            <GorillaGrid item xs={12}>
              <RolesEdit />
            </GorillaGrid>
          </>
        )}
        <EducationEdit />

        <GorillaStack gap={3} direction="row">
          <Box flex={1}>
            <AdditionalInfoEdit name={AdditionalInfoType.Certifications} />
          </Box>
          <Box flex={1}>
            <AdditionalInfoEdit
              name={AdditionalInfoType.AdditionalHighlights}
            />
          </Box>
        </GorillaStack>
      </GorillaGrid>
      {!isClientPortal && (
        <GorillaGrid
          item
          sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          gap={3}
        >
          <SkillsEdit />
          <JobsEdit />
          <IndustryEdit />
          <RolesEdit />
        </GorillaGrid>
      )}
    </GorillaGrid>
  )
}
