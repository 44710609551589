import React, { FC, useCallback, useState } from 'react'

import { Box, Container } from '@mui/material'
import {
  CandidateOnePagerStateEnum,
  candidateOnePagerStore,
  useSubjectSelector,
} from '@procom-labs/common'

import { ResumeCopilotProFileUpload } from '@submission-portal/components/resume-copilot-pro/components/resume-copilot-pro-file-upload'

import { CandidateOnePagerGrid } from './candidate-one-pager-grid'

export const CandidateOnePagerView: FC = () => {
  const [file, setFile] = useState<File | null>(null)
  const { state } = useSubjectSelector(candidateOnePagerStore, ['state'])

  const handleFileChange = useCallback((uploadedFile: File): void => {
    setFile(uploadedFile)
    candidateOnePagerStore.dispatch({
      state: CandidateOnePagerStateEnum.FILE_LOADED,
    })
  }, [])

  if (state === CandidateOnePagerStateEnum.NO_FILE) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <ResumeCopilotProFileUpload onChange={handleFileChange} />
      </Container>
    )
  }
  return (
    <Box
      sx={{
        height: '100vh',
        overflowY: 'scroll',
      }}
    >
      <CandidateOnePagerGrid file={file} />
    </Box>
  )
}
