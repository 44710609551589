/* eslint-disable no-unused-vars */
export enum DateAndTime {
  Seconds = 'seconds',
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Years = 'years',
}

export enum DateValueFormat {
  InOneWeek = 'In 1 week',
  InTwoWeek = 'In 2 weeks',
  InThreeWeek = 'In 3 weeks',
  InThePast = 'inThePast',
  InTheFuture = 'inTheFuture',
  DayMonthYear = 'dayMonthYear',
  DayMonthYearTimezone = 'dayMonthYearTimezone',
  DayMonthWithAMPM = 'dayMonthWithAMPM',
  DayMonthYearMedium = 'dayMonthYearMed',
  DayMonthYearShort = 'dayMonthYearShort',
  DateTimeFullTimeSimple = 'dateTimeFullTimeSimple',
  IsoDate = 'isoDate',
  Time12Hour = 'time12Hour',
}

export enum TimeZone {
  EST = 'EST',
  UTC = 'UTC',
}

export const DATE_TIME_WITH_ZONE = 'LLL dd, yyyy h:mm a z'

export const INTERVIEW_DATE_FORMAT = 'M/d/yyyy'

export const CANDIDATE_DATE_FORMATS = ['M/d/yyyy h:mm:ss a', 'yyyy-MM-dd']
