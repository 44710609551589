import { styled } from '@mui/material/styles'
import { DataGrid, DataGridProps } from '@mui/x-data-grid'
import { PaletteModeCode } from '@procom-labs/common'

import { GorillaDataGridRowContainer } from './data-grid-row'
import { WithGorillaInnerTheme } from './with-inner-theme'

const dataGridRowProps = {
  slots: {
    row: (params: any) => <GorillaDataGridRowContainer {...params} />,
  },
}

type ComponentProps = DataGridProps & {
  shrinkRows?: boolean
}

const StyledDataGrid = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== 'shrinkRows',
})<ComponentProps>(({ theme, onRowClick, shrinkRows }) => ({
  border: 'none',
  '.MuiDataGrid-main': {
    color: theme.palette.text.primary,
  },
  '.MuiDataGrid-withBorderColor': {
    border: 'none',
  },
  '.MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    '.MuiDataGrid-checkboxInput': {
      color: theme.palette.primary.dark,
    },
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Manrope, sans-serif',
  },
  '.MuiDataGrid-iconSeparator': {
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.between('xl', 'k2')]: {
    '.MuiDataGrid-columnHeaders': {
      paddingLeft: 12,
    },
  },
  [theme.breakpoints.up('xl')]: {
    '.MuiDataGrid-main': {
      overflow: 'hidden',
    },
    '.MuiDataGrid-columnHeader:first-of-type:not(.MuiDataGrid-columnHeaderCheckbox) .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer':
      {
        marginLeft: '40px',
      },
    '.MuiDataGrid-virtualScroller': {
      '.MuiDataGrid-cell:not(.MuiDataGrid-cell--withRenderer):empty': {
        display: 'none',
      },
    },
  },
  '.MuiTablePagination-root': {
    color: theme.palette.text.primary,
  },
  '.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
    visibility: 'visible',
  },
  '.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '.MuiDataGrid-row': {
    cursor: onRowClick ? 'pointer' : '',
    backgroundColor:
      theme.palette.mode === PaletteModeCode.dark
        ? theme.palette.common.glassOnGlass
        : theme.palette.background.default,
    borderRadius: '2rem',
    color: theme.palette.text.primary,
    padding: shrinkRows ? '4px 0' : '36px 0',
    [theme.breakpoints.up('xl')]: {
      '& > .MuiDataGrid-cell:first-of-type:not(.MuiDataGrid-cellCheckbox)': {
        paddingLeft: '50px',
      },
      '& > .MuiDataGrid-cellCheckbox': {
        paddingLeft: '32px',
        paddingRight: '8px',
      },
    },
    boxShadow:
      theme.palette.mode === PaletteModeCode.light
        ? `inset 0 0 0 1px ${theme.palette.text.primary}`
        : 'none',
    '&:hover': {
      backgroundColor:
        theme.palette.mode === PaletteModeCode.light
          ? theme.palette.action.hover
          : theme.palette.common.glassOnGlass,
      boxShadow: `inset 0 0 0 1px ${theme.palette.text.primary}`,
    },
    '.MuiDataGrid-checkboxInput': {
      color: theme.palette.primary.dark,
    },
    '&.Mui-selected': {
      backgroundColor:
        theme.palette.mode === PaletteModeCode.dark
          ? theme.palette.common.glassOnGlass
          : theme.palette.background.default,
    },
  },
  '.MuiDialog-root &': {
    '.MuiDataGrid-row': {
      padding: '24px 0',
    },
  },
}))

export const GorillaDataGrid = WithGorillaInnerTheme<ComponentProps>(
  StyledDataGrid,
  undefined,
  dataGridRowProps
)
