import { JobService } from '@procom-labs/common'
import { environment } from '@submission-portal/environment'
import { axios } from '@submission-portal/lib'
import { clientJobStore } from '@submission-portal/stores'

export const clientJobService = new JobService({
  baseEndpoint: environment.JOB_API_URL,
  clientJobStore,
  axios,
})
