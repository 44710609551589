import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { GorillaStack } from '@procom-labs/atoms'

export const SkillsWrapper: FC<{
  title: string
  maxYears?: number
  minYears?: number
}> = memo(({ title, maxYears, minYears, children }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))
  return (
    <GorillaStack
      p={isMobileOrTab ? 2 : 3}
      gap={1}
      sx={{ backgroundColor: 'onePager.boxBg', borderRadius: 2 }}
    >
      <GorillaStack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {title}
        </Typography>
        {maxYears ? (
          <Typography>
            {minYears ?? 0} - {maxYears}+{' '}
            {t('organisms.candidateOnePager.years')}
          </Typography>
        ) : null}
      </GorillaStack>
      <GorillaStack>{children}</GorillaStack>
    </GorillaStack>
  )
})
