import React, { FC, useMemo } from 'react'

import { Avatar, Box, Typography } from '@mui/material'
import { getInitials } from '@procom-labs/common'
import { AvatarStore, useAvatarColor } from '@procom-labs/molecules'

interface CellTextWithAvatarProps {
  text: string
  id: string
  avatarStore: AvatarStore
}

export const CellTextWithAvatar: FC<CellTextWithAvatarProps> = ({
  text,
  id,
  avatarStore,
}) => {
  const avatarBgColor = useAvatarColor(id, avatarStore)
  const avatarInitials = useMemo(() => getInitials(text, 2), [text])

  return (
    <Box display="flex" alignItems="center" gap={2} sx={{ width: '100%' }}>
      <Avatar
        style={{
          backgroundColor: `#${avatarBgColor.color}`,
          width: '40px',
          height: '40px',
        }}
      >
        {avatarInitials}
      </Avatar>

      <Box flexGrow={1} display="flex" flexDirection="column" gap={0}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" display="inline">
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
