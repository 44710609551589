import React, { forwardRef, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { getInitials, IJobSubmission } from '@procom-labs/common'

import { FormattedDateTime } from '@submission-portal/models'

import { SubmissionUpdateForm } from './submission-update-form'

type SubmissionUpdateProps = {
  submission: IJobSubmission
  nextHandler: () => void
  setResetEmail: React.Dispatch<React.SetStateAction<boolean>>
  openPreview: () => void
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
}

export const SubmissionUpdate = forwardRef<any, SubmissionUpdateProps>(
  (
    { submission, nextHandler, setResetEmail, setOpenModal, openPreview },
    ref
  ) => {
    const initials: string = useMemo(() => {
      return getInitials(submission.candidate.name, 2)
    }, [submission.candidate])

    const { t } = useTranslation('main')

    const dateAdded: FormattedDateTime = useMemo(() => {
      const date = DateTime.fromISO(submission.createdDateTime)
      return {
        date: date.toLocaleString(),
        time: date.toLocaleString(DateTime.TIME_SIMPLE),
      }
    }, [submission.createdDateTime])

    const lastModified: FormattedDateTime = useMemo(() => {
      const date = DateTime.fromISO(submission.lastModifiedDateTime)
      return {
        date: date.toLocaleString(),
        time: date.toLocaleString(DateTime.TIME_SIMPLE),
      }
    }, [submission.lastModifiedDateTime])

    return (
      <Grid container rowSpacing={2}>
        <Grid container item gap={2} alignItems="center" sx={{ marginX: 3 }}>
          <Grid item xs="auto">
            <Avatar>{initials}</Avatar>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography noWrap>{submission.candidate.name}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          columnSpacing={2}
          rowSpacing={1}
          sx={{
            backgroundColor: 'background.default',
            marginX: 3,
            marginY: 2,
            paddingY: 4,
          }}
        >
          <Grid container item md={3} columnSpacing={1}>
            <Grid item xs={4} md="auto">
              <Typography noWrap sx={{ color: 'text.secondary' }}>
                {t('submissionList.addedBy')}
              </Typography>
            </Grid>
            <Grid item xs={8} md="auto">
              <Typography noWrap>{submission.recruiterName}</Typography>
            </Grid>
          </Grid>
          <Grid container item md={3}>
            <Grid item xs={4} md={5} xl={3}>
              <Typography sx={{ color: 'text.secondary' }}>
                {t('submissionList.dateAdded')}
              </Typography>
            </Grid>
            <Grid item xs={8} md={5} xl={3}>
              <Typography noWrap>{dateAdded.date}</Typography>
              <Typography noWrap sx={{ color: 'text.secondary' }}>
                {dateAdded.time}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item md={3}>
            <Grid item xs={4} md={6} xl={4}>
              <Typography sx={{ color: 'text.secondary' }}>
                {t('submissionList.lastModified')}
              </Typography>
            </Grid>
            <Grid item xs={8} md={5} xl={3}>
              <Typography noWrap>{lastModified.date}</Typography>
              <Typography noWrap sx={{ color: 'text.secondary' }}>
                {lastModified.time}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item md={3} spacing={1}>
            <Grid item xs={4} md={3}>
              <Typography noWrap sx={{ color: 'text.secondary' }}>
                {t('submissionList.status')}
              </Typography>
            </Grid>
            <Grid item xs={8} md={8}>
              <Typography noWrap>{submission.status}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={8}
          lg={6}
          direction="column"
          sx={{ marginX: 3 }}
        >
          <SubmissionUpdateForm
            submission={submission}
            nextHandler={nextHandler}
            setResetEmail={setResetEmail}
            setOpenModal={setOpenModal}
            openPreview={openPreview}
            ref={ref}
          />
        </Grid>
      </Grid>
    )
  }
)
