import React, { FC, ReactNode } from 'react'
import { Button, ButtonProps, MenuProps } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { ActionMenuButton } from './action-menu-button'

interface DropDownActionMenuProps extends Omit<MenuProps, 'open' | 'anchorEl'> {
  id: string
  buttonProps?: ButtonProps
  children: (close: () => void) => ReactNode
}

export const DropDownActionMenuButton: FC<DropDownActionMenuProps> = ({
  children,
  buttonProps,
  ...props
}) => (
  <ActionMenuButton
    {...props}
    label={<Button endIcon={<ArrowDropDownIcon />} {...buttonProps} />}
  >
    {children}
  </ActionMenuButton>
)
