import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, SelectProps, Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import {
  DataObject,
  DataOption,
  ICandidate,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  SelectWithOptions,
  useAlert,
  useDefaultErrorHandler,
} from '@procom-labs/molecules'

import { PdfViewerDialog } from '@submission-portal/components'
import { useGetUserId, useViewOrDownloadFile } from '@submission-portal/hooks'
import { credStorage } from '@submission-portal/lib'
import { submissionService } from '@submission-portal/services'
import { serviceDescriptorStore } from '@submission-portal/stores'
import { FileActions } from '@submission-portal/types'

interface HighlightsFileSelectProps
  extends Omit<SelectProps, 'onChange' | 'MenuProps'> {
  options: DataOption<DataObject>[]
  candidate: ICandidate
  submissionId: string
  onChange: (isValidResponse: boolean) => void
}
export const HighlightsFileSelect: FC<HighlightsFileSelectProps> = ({
  options,
  candidate,
  submissionId,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation('main')
  const [resumeSelection, setResumeSelection] = useState<string>('')
  const [blobUrl, setBlobUrl] = useState('')
  const [showingFile, setShowingFile] = useState(false)
  const [processing, setProcessing] = useState(false)
  const { addAlert } = useAlert()
  const userId = useGetUserId()

  const rootOption = useMemo(() => {
    if (candidate.highlightsAdditionalDocuments?.length) {
      // Currently we only use the first file as the root option
      const { name, fileStorageId } = candidate.highlightsAdditionalDocuments[0]
      if (fileStorageId) {
        const title = `${name}`.trim()
        return { title, id: fileStorageId }
      }
    }
    return undefined
  }, [candidate.highlightsAdditionalDocuments])

  const handleResumes = useViewOrDownloadFile(
    userId ?? credStorage.get()?.atsUserId,
    setBlobUrl,
    setShowingFile,
    setProcessing
  )
  const handleError = useDefaultErrorHandler(
    addAlert,
    t('submissionDetail.candidateDetails.form.highlights.error')
  )
  const subscriptionRef = useSubscriptionRef()
  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  const handleCloseViewDialog = useCallback((): void => {
    setShowingFile(false)
  }, [])

  const handleFileAction = useCallback(
    (actionType: string) => {
      if (candidate.highlightsAdditionalDocuments?.length) {
        handleResumes(candidate.highlightsAdditionalDocuments, actionType)
      } else {
        handleError()
      }
    },
    [candidate.highlightsAdditionalDocuments, handleResumes, handleError]
  )

  const handleView = useCallback((): void => {
    handleFileAction(FileActions.View)
  }, [handleFileAction])

  const handleDownload = useCallback((): void => {
    handleFileAction(FileActions.Download)
  }, [handleFileAction])

  const handleSelection = useCallback(
    (newSelection, isRoot) => {
      setResumeSelection(isRoot ? '' : newSelection)
      setBlobUrl('')

      const fileObject = options.find((file) => file.id === newSelection)

      if (fileObject) {
        if (subscriptionRef.current && !subscriptionRef.current.closed) {
          subscriptionRef.current.unsubscribe()
        }
        subscriptionRef.current = candidateAtsService
          .postCandidateFile(
            {
              entityId: Number(candidate.atsUserId),
              fileId: Number(newSelection),
            },
            fileObject.data,
            submissionId,
            true
          ) // pass new flag
          .subscribe({
            next: (fileStorageId: string) => {
              const { fileSize, name } = fileObject.data
              submissionService.updateSubmissionHighlightsFiles({
                fileStorageId,
                size: fileSize.toString(10),
                name: name.replace(/\.(docx?|pdf)$/, ''),
                extension: '.pdf',
              })
              onChange(false)
            },
            error: () => {
              onChange(true)
              handleError()
            },
          })
      }
    },
    [
      submissionId,
      candidateAtsService,
      options,
      candidate.atsUserId,
      subscriptionRef,
      onChange,
      handleError,
    ]
  )
  return (
    <>
      {blobUrl && showingFile && (
        <PdfViewerDialog blobUrl={blobUrl} onClose={handleCloseViewDialog} />
      )}
      <Grid container gap={2}>
        <SelectWithOptions
          sx={{ width: '100%' }}
          placeholder={t(
            'submissionDetail.candidateDetails.form.highlights.noFile'
          )}
          value={resumeSelection}
          rootOption={rootOption}
          onChange={handleSelection}
          disabled={!options.length}
          displayEmpty
          clearable
          options={options}
          boxProps={{ flex: '1', maxWidth: rootOption ? '100%' : '50%' }}
          {...rest}
        />
        {candidate.highlightsAdditionalDocuments?.length ? (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="flex-start"
          >
            <Button size="medium" onClick={handleView} disabled={processing}>
              {t('common.btn.view')}
            </Button>

            <Button
              size="medium"
              onClick={handleDownload}
              disabled={processing}
            >
              {t('common.btn.download')}
            </Button>
          </Stack>
        ) : null}
      </Grid>
    </>
  )
}
