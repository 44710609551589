import Axios from 'axios-observable'
import { from, Observable, switchMap } from 'rxjs'

import { IAvatarColor } from '../interfaces'
import { SuperSubject } from '../models'
import {
  colorPallete,
  getBlobBase64,
  getProperty,
  getRandomColor,
} from '../util'

const storageKey = 'usersAvatarImage'

interface IAvatarCached {
  email: string | null
  url: string | null
}

interface AvatarServiceConfig {
  baseEndpoint: string
  axios: Axios
}

export class AvatarService {
  private readonly baseEndpoint: string

  private readonly axios: Axios

  private isLoadingSubject = new SuperSubject<boolean>(true)

  isLoading$ = this.isLoadingSubject.observable$

  private availableAvatarsColors = [...colorPallete]

  private avatarsColorsSubject = new SuperSubject<IAvatarColor[]>([])

  avatarsColors$ = this.avatarsColorsSubject.observable$

  constructor(config: AvatarServiceConfig) {
    this.baseEndpoint = config.baseEndpoint
    this.axios = config.axios
  }

  resetAvailableAvatarsColors = (): void => {
    this.isLoadingSubject.value = true
    this.availableAvatarsColors = [...colorPallete]
  }

  // any[], because it can be used with items collection
  initAvatarColors = (items: any[], itemIdKey: string): void => {
    this.resetAvailableAvatarsColors()
    const unique = Array.from(
      new Set(items.map((item) => getProperty(item, itemIdKey.split('.'))))
    )
    this.avatarsColorsSubject.value = unique.map((item) => {
      return {
        avatarId: item,
        color: this.pickAvatarColor(),
      }
    })
    this.isLoadingSubject.value = false
  }

  getAvatarColor(itemId?: string | number): string {
    const getAvatarColor = this.avatarsColorsSubject.value.find(
      (avatarColor: IAvatarColor) => {
        return avatarColor.avatarId === itemId
      }
    )

    return getAvatarColor ? getAvatarColor.color : '000000'
  }

  getAvatarsCached(): IAvatarCached[] | null {
    const avatarsCached = sessionStorage.getItem(storageKey)
    if (avatarsCached) return JSON.parse(avatarsCached)

    sessionStorage.setItem(storageKey, JSON.stringify([]))
    const newAvatarsCached = sessionStorage.getItem(storageKey)
    return newAvatarsCached ? JSON.parse(newAvatarsCached) : null
  }

  getUserAvatarImage(email: string | null = null): Observable<string> {
    let urlEndpoint: string
    if (email) {
      urlEndpoint = `${
        this.baseEndpoint
      }/User/avatar/email?${new URLSearchParams({ email })}`
    } else {
      throw new Error('Invalid params')
    }
    // get all avatars from session storage
    const avatarsCached = this.getAvatarsCached()
    // find avatar from array using fileGuid
    const avatarImageCached = this.getCachedAvatarImageByFileGuidOrEmail(
      avatarsCached,
      email
    )
    if (avatarImageCached) return avatarImageCached
    return this.getAvatarImage(urlEndpoint, email)
  }

  private getCachedAvatarImageByFileGuidOrEmail(
    avatarsCached: IAvatarCached[] | null,
    email: string | null
  ): Observable<string> | null {
    const avatarUrl = avatarsCached?.find((avatar) => {
      if (email) {
        return avatar.email === email
      }
      return false
    })
    if (avatarUrl?.url) {
      return from([avatarUrl.url])
    }
    return null
  }

  private getAvatarImage(
    urlEndpoint: string,
    email: string | null
  ): Observable<string> {
    return this.axios
      .get<ArrayBuffer>(urlEndpoint, {
        responseType: 'arraybuffer',
      })
      .pipe(
        switchMap(async ({ data }) => {
          const url = await getBlobBase64(data)
          if (typeof url !== 'string') throw new Error('Avatar not valid')
          const avatarsCached = this.getAvatarsCached()
          avatarsCached?.push({ email, url })
          sessionStorage.setItem(storageKey, JSON.stringify(avatarsCached))
          return url
        })
      )
  }

  private pickAvatarColor = (): string => {
    if (this.availableAvatarsColors.length < 1) {
      this.resetAvailableAvatarsColors()
    }

    const color = getRandomColor(this.availableAvatarsColors)
    this.availableAvatarsColors = this.availableAvatarsColors.filter(
      (availableColor) => availableColor !== color
    )
    return color
  }
}
