import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Replay } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { GorillaAlert, GorillaBox, GorillaStack } from '@procom-labs/atoms'
import {
  CandidateOnePagerStateEnum,
  candidateOnePagerStore,
  useSubjectSelector,
} from '@procom-labs/common'

import { CandidateOnePager } from '@submission-portal/components'
import { ResumeCopilotProLoader } from '@submission-portal/components/resume-copilot-pro/components/resume-copilot-pro-loader'
import { useOnePagerParser } from '@submission-portal/hooks'

export const CandidateOnePagerGrid: FC<{
  file: File | null
}> = ({ file }) => {
  const { t } = useTranslation('main')
  const { state, onePager, progress, error } = useSubjectSelector(
    candidateOnePagerStore,
    ['state', 'onePager', 'progress', 'error']
  )
  const [searchParams] = useSearchParams()
  const entityId = searchParams.get('JobSubmissionId')
  const { parse } = useOnePagerParser()

  const handleAnalyze = useCallback(() => {
    if (!file || !entityId) return

    parse(file, entityId)
  }, [file, parse, entityId])

  useEffect(() => {
    if (state === CandidateOnePagerStateEnum.FILE_LOADED) {
      handleAnalyze()
    }
  }, [state, handleAnalyze])

  if (state === CandidateOnePagerStateEnum.ERROR) {
    return (
      <GorillaStack direction="column" spacing={2} padding={2}>
        <GorillaAlert severity="error">{error}</GorillaAlert>
        <GorillaBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            size="large"
            startIcon={<Replay />}
            onClick={handleAnalyze}
            sx={{
              borderRadius: '24px',
              textTransform: 'none',
            }}
          >
            {t('common.btn.retry')}
          </Button>
        </GorillaBox>
      </GorillaStack>
    )
  }

  if (
    state === CandidateOnePagerStateEnum.FILE_LOADED ||
    state === CandidateOnePagerStateEnum.UPLOADING ||
    state === CandidateOnePagerStateEnum.PARSING
  ) {
    return <ResumeCopilotProLoader text={progress} />
  }

  if (!onePager) return null

  return <CandidateOnePager />
}
