import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Observable, switchMap } from 'rxjs'

import {
  alpha,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import {
  base64ToArrayBuffer,
  CandidateOnePagerStateEnum,
  candidateOnePagerStore,
  DataObject,
  DataOption,
  FeatureFlagNames,
  FileSource,
  GorillaResumeParsingStatus,
  ICandidate,
  IEntityType,
  IResume,
  useIsFeatureFlagEnabled,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  SelectWithOptions,
  useAlert,
  useDefaultErrorHandler,
} from '@procom-labs/molecules'
import { ResumeUploader } from '@procom-labs/organisms'

import { PdfViewerDialog } from '@submission-portal/components'
import { useGetUserId, useViewOrDownloadFile } from '@submission-portal/hooks'
import { credStorage } from '@submission-portal/lib'
import { rollbarInstance } from '@submission-portal/providers'
import {
  candidateOnePagerService,
  submissionService,
} from '@submission-portal/services'
import {
  serviceDescriptorStore,
  submissionStore,
} from '@submission-portal/stores'
import { FileActions, FileModes } from '@submission-portal/types'

export const SubmissionDetailFileSelector: React.FC<{
  candidate: ICandidate
  submissionId: string
  resumeOptions: DataOption<DataObject>[]
  uploadResume: (
    formData: FormData,
    onUploadProgress: (event: ProgressEvent) => void
  ) => Observable<Partial<IResume>>
  deleteResume: (fileStorageId: string) => Observable<any>
  hasResumeError: boolean
  onBlur?: () => void
  onFocus?: () => void
  onResumeChange: (isValidResponse: boolean) => void
  addOnePagertoCartPending?: (taskId: string) => void
  resumeSelection: string
  handleSelectResume: (newSelection: string) => void
}> = ({
  candidate,
  submissionId,
  resumeOptions,
  uploadResume,
  deleteResume,
  hasResumeError,
  onBlur,
  onFocus,
  onResumeChange,
  addOnePagertoCartPending,
  resumeSelection,
  handleSelectResume,
}) => {
  const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
    FeatureFlagNames.ResumeOnePager
  )

  const { t } = useTranslation('main')
  const { addAlert } = useAlert()
  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const [mode, setMode] = useState<FileModes>(FileModes.Select)
  const [blobUrl, setBlobUrl] = useState('')
  const [showingFile, setShowingFile] = useState(false)
  const [processing, setProcessing] = useState(false)

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])
  const { pendingOnePagers } = useSubjectSelector(submissionStore, [
    'pendingOnePagers',
  ])

  const subscriptionRef = useSubscriptionRef()
  const onePagerSubscriptionRef = useSubscriptionRef()
  const updateResumeSubscriptionRef = useSubscriptionRef()
  const userId = useGetUserId()
  const handleResumes = useViewOrDownloadFile(
    userId ?? credStorage.get()?.atsUserId,
    setBlobUrl,
    setShowingFile,
    setProcessing
  )

  const handleResumeUploadChange = useCallback(
    (value) => submissionService.updateSubmissionResume(value),
    []
  )
  const handleModeChange = useCallback(
    (e) => setMode(e.target.value as FileModes),
    []
  )

  const addOnePagerIdToResume = useCallback(
    (id: string) => {
      updateResumeSubscriptionRef.current = submissionService
        .addOnePagerIdToResume(id)
        .subscribe()
    },
    [updateResumeSubscriptionRef]
  )

  const getOnePagerByParsedResumeId = useCallback(
    (parsedResumeId: string, fileStorageId: string) => {
      onePagerSubscriptionRef.current = candidateOnePagerService
        .getOnePagerByParsedResumeId(
          parsedResumeId,
          fileStorageId,
          IEntityType.JobSubmission,
          submissionId
        )
        .subscribe({
          next: (onePager) => {
            addOnePagerIdToResume(onePager.id)
            if (onePager.status === GorillaResumeParsingStatus.Complete) {
              candidateOnePagerStore.dispatch({
                onePager,
                state: CandidateOnePagerStateEnum.DONE,
              })
              // Logic for cart submissions
              if (addOnePagertoCartPending) {
                // New resume was selected so we need to remove any old pending ones with the same jobSubmissionId
                const copy = [...pendingOnePagers]
                const index = copy.findIndex(
                  (pendingOnePager) =>
                    pendingOnePager.jobSubmissionId === onePager.entityId
                )
                if (index > -1) {
                  copy.splice(index, 1)
                  submissionStore.dispatch({
                    pendingOnePagers: copy,
                  })
                }
              }
            } else {
              candidateOnePagerStore.dispatch({
                taskId: onePager.parsedResumeId,
                state: CandidateOnePagerStateEnum.PARSING,
              })
              addOnePagertoCartPending?.(onePager.parsedResumeId)
            }
          },
        })
    },
    [
      onePagerSubscriptionRef,
      submissionId,
      addOnePagerIdToResume,
      addOnePagertoCartPending,
      pendingOnePagers,
    ]
  )

  const startOnePagerParse = useCallback(
    (selection: string, fileStorageId: string) => {
      onePagerSubscriptionRef.current = candidateAtsService
        .getCandidateFile({
          entityId: Number(candidate.atsUserId),
          fileId: Number(selection),
        })
        .pipe(
          switchMap((content) => {
            const arrayBuffer = base64ToArrayBuffer(content.content)
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' })
            const file = new File([blob], content.name, {
              type: 'application/pdf',
            })
            const formData = new FormData()
            formData.append('file', file)
            return candidateOnePagerService.startResumeParse(
              formData,
              submissionId,
              fileStorageId,
              {
                fileSourceType: FileSource.Bullhorn,
                fileSourceId: content.id.toString(),
              }
            )
          })
        )
        .subscribe({
          next: ({ onePagerId, taskId }) => {
            addOnePagertoCartPending?.(taskId)
            addOnePagerIdToResume(onePagerId)
          },
        })
    },
    [
      candidate,
      candidateAtsService,
      submissionId,
      onePagerSubscriptionRef,
      addOnePagerIdToResume,
      addOnePagertoCartPending,
    ]
  )

  const handleSelection = useCallback(
    (newSelection, isRoot) => {
      handleSelectResume(isRoot ? '' : newSelection)
      setBlobUrl('')

      const fileObject = resumeOptions.find(
        (resume) => resume.id === newSelection
      )

      if (fileObject) {
        if (subscriptionRef.current && !subscriptionRef.current.closed) {
          subscriptionRef.current.unsubscribe()
        }
        subscriptionRef.current = candidateAtsService
          .postCandidateFile(
            {
              entityId: Number(candidate.atsUserId),
              fileId: Number(newSelection),
            },
            fileObject.data,
            submissionId
          )
          .subscribe({
            next: (fileStorageId: string) => {
              const { fileSize, name } = fileObject.data
              submissionService.updateSubmissionResume({
                fileStorageId,
                size: fileSize.toString(10),
                name: name.replace('.docx', '').replace('.pdf', ''),
                extension: '.pdf',
              })
              onResumeChange(true)
              if (IS_ONE_PAGER_ENABLED) {
                if (!fileObject.data.parsedResumeId) {
                  startOnePagerParse(newSelection, fileStorageId)
                } else {
                  getOnePagerByParsedResumeId(
                    fileObject.data.parsedResumeId,
                    fileStorageId
                  )
                }
              }
            },
            error: () => {
              onResumeChange(false)
            },
          })
      }
    },
    [
      submissionId,
      candidateAtsService,
      resumeOptions,
      candidate.atsUserId,
      subscriptionRef,
      onResumeChange,
      startOnePagerParse,
      getOnePagerByParsedResumeId,
      IS_ONE_PAGER_ENABLED,
      handleSelectResume,
    ]
  )
  const handleCloseViewDialog = useCallback((): void => {
    setShowingFile(false)
  }, [])
  const handleFileAction = useCallback(
    (actionType: string) => {
      if (candidate.resume) {
        handleResumes([candidate.resume], actionType)
      } else {
        handleError()
      }
    },
    [candidate.resume, handleResumes, handleError]
  )
  const handleView = useCallback((): void => {
    handleFileAction(FileActions.View)
  }, [handleFileAction])

  const handleDownload = useCallback((): void => {
    handleFileAction(FileActions.Download)
  }, [handleFileAction])

  const rootOption = useMemo(() => {
    if (candidate.resume) {
      const { name, fileStorageId } = candidate.resume
      if (fileStorageId) {
        const title = `${name}`.trim()
        return { title, id: fileStorageId }
      }
    }

    return undefined
  }, [candidate.resume])

  return (
    <>
      {blobUrl && showingFile && (
        <PdfViewerDialog blobUrl={blobUrl} onClose={handleCloseViewDialog} />
      )}

      <RadioGroup row value={mode} onChange={handleModeChange}>
        <FormControlLabel
          value={FileModes.Select}
          control={<Radio />}
          label={t('candidatePreb.selectFileFromBullhorn')}
        />
        <FormControlLabel
          value={FileModes.Upload}
          control={<Radio />}
          label={t('candidatePreb.uploadNew')}
        />
      </RadioGroup>

      <Grid container gap={2}>
        {mode === FileModes.Select && (
          <SelectWithOptions
            sx={{ width: '100%' }}
            rootOption={rootOption}
            placeholder={t('candidatePreb.noResumeSelected')}
            value={resumeSelection}
            onChange={handleSelection}
            disabled={!resumeOptions.length}
            displayEmpty
            clearable
            options={resumeOptions}
            boxProps={{ flex: '1', maxWidth: '90%' }}
          />
        )}

        {mode === FileModes.Upload && (
          <ResumeUploader
            value={candidate.resume}
            uploadResume={uploadResume}
            deleteResume={deleteResume}
            onChange={handleResumeUploadChange}
            error={hasResumeError}
            onBlur={onBlur}
            onFocus={onFocus}
            logToRollbar={(error: string) => rollbarInstance.error(error)}
            dropzoneStyles={{
              flex: '1',
            }}
            fileStyles={{
              margin: '0',
              border: `1px solid ${alpha('#000', 0.23)}`,
              borderRadius: '4px',
              padding: '0px 8px 5px 8px',
            }}
          />
        )}

        {candidate.resume && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="flex-start"
          >
            <Button size="medium" onClick={handleView} disabled={processing}>
              {t('common.btn.view')}
            </Button>

            <Button
              size="medium"
              onClick={handleDownload}
              disabled={processing}
            >
              {t('common.btn.download')}
            </Button>
          </Stack>
        )}
      </Grid>
    </>
  )
}
