import React, { useCallback } from 'react'
import moment from 'moment'

import { MenuItem, Select, SelectChangeEvent, SxProps } from '@mui/material'

// @ts-ignore
import { timezones } from './timezone-list'

interface ITimeZone {
  value: string
  name: string
  onChange: (val: string) => void
  selectSX?: SxProps
}

const TimezoneFormat = 'UTC'

const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const TimeZoneSelector: React.FC<ITimeZone> = ({
  value: defaultValue,
  onChange,
  name,
  selectSX,
}) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const {
        target: { value },
      } = event

      onChange(value)
    },
    [onChange]
  )

  return (
    <>
      <Select
        id={name}
        name={name}
        value={defaultValue || currentTimeZone}
        onChange={handleChange}
        MenuProps={{ style: { maxHeight: '80vh' } }}
        sx={{
          width: '200px',
          height: '40px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          ...selectSX,
        }}
      >
        {timezones.map((option, index) => {
          const { value, std, dst } = option
          const prefix = `${TimezoneFormat}${moment().isDST() ? dst : std}`
          return (
            <MenuItem
              key={index}
              value={value}
            >{`(${prefix}) ${value}`}</MenuItem>
          )
        })}
      </Select>
    </>
  )
}
