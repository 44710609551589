export type FormattedDateTime = {
  date: string
  time: string
}

export enum ReportSubmissionType {
  VmsMsp = '1',
  Email = '2',
  Portal = '3',
}

export interface IJobSubmissionReport {
  fromDate: string
  toDate?: string
  vendor: string
  apiKey: string
}

export interface JobSubmissionReport {
  atsJobSubmissionId: string
  accountManagerName: string
  submissionType: number
  latitude: number
  longitude: number
  submissionDateTime: string
  clientName: string
  timestamp: Date
}

export enum LeaderboardHeaderType {
  AccountManager = 'Account Manager',
  VMSMSP = 'VMS/MSP',
  Email = 'Email',
  Portal = 'Portal',
}

export interface IChartData {
  label: string
  value: number
}
