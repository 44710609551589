import { FC, Suspense, useEffect, useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import {
  Navigate,
  useLocation,
  useRoutes,
  useSearchParams,
} from 'react-router-dom'
import { map, Subscription } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import { Typography } from '@mui/material'
import { Preloader } from '@procom-labs/atoms'
import {
  errorToString,
  IFrameEntityTypes,
  lazyImport,
  useFeatureFlags,
  useServices,
  VendorCodes,
} from '@procom-labs/common'
import {
  AlertContextProvider,
  NewAppVersionPrompt,
} from '@procom-labs/molecules'

import { CandidateOnePagerPoc } from '@submission-portal/features/candidate-one-pager-poc'
import { CandidatePrep } from '@submission-portal/features/candidate-prep'
import { EventTracking } from '@submission-portal/features/events-tracking'
import { LanguageCopilotMain } from '@submission-portal/routes/language-copilot-main'
import { SubmissionDataList } from '@submission-portal/routes/submission-data-list'

import { environment } from '../environment'
import { credStorage, vendorStorage } from '../lib'
import { IAuthCredential, PublicRouterPaths } from '../models'
import { authService } from '../services'

const { CopilotHighlights } = lazyImport(
  () => import('@submission-portal/routes/copilot-highlights'),
  'CopilotHighlights'
)

const { GorillaResumeRedirect } = lazyImport(
  () => import('@submission-portal/routes/gorilla-resume-redirect'),
  'GorillaResumeRedirect'
)

const { InterviewCopilotMain } = lazyImport(
  () => import('@submission-portal/routes/interview-copilot-main'),
  'InterviewCopilotMain'
)

const { SubmissionList } = lazyImport(
  () => import('@submission-portal/routes/submission-list'),
  'SubmissionList'
)

const { JobGenerator } = lazyImport(
  () => import('@submission-portal/routes/job-generator'),
  'JobGenerator'
)

const { SubmissionDetail } = lazyImport(
  () => import('@submission-portal/features/submission-detail'),
  'SubmissionDetail'
)

const { DocumentEditor } = lazyImport(
  () => import('@submission-portal/features/document-editor'),
  'DocumentEditor'
)

const isPublicRoute = (pathname: string): boolean => {
  if (Object.values<string>(PublicRouterPaths).includes(pathname)) {
    return true
  }

  // Handle the parameterized route separately
  // temp: should use a library to make the check instead
  const candidatePrepPattern =
    PublicRouterPaths.CandidatePrepEntry.split('/:')[0]
  return (
    pathname.startsWith(candidatePrepPattern) &&
    pathname.length > candidatePrepPattern.length
  )
}

export const AppRoutes: FC = () => {
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  // Need to return a defined boolean in order to ensure not to re-render the useEffect given that undefined and null are different values
  const { utilsService } = useServices()
  const { featureFlags, setFeatureFlags } = useFeatureFlags()
  const isAuthenticated =
    useObservable(() => authService.authData$.pipe(map((d) => !!d))) || false

  const routerElement = useRoutes([
    {
      path: '/',
      element: <SubmissionList />,
    },
    {
      path: ':id/*',
      element: <SubmissionDetail />,
    },
    {
      path: '*',
      element: <Navigate to="." />,
    },
  ])

  const publicRoutes = useRoutes([
    {
      path: PublicRouterPaths.Data,
      element: <SubmissionDataList />,
    },
    {
      path: PublicRouterPaths.CandidateOnePagerPOC,
      element: <CandidateOnePagerPoc />,
    },
    {
      path: PublicRouterPaths.CandidatePrep,
      element: <CandidatePrep />,
    },
    {
      path: PublicRouterPaths.CandidatePrepEntry,
      element: <CandidatePrep />,
    },
    {
      path: PublicRouterPaths.JobGenerator,
      element: <JobGenerator />,
    },
    {
      path: PublicRouterPaths.InterviewCopilot,
      element: <InterviewCopilotMain />,
    },
    {
      path: PublicRouterPaths.LanguageCopilot,
      element: (
        <GoogleReCaptchaProvider
          reCaptchaKey={environment.GOOGLE_RECAPTCHA_KEY}
        >
          <LanguageCopilotMain />
        </GoogleReCaptchaProvider>
      ),
    },
    {
      path: PublicRouterPaths.CopilotHighlights,
      element: <CopilotHighlights />,
    },
    {
      path: PublicRouterPaths.EventTracker,
      element: <EventTracking />,
    },
    {
      path: PublicRouterPaths.ProcomResumeCopilot,
      element: <GorillaResumeRedirect />,
    },
    {
      path: PublicRouterPaths.AplinResumeCopilot,
      element: <GorillaResumeRedirect />,
    },
    {
      path: PublicRouterPaths.EastWestStaffingResumeCopilot,
      element: <GorillaResumeRedirect />,
    },
    {
      path: PublicRouterPaths.VanderhouwenResumeCopilot,
      element: <GorillaResumeRedirect />,
    },
    {
      path: PublicRouterPaths.BullhornResumeCopilot,
      element: <GorillaResumeRedirect />,
    },
    {
      path: PublicRouterPaths.DocumentEditor,
      element: <DocumentEditor />,
    },
  ])

  useEffect(() => {
    const subscription = new Subscription()
    const tokenSubscription = new Subscription()
    let vendorSubscription = new Subscription()
    if (isAuthenticated) {
      setIsLoading(false)
      subscription.add(authService.getSingleClient().subscribe())
      if (featureFlags.length === 0) {
        subscription.add(
          utilsService.getVendorFeatureFlags().subscribe({
            next: (flags) => {
              setFeatureFlags(flags)
            },
          })
        )
      }
    } else if (!isAuthenticated) {
      const userId = searchParams.get('UserID')
      const entityId = searchParams.get('EntityID')
      const entityType = searchParams.get('EntityType')
      const username = searchParams.get('username')
      const password = searchParams.get('password')
      const vendorCode = searchParams.get('vendorCode')
      const apiKey = searchParams.get('apiKey')
      const hasQueryVendorJobCreds = entityId && entityType && vendorCode
      const cachedVendorJobCreds = vendorStorage.get()

      // AtsJobId
      const cachedCreds = credStorage.get()
      const hasQueryCreds = userId && username && password

      if (hasQueryVendorJobCreds || cachedVendorJobCreds) {
        vendorSubscription = authService
          .getJobVendor(
            hasQueryVendorJobCreds
              ? vendorCode
              : cachedVendorJobCreds?.vendorCode ?? VendorCodes.PCGL,
            decodeURIComponent(
              hasQueryVendorJobCreds
                ? entityId
                : cachedVendorJobCreds?.entityId ?? ''
            ),
            decodeURIComponent(
              hasQueryVendorJobCreds
                ? entityType
                : cachedVendorJobCreds?.entityType ?? ''
            ),
            apiKey ? decodeURIComponent(apiKey) : undefined
          )
          .subscribe({
            next: (response) => {
              if (hasQueryCreds || cachedCreds) {
                const payload: IAuthCredential | null = hasQueryCreds
                  ? {
                      atsJobId:
                        entityType === IFrameEntityTypes.Candidate
                          ? null
                          : decodeURIComponent(entityId ?? ''),
                      atsUserId: decodeURIComponent(userId),
                      account: decodeURIComponent(username),
                      code: decodeURIComponent(password),
                      vendorCode: response,
                    }
                  : cachedCreds
                if (payload) {
                  tokenSubscription.add(
                    authService
                      .authorize(
                        payload,
                        apiKey ? decodeURIComponent(apiKey) : undefined
                      )
                      .subscribe({
                        error: (err) => {
                          setError(errorToString(err))
                          setIsLoading(false)
                        },
                      })
                  )
                }
              } else {
                setIsLoading(false)
              }
            },
            error: (err) => {
              setError(errorToString(err))
              setIsLoading(false)
            },
          })
      } else {
        setIsLoading(false)
      }
    }
    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
      if (tokenSubscription && !tokenSubscription.closed) {
        tokenSubscription.unsubscribe()
      }
      if (vendorSubscription && !vendorSubscription.closed) {
        vendorSubscription.unsubscribe()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])
  if (isLoading) {
    return <Preloader center />
  }

  if (isPublicRoute(pathname)) {
    return (
      <AlertContextProvider>
        {publicRoutes}
        <NewAppVersionPrompt />
      </AlertContextProvider>
    )
  }

  if (isAuthenticated) {
    return (
      <AlertContextProvider>
        <Suspense fallback={<Preloader center />}>
          {routerElement}
          <NewAppVersionPrompt />
        </Suspense>
      </AlertContextProvider>
    )
  }

  return (
    <div className="center-screen">
      <NewAppVersionPrompt />
      <Typography paragraph variant="h3">
        Access Denied
      </Typography>
      {error && (
        <Typography variant="h5" paragraph>
          {error}
        </Typography>
      )}
    </div>
  )
}
