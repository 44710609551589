import { FC, ReactElement } from 'react'
import { DateTime } from 'luxon'

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSlotsComponentsProps } from '@mui/x-date-pickers/internals'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'

type DayType = DayCalendarSlotsComponentsProps<(string | null)[]>

interface IGorillaCalendar {
  selectedDate: DateTime
  highlightedDays: (string | null)[]
  handleSelectedDate: (selectedDate: DateTime) => void
  disablePast?: boolean
}

const ServerDay = (
  props: PickersDayProps<any> & { highlightedDays?: string[] }
): ReactElement => {
  const { highlightedDays = [], day, ...other } = props
  const stringDate = DateTime.fromISO(day, { zone: 'UTC' }).toFormat('D')
  const isDayHighlighted = highlightedDays.includes(stringDate)
  return (
    <PickersDay
      {...other}
      day={day}
      sx={{
        backgroundColor: isDayHighlighted ? 'common.purple' : 'transparent',
        color: isDayHighlighted ? 'common.white' : 'default',
        '&.MuiPickersDay-root.Mui-selected': {
          backgroundColor: other?.selected ? 'common.orange' : 'transparent',
          color: other?.selected ? 'common.white' : 'common.black',
        },
      }}
    />
  )
}
export const GorillaCalendar: FC<IGorillaCalendar> = ({
  selectedDate,
  highlightedDays,
  handleSelectedDate,
  disablePast = true,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DateCalendar
        disablePast={disablePast}
        value={selectedDate}
        onChange={handleSelectedDate}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          } as DayType,
        }}
      />
    </LocalizationProvider>
  )
}
