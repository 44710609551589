import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GorillaDialog } from '@procom-labs/atoms'
import { useSubjectSelector } from '@procom-labs/common'

import { submissionStore } from '@submission-portal/stores'

export const SubmissionSaveConfirmation: React.FC<{
  handleSave: () => void
}> = ({ handleSave }) => {
  const theme = useTheme()
  const { t } = useTranslation('main')
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))

  const { isSaveConfirmationModalOpen } = useSubjectSelector(submissionStore, [
    'isSaveConfirmationModalOpen',
  ])

  const handleNextWithoutSave = useCallback(() => {
    submissionStore.dispatch({
      isSaveConfirmationModalOpen: false,
      isCandidateCartOpen: false,
    })
  }, [])

  const handleSaveAndClose = useCallback(() => {
    handleSave()
  }, [handleSave])

  return (
    <GorillaDialog
      open={isSaveConfirmationModalOpen}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {t('submissionList.multipleCandidates.alerts.saveTitle')}
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" onClick={handleSaveAndClose}>
            {t('btn.saveProgress')}
          </Button>
          <Button onClick={handleNextWithoutSave} variant="text" type="button">
            {t('submissionList.multipleCandidates.alerts.saveActionCancel')}
          </Button>
        </Stack>
      </DialogContent>
    </GorillaDialog>
  )
}
