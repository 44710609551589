import {
  BaseSubjectStore,
  DataObject,
  DataOption,
  Employee,
  IContractorProfileResume,
  Job,
  SubmissionTemplate,
} from '@procom-labs/common'

export interface ICopilotStoreState {
  candidateSelection: string
  candidateSelections: DataOption<Employee>[]
  canSelectCandidate: boolean
  resumeSelections: DataOption<DataObject>[]
  resumeSelection: string
  resumeFile: IContractorProfileResume | null
  resumeFileBlob: string
  resumeSelectionBlob: string

  coverPageJobSelections: DataOption<Job>[]
  coverPageJobSelection: string

  coverPageSelections: DataOption<DataObject>[]
  coverPageSelection: string
  coverPageFile: IContractorProfileResume | null
  coverPageFileBlob: string
  coverPageSelectionBlob: string

  submissionTemplateSelections: DataOption<SubmissionTemplate>[]
  submissionTemplateSelection: string

  resumeFileSfdtText: string
  generating: boolean

  removePersonalInformation: boolean

  processingResume: boolean

  resumeURL: string
  drawerOpen: boolean
}

export class CopilotStore extends BaseSubjectStore<ICopilotStoreState> {
  getDefaultValue(): ICopilotStoreState {
    return {
      candidateSelection: '',
      canSelectCandidate: true,
      candidateSelections: [],
      resumeSelections: [],
      resumeSelection: '',
      resumeFile: null,
      resumeFileBlob: '',
      resumeSelectionBlob: '',

      coverPageJobSelections: [],
      coverPageJobSelection: '',
      coverPageSelections: [],
      coverPageSelection: '',
      coverPageFile: null,
      coverPageFileBlob: '',
      coverPageSelectionBlob: '',

      submissionTemplateSelections: [],
      submissionTemplateSelection: '',

      resumeFileSfdtText: '',
      generating: false,
      removePersonalInformation: false,
      processingResume: false,
      resumeURL: '',
      drawerOpen: true,
    }
  }
}

export const copilotStore = new CopilotStore()
