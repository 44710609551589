import React from 'react'

import BrandLogoBlackTextSvg from '@procom-labs/common/src/assets/brand/logo-black-text.svg'
import BrandLogoSmallSvg from '@procom-labs/common/src/assets/brand/logo-small.svg'
import BrandLogoSvg from '@procom-labs/common/src/assets/brand/logo.svg'

export const BrandLogo: React.FC<{
  style?: React.CSSProperties
  withText?: boolean
  textColor?: 'black' | 'white'
}> = ({ withText, style, textColor = 'white' }) => {
  if (withText) {
    return (
      <img
        src={textColor === 'white' ? BrandLogoSvg : BrandLogoBlackTextSvg}
        style={{ ...style }}
        alt="Gorilla Works Logo"
      />
    )
  }
  return (
    <img
      src={BrandLogoSmallSvg}
      style={{ ...style }}
      alt="Gorilla Works Logo"
    />
  )
}
