import React, { FC, ReactNode } from 'react'
import { IconButton, MenuProps } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ActionMenuButton } from './action-menu-button'

interface ShowMoreActionMenuProps extends Omit<MenuProps, 'open' | 'anchorEl'> {
  id: string
  children: (close: () => void) => ReactNode
}

export const ShowMoreActionMenuButton: FC<ShowMoreActionMenuProps> = ({
  children,
  ...props
}) => (
  <ActionMenuButton
    {...props}
    label={
      <IconButton>
        <MoreVertIcon />
      </IconButton>
    }
  >
    {children}
  </ActionMenuButton>
)
