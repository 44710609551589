import { Box, BoxProps } from '@mui/material'
import { FC, useRef, useEffect, ReactNode } from 'react'

interface ViewportResizerProps extends BoxProps {
  children?: ReactNode
  delta?: number
}

export const ViewportResizer: FC<ViewportResizerProps> = ({
  children,
  sx,
  delta = 50,
  ...restProps
}) => {
  const boxRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const adjustHeight = (): void => {
      if (boxRef.current) {
        const rect = boxRef.current.getBoundingClientRect()
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight
        const remainingHeight = viewportHeight - rect.top - delta
        const value = `${remainingHeight}px`
        boxRef.current.style.maxHeight = value
        boxRef.current.style.minHeight = value
      }
    }

    // adjust the height when the component is mounted
    adjustHeight()

    // and when the window is resized
    window.addEventListener('resize', adjustHeight)

    return () => {
      window.removeEventListener('resize', adjustHeight)
    }
  }, [delta])

  return (
    <Box sx={{ overflowY: 'auto', ...sx }} ref={boxRef} {...restProps}>
      {children}
    </Box>
  )
}
