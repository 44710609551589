import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Subscription } from 'rxjs'

import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GridColDef } from '@mui/x-data-grid'
import { ColumnDescriptor, useSubjectSelector } from '@procom-labs/common'
import { useAlert, useFetchAvatarColors } from '@procom-labs/molecules'
import {
  CellDateTime,
  CellText,
  CellTextWithAvatar,
} from '@procom-labs/organisms'

import { CandidatePrepActionCell } from '@submission-portal/components/candidate-prep/cells/candidate-prep-action-cell'
import { submissionService } from '@submission-portal/services'
import {
  avatarStore,
  candidatePrepSelectStore,
} from '@submission-portal/stores'
import {
  Candidate,
  CandidatePrepFields,
  SubmissionFields,
  SubmissionSearchFilter,
} from '@submission-portal/types'

const formatObjectToString = <T extends Record<string, any>>(
  obj: T
): string => {
  return Object.values(obj)
    .map((value) => (typeof value === 'string' ? value.trim() : ''))
    .filter((value) => value !== '')
    .join(', ')
}

export const useGetBullhornCandidatePrepColumnDescriptors =
  (): ColumnDescriptor[] => {
    const { t } = useTranslation('main')

    return [
      {
        field: CandidatePrepFields.CandidateFullName,
        headerName: t(`candidatePreb.candidate`),
        minWidth: 200,
      },
      {
        field: CandidatePrepFields.Location,
        headerName: t(`candidatePreb.location`),
        minWidth: 150,
        sortable: false,
      },
      {
        field: CandidatePrepFields.CandidateAvailability,
        headerName: t(`candidatePreb.availability`),
        minWidth: 150,
      },
      {
        field: CandidatePrepFields.Source,
        headerName: t(`candidatePreb.source`),
        sortable: false,
      },
      {
        field: SubmissionFields.Actions,
        headerName: t(`candidatePreb.actions`),
        maxWidth: 200,
        sortable: false,
      },
    ]
  }

export const useBullhornCandidatePrepDataColumns =
  (): GridColDef<Candidate>[] => {
    const theme = useTheme()
    const mobileView = useMediaQuery(() => theme.breakpoints.down('md'))

    const columnDescriptors = useGetBullhornCandidatePrepColumnDescriptors()

    const cellRenderer = useMemo(
      () =>
        (field: string, cell: { row: Candidate }): React.ReactNode => {
          const { row } = cell
          const { id: entryId } = row
          switch (field) {
            case CandidatePrepFields.CandidateFullName:
              return (
                <CellTextWithAvatar
                  id={entryId?.toString?.()}
                  text={`${row.firstName} ${row.lastName}`}
                  avatarStore={avatarStore}
                />
              )
            case CandidatePrepFields.Location:
              return <CellText value={`${formatObjectToString(row.address)}`} />
            case CandidatePrepFields.CandidateAvailability:
              return <CellDateTime value={row.dateAvailable} />
            case CandidatePrepFields.Source:
              return <CellText value={`${row.source}`} />
            case CandidatePrepFields.Actions: {
              return <CandidatePrepActionCell id={entryId} />
            }
            default:
              // Should not happen
              return <></>
          }
        },
      []
    )

    return useMemo(
      () =>
        columnDescriptors.map((columnDescriptor) => ({
          ...columnDescriptor,
          flex: Number(!mobileView),
          renderCell: (cell: any) => cellRenderer(columnDescriptor.field, cell),
        })),
      [cellRenderer, columnDescriptors, mobileView]
    )
  }

export const useBullhornCandidatesDataRecords = (
  searchFilter: SubmissionSearchFilter
): { rows: Candidate[]; rowCount: number; loading: boolean } => {
  const { addAlert } = useAlert()
  const { t } = useTranslation('main')

  const [searchParams] = useSearchParams()
  const jobId = searchParams.get('EntityID')

  const fetchAvatarColors = useFetchAvatarColors(avatarStore)

  const {
    candidates: rows,
    count: rowCount,
    loading,
    size,
  } = useSubjectSelector(candidatePrepSelectStore, [
    'candidates',
    'count',
    'loading',
    'size',
  ])

  useEffect(() => {
    let subscription: Subscription | null = null
    if (jobId) {
      const { keyword } = searchFilter
      if (keyword) {
        const { sortField, sortOrder, currentPage, pageSize } = searchFilter
        const sortBy = sortField ? `${sortField} ${sortOrder}` : undefined
        subscription = submissionService
          .searchSubmissions(
            Number(jobId),
            keyword,
            size,
            currentPage * pageSize,
            sortBy
          )
          .subscribe({
            next: ({ count, candidates }) => {
              fetchAvatarColors(candidates.map(({ id }) => id.toString()))
              candidatePrepSelectStore.dispatch({
                candidates,
                count,
                loading: false,
              })
            },
            error: () => {
              candidatePrepSelectStore.dispatch({
                loading: false,
              })
              addAlert({
                message: t('common.alert.somethingWrong'),
                severity: 'error',
              })
            },
          })
      } else {
        // In case of empty keyword, we do not attempt to search
        candidatePrepSelectStore.dispatch({
          candidates: [],
          count: 0,
          loading: false,
        })
      }
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [addAlert, t, fetchAvatarColors, jobId, searchFilter, size])

  return { rows, rowCount, loading }
}
