import { IEntityType } from './core'

export namespace TrackingEvents {
  export enum DashboardCard {
    CardAction = 'Dashboard Card - Action CTA Click',
    ViewJobsCardClick = 'Dashboard Card - View Jobs - Card Click',
    CopilotCardClick = 'Dashboard Card - Copilot - Card Click',
    RecruitmentSupportCardClick = 'Dashboard Card - Recruitment Support - Card Click',
    OnboardWorkerCardClick = 'Dashboard Card - Onboard Worker - Card Click',
    SearchTalentCardClick = 'Dashboard Card - Search Talent - Card Click',
    RateSupportCardClick = 'Dashboard Card - Rate Support - Card Click',
    ViewJobs = 'Dashboard Card - View Jobs',
    PostJob = 'Dashboard Card - Post Job',
    ReviewSubmission = 'Dashboard Card - Review Submission',
    JobDescription = 'Dashboard Card - Job Description',
    InterviewQuestions = 'Dashboard Card - Interview Questions',
  }

  export enum AICopilots {
    InterviewQuestionsClientPortal = 'InterviewQuestionsClientPortal',
    InterviewQuestionsEmployerPortal = 'InterviewQuestionsEmployerPortal',
    InterviewQuestionsContractorPortal = 'InterviewQuestionsContractorPortal',
    InterviewQuestionsSubmissionPortal = 'InterviewQuestionsSubmissionPortal',
    JobDescriptionClientPortal = 'JobDescriptionClientPortal',
    JobDescriptionEmployerPortal = 'JobDescriptionEmployerPortal',
    JobDescriptionBullhorn = 'JobDescriptionBullhorn',
    CandidateHighlightsClientPortal = 'CandidateHighlightsClientPortal',
    CandidateHighlightsEmployerPortal = 'CandidateHighlightsEmployerPortal',
    CandidateHighlightsBullhorn = 'CandidateHighlightsBullhorn',
    ResumeCopilotContractorPortal = 'ResumeCopilotContractorPortal',
    ImportResumeCopilotProSubmissionPortal = 'ImportResumeCopilotProSubmissionPortal',
    ExportResumeCopilotProSubmissionPortal = 'ExportResumeCopilotProSubmissionPortal',
  }

  export enum JobCard {
    Clicked = 'Job Card',
    ContactAM = 'Job Card - Contact AM',
    ViewCandidate = 'Job Card - View Candidates',
  }

  export enum JobPost {
    JobPublished = 'Job Post - Job Published',
  }

  export enum JobDetail {
    ContactAM = 'Job Details - Contact AM',
    ViewCandidate = 'Job Details - View Candidates',
    OpenChat = 'Job Details - Open Chat',
  }

  export enum JobSubmission {
    OpenChat = 'Job Submission - Open Chat',
  }

  export enum CandidateCard {
    ContactAM = 'Candidate Card - Contact AM',
    QuickView = 'Candidate Card -  Quick view',
    Reject = 'Candidate Card - Reject',
    RejectOpenModal = 'Candidate Card - Reject - Open Modal',
    RequestInterviewOpenModal = 'Candidate Card - Request Interview - Open Modal',
    RequestInterview = 'Candidate Card - Request Interview',
    EditInterviewRequest = 'Candidate Card - Edit Interview Request',
    Shortlist = 'Candidate Card - Shortlist',
    ViewProfile = 'Candidate Card - View Profile',
    ViewOnePager = 'Candidate Card - View One Pager',
    OfferExtendedOpenModal = 'Candidate Card - Offer Extended - Open Modal',
    OfferExtended = 'Candidate Card - Offer Extended',
    Submitted = 'Candidate - Candidate Submitted',
    PreScreen = 'Candidate - Candidate PreScreen',
  }

  export enum CandidateDetail {
    ContactAM = 'Candidate Details - Contact AM',
    DownloadResume = 'Candidate Details - Download Resume',
    DownloadDocument = 'Candidate Details - Download Document',
    ViewDocument = 'Candidate Details - View Document',
    QuickView = 'Candidate Details -  Quick view',
    Reject = 'Candidate Details - Reject',
    RequestInterview = 'Candidate Details - Request Interview',
    Shortlist = 'Candidate Details - Shortlist',
    NoteNew = 'Candidate Details - Note Added',
    NoteReplyNew = 'Candidate Details - Note Replied',
    NoteDeleted = 'Candidate Details - Note Deleted',
  }

  export enum TalentPoolList {
    ViewTalentPoolList = 'Talent Pool - View Talent Pool',
  }

  export enum TalentPoolDetail {
    ViewCandidate = 'Talent Pool Details - View Candidate',
    Interested = 'Talent Pool Details - Interested',
    NotInterested = 'Talent Pool Details - Not Interested',
    UndoInterested = 'Talent Pool Details - Undo Interested',
    UndoNotInterested = 'Talent Pool Details - Undo Not Interested',
    CandidateAdded = 'Talent Pool Details - Candidate Added',
  }

  export enum CandidateList {
    ViewCandidate = 'Candidate List - View Candidate Profile',
  }

  export enum EmployerCandidate {
    ViewProfile = 'Employer Candidate Details - View Profile',
    ViewAppliedJobs = 'Employer Candidate Details - View Applied Jobs',
    UpdateProfile = 'Candidate - Candidate Profile Updated',
    OfferExtended = 'Candidate - Candidate OfferExtended',
    OfferRejected = 'Candidate - Candidate OfferRejected',
    InterviewRequested = 'Candidate - Candidate InterviewRequested',
    InterviewConfirmed = 'Candidate - Candidate InterviewConfirmed',
    Rejected = 'Candidate - Candidate Rejected',
    Hired = 'Candidate - Candidate Hired',
    Submitted = 'Candidate - Candidate Submitted',
    PreScreen = 'Candidate - Candidate PreScreen',
    CandidateAdded = 'Candidate - Candidate Added',
    JobApplied = 'Candidate - Contractor Job Applied',
    ContractorUpdateProfile = 'Candidate - Contractor Profile Updated',
    RejectionEmail = 'Candidate Rejection Email - Rejection Email Sent',
  }

  export enum OnboardingEvents {
    SurveyReleased = 'SurveyReleased',
    UpdateDueDates = 'UpdateDueDates',
    AddItemsToOnboarding = 'AddItemsToOnboarding',
    OnboardingDiscarded = 'OnboardingDiscarded',
    InformationRequestDiscarded = 'InformationRequestDiscarded',
    IcQuestionareOverride = 'IcQuestionareOverride',
    ContractCreated = 'ContractCreated',
    ContractEdited = 'ContractEdited',
    ContractReleased = 'ContractReleased',
    UpdatedContractReleased = 'UpdatedContractReleased',
    ContractRejected = 'ContractRejected',
    ContractSigned = 'ContractSigned',
    DownloadedOnboardingFiles = 'DownloadedOnboardingFiles',
    ViewedPackage = 'ViewedPackage',
    ViewedChanges = 'ViewedChanges',
    SurveyReReleased = 'SurveyReReleased',
    ContractDiscarded = 'ContractDiscarded',
  }

  export enum DocumentComplianceEvents {
    UploadNew = 'UploadNew',
    BackToForm = 'BackToForm',
    ContactSupport = 'ContactSupport',
    Reevaluate = 'Reevaluate',
    ProceedAnyway = 'ProceedAnyway',
    CloseModal = 'CloseModal',
  }

  export enum DataTracking {
    FriResumeViewInClientPortal = 'Data Tracking - Frictionless - Resume View In Client Portal',
    FriViewCandidate = 'Data Tracking - Frictionless - View Candidate',
    FriDownloadResume = 'Data Tracking - Frictionless - Candidate Details - Download Resume',
    FriRequestInterview = 'Data Tracking - Frictionless - Candidate Card - Request Interview',
    FriOfferExtended = 'Data Tracking - Frictionless - Candidate Card - Offer Extended',
    FriEditInterviewRequest = 'Data Tracking - Frictionless - Candidate Card - Edit Interview Request',
    FriOtpLogin = 'Data Tracking - Frictionless - Otp Login',
    FriPasswordLogin = 'Data Tracking - Frictionless - Password Login',
    FriRestrictedMenuClick = 'Data Tracking - Frictionless - Menu Item Click - {0}',
    FriCandidateShortlist = 'Data Tracking - Frictionless - Candidate Card - Shortlist',
    FriCandidateReject = 'Data Tracking - Frictionless - Candidate Card - Reject',
    BhClientPortalCandidateView = 'Data Tracking - BH Client Portal - Review & Submit',
  }

  export enum Notification {
    NotificationClicked = 'Notification - Clicked - {0}',
  }

  export enum CaseStudy {
    ViewList = 'Case Study - View List',
    Search = 'Case Study - Search',
    ViewDetails = 'Case Study - View Details',
    DownloadPdf = 'Case Study - Download PDF',
    DownloadWord = 'Case Study - Download Word',
    Edited = 'Case Study - Edited',
    PlacementDataRefreshed = 'Case Study - Placement Data Refreshed',
  }
}

export interface ITrackData {
  entityType: IEntityType
  entityId: string
  event:
    | TrackingEvents.JobCard
    | TrackingEvents.JobDetail
    | TrackingEvents.CandidateCard
    | TrackingEvents.CandidateDetail
    | TrackingEvents.TalentPoolList
    | TrackingEvents.TalentPoolDetail
    | TrackingEvents.CandidateList
    | TrackingEvents.EmployerCandidate
    | TrackingEvents.DataTracking
    | TrackingEvents.Notification
    | TrackingEvents.CaseStudy
    | TrackingEvents.JobPost

  parentEntityId?: string
  parentEntityType?: IEntityType
  comments?: string
  atsJobId?: number
  jobTitle?: string
  candidateName?: string
  documentName?: string
  talentPoolName?: string
  contractProfileId?: string
  description?: string
  hiringManagerName?: string
}

export interface IPerformanceTracking {
  url: string
  startTime: string
  endTime?: string
  duration?: number
  userProfileId?: string // FBO users only
  instanceUiId: ''
  httpVerb: string
  exception?: string
  httpStatusCode?: number
  portalImpersonatorUserId?: string // Will be added when impersonating story is completed
  portalUserId?: string
  requestSource: 'Client Connections'
}

export type DurationTrackingEvents =
  | TrackingEvents.CandidateCard.ViewProfile
  | TrackingEvents.CandidateCard.ViewOnePager
  | TrackingEvents.CandidateList.ViewCandidate
  | TrackingEvents.TalentPoolDetail.ViewCandidate
  | TrackingEvents.TalentPoolList.ViewTalentPoolList
  | TrackingEvents.DataTracking.FriViewCandidate
