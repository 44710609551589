import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { List, ListItem, ListItemText } from '@mui/material'

import { SkillsWrapper } from './skills-wrapper'

export const Roles: FC<{ roles: string[] }> = memo(({ roles }) => {
  const { t } = useTranslation()
  if (roles.length === 0) return null
  return (
    <SkillsWrapper title={t('organisms.candidateOnePager.openToRoles')}>
      <List sx={{ listStyleType: 'disc', pl: 3 }}>
        {roles.map((role) => (
          <ListItem sx={{ p: 0, display: 'list-item' }} key={role}>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
              }}
              primary={role}
            />
          </ListItem>
        ))}
      </List>
    </SkillsWrapper>
  )
})
