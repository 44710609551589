import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FileCopyOutlined } from '@mui/icons-material'
import {
  getNormalizedDateString,
  GorillaResumeCertification,
  isADate,
  OnePagerListItemObject,
} from '@procom-labs/common'

import { List } from './list'

export const Certifications: FC<{
  certifications: GorillaResumeCertification[]
}> = memo(({ certifications }) => {
  const { t } = useTranslation()
  const getDateString = (date: string): string => {
    return isADate(date)
      ? getNormalizedDateString(date, { month: 'short', year: 'numeric' })
      : date
  }

  const items: OnePagerListItemObject[] = useMemo(
    () =>
      certifications?.map((certification) => ({
        primary: certification.name,
        secondary: certification.dateReceived
          ? ` | ${getDateString(certification.dateReceived)}`
          : '',
      })),
    [certifications]
  )

  return (
    <List
      icon={FileCopyOutlined}
      title={t('organisms.candidateOnePager.certifications')}
      items={items}
    />
  )
})
