import { IAuthCredential } from '../models'

const storageKey = 'PROCOM_USER_CREDS'

export const credStorage = {
  get: (): IAuthCredential | null => {
    const rawValue = localStorage.getItem(storageKey)
    if (!rawValue) return null

    const value = JSON.parse(rawValue) as IAuthCredential
    return value
  },
  set: (v: IAuthCredential) => {
    window.localStorage.setItem(storageKey, JSON.stringify(v))
  },
  clear: () => {
    window.localStorage.removeItem(storageKey)
  },
}
