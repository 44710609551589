import { TFuncKey } from 'react-i18next'

const listOfRejections = [
  'needStrongCandidate',
  'overQualifiedCandidate',
  'poorCommunication',
  'poorInterviewPerformance',
  'notEnoughExperience',
  'lackOfRoleSpecificExperience',
  'other',
]

export const RejectionHeadings: TFuncKey[] = listOfRejections.map(
  (item) => `common.candidates.dialog.reject.headings.${item}` as TFuncKey
)
