import React from 'react'
import ReactDOM from 'react-dom'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { environment } from '@submission-portal/environment'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'

const gtmParams = { id: environment.GTM_KEY }

ReactDOM.render(
  <React.StrictMode>
    <GTMProvider state={gtmParams}>
      <App />
    </GTMProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
