import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { map, tap } from 'rxjs'

import {
  ICandidate,
  IJobSubmission,
  JobStatusActions,
  JobSubmissionAtsStatusLabels,
  JobSubmissionAtsStatusTypeMap,
  JobSubmissionAtsStatusTypes,
  JobSubmissionPortalStatusTypes,
  JobSubmissionStatusTypes,
  SubmissionActions,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert, useDefaultErrorHandler } from '@procom-labs/molecules'

import {
  fetchResumeAndCoverPage,
  saveCandidateData,
} from '@submission-portal/components/candidate-prep/candidate-prep-utils'
import {
  useAtsResourcesFetcher,
  useScopedFetchFile,
} from '@submission-portal/hooks'
import { useErrorAndCompletionStoreObserver } from '@submission-portal/hooks/use-completion-store-observer'
import { submissionService } from '@submission-portal/services/submission.service'
import { submissionStore } from '@submission-portal/stores'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import { jobStatusStore } from '@submission-portal/stores/job-status-store'
import { ICandidatePrepFormValues } from '@submission-portal/types'

export const useUpdatePortalStatus = (): ((
  jobSubmissionId: string,
  action: SubmissionActions,
  formValues: ICandidatePrepFormValues | undefined
) => void) => {
  const { id } = useParams()
  const { addAlert } = useAlert()
  const { t } = useTranslation('main')
  const { candidate: submission } = useSubjectSelector(candidatesPrepStore, [
    'candidate',
  ])
  const candidate = submission?.candidate
  const onFetchFile = useScopedFetchFile()
  const fetchResource = useAtsResourcesFetcher(onFetchFile)

  const subscriptionRef = useSubscriptionRef()

  const errorAndCompletionObserver = useErrorAndCompletionStoreObserver({
    store: submissionStore,
  })

  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const doHandleError = useCallback((): void => {
    candidatesPrepStore.dispatch({
      processing: false,
    })
    handleError()
  }, [handleError])

  const updateJobSubmissionStatus = useCallback(
    (jobSubmissionId: string, action: SubmissionActions): void => {
      switch (action) {
        case SubmissionActions.ClientSubmission:
          // In case of client submission, we defer submission awaiting for confirmation by other places
          candidatesPrepStore.dispatch({
            clientSubmissionUnderProcessing: jobSubmissionId,
          })
          break
        case SubmissionActions.SubmittedToManager:
          candidatesPrepStore.dispatch({
            processing: true,
          })

          submissionStore.dispatch({
            submissions: submissionStore
              .getStateValue()
              .submissions?.map((current) => {
                if (current.jobSubmissionId === jobSubmissionId) {
                  return {
                    ...current,
                    status: JobSubmissionStatusTypes.SubmittedToManager,
                  }
                }
                return current
              }),
          })
          submissionService
            .submitCandidate(jobSubmissionId, {
              status: action,
              uploadDocuments: true,
            })
            .subscribe({
              ...errorAndCompletionObserver,
              next: (updatedSummary) => {
                candidatesPrepStore.dispatch({
                  processing: false,
                  candidate: {
                    ...submission,
                    status: updatedSummary.status,
                  } as IJobSubmission,
                })
                addAlert({
                  message: t('candidatePreb.sentToManagerSuccess'),
                  severity: 'success',
                })
              },
            })

          break
        default: {
          candidatesPrepStore.dispatch({
            processing: false,
          })
          break
        }
      }
    },
    [submission, addAlert, errorAndCompletionObserver, t]
  )

  return useCallback(
    (jobSubmissionId, action, formValues) => {
      // If the id exists, we are on the details page and have to fetch resume and cover page
      // If this id doesn't exist, we are on the list page and have to fetch the job submission object
      if (id) {
        subscriptionRef.current = fetchResumeAndCoverPage({
          fetchResource,
          candidateStore: candidatesPrepStore,
        }).subscribe({
          next: (files) => {
            // Perform validation checks
            if (!files.resume?.fileStorageId) {
              candidatesPrepStore.dispatch({
                processing: false,
              })
              addAlert({
                severity: 'error',
                message: t('candidatePreb.profileMissingResumeError'),
              })
              return
            }
            if (!candidate?.formattedSubmissionFile?.fileStorageId) {
              candidatesPrepStore.dispatch({
                processing: false,
              })
              addAlert({
                severity: 'error',
                message: t(
                  'candidatePreb.profileMissingFormattedSubmissionError'
                ),
              })
              return
            }

            if (!submission) {
              doHandleError()
              return
            }
            // Clear any previous subscription if active
            if (subscriptionRef.current && !subscriptionRef.current.closed) {
              subscriptionRef.current.unsubscribe()
            }

            // First perform a save, then update the submission with the new status
            subscriptionRef.current = saveCandidateData(
              submission,
              formValues,
              files,
              () => updateJobSubmissionStatus(jobSubmissionId, action),
              doHandleError
            )
          },
          error: doHandleError,
        })
      } else {
        submissionService
          .getJobSubmission(jobSubmissionId)
          .pipe(
            map((record) => ({
              resume: record.candidate.resume,
              formattedSubmissionFile: record.candidate.formattedSubmissionFile,
            }))
          )
          .subscribe({
            next: (files) => {
              // Perform validation checks
              if (!files.resume?.fileStorageId) {
                candidatesPrepStore.dispatch({
                  processing: false,
                })
                addAlert({
                  severity: 'error',
                  message: t('candidatePreb.profileMissingResumeError'),
                })
                return
              }
              if (!files?.formattedSubmissionFile?.fileStorageId) {
                candidatesPrepStore.dispatch({
                  processing: false,
                })
                addAlert({
                  severity: 'error',
                  message: t(
                    'candidatePreb.profileMissingFormattedSubmissionError'
                  ),
                })
                return
              }

              // Directly update the status when the call is being made from the Candidate List page
              updateJobSubmissionStatus(jobSubmissionId, action)
            },
            error: doHandleError,
          })
      }
    },
    [
      id,
      addAlert,
      candidate?.formattedSubmissionFile?.fileStorageId,
      doHandleError,
      fetchResource,
      updateJobSubmissionStatus,
      submission,
      subscriptionRef,
      t,
    ]
  )
}

export const useUpdateAtsStatus = (): ((
  record: {
    atsUserId: number
    jobSubmissionId: string
    additionalData?: Partial<ICandidate>
  },
  status: string,
  callbacks?: {
    error?: () => void
    complete?: () => void
    tap?: (updatedCandidate: ICandidate) => void
  }
) => void) => {
  const errorAndCompletionObserver = useErrorAndCompletionStoreObserver({
    store: submissionStore,
  })
  const subscriptionRef = useSubscriptionRef()

  const { submissions: rows } = useSubjectSelector(submissionStore, [
    'submissions',
  ])

  const { jobStatuses } = useSubjectSelector(jobStatusStore, ['jobStatuses'])

  return useCallback(
    (record, atsJobSubmissionStatus, callbacks) => {
      const { atsUserId, jobSubmissionId, additionalData } = record

      const relevantStatus = jobStatuses.find(
        ({ bullhornStatus }) => bullhornStatus === atsJobSubmissionStatus
      )

      const portalStatus = relevantStatus?.portalStatus

      const newRows = rows.map((candidate) =>
        candidate.atsUserId === atsUserId ||
        candidate.jobSubmissionId === jobSubmissionId
          ? {
              ...candidate,
              atsSubmissionStatus:
                atsJobSubmissionStatus as JobSubmissionAtsStatusLabels,
            }
          : candidate
      )

      // For immediate feedback until hearing back from the server
      submissionStore.dispatch({
        loading: true,
        submissions: newRows,
      })

      subscriptionRef.current = submissionService
        .updateStatus({
          jobSubmissionId,
          atsJobSubmissionStatus,
          jobSubmissionStatus: portalStatus ?? '',
          ...additionalData,
        })
        .pipe(tap(callbacks?.tap))
        .subscribe({
          ...errorAndCompletionObserver,
          next: (updatedCandidate) => {
            const updatedRows = newRows.map((submission) =>
              submission.atsUserId === atsUserId ||
              submission.jobSubmissionId === jobSubmissionId
                ? {
                    ...submission,
                    status:
                      updatedCandidate.jobSubmissionStatus as JobSubmissionStatusTypes,
                  }
                : submission
            )

            submissionStore.dispatch({
              loading: false,
              submissions: updatedRows,
            })
          },
          error: callbacks?.error,
          complete: callbacks?.complete,
        })
    },
    [errorAndCompletionObserver, rows, jobStatuses, subscriptionRef]
  )
}

export const useAtsStatuses = (): {
  value: JobSubmissionAtsStatusTypes
  label: string
  actions: string | null
}[] => {
  const { t } = useTranslation('main')

  const { jobStatuses } = useSubjectSelector(jobStatusStore, ['jobStatuses'])

  return useMemo(
    () =>
      jobStatuses.map((jobStatus) => {
        const { bullhornStatus } = jobStatus
        const statusType = JobSubmissionAtsStatusTypeMap[bullhornStatus]
        return {
          value: statusType,
          label: t(`candidatePreb.atsStatus.${statusType}`),
          actions: jobStatus.actions,
        }
      }),
    [jobStatuses, t]
  )
}
export const usePortalStatuses = (): {
  value: JobSubmissionPortalStatusTypes
  label: string
}[] => {
  const { t } = useTranslation('main')
  return useMemo(
    () =>
      Object.values(JobSubmissionPortalStatusTypes).map((current) => ({
        value: current,
        label: t(`candidatePreb.portalStatus.${current}`),
      })),
    [t]
  )
}

export const useTriggerActionPopup = (): ((
  record: Partial<ICandidate>,
  action: JobStatusActions,
  label: string
) => void) => {
  const updateAtsStatus = useUpdateAtsStatus()
  const { t } = useTranslation('main')
  const { addAlert } = useAlert()
  const subscriptionRef = useSubscriptionRef()

  return useCallback(
    (record, action, label) => {
      switch (action) {
        case JobStatusActions.RejectionPopup: {
          candidatesPrepStore.dispatch({
            isModalRejectedOpen: true,
            modalCandidateData: record,
            modalAtsStatus: label,
          })
          break
        }
        case JobStatusActions.ShortlistPopup: {
          if (record.atsUserId && record.jobSubmissionId) {
            updateAtsStatus(
              {
                atsUserId: record.atsUserId,
                jobSubmissionId: record.jobSubmissionId,
                additionalData: {
                  shortlistedDateTime: new Date(
                    new Date(Date.now()).toUTCString()
                  ).toISOString(),
                },
              },
              label,
              {
                complete: () => {
                  candidatesPrepStore.dispatch({
                    modalAtsStatus: label,
                    candidateStatus: label as JobSubmissionAtsStatusLabels,
                  })
                  addAlert({
                    severity: 'success',
                    message: t('common.alert.itemSaved'),
                  })
                },
                error: () => {
                  addAlert({
                    message: t('common.alert.somethingWrong'),
                    severity: 'error',
                  })
                },
                tap: (updatedCandidate) =>
                  candidatesPrepStore.dispatch({
                    modalCandidateData: {
                      ...record,
                      interviewRequest: updatedCandidate.interviewRequest,
                    },
                  }),
              }
            )
          }
          break
        }
        case JobStatusActions.OfferPopup: {
          if (record.jobSubmissionId) {
            subscriptionRef.current = submissionService
              .getJobSubmission(record.jobSubmissionId)
              .subscribe({
                next: (jobSubmission) => {
                  candidatesPrepStore.dispatch({
                    modalCandidateData: {
                      ...record,
                      offerExtendedNote: jobSubmission?.offerExtendedNote,
                    },
                    isModalExtendOfferOpen: true,
                    modalAtsStatus: label,
                  })
                },
                error: () => {
                  addAlert({
                    message: t('common.alert.somethingWrong'),
                    severity: 'error',
                  })
                },
              })
          }
          break
        }
        case JobStatusActions.InterviewPopup: {
          if (record.jobSubmissionId) {
            subscriptionRef.current = submissionService
              .getJobSubmission(record.jobSubmissionId)
              .subscribe({
                next: (jobSubmission) => {
                  candidatesPrepStore.dispatch({
                    modalCandidateData: {
                      ...record,
                      ...(jobSubmission.interviewRequestInfo.length
                        ? {
                            interviewRequest:
                              jobSubmission.interviewRequestInfo[0],
                          }
                        : {}),
                    },
                    isModalRequestInterviewOpen: true,
                    modalAtsStatus: label,
                  })
                },
                error: () => {
                  addAlert({
                    message: t('common.alert.somethingWrong'),
                    severity: 'error',
                  })
                },
              })
          }
          break
        }
        default: {
          break
        }
      }
    },
    [addAlert, t, updateAtsStatus, subscriptionRef]
  )
}
