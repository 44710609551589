import {
  ContractorPortalJobStatus,
  CurrencyTypes,
  DurationsEnum,
  EmploymentTypes,
  JobPositionStatusCodeEnum,
  JobStatus,
  PreferredLanguageType,
  RateType,
  RequirePreferenceCodeEnum,
} from '../../enums'
import { IBase } from '../base'
import { ICollaborator } from '../collaborators'

interface ISovrenJob {
  parsedJobOrder: string | null
  parsedOn: string | null
  indexId: string | null
  documentId: string | null
  indexedOn: string | null
}

interface ISkills {
  name: string
  isRequired?: boolean
  minimumYearsOfExperience: number
  maximumYearsOfExperience: number | null
  qualificationPreferenceCode: RequirePreferenceCodeEnum | ''
}

export interface IScreeningQuestions {
  responseType: string
  idealAnswer: string
  englishQuestion: string
  frenchQuestion: string
  isMandatory: boolean
  order: number
  answer?: string
  passed?: boolean
  isQuestionAIGenerated?: boolean
  beingTranslatedFrom?: string
}

export interface IJob extends IBase {
  vendorCode: string
  clientCode: string
  clientName: string
  clientRequisitionNumber: string | null
  atsType: string | null
  atsJobId: number
  title: string
  description: string
  draftJobDescription?: string
  billRate: number | null
  jobCompensationTypeCode: RateType | string | null
  currencyCode: CurrencyTypes | string | null
  markupPercentage: number | null
  permFeePercentage: number | null
  workLocation: string | null
  onsite: string | null
  workplaceTypeCodes: UserWorkplacePreference[] | null
  jobPositionStatusCode: JobPositionStatusCodeEnum | string | null
  employmentType: EmploymentTypes
  jobDuration: number | null
  jobDurationType: DurationsEnum | string
  startDate: string | null
  endDate: string | null
  dueDate: string | null
  closeDate: string | null
  datePosted: string
  dateAdded: string
  dateLastUpdated: string
  lastModifiedDateTime: string
  importedOnDateTime: string
  lastSyncedDateTime: string
  isPublic: boolean
  isOpen: boolean
  isViewed?: boolean
  noOfPositions: number | null
  hiringManagerName: string | null
  hiringManagerEmail: string | null
  accountManagerName: string
  accountManagerEmail: string
  resourceManagerName: string
  resourceManagerEmail: string
  recruiterName: string | null
  recruiterEmail: string | null
  atsJobStatus: string
  portalJobStatus: JobStatus
  contractorPortalJobStatus?: ContractorPortalJobStatus
  noOfSubmittedCandidates: number | null
  noOfShortlistedCandidates: number | null
  noOfInterviewCandidates: number | null
  noOfOfferCandidates: number | null
  noOfRejectedCandidates: number | null
  noOfActiveCandidates: number | null
  noOfCandidates: number | null
  noOfNewCandidates: number | null
  isHtmlDescription: boolean
  companyOverviewHtml: string | null
  jobFooterHtml: string | null
  isDisplayJobFooterEnabled: boolean
  education: string | null
  isDisplayEducationEnabled: boolean
  intakeNotesHtml: string | null
  isOptimizedForJobMatching: boolean
  jobCategoryCode: string | null
  jobFunctionCode: string | null
  lineOfBusiness: string | null
  lineOfBusinessId: number | null
  showJobInVendorHub: boolean
  isDirectSourcingJob: boolean
  addressLine1: string
  addressLine2: string | null
  city: string | null
  state: string | null
  stateCode: string | null
  country: string
  countryCode: string
  postalCode: string
  latitude: number | null
  longitude: number | null
  recruiterId: string | null
  isWorkFromHomeAllowed: boolean
  clientLogoUrl: string | null
  clientJobPageUrl: string | null
  isRedirectToClientSite: boolean
  isSavedJob: boolean
  isApplied: boolean
  isExpiryNotified: boolean
  lineOfBusinessCode: string | null
  companyOverview: string | null
  isDisplayCompanyOverview: boolean
  isDisplayJobFooter: boolean
  jobFooter: string | null
  canWorkFromHome: boolean
  sovrenJob: ISovrenJob | null
  weightedScore: number
  sovScore: number
  isJobRecommendationRated: true
  isCompanyOverviewEnabled: boolean
  skills: ISkills[] | null
  certifications: {
    name: string
    qualificationPreferenceCode: RequirePreferenceCodeEnum | ''
  }[]
  datePublishedOn: string | null
  collaborators: ICollaborator[]
  maxCompensation: number | null
  minCompensation: number | null
  sendbirdChannelUrl: string | null
  aiJobTuning: IAiJobTuning | null
  postingLanguage?: string
  screeningQuestions?: IScreeningQuestions[] | null
  isDisplayScreeningQuestionsEnabled: boolean
  isRejectNotificationEnabled: boolean
}

export interface IAiJobTuning {
  seniority: string | null
  creativityLevel: string | null
  tags: string[]
}

export interface IJobRecommendation {
  jobId: string
  sovScore: number
}

export type CandidatesValues = Pick<
  IJob,
  | 'noOfNewCandidates'
  | 'noOfShortlistedCandidates'
  | 'noOfInterviewCandidates'
  | 'noOfOfferCandidates'
  | 'noOfRejectedCandidates'
>

export interface IJobDetail extends IJob {}

export enum JobCreativityLevel {
  Creative = 'Creative',
  Balanced = 'Balanced',
  Precise = 'Precise',
}

export enum JobSeniorityLevel {
  Senior = 'Senior',
  Intermediate = 'Intermediate',
  Junior = 'Junior',
}

export interface IAiJobPromptPayload {
  title?: string | null
  seniority?: JobSeniorityLevel
  workplaceType?: UserWorkplacePreference | null
  language?: PreferredLanguageType
  tags?: string[]
  creativityLevel?: JobCreativityLevel
  clientSocketConnectionId?: string | null
  employmentType?: EmploymentTypes
  location?: string | null
  atsJobDescription?: string
  startDate?: string | null
  atsJobIntakeNotes?: string
  atsJobId?: string | null
  jobId?: string | null
  additionalSourceContent?: string
  templateId?: string | null
}

export interface IBhJobOrderInfo {
  atsJobId: number
  title: string
  description: string
  seniority: JobSeniorityLevel
  workplaceType: UserWorkplacePreference
  location: string
  startDate: string | null
  intakeNotes?: string
  notes?: string[]
  employmentType?: string | null
}

export enum UserWorkplacePreference {
  Onsite = 'Onsite',
  Hybrid = 'Hybrid',
  Remote = 'Remote',
}

export interface IEmploymentTypes {
  id: string
  code: EmploymentTypes
  displayValue: string
  displayValueInFrench: string
}

export interface IJobDetails {
  title?: string | null
  category?: string | null
  location?: string | null
  workplaceType?: UserWorkplacePreference | null
  clientName?: string | null
  employmentType?: EmploymentTypes
  atsJobDescription?: string | null
  atsJobIntakeNotes?: string
}
export interface IAiEmployerJobPromptPayload extends IAiJobPromptPayload {}

interface IInterviewQuestionsBase {
  jobId?: string
  behavioralQuestions: boolean
  technicalQuestions: boolean
  includeAnswers: boolean
  language: PreferredLanguageType
  atsUserId?: string
  notes?: string[] | null
}
export interface IInterviewQuestionsParams extends IInterviewQuestionsBase {
  atsJobId?: string
}

export interface IInterviewQuestionsPayload extends IInterviewQuestionsBase {
  clientSocketConnectionId: string | null
  atsJobId?: number
}

export interface IInterviewPreparationsFields {
  questionsTypes: {
    behavioural: boolean
    technical: boolean
  }
  includeAnswers: boolean
  language: string
}

export interface IInterviewMetadata extends IInterviewPreparationsFields {
  response: string
}

export interface IAIScreeningQuestions {
  jobTitle: string
  jobDescription: string
  existingQuestions?: string[]
}

export interface IAIScreeningQuestionsResponse {
  startingIndex: number
  response: string
}
