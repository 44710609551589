import React, { useCallback, useEffect, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { map, Observable, of } from 'rxjs'

import {
  AtsJobOrderFileParameter,
  DataObjectContent,
  IContractorProfileResume,
  IDocument,
  IEmailTemplate,
  IResume,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import {
  AtsResourcesFetcherReturn,
  ResourceFetchRequest,
  ResourceFetchScope,
} from '@submission-portal/hooks/resources-core-hooks'
import { submissionService } from '@submission-portal/services'
import { contractorProfileService } from '@submission-portal/services/contractor-profile.service'
import { serviceDescriptorStore } from '@submission-portal/stores'
import {
  AdditionalDocument,
  candidatesPrepStore,
  CandidatesPrepStore,
} from '@submission-portal/stores/candidates-prep-store'
import { jobStatusStore } from '@submission-portal/stores/job-status-store'
import { FileModes, IEmailForm } from '@submission-portal/types'
import { buildEmailObject } from '@submission-portal/utils'

export const useUploadResource: () => (
  formData: FormData,
  onUploadProgress: (event: ProgressEvent) => void
) => Observable<IResume> = () => {
  return useCallback(
    (formData: FormData, onUploadProgress: (event: ProgressEvent) => void) => {
      formData.append('ShouldParseResume', 'false')
      return contractorProfileService
        .uploadResume(formData, onUploadProgress)
        .pipe(map((data) => data.contractorResume))
    },
    []
  )
}
export const useUploadedResourceChange: (
  atsUserId: string,
  onFileChange: (
    fileProp: IContractorProfileResume | null,
    fileBlobProp: string
  ) => void
) => (file: IResume | null) => void = (atsUserId, onFileChange) => {
  const subscriptionRef = useSubscriptionRef()

  return useCallback(
    (file: IResume | null): void => {
      const fileStorageId = file?.fileStorageId
      if (fileStorageId) {
        if (subscriptionRef.current && !subscriptionRef.current.closed) {
          subscriptionRef.current.unsubscribe()
        }

        subscriptionRef.current = contractorProfileService
          .downloadResume(fileStorageId, atsUserId)
          .subscribe({
            next: (buffer) => {
              const blob = new Blob([buffer])
              const url = URL.createObjectURL(blob)
              onFileChange(file as IContractorProfileResume, url)
            },
          })
      } else {
        onFileChange(null, '')
      }
    },
    [atsUserId, subscriptionRef, onFileChange]
  )
}
export const useGetUserId = (): string => {
  const [searchParams] = useSearchParams()
  return useMemo(() => searchParams.get('UserID') ?? '', [searchParams])
}

export const useGetAtsJobId = (): string | null => {
  const [searchParams] = useSearchParams()
  return useMemo(() => searchParams.get('EntityID') ?? null, [searchParams])
}
export const useScopedFetchFile = (): ((
  args: ResourceFetchRequest
) => Observable<DataObjectContent>) => {
  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  const { candidate: submission } = useSubjectSelector(candidatesPrepStore, [
    'candidate',
  ])

  return useCallback(
    ({ fileId, convertToPdf, scope }) => {
      switch (scope) {
        case ResourceFetchScope.AtsJob:
          return candidateAtsService.getJobOrderFile(
            {
              entityId: submission?.atsJobId ?? 0,
              fileId: Number(fileId),
            },
            convertToPdf
          )
        case ResourceFetchScope.AtsCandidate:
        default:
          return candidateAtsService.getCandidateFile(
            {
              entityId: Number(submission?.candidate.atsUserId),
              fileId: Number(fileId),
            },
            convertToPdf
          )
      }
    },
    [candidateAtsService, submission?.atsJobId, submission?.candidate.atsUserId]
  )
}

interface FetchContractorProfileResourcesArgs {
  fetchResource: AtsResourcesFetcherReturn
  candidateStore: CandidatesPrepStore
}

type RequestMap = {
  [key: string]: 'resume' | 'coverPage'
}

type BuildRequestsResult = {
  requests: Array<{
    fileId: string
    scope: ResourceFetchScope
    convertToPdf: boolean
  }>
  orderMap: RequestMap
}

type ResponseType = {
  resume?: IResume | null
  coverPage?: IResume | null
  additionalDocuments?: IDocument[]
}

const buildRequestsAndMap = (
  candidateStore: CandidatesPrepStore
): BuildRequestsResult => {
  const {
    resumeMode,
    resumeFile,
    resumeSelection,
    coverPageMode,
    coverPageFile,
    coverPageSelection,
  } = candidateStore.getStateValue()

  const requests = []
  const orderMap: RequestMap = {}

  if (resumeMode === FileModes.Select && !resumeFile && resumeSelection) {
    requests.push({
      fileId: resumeSelection,
      scope: ResourceFetchScope.AtsCandidate,
      convertToPdf: true,
    })
    orderMap[requests.length - 1] = 'resume'
  }

  if (
    coverPageMode === FileModes.Select &&
    !coverPageFile &&
    coverPageSelection
  ) {
    requests.push({
      fileId: coverPageSelection,
      scope: ResourceFetchScope.AtsJob,
      convertToPdf: true,
    })
    orderMap[requests.length - 1] = 'coverPage'
  }

  return { requests, orderMap }
}

const formatAdditionalDocuments = (
  additionalDocuments: AdditionalDocument[]
): IDocument[] => {
  return additionalDocuments.reduce((previousValue, document) => {
    if (document.file) {
      return [
        ...previousValue,
        {
          ...document.file,
          uploadedOn: document.file.uploadedDatetime,
        } as IDocument,
      ]
    }

    return [...previousValue]
  }, [] as IDocument[])
}

const buildResponse = (
  results: Array<IResume>,
  orderMap: RequestMap,
  candidateStore: CandidatesPrepStore
): ResponseType => {
  const {
    resumeFile,
    resumeMode,
    coverPageFile,
    coverPageMode,
    additionalDocuments,
    candidate: submission,
  } = candidateStore.getStateValue()

  const currentResume = submission?.candidate?.resume
  const currentCoverPage = submission?.candidate?.coverPage

  const response: ResponseType = {}

  results.forEach((result, index) => {
    const key = orderMap[index]
    response[key] = result
  })

  if (resumeFile && resumeMode === FileModes.Upload) {
    response.resume = resumeFile
  }

  if (coverPageFile && coverPageMode === FileModes.Upload) {
    response.coverPage = coverPageFile
  }

  if (!response.resume) {
    response.resume = currentResume
  }

  if (!response.coverPage) {
    response.coverPage = currentCoverPage
  }

  if (additionalDocuments.length) {
    response.additionalDocuments =
      formatAdditionalDocuments(additionalDocuments)
  }

  return response
}

export const fetchContractorProfileResources = ({
  fetchResource,
  candidateStore,
}: FetchContractorProfileResourcesArgs): Observable<ResponseType> => {
  const { requests, orderMap } = buildRequestsAndMap(candidateStore)

  if (requests.length === 0) {
    const {
      resumeFile,
      coverPageFile,
      additionalDocuments,
      candidate: submission,
    } = candidateStore.getStateValue()
    const currentResume = submission?.candidate?.resume
    const currentCoverPage = submission?.candidate?.coverPage

    return of({
      resume: resumeFile ?? currentResume,
      coverPage: coverPageFile ?? currentCoverPage,
      additionalDocuments: formatAdditionalDocuments(additionalDocuments),
    })
  }

  return fetchResource(requests).pipe(
    map((results: Array<IResume>) =>
      buildResponse(results, orderMap, candidateStore)
    )
  )
}

export const useFetchSubmissionStatuses = (): void => {
  useEffect(() => {
    const subscription = submissionService.submissionStatuses().subscribe({
      next: (jobStatuses) => {
        jobStatusStore.dispatch({
          jobStatuses,
        })
      },
    })

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [])
}

export const useFetchJobAccountManagerName = (): void => {
  const [searchParams] = useSearchParams()
  const entityId = searchParams.get('EntityID')
  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])
  const subscriptionRef = useSubscriptionRef()

  useEffect(() => {
    if (entityId) {
      subscriptionRef.current = candidateAtsService
        .getJobOrder({
          atsJobId: parseInt(entityId, 10),
        } as AtsJobOrderFileParameter)
        .subscribe({
          next: (jobOrder) => {
            candidatesPrepStore.dispatch({
              accountManagerName: jobOrder.accountManagerName,
              jobTitle: jobOrder.title,
            })
          },
        })
    }
  }, [entityId, subscriptionRef, candidateAtsService])
}

export const useHandleSaveEmailContent = (
  formInstance: UseFormReturn<IEmailForm>,
  id: string | null,
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
  templateData?: IEmailTemplate[],
  multipleCandidates?: boolean
): (() => void) => {
  const { addAlert } = useAlert()
  const { t } = useTranslation('main')
  const saveEmailRef = useSubscriptionRef()
  return useCallback(() => {
    if (id && templateData) {
      const data = formInstance.getValues()
      setIsSaving(true)
      saveEmailRef.current = submissionService
        .updateSubmissionEmail(
          id,
          buildEmailObject(data, templateData),
          multipleCandidates
        )
        .subscribe({
          complete: () => {
            addAlert({
              message: t('submissionDetail.candidateDetails.alert.save'),
            })
          },
          error: () => {
            addAlert({
              severity: 'error',
              message: t('submissionDetail.candidateDetails.alert.failed'),
            })
          },
        })
      saveEmailRef.current.add(() => setIsSaving(false))
    }
  }, [
    addAlert,
    setIsSaving,
    t,
    saveEmailRef,
    id,
    formInstance,
    templateData,
    multipleCandidates,
  ])
}
