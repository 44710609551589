import React from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import {
  Grid,
  PopperPlacementType,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { DateRangeInput } from './date-range'
import { DatePickerSlotsComponentsProps } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'

// react-hook-form converts iso date string to date in case we use date validation. Thus, instead
// we validate against date string
export const IsoDateStringSchema = Yup.string()
  .nullable()
  .matches(
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    'Must be a valid ISO date string'
  )
const FieldTitle: React.FC = ({ children }: TypographyProps) => (
  <Typography variant="h6">{children}</Typography>
)

interface DateRangeControllerProps<T extends FieldValues> {
  control: Control<T>
  name: keyof T
  value: any
  label: string
  maxDate?: Date
  inputProps?: any
  titleProps?: TypographyProps
  slotProps?: DatePickerSlotsComponentsProps<DateTime>
  sx?: SxProps<Theme>
}

export const DateRangeValidationInput: React.FC<
  DateRangeControllerProps<any>
> = ({
  control,
  name,
  value,
  label,
  maxDate,
  inputProps,
  titleProps,
  slotProps,
  sx,
}) => {
  const { t } = useTranslation('main')
  const theme = useTheme()

  return (
    <>
      <Grid item xs={12}>
        <FieldTitle {...titleProps}>{label}</FieldTitle>
      </Grid>
      <Grid item xs={12} marginTop="10px">
        <Controller
          name={name as Path<any>}
          control={control}
          render={({ field: { onChange, onBlur, value: controlledValue } }) => (
            <DateRangeInput
              onChange={onChange}
              onBlur={onBlur}
              value={controlledValue || value}
              direction="column"
              hidePlaceholder
              maxDate={maxDate}
              label={{
                startDate: `${t(`common.inputs.from`)}`,
                endDate: `${t('common.inputs.to')}`,
              }}
              {...inputProps}
              slotProps={slotProps}
              sx={sx}
            />
          )}
        />
      </Grid>
    </>
  )
}
