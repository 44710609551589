import React, { FC, useCallback, useEffect, useState } from 'react'

import { useSubjectSelector } from '@procom-labs/common'
import { SearchAndFilterBar } from '@procom-labs/organisms'

import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

export const SubmissionDataSearchBar: FC<{
  handleChangeKeyword: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ handleChangeKeyword }) => {
  const [filterCount, setFilterCount] = useState(0)

  const { searchFilter, isSearchOpen } = useSubjectSelector(
    candidatesPrepStore,
    ['searchFilter', 'isSearchOpen']
  )

  const handleShowSearch = useCallback(() => {
    candidatesPrepStore.dispatch({
      isSearchOpen: !isSearchOpen,
    })
  }, [isSearchOpen])

  const handleOpenModal = useCallback(() => {
    candidatesPrepStore.dispatch({
      isFilterModalOpen: true,
    })
  }, [])

  useEffect(() => {
    const currCount = 0
    // if (Object.keys(searchFilter.dateAddedRange).length) currCount += 1
    // if (Object.keys(searchFilter.dateLastModifiedRange).length) currCount += 1
    setFilterCount(currCount)
  }, [searchFilter])

  return (
    <SearchAndFilterBar
      handleChangeKeyword={handleChangeKeyword}
      keyword={searchFilter.keyword}
      handleOpenModal={handleOpenModal}
      filterCount={filterCount}
      handleShowSearch={handleShowSearch}
    />
  )
}
