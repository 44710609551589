import { BaseSubjectStore } from '@procom-labs/common'

import {
  Candidate,
  DefaultSubmissionSearchFilter,
  SubmissionSearchFilter,
} from '@submission-portal/types'

export interface ICandidatePrepSelectStoreState {
  loading: boolean
  searchFilter: SubmissionSearchFilter
  count: number
  size: number
  candidates: Candidate[]
  isFilterModalOpen: boolean
}

export class CandidatePrepSelectStore extends BaseSubjectStore<ICandidatePrepSelectStoreState> {
  getDefaultValue(): ICandidatePrepSelectStoreState {
    return {
      loading: true,
      searchFilter: DefaultSubmissionSearchFilter,
      count: 0,
      size: 10,
      candidates: [],
      isFilterModalOpen: false,
    }
  }
}

export const candidatePrepSelectStore = new CandidatePrepSelectStore()
