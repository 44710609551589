import {
  BaseSubjectStore,
  CandidateAtsService,
  VendorCodes,
} from '@procom-labs/common'

import { environment } from '@submission-portal/environment'
import { axios } from '@submission-portal/lib'

export interface IServiceDescriptorStoreState {
  candidateAtsService: CandidateAtsService
}

export class ServiceDescriptorStore extends BaseSubjectStore<IServiceDescriptorStoreState> {
  getDefaultValue(): IServiceDescriptorStoreState {
    const searchParams = new URLSearchParams(window.location.search)
    const vendorCode =
      (searchParams.get('vendorCode') as VendorCodes) || VendorCodes.PCGL

    return {
      candidateAtsService: new CandidateAtsService({
        baseUrl: environment.JOB_API_URL,
        axios,
        apiKey: environment.PROCOM_VENDOR_API_KEYS[vendorCode],
      }),
    }
  }
}

export const serviceDescriptorStore = new ServiceDescriptorStore()
