import React, { FC, memo, useState } from 'react'
import { Controller } from 'react-hook-form'

import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { IconButton, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import { TypographyTypeMap } from '@mui/material/Typography/Typography'
import { GorillaStack } from '@procom-labs/atoms'

export const EditableLabel: FC<{
  name: string
  typographyVariant?: TypographyTypeMap['props']['variant']
  inputFontSize?: any
}> = memo(({ name, typographyVariant = 'subtitle1', inputFontSize }) => {
  const [isEditing, setIsEditing] = useState(false)
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <GorillaStack direction="row" alignItems="center" gap={1}>
          {isEditing ? (
            <TextField
              size="small"
              variant="standard"
              {...field}
              InputProps={{
                inputProps: {
                  maxLength: 30,
                },
                sx: {
                  '& input': {
                    fontSize: inputFontSize || 'inherit',
                    pb: 0,
                  },
                },
                endAdornment: (
                  <IconButton onClick={() => setIsEditing(false)}>
                    <CheckRoundedIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          ) : (
            <>
              <Typography variant={typographyVariant}>{field.value}</Typography>
              <IconButton onClick={() => setIsEditing(true)}>
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </GorillaStack>
      )}
    />
  )
})
