import React from 'react'
import { useObservable } from 'rxjs-hooks'

import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { clientService } from '@procom-labs/common'

export const BackdropLoader: React.FC = () => {
  const backDropLoading = useObservable(
    () => clientService.backDropLoading$,
    false
  )
  if (backDropLoading) {
    return (
      <Backdrop
        open
        sx={{
          color: 'primary.main',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return null
}
