import { JobSubmissionStatusTypes, VendorCodes } from '@procom-labs/common'

const mapSubmissionTypeAPLN: Record<string, JobSubmissionStatusTypes> = {
  [JobSubmissionStatusTypes.SubmittedToManager]:
    JobSubmissionStatusTypes.Pipeline,
}

export const atsStatusMapper = (
  vendorCode: VendorCodes,
  submissionStatus: JobSubmissionStatusTypes
) => {
  const reverseTypes = {} as Record<string, JobSubmissionStatusTypes>
  Object.keys(JobSubmissionStatusTypes).forEach((key) => {
    reverseTypes[
      JobSubmissionStatusTypes[key as keyof typeof JobSubmissionStatusTypes]
    ] = key as JobSubmissionStatusTypes
  })

  if (vendorCode === VendorCodes.APLN || vendorCode === VendorCodes.CMPSTF) {
    return mapSubmissionTypeAPLN[submissionStatus]
  }

  return JobSubmissionStatusTypes[
    reverseTypes[submissionStatus] as keyof typeof JobSubmissionStatusTypes
  ]
}
