import { FC, memo } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GorillaStack } from '@procom-labs/atoms'

import { EditableLabel } from './editable-label'
import { EditableSkills } from './editable-skills'
import { MinMaxField } from './min-max-field'

export const SkillsEdit: FC = memo(() => {
  const { t } = useTranslation('main')
  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))
  const { fields } = useFieldArray({
    name: 'skills.groups',
  })
  return (
    <GorillaStack
      p={isMobileOrTab ? 2 : 3}
      gap={2}
      sx={{ backgroundColor: 'onePager.boxBg', borderRadius: 2 }}
    >
      <MinMaxField
        title={t('organisms.candidateOnePager.skills')}
        switchName="visibility.Skills"
        minName="skills.minYears"
        maxName="skills.maxYears"
      />
      {fields.map((field, index) => (
        <GorillaStack gap={1} key={field.id}>
          <GorillaStack gap={1}>
            <EditableLabel name={`skills.groups.${index}.type`} />
            <EditableSkills
              name={`skills.groups.${index}.skills`}
              fieldName="name"
              removePrompt={t('organisms.candidateOnePager.skillsRemove')}
            />
          </GorillaStack>
        </GorillaStack>
      ))}
    </GorillaStack>
  )
})
