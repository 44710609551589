import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { finalize, map, Observable, of, tap } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import {
  IContractorProfileResume,
  IResume,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import { useUploadedResourceChange } from '@submission-portal/hooks'
import { submissionService } from '@submission-portal/services'
import { jobAiService } from '@submission-portal/services/job-ai.service'
import { submissionStore } from '@submission-portal/stores'
import {
  candidatesPrepStore,
  ICandidatesPrepStoreState,
} from '@submission-portal/stores/candidates-prep-store'
import { DefaultSubmissionSearchFilter } from '@submission-portal/types'

export const useNavigateToCandidateSubmissions = (): (() => void) => {
  const navigate = useNavigate()
  const location = useLocation()

  return useCallback(() => {
    const queryParams = location.search
    navigate(`/candidate-prep${queryParams}`)
  }, [location, navigate])
}

export const useNavigateToCandidateSubmission = (): ((
  submission: string
) => void) => {
  const navigate = useNavigate()
  const location = useLocation()

  return useCallback(
    (submission) => {
      const queryParams = location.search
      navigate(`/candidate-prep/${submission}${queryParams}`)
    },
    [location, navigate]
  )
}

export const useUploadResourceChange: (
  atsUserId: string,
  resumeFileProp: keyof ICandidatesPrepStoreState,
  resumeFileBlobProp: keyof ICandidatesPrepStoreState
) => (file: IResume | null) => void = (atsUserId, fileProp, fileBlobProp) => {
  const onFileChange = useCallback(
    (
      filePropValue: IContractorProfileResume | null,
      fileBlobPropValue: string
    ): void => {
      candidatesPrepStore.dispatch({
        [fileProp]: filePropValue,
        [fileBlobProp]: fileBlobPropValue,
      })
    },
    [fileProp, fileBlobProp]
  )

  return useUploadedResourceChange(atsUserId ?? '', onFileChange)
}

export const useUploadAdditionalDocumentsChange: (
  atsUserId: string
) => (file: IResume | null) => void = (atsUserId) => {
  const { additionalDocuments } = useSubjectSelector(candidatesPrepStore, [
    'additionalDocuments',
  ])

  const onFileChange = useCallback(
    (
      filePropValue: IContractorProfileResume | null,
      fileBlobPropValue: string
    ): void => {
      const updatedDocs = [...additionalDocuments]
      const index = additionalDocuments.length - 1
      if (filePropValue && fileBlobPropValue !== '') {
        updatedDocs[index] = {
          ...additionalDocuments[index],
          file: filePropValue,
          fileBlob: fileBlobPropValue,
        }
        candidatesPrepStore.dispatch({
          additionalDocuments: updatedDocs,
        })
      }
    },
    [additionalDocuments]
  )

  return useUploadedResourceChange(atsUserId ?? '', onFileChange)
}

export const useDeleteCandidatesPrepResource: (
  fileProp: keyof ICandidatesPrepStoreState,
  fileBlobProp: keyof ICandidatesPrepStoreState
) => (id: string) => Observable<any> = (resumeFileProp, resumeFileBlobProp) => {
  return useCallback(
    (id: string): Observable<any> => {
      candidatesPrepStore.dispatch({
        [resumeFileProp]: null,
        [resumeFileBlobProp]: '',
      })

      return of(id)
    },
    [resumeFileProp, resumeFileBlobProp]
  )
}

export const useDeleteAdditionalDocument: () => (
  id: string
) => Observable<any> = () => {
  const { additionalDocuments } = useSubjectSelector(candidatesPrepStore, [
    'additionalDocuments',
  ])

  return useCallback(
    (id: string): Observable<any> => {
      const updatedDocs = [...additionalDocuments]
      if (additionalDocuments.length === 1) {
        updatedDocs[0] = {
          file: null,
          fileBlob: '',
        }
      }

      candidatesPrepStore.dispatch({
        additionalDocuments: updatedDocs.filter(
          (doc) => doc.file?.fileStorageId !== id
        ),
      })

      return of(id)
    },
    [additionalDocuments]
  )
}

export const useIsCandidatePrepReadOnly = (): boolean => {
  const { processing } = useSubjectSelector(candidatesPrepStore, ['processing'])

  const isJobLoading = useObservable(() => jobAiService.isJobLoading$, false)
  return processing || isJobLoading
}
export const useFetchSubmissions = (): ((keyword: string) => void) => {
  const [searchParams] = useSearchParams()
  const jobId = searchParams.get('EntityID')

  return useCallback(
    (keyword: string) => {
      submissionStore.dispatch({
        loading: true,
      })

      submissionService
        .fetchSubmissions(jobId, {
          ...DefaultSubmissionSearchFilter,
          keyword,
        })
        .pipe(
          tap(({ submissions, count }) => {
            submissionStore.dispatch({
              submissions,
              count,
            })
          }),
          map((data) => data.submissions),
          finalize(() => {
            submissionStore.dispatch({
              loading: false,
            })
          })
        )
        .subscribe()
    },
    [jobId]
  )
}
export const usePrepForSubmission = (jobId: number): any => {
  const { addAlert } = useAlert()
  const { t } = useTranslation('main')

  const fetchSubmissions = useFetchSubmissions()

  const { searchFilter } = useSubjectSelector(candidatesPrepStore, [
    'searchFilter',
  ])

  const subscriptionRef = useSubscriptionRef()

  return useCallback(
    (atsCandidateId: number) => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
      }

      subscriptionRef.current = submissionService
        .selectForPrep(jobId, atsCandidateId)
        .subscribe({
          next: (): void => {
            // We re-fetch all submissions to ensure that candidates will be placed as expected
            // We can simply manipulate the table and add a new row for the new entry but this is
            // a way to ensure that the table will not ed up with out-of-sync entries
            fetchSubmissions(searchFilter.keyword)
          },
          error: () => {
            addAlert({
              message: t('common.alert.somethingWrong'),
              severity: 'error',
            })
          },
        })
    },
    [
      fetchSubmissions,
      t,
      searchFilter.keyword,
      subscriptionRef,
      addAlert,
      jobId,
    ]
  )
}
