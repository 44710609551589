import React, { useMemo } from 'react'

import { useDebouncedSubjectSelector } from '@procom-labs/common'
import { useDataPaginationProps } from '@procom-labs/molecules'

import {
  AtsJobSubmissionView,
  SubmissionDataFilterModal,
} from '@submission-portal/components'
import CandidatePrepHeader from '@submission-portal/components/candidate-prep/candidate-prep-header'
import { filterAndSortPrepCandidates } from '@submission-portal/components/candidate-prep/candidate-prep-utils'
import {
  useCandidatePrepDataColumns,
  useCandidatePrepDataRecords,
  useOnSortCandidatePrepModelChange,
} from '@submission-portal/components/candidate-prep/hooks/candidate-prep-data-hooks'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

import { CandidatesPrepDataFilterModal } from '../candidates-prep-data-filter-modal'

export const CandidatePrepCandidatePreview: React.FC = () => {
  const columns = useCandidatePrepDataColumns()

  const { searchFilter } = useDebouncedSubjectSelector(
    candidatesPrepStore,
    ['searchFilter'],
    300
  )

  const { rows, loading } = useCandidatePrepDataRecords(searchFilter)

  const paginationProps = useDataPaginationProps(
    candidatesPrepStore,
    searchFilter
  )

  const onSortModelChange = useOnSortCandidatePrepModelChange()

  const [filteredRows, filteredRowCount] = useMemo(() => {
    return filterAndSortPrepCandidates(rows, searchFilter)
  }, [searchFilter, rows])

  return (
    <>
      <AtsJobSubmissionView
        rows={filteredRows}
        rowCount={filteredRowCount}
        loading={loading}
        columns={columns}
        onSortModelChange={onSortModelChange}
        {...paginationProps}
      >
        <CandidatePrepHeader />
        <SubmissionDataFilterModal />
      </AtsJobSubmissionView>
      <CandidatesPrepDataFilterModal />
    </>
  )
}
