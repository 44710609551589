import { styled } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Grid,
  IconButton as MuiIconButton,
  ListItemIcon as MuiListItemIcon,
} from '@mui/material'

export const CandidateAvatar = styled(
  Avatar,
  {}
)(() => ({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '2rem',
  fontWeight: 700,
  height: 44,
  lineHeight: 1.33,
  width: 44,
}))

export const ContainerGrid = styled(
  Grid,
  {}
)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.primary}`,
  borderRadius: theme.spacing(2.5), // 20px
  padding: theme.spacing(5),
  position: 'relative',
  overflow: 'hidden',
}))

export const ScrollableBox = styled(
  Box,
  {}
)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
  },
}))

export const ListItemIcon = styled(
  MuiListItemIcon,
  {}
)(({ theme }) => ({
  color: theme.palette.info.dark,
  marginRight: '1rem',
  minWidth: 0,
}))

export const IconButton = styled(
  MuiIconButton,
  {}
)(({ theme }) => ({
  color: theme.palette.common.black,
}))
