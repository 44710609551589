import React, { useMemo } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material/styles'

type IconContainerProps = {
  active?: boolean
  completed?: boolean
}

const IconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'completed',
})<IconContainerProps>(({ active, completed }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  border: '3px solid #8A325F',
  backgroundColor: completed || active ? '#8A325F' : 'transparent',
}))

export const ProgressStepperContainer = styled(Stepper)({
  '& .MuiStepConnector-line': {
    top: 10,
  },
  '.Mui-active.MuiStepLabel-label, .Mui-completed.MuiStepLabel-label': {
    color: '#8A325F',
  },
  '& .MuiStepLabel-label': {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '& .MuiStepLabel-label:hover': {
    cursor: 'pointer',
  },
})

const StepIcon: React.FC<{
  active: boolean
  completed: boolean
}> = ({ active, completed }) => {
  return <IconContainer active={active} completed={completed} />
}

export interface ProgressStepperProps extends BoxProps {
  steps: string[]
  activeStep: number
  setActiveStep: (step: number) => void
  stepperProps?: React.ComponentProps<typeof Stepper>
}

export const ProgressStepper: React.FC<ProgressStepperProps> = ({
  steps,
  activeStep,
  setActiveStep,
  stepperProps,
  ...boxProps
}) => {
  const renderedSteps = useMemo(
    () =>
      steps.map((label, index) => (
        <Step key={label} onClick={() => setActiveStep(index)}>
          <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
        </Step>
      )),
    [steps, setActiveStep]
  )

  return (
    <Box sx={{ width: '100%' }} {...boxProps}>
      <ProgressStepperContainer
        activeStep={activeStep}
        alternativeLabel
        {...stepperProps}
      >
        {renderedSteps}
      </ProgressStepperContainer>
    </Box>
  )
}
