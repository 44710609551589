import React, { FC, memo, useCallback, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import { Box, IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import { GorillaStack } from '@procom-labs/atoms'
import { TextHookField } from '@procom-labs/molecules'

import { RemoveDialog } from '../dialogs/remove-dialog'

export const EditableSkills: FC<{
  name: string
  removePrompt: string
  emptyAdd?: unknown
  hideYears?: boolean
  fieldName?: string
}> = memo(({ name, removePrompt, emptyAdd, hideYears = false, fieldName }) => {
  const { t } = useTranslation('main')
  const { fields, append, remove } = useFieldArray({ name })

  const [openDelete, setOpenDelete] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()

  const handleCloseDialog = useCallback(() => {
    setOpenDelete(false)
    setSelectedIndex(undefined)
  }, [])

  const handleOpenDialog = useCallback((index: number) => {
    setSelectedIndex(index)
    setOpenDelete(true)
  }, [])

  const handleDelete = useCallback(() => {
    if (selectedIndex !== undefined) {
      remove(selectedIndex)
      handleCloseDialog()
    }
  }, [handleCloseDialog, remove, selectedIndex])

  const handleAdd = useCallback(
    () =>
      append(
        emptyAdd ?? {
          name: '',
          yearsOfExperience: 0,
        }
      ),
    [append, emptyAdd]
  )

  return (
    <>
      {fields.map((field, index) => {
        const fieldNamePart = fieldName ? `.${fieldName}` : ''
        const fullName = `${name}.${index}${fieldNamePart}`
        return (
          <GorillaStack
            gap={1}
            direction="row"
            alignItems="center"
            key={field.id}
          >
            <Box sx={{ flex: 2 }}>
              <TextHookField
                size="small"
                name={fullName}
                InputProps={{
                  inputProps: {
                    sx: { py: '6.5px' },
                  },
                }}
                variant="outlined"
              />
            </Box>
            {!hideYears && (
              <Box sx={{ flex: 1 }}>
                <TextHookField
                  type="number"
                  size="small"
                  defaultValue={0}
                  InputProps={{
                    inputProps: {
                      className: 'counter',
                      min: 0,
                      sx: { py: '6.5px', pr: '14px' },
                    },
                    endAdornment: t('organisms.candidateOnePager.years'),
                  }}
                  variant="outlined"
                  name={`${name}.${index}.yearsOfExperience`}
                  allowOnlyDigits={false}
                  maxDecimalPlaces={1}
                />
              </Box>
            )}

            <IconButton
              sx={{ p: '6px' }}
              onClick={() => handleOpenDialog(index)}
            >
              <DeleteOutlineRoundedIcon />
            </IconButton>
          </GorillaStack>
        )
      })}
      <Box>
        <Button
          onClick={handleAdd}
          size="small"
          startIcon={<AddRoundedIcon />}
          sx={{ px: 2, textTransform: 'capitalize' }}
        >
          {t('common.btn.add')}
        </Button>
      </Box>
      <RemoveDialog
        open={openDelete}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
        title={removePrompt}
        cancelId="btn-cancel-delete-skill"
        deleteId="cta-delete-skill"
      />
    </>
  )
})
