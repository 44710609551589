import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { debounceTime, Subscription } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PersonIcon from '@mui/icons-material/Person'
import {
  alpha,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { useTheme } from '@mui/material/styles'
import { Preloader } from '@procom-labs/atoms'
import {
  CandidateOnePagerStateEnum,
  candidateOnePagerStore,
  errorToString,
  FeatureFlagNames,
  getInitials,
  GorillaResumeParsingStatus,
  ICartCandidate,
  useIsFeatureFlagEnabled,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import { SubmissionOnePager } from '@submission-portal/features/submission-detail/components'
import { SubmissionUpdateForm } from '@submission-portal/features/submission-detail/components/submission-update/submission-update-form'
import { usePrompt } from '@submission-portal/hooks'
import { rollbarInstance } from '@submission-portal/providers'
import {
  candidateOnePagerService,
  jobAiService,
  submissionService,
} from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores'

enum Steps {
  CandidateDetails = 0,
  OnePager = 1,
}
export const CandidateCartPrepForm: FC<{
  candidate: ICartCandidate
  nextHandler: () => void
  backHandler: () => void
  handleBackToList: () => void
  enableBack: boolean
  handleScrollToTop: () => void
}> = ({
  candidate,
  nextHandler,
  backHandler,
  enableBack,
  handleBackToList,
  handleScrollToTop,
}) => {
  const { t } = useTranslation('main')
  const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
    FeatureFlagNames.ResumeOnePager
  )
  const { pendingOnePagers, finishedOnePagers } = useSubjectSelector(
    submissionStore,
    ['pendingOnePagers', 'finishedOnePagers']
  )

  const { onePager } = useSubjectSelector(candidateOnePagerStore, ['onePager'])
  const onePagerSubscriptionRef = useSubscriptionRef()
  const nextOrSubmitRef = useSubscriptionRef()

  const [currentStep, setCurrentStep] = useState<Steps>(Steps.CandidateDetails)
  const [isLoading, setIsLoading] = useState(true)
  const [canNavigate, setCanNavigate] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { addAlert } = useAlert()

  const theme = useTheme()
  const emailSubmissionFormRef = useRef<any>()
  const generateCandidateStreamRef = useSubscriptionRef()

  const submission = useObservable(() => submissionService.currentSubmission$)
  const submissionUpdateFormProps = useObservable(() =>
    submissionService.submissionUpdateFormPropSubject.observable$.pipe(
      debounceTime(300)
    )
  )
  usePrompt(
    t('submissionDetail.candidateDetails.alert.leave'),
    canNavigate ? false : submissionUpdateFormProps?.dirty || false
  )

  const getOnePager = useCallback(
    (onePagerId: string) => {
      onePagerSubscriptionRef.current = candidateOnePagerService
        .getCandidateOnePager(onePagerId)
        .subscribe({
          next: (op) => {
            if (op.status === GorillaResumeParsingStatus.Complete) {
              candidateOnePagerStore.dispatch({
                onePager: op,
                state: CandidateOnePagerStateEnum.DONE,
                progress: op.progressMessage,
                taskId: op.parsedResumeId,
              })
            } else {
              candidateOnePagerStore.dispatch({
                onePager: op,
                state: CandidateOnePagerStateEnum.PARSING,
              })
            }
          },
        })
    },
    [onePagerSubscriptionRef]
  )

  const addOnePagertoPending = useCallback(
    (taskId: string) => {
      if (submission?.id) {
        const foundIndex = pendingOnePagers.findIndex(
          (p) => p.jobSubmissionId === submission.id
        )

        const pending = [...pendingOnePagers]

        if (foundIndex > -1) {
          pending[foundIndex] = {
            taskId,
            jobSubmissionId: submission.id,
            progress: '',
          }
        } else {
          pending.push({
            taskId,
            jobSubmissionId: submission.id,
            progress: '',
          })
        }

        submissionStore.dispatch({
          pendingOnePagers: pending,
        })
      }
    },
    [pendingOnePagers, submission?.id]
  )

  const handleNextStepNavigation = useCallback(
    (values) => {
      setCanNavigate(true)
      setCurrentStep(Steps.OnePager)
      if (values.isOnePagerEnabled && IS_ONE_PAGER_ENABLED) {
        handleScrollToTop()
        setCurrentStep(Steps.OnePager)
      } else {
        nextHandler()
      }
    },
    [IS_ONE_PAGER_ENABLED, handleScrollToTop, nextHandler]
  )

  const next = useCallback((): void => {
    if (submissionUpdateFormProps) {
      const { isValid, dirty, values, submitForm, setSubmitting } =
        submissionUpdateFormProps

      const hasResume = !!submission?.candidate.resume?.fileStorageId

      if (isValid && hasResume && values) {
        if (dirty) {
          setSubmitting?.(true)
          setCanNavigate(false)

          const savedTemplate =
            submission?.candidate.submissionEmailTemplate?.emailTemplates.find(
              (template) => template.language === values.submissionLanguage
            )

          nextOrSubmitRef.current = submissionService
            .updateSubmission(values, !!savedTemplate)
            .subscribe({
              complete: () => {
                setSubmitting?.(false)
                setCanNavigate(true)
                addAlert({
                  message: t('submissionDetail.candidateDetails.alert.save'),
                })

                if (values.isOnePagerEnabled && IS_ONE_PAGER_ENABLED) {
                  handleScrollToTop()
                  setCurrentStep(Steps.OnePager)
                } else {
                  nextHandler()
                }
              },
              error: () => {
                setSubmitting?.(false)
                addAlert({
                  severity: 'error',
                  message: t('submissionDetail.candidateDetails.alert.failed'),
                })
              },
            })
        } else {
          handleNextStepNavigation(values)
        }
      } else if (submitForm && setSubmitting) {
        submitForm()
        setSubmitting(false)
        rollbarInstance.error('Please complete missing fields.')
        addAlert({
          severity: 'error',
          message: t('submissionDetail.candidateDetails.alert.missingField'),
        })
      }
    }
  }, [
    nextHandler,
    nextOrSubmitRef,
    IS_ONE_PAGER_ENABLED,
    handleScrollToTop,
    handleNextStepNavigation,
    addAlert,
    submissionUpdateFormProps,
    submission?.candidate.submissionEmailTemplate?.emailTemplates,
    submission?.candidate.resume?.fileStorageId,
    t,
  ])

  useEffect(() => {
    return () => {
      candidateOnePagerStore.reset()
    }
  }, [])

  useEffect(() => {
    generateCandidateStreamRef.current = jobAiService
      .getCandidateHighlightsStream()
      .subscribe()
  }, [generateCandidateStreamRef])

  useEffect(() => {
    const subscription = new Subscription()
    if (candidate.atsJobSubmissionId) {
      setIsLoading(true)
      subscription.add(
        submissionService
          .getSubmissionDetail(+candidate.atsJobSubmissionId)
          .subscribe({
            next: () => setIsLoading(false),
            error: (err) => {
              setError(errorToString(err))
              setIsLoading(false)
            },
          })
      )
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [candidate.atsJobSubmissionId])

  useEffect(() => {
    if (onePager && onePager?.status !== GorillaResumeParsingStatus.Complete) {
      // If one pager is not complete, look for it in the onePagersProgress/pendingOnePagers array and use the data to show the progress
      const found = pendingOnePagers.find(
        (pending) => pending.taskId === onePager.parsedResumeId
      )

      if (found) {
        candidateOnePagerStore.dispatch({
          state: CandidateOnePagerStateEnum.PARSING,
          progress: found.progress,
          taskId: found.taskId,
        })
      }
    }
  }, [onePager, pendingOnePagers])

  useEffect(() => {
    const onePagerId = submission?.candidate?.resume?.onePagerId
    if (onePagerId) {
      // If the one pager is in the finishedOnePagers array, update the candidateOnePagerStore
      const foundFinished = finishedOnePagers.find(
        (finished) => finished.onePager?.id === onePagerId
      )

      if (foundFinished) {
        candidateOnePagerStore.dispatch({
          state: foundFinished.state,
          onePager: foundFinished.onePager,
          taskId: foundFinished.taskId,
          progress: foundFinished.progress,
          error: foundFinished.error,
        })
      } else {
        // If one pager is not in the finishedOnePagers array, get the one pager and update the candidateOnePagerStore
        getOnePager(onePagerId)
      }
    } else {
      const foundFinished = finishedOnePagers.find(
        (finished) => finished.jobSubmissionId === submission?.id
      )
      if (foundFinished) {
        candidateOnePagerStore.dispatch({
          state: foundFinished.state,
          onePager: foundFinished.onePager,
          taskId: foundFinished.taskId,
          progress: foundFinished.progress,
          error: foundFinished.error,
        })
      }
    }
  }, [
    finishedOnePagers,
    submission?.candidate?.resume?.onePagerId,
    getOnePager,
    submission?.id,
  ])

  useEffect(() => {
    if (candidate) {
      setCurrentStep(Steps.CandidateDetails)
    }
  }, [candidate])

  useEffect(() => {
    candidateOnePagerStore.dispatch({
      avatarColor: candidate.backgroundColor
        ? candidate.backgroundColor
        : theme.palette.common.black,
    })
  }, [candidate.backgroundColor, theme.palette.common.black])

  if (isLoading) {
    return <Preloader center />
  }

  if (!submission)
    return (
      <div className="center-screen">
        <Typography paragraph variant="h3">
          {t('error.notFound')}
        </Typography>
        {error && (
          <Typography variant="h5" paragraph>
            {error}
          </Typography>
        )}
      </div>
    )

  return (
    <Box>
      <Grid container flexWrap="nowrap">
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: 'fit-content',
            my: 'auto',
            pr: 2,
          }}
        >
          <Tooltip
            title={t('submissionDetail.candidateDetails.tooltip.backToList')}
          >
            <IconButton onClick={handleBackToList}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          {currentStep === Steps.OnePager && (
            <Typography
              sx={{ color: 'text.secondary' }}
              variant="h5"
              fontWeight={700}
            >
              {t('submissionDetail.candidateOnePager.title')}
            </Typography>
          )}
        </Grid>

        {currentStep === Steps.CandidateDetails && (
          <Grid
            item
            container
            gap={2}
            key={submission.atsJobSubmissionId}
            sx={{
              p: '10px 18px',
              borderRadius: '4px',
              backgroundColor: alpha(theme.palette.info.light, 0.2),
            }}
          >
            <Grid
              item
              xs={5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: 'fit-content',
              }}
            >
              <Avatar
                src=""
                alt={`${submission.candidate.firstName}-${submission.candidate.lastName}`}
                sx={{
                  backgroundColor: candidate.backgroundColor
                    ? `#${candidate.backgroundColor}`
                    : `${theme.palette.common.black}`,
                  width: 44,
                  height: 44,
                  fontWeight: 400,
                  fontSize: 20,
                  lineHeight: 20,
                  color: (innerTheme) => innerTheme.palette.common.white,
                }}
              >
                {getInitials(
                  `${candidate.firstName} ${candidate.lastName}`,
                  2
                ) || <PersonIcon sx={{ width: 35, height: 35 }} />}
              </Avatar>
              <Typography sx={{ ml: '12px' }}>
                {`${candidate.firstName} ${candidate.lastName}`}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: 'fit-content',
              }}
            >
              <EmailOutlinedIcon fontSize="small" sx={{ mr: 1, mt: '2px' }} />
              <Typography>{candidate.email}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      {currentStep === Steps.CandidateDetails && (
        <Grid
          container
          item
          xs={12}
          md={8}
          lg={6}
          direction="column"
          sx={{ marginX: 3 }}
        >
          <SubmissionUpdateForm
            submission={submission}
            nextHandler={next}
            ref={emailSubmissionFormRef}
            backHandler={enableBack ? backHandler : undefined}
            isCartSubmission
            addOnePagertoCartPending={addOnePagertoPending}
          />
        </Grid>
      )}
      {currentStep === Steps.OnePager && (
        <SubmissionOnePager
          submission={submission}
          backHandler={() => {
            handleScrollToTop()
            setCurrentStep(Steps.CandidateDetails)
          }}
          nextHandler={nextHandler}
          addOnePagertoCartPending={addOnePagertoPending}
        />
      )}
    </Box>
  )
}
