import React, { FC, memo, useCallback, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import {
  Box,
  FormControl,
  IconButton,
  OutlinedInput,
  SvgIconTypeMap,
  useMediaQuery,
} from '@mui/material'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { GorillaStack } from '@procom-labs/atoms'
import { IOnePagerForm } from '@procom-labs/common'
import { DatePickerHookField, TextHookField } from '@procom-labs/molecules'

import { SectionTitle } from '../common'
import { RemoveDialog } from '../dialogs/remove-dialog'

const CustomTextField: FC<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  return (
    <Controller
      name={name}
      render={({ field: inputField }) => (
        <FormControl sx={{ flex: 1 }}>
          <InputLabel
            sx={{
              position: 'relative',
              transform: 'none',
              mb: '2px',
            }}
            variant="outlined"
            htmlFor={inputField.name}
          >
            <Typography variant="body2">{label}</Typography>
          </InputLabel>
          <OutlinedInput
            id={inputField.name}
            {...inputField}
            size="small"
            inputProps={{ sx: { py: '6.5px' } }}
          />
        </FormControl>
      )}
    />
  )
}

type WorkExperienceKeys = keyof IOnePagerForm['workExperience'][number]
type EducationKeys = keyof IOnePagerForm['education'][number]

type FieldKey = WorkExperienceKeys | EducationKeys
export const MultiField: FC<{
  name: string
  removePrompt: string
  fieldKeys: {
    key: FieldKey
    label: string
  }[]
  title: string
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string }
}> = memo(({ name, removePrompt, fieldKeys, title, icon: IconComponent }) => {
  const { t } = useTranslation('main')
  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))

  const [openDelete, setOpenDelete] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()

  const { fields, remove, append } = useFieldArray({
    name,
  })

  const handleCloseDialog = useCallback(() => {
    setOpenDelete(false)
    setSelectedIndex(undefined)
  }, [])

  const handleOpenDialog = useCallback((index: number) => {
    setSelectedIndex(index)
    setOpenDelete(true)
  }, [])

  const handleAddExperience = useCallback(
    () =>
      // Add a new object to the form field array with empty values
      // Use the same keys as the fieldKeys
      append({
        [fieldKeys[0].key]: '',
        [fieldKeys[1].key]: '',
        [fieldKeys[2].key]: '',
        [fieldKeys[3].key]: '',
      }),
    [append, fieldKeys]
  )

  const handleDeleteExperience = useCallback(() => {
    if (selectedIndex !== undefined) {
      remove(selectedIndex)
      handleCloseDialog()
    }
  }, [remove, selectedIndex, handleCloseDialog])

  return (
    <>
      <GorillaStack gap={3}>
        <SectionTitle
          icon={
            <IconComponent
              sx={{
                color: theme.palette.secondary.main,
                fontSize: '18px',
              }}
            />
          }
          title={title}
        />
        {fields.map((field, index) => (
          <GorillaStack gap={2} key={field.id}>
            <GorillaStack direction={isMobileOrTab ? 'column' : 'row'} gap={2}>
              <GorillaStack gap={1} direction="row" alignItems="end" flex={1}>
                <DatePickerHookField
                  views={['year', 'month']}
                  format="MM/yyyy"
                  name={`${name}.${index}.${fieldKeys[0].key}`}
                  label="From"
                  customLabelProps={{
                    sx: { fontSize: theme.typography.body2.fontSize },
                  }}
                  formatter={(value) => value.toFormat('yyyy-MM')}
                  textFieldProps={{
                    size: 'small',
                    variant: 'outlined',
                    InputProps: {
                      sx: { minWidth: '138px' },
                      inputProps: {
                        sx: { py: '6.5px' },
                        placeholder: 'MM/YYYY',
                      },
                    },
                  }}
                />
                <DatePickerHookField
                  views={['year', 'month']}
                  format="MM/yyyy"
                  name={`${name}.${index}.${fieldKeys[1].key}`}
                  label="To"
                  customLabelProps={{
                    sx: { fontSize: theme.typography.body2.fontSize },
                  }}
                  formatter={(value) => value.toFormat('yyyy-MM')}
                  textFieldProps={{
                    size: 'small',
                    variant: 'outlined',
                    InputProps: {
                      sx: { minWidth: '138px' },
                      inputProps: {
                        sx: { py: '6.5px' },
                        placeholder: 'MM/YYYY',
                      },
                    },
                  }}
                />
              </GorillaStack>

              <CustomTextField
                name={`${name}.${index}.${fieldKeys[2].key}`}
                label={fieldKeys[2].label}
              />
              <CustomTextField
                name={`${name}.${index}.${fieldKeys[3].key}`}
                label={fieldKeys[3].label}
              />

              {!isMobileOrTab && (
                <Box mt="auto">
                  <IconButton
                    sx={{ p: '6px' }}
                    onClick={() => handleOpenDialog(index)}
                  >
                    <DeleteOutlineRoundedIcon />
                  </IconButton>
                </Box>
              )}
            </GorillaStack>
            <GorillaStack gap={1}>
              <GorillaStack direction="row" justifyContent="space-between">
                <Typography variant="body2">{fieldKeys[4].label}</Typography>
              </GorillaStack>
              <TextHookField
                fullWidth
                name={`${name}.${index}.${fieldKeys[4].key}`}
                rows={5}
                multiline
              />
            </GorillaStack>
            {isMobileOrTab && (
              <Box mx="auto">
                <IconButton
                  sx={{ p: '6px' }}
                  onClick={() => handleOpenDialog(index)}
                >
                  <DeleteOutlineRoundedIcon />
                </IconButton>
              </Box>
            )}
          </GorillaStack>
        ))}

        <Box sx={{ mr: 'auto' }}>
          <Button
            onClick={handleAddExperience}
            size="small"
            startIcon={<AddRoundedIcon />}
            sx={{ px: 2, textTransform: 'capitalize' }}
          >
            {t('common.btn.add')}
          </Button>
        </Box>
      </GorillaStack>
      <RemoveDialog
        open={openDelete}
        onClose={handleCloseDialog}
        onDelete={handleDeleteExperience}
        title={removePrompt}
        cancelId="btn-cancel-delete"
        deleteId="cta-delete"
      />
    </>
  )
})
