import { DateTime } from 'luxon'
import { TFuncKey } from 'react-i18next'

import { CandidateCardsStatusList } from '../config'
import { CANDIDATE_DATE_FORMATS, DateValueFormat } from '../enums'
import { ICandidate } from '../models'
import { formatDates } from './format-dates'

export const useStatusDate = (candidate: ICandidate): string => {
  const dateProperty: string =
    CandidateCardsStatusList.find(
      (card) => card.submissionStatus === candidate.jobSubmissionStatus
    )?.date || ''
  if (!dateProperty) return ''
  return formatDates(
    DateValueFormat.InThePast,
    candidate[dateProperty as keyof typeof candidate] as string
  )
}

export const detectDateFormat = (input: string) =>
  CANDIDATE_DATE_FORMATS.find((format) => {
    const parsedDate = DateTime.fromFormat(input, format)
    return parsedDate.isValid
  }) ?? null

export const getRatePeriodLabelKey = (
  ratePeriodType: string | null
): TFuncKey => {
  return ratePeriodType
    ? (`common.compensation.${ratePeriodType.toLowerCase()}` as TFuncKey)
    : ('common.compensation.hour' as TFuncKey)
}
