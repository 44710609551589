import { FC } from 'react'
import { ThemeProvider } from '@mui/material'
import { AppTypes, themeService } from '@procom-labs/common'
import { EventsTracking } from '@submission-portal/components/event-tracking/events-tracking'

export const EventTracking: FC = () => {
  // gorilla theme is not set for submission portal so using the default Procom theme for this page
  const theme = themeService.initializeBaseTheme(null, AppTypes.submission)
  return (
    <ThemeProvider theme={theme}>
      <EventsTracking />
    </ThemeProvider>
  )
}
