import { ICandidateCards } from '@common/models'

import { CandidateStatusType } from '../enums'

export const CandidateCardsStatusList: Array<ICandidateCards> = [
  {
    submissionStatus: CandidateStatusType.ClientSubmission,
    transKey: 'common.candidates.funnels.new',
    status: {
      transKey: 'common.candidates.status.submitted',
    },
    icon: 'AssignmentIndIcon',
    date: 'dateSubmitted',
    totalCandidateKey: 'noOfNewCandidates',
    cards: [],
    colorScheme: 'new',
  },
  {
    submissionStatus: CandidateStatusType.Shortlist,
    transKey: 'common.candidates.funnels.shortlist',
    status: {
      transKey: 'common.candidates.status.shortlisted',
    },
    icon: 'RecentActors',
    date: 'shortlistedDateTime',
    totalCandidateKey: 'noOfShortlistedCandidates',
    cards: [],
    colorScheme: 'shortlist',
  },
  {
    submissionStatus: CandidateStatusType.Interview,
    transKey: 'common.candidates.funnels.interview',
    status: {
      transKey: 'common.candidates.status.interviewRequested',
    },
    icon: 'DateRange',
    date: 'interviewRequestedDateTime',
    totalCandidateKey: 'noOfInterviewCandidates',
    cards: [],
    colorScheme: 'interview',
  },
  {
    submissionStatus: CandidateStatusType.Offer,
    transKey: 'common.candidates.funnels.offer',
    status: {
      transKey: 'common.candidates.status.offerExtended',
    },
    icon: 'InsertEmoticonIcon',
    date: 'offerExtendedDateTime',
    totalCandidateKey: 'noOfOfferCandidates',
    cards: [],
    colorScheme: 'offer',
  },
  {
    submissionStatus: CandidateStatusType.Rejected,
    transKey: 'common.candidates.funnels.rejected',
    status: {
      transKey: 'common.candidates.status.rejected',
    },
    icon: 'PersonOffIcon',
    date: 'rejectionDateTime',
    totalCandidateKey: 'noOfRejectedCandidates',
    cards: [],
    colorScheme: 'rejected',
  },
]
