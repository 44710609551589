import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, styled } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import {
  useDebouncedSubjectSelector,
  useSubjectSelector,
} from '@procom-labs/common'
import { SearchBarWrapper } from '@procom-labs/molecules'
import { SearchBar } from '@procom-labs/organisms'

import { BullhornCandidateForm } from '@submission-portal/components/candidate-prep/bullhorn-candidate-prep/bullhorn-candidate-form'
import { CandidateInfoButton } from '@submission-portal/components/candidate-prep/candidate-info-button'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

import { SubmissionDataSearchBar } from './submission-data-search-bar'

interface CandidatePrepHeaderProps extends BoxProps {}

const StyledOuterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const StyledInnerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

const CandidatePrepHeader: React.FC<CandidatePrepHeaderProps> = ({
  ...rest
}) => {
  const { t } = useTranslation('main')

  const { isSearchOpen, isAddingCandidate } = useSubjectSelector(
    candidatesPrepStore,
    ['isSearchOpen', 'isAddingCandidate']
  )

  // Use debounce to apply delay to the search query
  const { searchFilter } = useDebouncedSubjectSelector(
    candidatesPrepStore,
    ['searchFilter'],
    300
  )

  const handleChangeKeyword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      candidatesPrepStore.dispatch({
        searchFilter: {
          ...searchFilter,
          keyword: e.target.value,
        },
      })
    },
    [searchFilter]
  )

  const handleCloseModal = useCallback(
    () =>
      candidatesPrepStore.dispatch({
        isAddingCandidate: false,
      }),
    []
  )

  return (
    <StyledOuterBox {...rest}>
      <StyledInnerBox>
        <CandidateInfoButton />
      </StyledInnerBox>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <SubmissionDataSearchBar handleChangeKeyword={handleChangeKeyword} />

        <SearchBarWrapper searchOpen={isSearchOpen}>
          <SearchBar
            handleChangeKeyword={handleChangeKeyword}
            keyword={searchFilter?.keyword}
          />
        </SearchBarWrapper>
      </Box>

      <Dialog
        open={isAddingCandidate}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            height: '80%',
            backgroundColor: '#fff',
          },
        }}
      >
        <BullhornCandidateForm
          hideAlert
          searchBarLabel={t('candidatePreb.searchCandidatePlaceholderNameOnly')}
        />
      </Dialog>
    </StyledOuterBox>
  )
}

export default CandidatePrepHeader
