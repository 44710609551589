import React, { ReactNode, useMemo } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

interface ConfirmationPreviewDialogProps
  extends Omit<DialogProps, 'open' | 'onClose'> {
  isOpen: boolean
  title: string
  subtitle?: string
  onCancel: () => void
  onSubmit: () => void
  cancelLabel?: string
  submitLabel?: string
  additionalActions?: ReactNode
}

export const ConfirmationPreviewDialog: React.FC<
  ConfirmationPreviewDialogProps
> = ({
  isOpen,
  title,
  subtitle,
  children,
  onCancel,
  onSubmit,
  cancelLabel,
  submitLabel,
  additionalActions,
  ...dialogProps
}) => {
  const theme = useTheme()
  const { t } = useTranslation('main')

  const finalCancelLabel = useMemo(
    () => cancelLabel || t('common.btn.cancel'),
    [cancelLabel, t]
  )
  const finalSubmitLabel = useMemo(
    () => submitLabel || t('common.btn.submit'),
    [submitLabel, t]
  )

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      sx={{ '& .MuiPaper-root': { backgroundColor: '#fff' } }}
      {...dialogProps}
    >
      <DialogTitle
        id="confirmation-preview-dialog-title"
        sx={{ paddingBottom: 0 }}
      >
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body2" align="left">
            {subtitle}
          </Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ '&&': { paddingTop: theme.spacing(3.75) } }}>
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: additionalActions ? 'space-between' : 'flex-end',
          marginTop: '8px',
        }}
      >
        {additionalActions}
        <Stack direction="row" spacing={1}>
          <Button onClick={onCancel}>{finalCancelLabel}</Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {finalSubmitLabel}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
