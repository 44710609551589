import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Field,
  FieldProps,
  FormikHelpers,
  FormikProvider,
  useFormik,
} from 'formik'
import { TextField } from 'formik-mui'
import { DateTime } from 'luxon'
import moment from 'moment/moment'
import { TFuncKey, useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import DeleteIcon from '@mui/icons-material/Delete'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import NotesIcon from '@mui/icons-material/Notes'
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GorillaDialog } from '@procom-labs/atoms'
import {
  DateValueFormat,
  formatDates,
  formatTimeZone,
  IAvailabilityPayload,
  ICandidate,
  ICollaborator,
  ICollaboratorInvitePayload,
  IInterviewPlatform,
  IInvitees,
  InterviewFormPayload,
  InterviewPlatforms,
  isDateEqualGreaterThanToday,
} from '@procom-labs/common'
import {
  CollaboratorToggle,
  GorillaCalendar,
  GorillaSlider,
  ModalCloseButton,
  RadioGroupInputOption,
  ScrollButtonList,
  timezones,
  TimeZoneSelector,
} from '@procom-labs/molecules'

import {
  DurationIntervalEnum,
  IDurationInterval,
  ITimeSlot,
  timeSlots30,
  timeSlots60,
} from '../types'
import { InterviewSuccessModal } from './candidate-interview-success-modal'
import { CandidateRequestInterviewEdit } from './candidate-request-interview-edit'
import { InterviewTimeSlots } from './inteview-time-slots'

const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
export const interviewRequestFormDefaultValues: InterviewFormPayload = {
  interviewDateTimes: [],
  interviewRequestAdditionalNotes: '',
  interviewPlatform: '',
  interviewPlatformOther: '',
  interviewMeetingLocation: '',
  interviewCollaborators: [],
}

const TimezoneFormat = 'UTC'

export const CandidateRequestInterviewModalContent: React.FC<{
  open: boolean
  handleClose: () => void
  isAvailabilitySent: boolean
  candidate: Partial<ICandidate> | null
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  accountManagerName: string
  collaborators?: ICollaborator[] | null
  onSubmit: (
    payloadFormatted: IAvailabilityPayload[],
    interviewRequestAdditionalNotes: string,
    actions?: FormikHelpers<InterviewFormPayload>,
    initialFormValues?: InterviewFormPayload,
    interviewRequestDetails?: IInterviewPlatform,
    invitees?: IInvitees[]
  ) => void
}> = ({
  open,
  handleClose,
  isAvailabilitySent,
  candidate,
  isEditing,
  setIsEditing,
  accountManagerName,
  collaborators,
  onSubmit,
}) => {
  const theme = useTheme()
  const { t } = useTranslation('main')
  const mobileView = useMediaQuery(() => theme.breakpoints.down('md'))

  const [initialFormValues, setInitialFormValues] = useState(
    interviewRequestFormDefaultValues
  )
  const [isAddingNotes, setIsAddingNotes] = useState(
    !!candidate?.interviewRequest?.interviewRequestAdditionalNotes
  )

  const [selectedTimeZone, setSelectedTimeZone] = useState(currentTimeZone)
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now())
  const [mobileViewSlideIndex, setMobileViewSlideIndex] = useState<number>(0)

  const interviewPlatformOptions: RadioGroupInputOption[] = [
    {
      label: `${InterviewPlatforms.Zoom}` as TFuncKey,
      value: InterviewPlatforms.Zoom,
    },
    {
      label: `${InterviewPlatforms.MicrosoftTeams}` as TFuncKey,
      value: InterviewPlatforms.MicrosoftTeams,
    },
    {
      label: t('organisms.interviewModal.platform.inPerson'),
      value: InterviewPlatforms.InPerson,
    },
    {
      label: t('organisms.interviewModal.platform.other'),
      value: InterviewPlatforms.Other,
    },
  ]

  const durationIntervals: IDurationInterval[] = useMemo(
    () => [
      {
        id: DurationIntervalEnum.TimeDuration30,
        title: '30 minutes',
        durations: timeSlots30,
      },
      {
        id: DurationIntervalEnum.TimeDuration60,
        title: '60 minutes',
        durations: timeSlots60,
      },
    ],
    []
  )

  const [durationIntervalId, setDurationIntervalId] =
    useState<DurationIntervalEnum>(durationIntervals[0].id)

  const validationSchema = Yup.object().shape({
    interviewDateTimes: Yup.array(
      Yup.object().shape({
        date: Yup.string()
          .typeError(t('common.candidates.dialog.interview.form.invalidDate'))
          .required(t('common.candidates.dialog.interview.form.requiredDate'))
          .test(
            'date',
            (date) =>
              `${date.value}: ${t(
                'common.candidates.dialog.interview.form.pastDateError'
              )}`,
            (value: string | undefined) => {
              if (value) {
                return isDateEqualGreaterThanToday(value)
              }
              return true
            }
          )
          .required(t('common.candidates.dialog.interview.form.requiredDate')),
        fromTime: Yup.string().required(
          t('common.candidates.dialog.interview.form.requiredTime')
        ),
        toTime: Yup.string().required(
          t('common.candidates.dialog.interview.form.requiredTime')
        ),
      })
    )
      .min(1, t('common.candidates.dialog.interview.requiredError'))
      .required(t('common.candidates.dialog.interview.requiredError')),
  })

  const handleUpdateCandidate = useCallback(
    (
      values: InterviewFormPayload,
      actions: FormikHelpers<InterviewFormPayload>
    ): void => {
      if (!candidate) return
      const payloadFormatted: IAvailabilityPayload[] = []
      values.interviewDateTimes.forEach((value, index) => {
        if (value.fromTime && value.toTime && value.date) {
          const selectedTimeZoneObj = timezones.find(
            (timezone) => timezone.value === selectedTimeZone
          )
          let formattedTimeZoneValue = ''
          if (selectedTimeZoneObj) {
            const { value: rawValue, std, dst } = selectedTimeZoneObj
            const prefix = `${TimezoneFormat}${moment().isDST() ? dst : std}`
            formattedTimeZoneValue = `(${prefix}) ${rawValue}`
          }
          payloadFormatted[index] = {
            ...value,
            timeZone: formattedTimeZoneValue || value.timeZone,
          }
        }
      })
      const invitedCollaborators: IInvitees[] | undefined =
        values.interviewCollaborators
          ?.filter((collaborator) => collaborator.isInvited)
          .map((collaborator) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { isInvited, ...rest } = collaborator
            return rest
          })

      const interviewPlatform: IInterviewPlatform = {
        platform: values.interviewPlatform ?? '',
        specifyOther: values.interviewPlatformOther ?? '',
        interviewLinkLocation: values.interviewMeetingLocation ?? '',
      }
      onSubmit(
        payloadFormatted,
        values.interviewRequestAdditionalNotes,
        actions,
        initialFormValues,
        interviewPlatform,
        invitedCollaborators
      )
    },
    [candidate, onSubmit, initialFormValues, selectedTimeZone]
  )

  const formikInstance = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleUpdateCandidate,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  })
  const {
    errors,
    isSubmitting,
    values,
    setErrors,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formikInstance

  useEffect(
    () =>
      setIsAddingNotes(
        !!candidate?.interviewRequest?.interviewRequestAdditionalNotes
      ),
    [candidate?.interviewRequest?.interviewRequestAdditionalNotes]
  )

  const isUpdatingRequest = !!initialFormValues.interviewRequestCreatedDateTime

  const requestedDate = useMemo(
    () =>
      initialFormValues.interviewRequestCreatedDateTime &&
      formatDates(
        DateValueFormat.DateTimeFullTimeSimple,
        initialFormValues.interviewRequestCreatedDateTime
      ),
    [initialFormValues.interviewRequestCreatedDateTime]
  )

  useEffect(() => {
    if (collaborators && candidate) {
      const alreadyAddedCollaborators = new Set<string>()
      candidate.interviewRequest?.invitees?.forEach((invitee) =>
        alreadyAddedCollaborators.add(invitee.email)
      )
      const isValidInvitees =
        candidate.interviewRequest &&
        candidate.interviewRequest.invitees &&
        candidate.interviewRequest.invitees.length > 0

      const tempList: ICollaboratorInvitePayload[] = []
      collaborators.map((collaborator) =>
        tempList.push({
          id: collaborator.id ?? '',
          email: collaborator.email,
          firstName: collaborator.firstName,
          lastName: collaborator.lastName,
          isInvited: isValidInvitees
            ? alreadyAddedCollaborators.has(collaborator.email ?? '')
            : true,
        })
      )

      const candidateTimezone =
        candidate.interviewRequest?.interviewDateTimes?.[0].timeZone
      if (candidateTimezone) {
        const trimmedTimezone = candidateTimezone
          .slice(candidateTimezone.lastIndexOf(')') + 2)
          .trim()
        setSelectedTimeZone(trimmedTimezone)
      }

      setInitialFormValues((prevState) => ({
        ...prevState,
        interviewCollaborators: tempList,
      }))
    }
  }, [collaborators, candidate])
  useEffect(() => {
    if (
      candidate &&
      candidate.interviewRequest &&
      candidate.interviewRequest.interviewDateTimes.length > 0
    ) {
      const payloadFormatted: IAvailabilityPayload[] = []
      candidate.interviewRequest.interviewDateTimes.forEach((value, index) => {
        if (value.fromTime && value.toTime && value.date) {
          payloadFormatted[index] = {
            fromTime: value.fromTime.toString(),
            toTime: value.toTime.toString(),
            date: value.date,
            timeZone: formatTimeZone(value.timeZone ?? '') ?? currentTimeZone,
          }
        }
      })
      setInitialFormValues((prevState) => ({
        ...prevState,
        interviewDateTimes: payloadFormatted,
        interviewRequestAdditionalNotes:
          candidate.interviewRequest?.interviewRequestAdditionalNotes ?? '',
        interviewRequestCreatedDateTime:
          candidate.interviewRequest?.interviewRequestCreatedDateTime,
        interviewPlatform:
          candidate.interviewRequest?.interviewPlatform?.platform,
        interviewMeetingLocation:
          candidate.interviewRequest?.interviewPlatform?.interviewLinkLocation,
        interviewPlatformOther:
          candidate.interviewRequest?.interviewPlatform?.specifyOther,
      }))
    } else {
      setInitialFormValues((prevState) => ({
        ...interviewRequestFormDefaultValues,
        interviewCollaborators: prevState.interviewCollaborators,
      }))
    }
  }, [candidate])

  const timeSlotIntervals: ITimeSlot[] = useMemo(() => {
    const { durations = [] } =
      durationIntervals.find(
        (interval) => interval.id === durationIntervalId
      ) || {}
    return durations
  }, [durationIntervalId, durationIntervals])

  const handleSelectedDate = useCallback(
    (newSelectedDate: DateTime): void => {
      setSelectedDate(newSelectedDate)
      if (mobileView) setMobileViewSlideIndex(1)
    },
    [mobileView]
  )

  const isMatchingTimeSlot = useCallback(
    (timeSlot: ITimeSlot, interviewSlot: IAvailabilityPayload) => {
      return (
        interviewSlot.date === selectedDate?.toFormat('D') &&
        interviewSlot.fromTime === timeSlot.fromTime &&
        interviewSlot.toTime === timeSlot.toTime
      )
    },
    [selectedDate]
  )

  const handleSelectTime = useCallback(
    (timeSlot: ITimeSlot) => {
      const index = values.interviewDateTimes.findIndex(
        (interviewSlot: IAvailabilityPayload) =>
          isMatchingTimeSlot(timeSlot, interviewSlot)
      )

      const newInterviewTimeSlots =
        index >= 0
          ? values.interviewDateTimes.filter(
              (_: IAvailabilityPayload, i: number) => i !== index
            )
          : [
              ...values.interviewDateTimes,
              {
                date: selectedDate?.toFormat('D'),
                timeZone: selectedTimeZone,
                fromTime: timeSlot.fromTime,
                toTime: timeSlot.toTime,
              },
            ]
      setErrors({})
      setFieldValue('interviewDateTimes', newInterviewTimeSlots)
    },
    [
      selectedTimeZone,
      values.interviewDateTimes,
      setFieldValue,
      isMatchingTimeSlot,
      selectedDate,
      setErrors,
    ]
  )

  const isTimeSlotsSelected = useCallback(
    (timeSlot: ITimeSlot, interviewDateTimes: IAvailabilityPayload[]) =>
      interviewDateTimes.some((datetime: IAvailabilityPayload) =>
        isMatchingTimeSlot(timeSlot, datetime)
      ),
    [isMatchingTimeSlot]
  )

  const handleDeleteTimeSlot = useCallback(
    (index: number): void => {
      const newInterviewTimeSlots = [...values.interviewDateTimes]
      newInterviewTimeSlots.splice(index, 1)
      setFieldValue('interviewDateTimes', newInterviewTimeSlots)
      setErrors({})
    },
    [setErrors, setFieldValue, values.interviewDateTimes]
  )

  const availableTimeSlotIntervals = useMemo(() => {
    // this is for filtering todays past hours if today is selected
    const now = DateTime.now()
    const today = now.startOf('day')
    const selectedDay = selectedDate?.startOf('day')

    // adding selected flag to time slots
    const timeSlotIntervalsWithSelection = timeSlotIntervals.map(
      (timeSlot) => ({
        ...timeSlot,
        selected: isTimeSlotsSelected(timeSlot, values.interviewDateTimes),
      })
    )
    // if the selected date is bigger than today (tomorrow onward) we return all available interview's time slots
    if (!selectedDate || selectedDay > today) {
      return timeSlotIntervalsWithSelection
    }
    const timeInTimeZone = now.setZone(selectedTimeZone)

    // if slected date is today we filter past hours
    return timeSlotIntervalsWithSelection.filter((curr) => {
      const current = DateTime.fromFormat(curr.fromTime, 'h:mm a', {
        zone: selectedTimeZone,
      })
      return current > timeInTimeZone
    })
  }, [
    timeSlotIntervals,
    selectedDate,
    selectedTimeZone,
    values.interviewDateTimes,
    isTimeSlotsSelected,
  ])
  const selectedInteviewDatetimes = useMemo((): IAvailabilityPayload[] => {
    return values.interviewDateTimes
      .filter(
        (datetime) =>
          !!datetime.date && !!datetime.fromTime && !!datetime.toTime
      )
      .sort((a: IAvailabilityPayload, b: IAvailabilityPayload) => {
        const dateA = new Date(a.date as string)
        const dateB = new Date(b.date as string)
        return dateA.getTime() - dateB.getTime()
      })
  }, [values.interviewDateTimes])

  useEffect(() => {
    if (!open) {
      // clean up form values
      // I didn't put clean up in return of useEffect because closing this modal only make open === false and not unmounting the component.
      // So, I need clean up when open status is changed to false
      resetForm()
      setSelectedTimeZone(currentTimeZone)
      setDurationIntervalId(durationIntervals[0]?.id)
    }
  }, [open, resetForm, durationIntervals])
  return (
    <GorillaDialog
      isLightMode
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          paddingY: { xs: 3.5, lg: 3.5 },
          paddingX: { xs: 2.5, lg: 4 },
          width: '100%',
          maxWidth: '650px',
        },
      }}
    >
      {!isAvailabilitySent ? (
        <>
          {isUpdatingRequest &&
          candidate?.interviewRequest?.interviewDateTimes &&
          !isEditing ? (
            <CandidateRequestInterviewEdit
              accountManagerName={accountManagerName}
              requestedDate={requestedDate || ''}
              values={candidate.interviewRequest.interviewDateTimes}
              interviewNotes={
                initialFormValues.interviewRequestAdditionalNotes || ''
              }
              handleClose={handleClose}
              handleEdit={() => setIsEditing(true)}
            />
          ) : (
            <FormikProvider value={formikInstance}>
              <form onSubmit={handleSubmit}>
                <DialogTitle sx={{ p: 0 }}>
                  <Grid
                    container
                    display="grid"
                    gridTemplateColumns="1fr 50px"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        display="inline"
                        variant="h22Bold800"
                        fontSize={mobileView ? 18 : 22}
                      >
                        {t('common.candidates.dialog.interview.prompt1')}
                      </Typography>
                      <Typography component="p" variant="subtitle1">
                        {t('common.candidates.dialog.interview.prompt2')}{' '}
                        {accountManagerName}.
                      </Typography>
                    </Grid>
                    <Grid item alignItems="center">
                      <ModalCloseButton
                        handleClose={handleClose}
                        id="btn-close-request-interview-modal"
                        sx={{ top: 20, right: 20 }}
                      />
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent
                  sx={{
                    mt: 3,
                    maxHeight: 600,
                    px: 0.5,
                  }}
                >
                  <Stack
                    direction={mobileView ? 'column' : 'row'}
                    spacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}
                  >
                    <Stack
                      display="grid"
                      alignItems="center"
                      gridTemplateColumns="90px 1fr"
                    >
                      <Box>
                        <Typography
                          variant="h22Bold800"
                          sx={{ fontSize: '14px' }}
                        >
                          {t('common.candidates.dialog.interview.timeZone')}:
                        </Typography>
                      </Box>
                      <Box>
                        <Field>
                          {({ field }: any) => {
                            return (
                              <FormControl>
                                <TimeZoneSelector
                                  {...field}
                                  onChange={(val) => setSelectedTimeZone(val)}
                                  value={selectedTimeZone}
                                  selectSX={{ width: mobileView ? 210 : 250 }}
                                />
                              </FormControl>
                            )
                          }}
                        </Field>
                      </Box>
                    </Stack>
                    <Stack
                      display="grid"
                      alignItems="center"
                      gridTemplateColumns={mobileView ? '90px 1fr' : '80px 1fr'}
                      justifyContent={{ sm: 'flex-start', md: 'flex-end' }}
                    >
                      <Box>
                        <Typography
                          variant="h22Bold800"
                          sx={{ fontSize: '14px' }}
                        >
                          {t('common.candidates.dialog.interview.duration')}:
                        </Typography>
                      </Box>
                      <Box>
                        <Select
                          displayEmpty
                          onChange={(event) =>
                            setDurationIntervalId(
                              event.target.value as DurationIntervalEnum
                            )
                          }
                          value={durationIntervalId}
                          fullWidth
                          sx={{
                            width: mobileView ? 210 : '100%',
                          }}
                          size="small"
                        >
                          {durationIntervals.map((interval) => (
                            <MenuItem
                              key={`duration-inteval-${interval.id}`}
                              value={interval.id}
                            >
                              {interval.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Stack>
                  </Stack>
                  {mobileView ? (
                    <Box mt={3} px={1}>
                      <GorillaSlider
                        selectedSlideIndex={mobileViewSlideIndex}
                        setSelectedSlideIndex={setMobileViewSlideIndex}
                      >
                        <Stack key="dateCalendar">
                          <GorillaCalendar
                            selectedDate={selectedDate as DateTime}
                            highlightedDays={values.interviewDateTimes.map(
                              (time) => time.date
                            )}
                            handleSelectedDate={handleSelectedDate}
                          />
                        </Stack>
                        {selectedDate && (
                          <Stack key="timeSlotList">
                            <ScrollButtonList
                              id="inteview-email-modal-item"
                              listContainerSX={{ height: '300px' }}
                            >
                              <InterviewTimeSlots
                                timeSlots={availableTimeSlotIntervals}
                                handleSelectTimeSlot={handleSelectTime}
                              />
                            </ScrollButtonList>
                          </Stack>
                        )}
                      </GorillaSlider>
                    </Box>
                  ) : (
                    <Stack direction="row" mt={3}>
                      <Box>
                        <GorillaCalendar
                          selectedDate={selectedDate as DateTime}
                          highlightedDays={values.interviewDateTimes.map(
                            (time) => time.date
                          )}
                          handleSelectedDate={handleSelectedDate}
                        />
                      </Box>
                      {selectedDate && (
                        <Box px={{ md: 4, lg: 4 }}>
                          <ScrollButtonList
                            id="inteview-email-modal-item"
                            listContainerSX={{ height: '300px' }}
                          >
                            <InterviewTimeSlots
                              timeSlots={availableTimeSlotIntervals}
                              handleSelectTimeSlot={handleSelectTime}
                            />
                          </ScrollButtonList>
                        </Box>
                      )}
                    </Stack>
                  )}
                  {values.interviewDateTimes.length > 0 && (
                    <Box mb={3}>
                      <Typography component="h6" variant="h6">
                        {t('common.candidates.dialog.interview.selectedDates')}
                      </Typography>

                      {selectedInteviewDatetimes.map(
                        (interviewDateTime, index) => (
                          <Stack
                            key={`${interviewDateTime.date}-${interviewDateTime.fromTime}-${interviewDateTime.toTime}`}
                            pt={1}
                            mb={0.5}
                            spacing={1}
                            alignItems="center"
                            direction="row"
                          >
                            <Box>
                              <Typography
                                variant="body1"
                                sx={{
                                  minWidth: '115px',
                                }}
                              >
                                {DateTime.fromFormat(
                                  interviewDateTime.date as string,
                                  'D'
                                ).toFormat('ccc dd LLLL')}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                textAlign="center"
                                sx={{
                                  fontSize: '14px',
                                  backgroundColor: 'primary.main',
                                  color: 'common.white',
                                  borderRadius: '5px',
                                }}
                                width="150px"
                                py={0.5}
                              >
                                {`${interviewDateTime.fromTime} - ${interviewDateTime.toTime}`}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <DeleteIcon
                                sx={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleDeleteTimeSlot(index)}
                              />
                            </Box>
                          </Stack>
                        )
                      )}
                    </Box>
                  )}
                  <Stack direction="column" justifyContent="center" spacing={3}>
                    {Array.isArray(errors.interviewDateTimes) ? (
                      errors.interviewDateTimes.map((errorObj, objIndex) => (
                        <React.Fragment
                          key={`interview-time-error-obj-${objIndex}`}
                        >
                          {Object.values(errorObj).map(
                            (errorMessage, index) => (
                              <Typography
                                key={`interview-time-error-${index}`}
                                variant="body1"
                                gutterBottom
                                component="p"
                                color="error"
                              >
                                {errorMessage}
                              </Typography>
                            )
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <Typography
                        variant="body1"
                        gutterBottom
                        component="p"
                        color="error"
                      >
                        {errors.interviewDateTimes ?? ''}
                      </Typography>
                    )}

                    {/* Collaborators */}
                    {initialFormValues.interviewCollaborators && (
                      <CollaboratorToggle
                        collaborators={initialFormValues.interviewCollaborators}
                      />
                    )}

                    {/* Platform */}
                    <Stack>
                      <Typography component="h6" variant="h6">
                        {t('common.candidates.dialog.interview.form.platform')}
                      </Typography>
                      <Field name="interviewPlatform">
                        {({ field }: FieldProps) => (
                          <FormControl>
                            <RadioGroup
                              {...field}
                              row
                              onChange={(event, value) => {
                                setFieldValue(field.name, value)
                                if (value !== InterviewPlatforms.Other) {
                                  setFieldValue('interviewPlatformOther', '')
                                }
                              }}
                            >
                              {interviewPlatformOptions.map(
                                (platform, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={platform.value}
                                    label={platform.label}
                                    control={<Radio />}
                                  />
                                )
                              )}
                            </RadioGroup>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>

                    {values.interviewPlatform === InterviewPlatforms.Other && (
                      <Field
                        component={TextField}
                        name="interviewPlatformOther"
                        id="interviewPlatformOther"
                        placeholder={t(
                          'common.candidates.dialog.interview.form.specify'
                        )}
                      />
                    )}

                    <Field
                      component={TextField}
                      name="interviewMeetingLocation"
                      placeholder={
                        values.interviewPlatform === InterviewPlatforms.InPerson
                          ? t(
                              `common.candidates.dialog.interview.form.interviewAddress`
                            )
                          : t(
                              `common.candidates.dialog.interview.form.meetingLink`
                            )
                      }
                      id="interviewMeetingLocation"
                      fullWidth
                    />

                    {(!mobileView || (mobileView && isAddingNotes)) && (
                      <Field
                        component={TextField}
                        placeholder={`${t(
                          'common.candidates.dialog.interview.form.additionalNotes'
                        )}`}
                        id="outlined-multiline-static"
                        multiline
                        name="interviewRequestAdditionalNotes"
                        rows={6}
                        fullWidth
                      />
                    )}
                  </Stack>
                  {mobileView && (
                    <Button
                      id="btn-delete-or-add-notes"
                      variant="outlined"
                      startIcon={
                        isAddingNotes ? <DeleteOutlineIcon /> : <NotesIcon />
                      }
                      fullWidth
                      color={isAddingNotes ? 'error' : 'primary'}
                      sx={{ mb: 1.25, mt: 2 }}
                      onClick={() => {
                        if (isAddingNotes)
                          setFieldValue('interviewRequestAdditionalNotes', '')
                        setIsAddingNotes(!isAddingNotes)
                      }}
                    >
                      {isAddingNotes
                        ? t('common.candidates.dialog.interview.deleteNotes')
                        : t('common.candidates.dialog.interview.addNotes')}
                    </Button>
                  )}
                </DialogContent>
                <DialogActions
                  sx={{
                    columnGap: 2,
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'center',
                    pt: { md: 2, lg: 2 },
                  }}
                >
                  {!mobileView && (
                    <Button
                      id="btn-close-request-interview-modal"
                      variant="outlined"
                      onClick={handleClose}
                    >
                      {t('common.btn.cancel')}
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    id="cta-send-interview-availability"
                    fullWidth={mobileView}
                    disabled={isSubmitting}
                    endIcon={isSubmitting && <CircularProgress size={18} />}
                  >
                    {t(
                      'common.candidates.dialog.interview.sendAvailabilityBtn'
                    )}
                  </Button>
                </DialogActions>
              </form>
            </FormikProvider>
          )}
        </>
      ) : (
        <InterviewSuccessModal
          isUpdatingRequest={isUpdatingRequest}
          open={open}
          accountManagerName={accountManagerName}
          handleClose={handleClose}
        />
      )}
    </GorillaDialog>
  )
}
