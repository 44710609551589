import React from 'react'
import { Grid, Tab, TabProps, Typography } from '@mui/material'

interface IconTabProps extends TabProps {
  icon: React.ReactElement
  label: string
}

export const IconTab: React.FC<IconTabProps> = ({ icon, label, ...rest }) => {
  return (
    <Tab
      icon={
        <Grid container alignItems="center" spacing={1}>
          <Grid item>{icon}</Grid>
          <Grid item>
            <Typography>{label}</Typography>
          </Grid>
        </Grid>
      }
      {...rest}
    />
  )
}
