/* eslint-disable import/no-extraneous-dependencies */
import { Theme } from '@mui/material/styles'
import { Box, BoxProps } from '@mui/material'
import { OverridableComponent } from '@mui/types'
import { BoxTypeMap } from '@mui/system'

import { InnerThemeProps, WithGorillaInnerTheme } from './with-inner-theme'

export const GorillaBox = WithGorillaInnerTheme<BoxProps>(
  Box
) as OverridableComponent<BoxTypeMap<InnerThemeProps, 'div', Theme>>
