import { Box, Typography } from '@mui/material'
import React from 'react'
import { useDataTimeStrings } from './cell-date-hooks'

export interface CellDateTimeViewProps {
  label: string
  value: string
}

export const CellDateTimeView: React.FC<CellDateTimeViewProps> = ({
  label,
  value,
}) => {
  const [dateString, timeString] = useDataTimeStrings(value)

  return (
    <Box>
      <Typography noWrap sx={{ marginRight: 1 }}>
        {label}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography noWrap sx={{ marginRight: 1, color: 'text.secondary' }}>
          {dateString}
        </Typography>
        <Typography noWrap sx={{ color: 'text.secondary' }}>
          {timeString}
        </Typography>
      </Box>
    </Box>
  )
}
