import { ContractorProfileService, VendorCodes } from '@procom-labs/common'

import { environment } from '@submission-portal/environment'

import { axios } from '../lib/axios'

const searchParams = new URLSearchParams(window.location.search)
const vendorCode =
  (searchParams.get('vendorCode') as VendorCodes) || VendorCodes.PCGL

export const contractorProfileService = new ContractorProfileService({
  axios,
  authApiUrl: environment.AUTH_API_URL,
  jobApiUrl: environment.JOB_API_URL,
  apiKey: environment.PROCOM_VENDOR_API_KEYS[vendorCode],
})
