import { FC, useCallback, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { Theme } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  capitalizeWords,
  getInitials,
  JobSubmissionStatusTypes,
} from '@procom-labs/common'

import { useNavigateToSubmission } from '@submission-portal/hooks'
import {
  FormattedDateTime,
  ISubmissionSummary,
} from '@submission-portal/models'
import { SubmissionSource } from '@submission-portal/types'
import { ValidViewSubmissionStatuses } from '@submission-portal/types/models'

type SubmissionListItemProps = {
  submission: ISubmissionSummary
}

export const SubmissionListItem: FC<SubmissionListItemProps> = ({
  submission,
}: SubmissionListItemProps) => {
  const { t } = useTranslation('main')

  const isSmallDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const initials: string = useMemo(() => {
    return `${getInitials(submission.firstName)}${getInitials(
      submission.lastName
    )}`
  }, [submission.firstName, submission.lastName])

  const dateAdded: FormattedDateTime = useMemo(() => {
    const date = DateTime.fromISO(submission.dateAddedOnAts)
    return {
      date: date.toLocaleString(),
      time: date.toLocaleString(DateTime.TIME_SIMPLE),
    }
  }, [submission.dateAddedOnAts])

  const lastModifiedDateTime: FormattedDateTime = useMemo(() => {
    const date = DateTime.fromISO(submission.dateLastModified)
    return {
      date: date.toLocaleString(),
      time: date.toLocaleString(DateTime.TIME_SIMPLE),
    }
  }, [submission.dateLastModified])

  const submissionStatus: string = useMemo(() => {
    return capitalizeWords(submission.status ?? '')
  }, [submission.status])

  const isView = useMemo(
    () =>
      ValidViewSubmissionStatuses.includes(
        submission.status?.toLowerCase() ?? ''
      ),
    [submission.status]
  )

  const navigateToSubmission = useNavigateToSubmission()
  const handleSubmit = useCallback(() => {
    navigateToSubmission(submission)
  }, [submission, navigateToSubmission])

  return (
    <Grid
      container
      columns={16}
      spacing={1}
      rowGap={1}
      sx={{
        padding: 2,
        margin: 1,
        backgroundColor: 'background.default',
      }}
    >
      <Grid container item xs={16} md={3} columnSpacing={2} alignItems="center">
        <Grid item xs="auto">
          <Avatar>{initials}</Avatar>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography fontWeight={500} noWrap>
            {submission.firstName} {submission.lastName}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={isSmallDevice} item xs={16} sm={6} md={2}>
        {isSmallDevice && (
          <Grid item xs={6} sm={4}>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('submissionList.dateAdded')}:
            </Typography>
          </Grid>
        )}
        <Grid item xs={6} sm={8} md={12}>
          <Typography noWrap>{dateAdded.date}</Typography>
          <Typography noWrap sx={{ color: 'text.secondary' }}>
            {dateAdded.time}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={isSmallDevice} item xs={16} sm={6} md={2}>
        {isSmallDevice && (
          <Grid item xs={6} sm={4}>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('submissionList.lastModified')}:
            </Typography>
          </Grid>
        )}
        <Grid item xs={6} sm={8} md={12}>
          <Typography noWrap>{lastModifiedDateTime.date}</Typography>
          <Typography noWrap sx={{ color: 'text.secondary' }}>
            {lastModifiedDateTime.time}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={isSmallDevice} item xs={16} sm={6} md={2}>
        {isSmallDevice && (
          <Grid item xs={6} sm={4}>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('submissionList.addedBy')}:
            </Typography>
          </Grid>
        )}
        <Grid item xs={6} sm={8} md={12}>
          <Typography noWrap>{submission.addedBy}</Typography>
        </Grid>
      </Grid>
      <Grid container={isSmallDevice} item xs={16} sm={6} md={3}>
        {isSmallDevice && (
          <Grid item xs={6} sm={4}>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('submissionList.status')}:
            </Typography>
          </Grid>
        )}
        <Grid item xs={6} sm={8} md="auto">
          <Typography
            noWrap
            sx={{
              color:
                submission.status?.toLowerCase() ===
                JobSubmissionStatusTypes.ClientSubmission.toLowerCase()
                  ? 'success.dark'
                  : undefined,
            }}
          >
            {submissionStatus}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={16} md={3} className="text-center">
        {submission.submissionSource === SubmissionSource.Bullhorn ? (
          t('submissionList.bhSubmission')
        ) : (
          <Button
            type="button"
            variant="outlined"
            onClick={handleSubmit}
            sx={{ textWrap: 'wrap' }}
          >
            {isView
              ? t('submissionList.btn.view')
              : t('submissionList.btn.submit')}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
