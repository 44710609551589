import React, { FC, memo } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { GorillaStack } from '@procom-labs/atoms'
import { TextHookField } from '@procom-labs/molecules'

export const MinMaxField: FC<{
  title: string
  switchName: string
  minName: string
  maxName: string
}> = memo(({ title, switchName, minName, maxName }) => {
  const { t } = useTranslation('main')
  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))
  return (
    <GorillaStack
      gap={2}
      direction={isMobileOrTab ? 'column' : 'row'}
      alignItems={isMobileOrTab ? 'start' : 'center'}
    >
      <GorillaStack gap={2} direction="row" alignItems="center">
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {title}
        </Typography>
        <Controller
          name={switchName}
          defaultValue={false}
          render={({ field }) => (
            <Switch
              {...field}
              color="primary"
              checked={Boolean(field.value)}
              sx={{ ml: '-12px' }}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      </GorillaStack>
      <GorillaStack gap={2} direction="row" alignItems="center">
        <TextHookField
          type="number"
          size="small"
          defaultValue={1}
          InputProps={{
            sx: {
              minWidth: '100px',
            },
            inputProps: {
              className: 'counter',
              min: 1,
              sx: { py: '6.5px', pr: '10px' },
            },
            endAdornment: t('organisms.candidateOnePager.years'),
          }}
          variant="outlined"
          name={minName}
          allowOnlyDigits={false}
          maxDecimalPlaces={1}
        />
        <Typography variant="body2">
          {t('organisms.candidateOnePager.to')}
        </Typography>
        <TextHookField
          type="number"
          size="small"
          InputProps={{
            sx: {
              minWidth: '100px',
            },
            inputProps: {
              className: 'counter',
              min: 1,
              sx: { py: '6.5px', pr: '10px' },
            },
            endAdornment: t('organisms.candidateOnePager.years'),
          }}
          variant="outlined"
          name={maxName}
          allowOnlyDigits={false}
          maxDecimalPlaces={1}
        />
      </GorillaStack>
    </GorillaStack>
  )
})
