import { GridFeatureMode, GridPaginationModel } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { paginationLabelDisplayedRows } from '@procom-labs/common'

export interface IPaginationProps {
  onPaginationModelChange: (model: GridPaginationModel) => void
  paginationModel: {
    page: number
    pageSize: number
  }
  pagination: true
  paginationMode: GridFeatureMode
  pageSizeOptions: number[]
  localeText: {
    MuiTablePagination: {
      labelDisplayedRows: ({
        from,
        to,
        count,
      }: {
        from: number
        to: number
        count: number
      }) => string
    }
  }
}

export const usePaginationProps = (
  handleChangePage: (model: GridPaginationModel) => void,
  currentPage: number,
  pageSize: number
): IPaginationProps => {
  const { t } = useTranslation('main')

  return {
    onPaginationModelChange: handleChangePage,
    paginationModel: {
      page: currentPage,
      pageSize,
    },
    pagination: true,
    paginationMode: 'server',
    pageSizeOptions: [pageSize],
    localeText: {
      MuiTablePagination: {
        labelDisplayedRows: paginationLabelDisplayedRows(t),
      },
    },
  }
}
