import React from 'react'
import { useTranslation } from 'react-i18next'

export const CopilotIcon: React.FC = () => {
  const { t } = useTranslation('main')
  return (
    <img
      src="/assets/images/ai-generator.svg"
      alt={t(`common.form.jobDescription.aiJobDescription`)}
      style={{ marginRight: '5px' }}
    />
  )
}
