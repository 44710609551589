import { useMemo } from 'react'
import { DateTime } from 'luxon'

export const useDataTimeStrings = (
  value: string | number | null
): [string, string] => {
  const { dateString, timeString } = useMemo(() => {
    const dateInstance =
      typeof value === 'number'
        ? DateTime.fromMillis(value)
        : DateTime.fromISO(value as string)

    return {
      dateString: dateInstance.toLocaleString(),
      timeString: dateInstance.toLocaleString(DateTime.TIME_SIMPLE),
    }
  }, [value])
  return [dateString, timeString]
}
