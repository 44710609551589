import React from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import { CandidatePrepEmailConfirmation } from '@submission-portal/components/candidate-prep/candidate-prep-email-confirmation'
import {
  CandidateExtendOfferModal,
  CandidateRejectedModal,
  CandidateRequestInterviewModal,
} from '@submission-portal/components/candidate-prep/components/modals'
import { CandidatePrepCandidateEditor } from '@submission-portal/components/candidate-prep/pages/candidate-prep-candidate-editor'
import { CandidatePrepCandidatePreview } from '@submission-portal/components/candidate-prep/pages/candidate-prep-candidate-preview'
import {
  useFetchJobAccountManagerName,
  useFetchSubmissionStatuses,
} from '@submission-portal/hooks'

export const CandidatePrepView: React.FC = () => {
  useFetchSubmissionStatuses()
  useFetchJobAccountManagerName()

  const { id } = useParams()

  return (
    <Box sx={{ m: 4 }}>
      <CandidatePrepEmailConfirmation />
      <CandidateRejectedModal />
      <CandidateRequestInterviewModal />
      <CandidateExtendOfferModal />

      {id ? (
        <CandidatePrepCandidateEditor />
      ) : (
        <CandidatePrepCandidatePreview />
      )}
    </Box>
  )
}
