import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Button, styled, Typography } from '@mui/material'
import { useSubjectSelector } from '@procom-labs/common'

import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

const InfoIcon = styled(PersonAddIcon)({
  width: '16px',
  height: '20px',
})
export const CandidateInfoButton: React.FC = () => {
  const { t } = useTranslation('main')

  const { isAddingCandidate } = useSubjectSelector(candidatesPrepStore, [
    'isAddingCandidate',
  ])

  const handleOpenModal = useCallback(
    () =>
      candidatesPrepStore.dispatch({
        isAddingCandidate: true,
      }),
    []
  )

  return (
    <>
      <Typography variant="h6">{t('candidatePreb.candidateList')}</Typography>
      <Button
        size="medium"
        disabled={isAddingCandidate}
        startIcon={<InfoIcon />}
        id="btn-open-add-collaborator-modal"
        onClick={handleOpenModal}
        sx={{ paddingTop: '8px' }}
      >
        {t('candidatePreb.addCandidate')}
      </Button>
    </>
  )
}
