import React from 'react'
import { Grid } from '@mui/material'
import { FieldTitle } from './field-title'
import { CheckboxWithLabel } from './checkbox-with-label'

interface CheckboxesGroupProps {
  label: string
  options: { name: string; label: string }[]
}

export const CheckboxGroup: React.FC<CheckboxesGroupProps> = ({
  label,
  options,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <FieldTitle>{label}</FieldTitle>
      </Grid>
      {options.map(({ name, label: optionLabel }) => (
        <Grid key={name} item xs={12}>
          <CheckboxWithLabel name={name} label={optionLabel} />
        </Grid>
      ))}
    </>
  )
}
