import { Observable } from 'rxjs'

import { DocumentParserErrors } from '@submission-portal/models'
import { fileActionsService } from '@submission-portal/services'

const PdfExtensions: string[] = ['pdf']
const DocExtensions: string[] = ['doc', 'docx']
export const AllowedExtensions: string[] = [...PdfExtensions, ...DocExtensions]

const parseTextFromFile = (
  file: File,
  extension: string
): Observable<string> => {
  if (file && AllowedExtensions.includes(extension)) {
    const formData = new FormData()
    formData.append('file', file)
    return fileActionsService.getFileText(formData, extension, false)
  }
  return new Observable<string>((observer) => {
    observer.error(new Error(DocumentParserErrors.InvalidFile))
  })
}

export { parseTextFromFile }
