import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 13,
  borderRadius: 5,
}))
export const SkillProgress: FC<{
  label: string
  value: number
  years: number | string
  linearProgressColor?: string
  linearProgressColorBg?: string
}> = memo(
  ({ label, value, years, linearProgressColor, linearProgressColorBg }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))
    const progressLabel = useMemo((): string => {
      const validYears =
        typeof years === 'string'
          ? parseFloat(parseFloat(years).toFixed(1))
          : years

      if (validYears === 0) {
        return t('organisms.candidateOnePager.lessThan1Yr')
      }

      if (validYears > 0 && validYears < 1) {
        const months = Math.round(validYears * 12)
        return `${months} ${
          months === 1
            ? t('organisms.candidateOnePager.month')
            : t('organisms.candidateOnePager.months')
        }`
      }

      return validYears === 1
        ? `${validYears} ${t('organisms.candidateOnePager.year')}`
        : `${validYears} ${t('organisms.candidateOnePager.years')}`
    }, [t, years])

    return (
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isMobile ? 'start' : 'center'}
        sx={{ width: '100%' }}
        gap={2}
      >
        <Typography sx={{ flex: 3 }} variant="body2">
          {label}
        </Typography>
        <Box
          sx={{ width: isMobile ? '100%' : 'auto', height: '13px', flex: 5 }}
        >
          <Tooltip title={progressLabel}>
            <BorderLinearProgress
              sx={{
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor:
                    linearProgressColorBg ?? 'onePager.skillsProgressBg',
                },
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                  backgroundColor:
                    linearProgressColor ?? 'onePager.skillsProgress',
                },
              }}
              variant="determinate"
              value={value}
            />
          </Tooltip>
        </Box>
      </Stack>
    )
  }
)
