import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { EditableSkills } from './editable-skills'
import { MinMaxField } from './min-max-field'

export const JobsEdit: FC = memo(() => {
  const { t } = useTranslation('main')
  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))

  return (
    <Stack
      p={isMobileOrTab ? 2 : 3}
      gap={1.5}
      sx={{ backgroundColor: 'onePager.boxBg', borderRadius: 2 }}
    >
      <MinMaxField
        title={t('organisms.candidateOnePager.jobs')}
        switchName="visibility.Jobs"
        minName="jobs.minYears"
        maxName="jobs.maxYears"
      />
      <EditableSkills
        removePrompt={t('organisms.candidateOnePager.jobsRemove')}
        name="jobs.jobs"
        fieldName="name"
      />
    </Stack>
  )
})
