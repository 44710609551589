import React, { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Subscription } from 'rxjs'

import {
  candidateOnePagerStore,
  IOnePagerForm,
  OnePagerModes,
  useSubjectSelector,
} from '@procom-labs/common'
import {
  mapFormValuesToCandidateOnePager,
  OnePagerEdit,
  OnePagerView,
  OnePagerWrapper,
} from '@procom-labs/organisms'

import { environment } from '@submission-portal/environment'
import { jobAiService } from '@submission-portal/services'

export const CandidateOnePager: FC<{
  onSave?: () => void
  hideCandidateDescription?: boolean
  isClientPortal?: boolean
}> = ({ hideCandidateDescription, isClientPortal, onSave }) => {
  const [currentView, setCurrentView] = useState<OnePagerModes>(
    OnePagerModes.VIEW
  )
  const { onePager } = useSubjectSelector(candidateOnePagerStore, ['onePager'])

  useEffect(() => {
    const subscription = new Subscription()
    if (!hideCandidateDescription)
      subscription.add(jobAiService.getCandidateHighlightsStream().subscribe())

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [hideCandidateDescription])

  const formMethods = useFormContext<IOnePagerForm>()

  const toggleCurrentView = useCallback(() => {
    if (currentView === OnePagerModes.EDIT && onePager) {
      const values = formMethods.getValues()

      const editedOnePager = mapFormValuesToCandidateOnePager(values, onePager)

      candidateOnePagerStore.dispatch({
        onePager: editedOnePager,
      })
    }

    setCurrentView((prev) =>
      prev === OnePagerModes.EDIT ? OnePagerModes.VIEW : OnePagerModes.EDIT
    )
  }, [formMethods, currentView, onePager])

  if (!onePager) return null

  return (
    <OnePagerWrapper
      toggleCurrentView={toggleCurrentView}
      currentView={currentView}
      {...(formMethods.formState.isDirty && onSave ? { onSave } : {})}
    >
      {currentView === OnePagerModes.EDIT ? (
        <OnePagerEdit
          hideCandidateDescription={hideCandidateDescription}
          googleMapsApiKey={environment.GOOGLE_MAPS_API_KEY}
          tinyMceApiKey={environment.TINY_MCE_API_KEY}
          jobAiService={jobAiService}
          isClientPortal={isClientPortal}
        />
      ) : (
        <OnePagerView
          hideCandidateDescription={hideCandidateDescription}
          isClientPortal={isClientPortal}
        />
      )}
    </OnePagerWrapper>
  )
}
