export enum SubmissionFields {
  Candidate = 'Candidate',
  DateAdded = 'DateAdded',
  LastModified = 'DateLastModified',
  AddedBy = 'AddedBy',
  Actions = 'Actions',
  ReviewAndSubmit = 'ReviewAndSubmit',
  Insights = 'Insights',
  Status = 'Status',
  Source = 'Source',
  ClientActivity = 'ClientActivity',
}

export enum CandidatePrepFields {
  CandidateFullName = 'CandidateFullName',
  Location = 'Location',
  CandidateAvailability = 'CandidateAvailability',
  Source = 'Source',
  Actions = 'Actions',
}

export enum CandidatesPrepFields {
  Candidate = 'Candidate',
  DateAdded = 'DateAdded',
  LastModified = 'DateLastModified',
  LastViewed = 'LastViewed',
  AddedBy = 'AddedBy',
  Actions = 'Actions',
  Status = 'Status',
  Source = 'Source',
}

export enum FileActions {
  View = 'view',
  Download = 'download',
}

export enum ReviewAndSubmitKeys {
  Submitted = 'submitted',
  ReadyForSubmission = 'readyForSubmission',
  SubmittedViaBh = 'submittedViaBh',
}

export enum DisplayModes {
  List = 'list',
  Card = 'card',
}

export enum SubmissionSource {
  Bullhorn = 'Bullhorn',
  BullhornUtility = 'BHUtility',
}

export enum EmailRecipientType {
  TO = 'TO',
  CC = 'CC',
  BCC = 'BCC',
}
