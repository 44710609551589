import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  JobSubmissionAtsStatusLabels,
  useSubjectSelector,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'
import {
  CandidateExtendOfferModalContent,
  OfferFormPayload,
} from '@procom-labs/organisms'

import { useUpdateAtsStatus } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-info-hooks'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

export const CandidateExtendOfferModal: React.FC = () => {
  const { t } = useTranslation('main')
  const { addAlert } = useAlert()

  const [isCommentsSent, setIsCommentsSent] = useState(false)

  const {
    isModalExtendOfferOpen: open,
    modalCandidateData: candidate,
    accountManagerName,
    jobTitle,
    modalAtsStatus,
  } = useSubjectSelector(candidatesPrepStore, [
    'isModalExtendOfferOpen',
    'modalCandidateData',
    'accountManagerName',
    'jobTitle',
    'modalAtsStatus',
  ])

  const updateAtsStatus = useUpdateAtsStatus()

  const handleUpdateCandidate = useCallback(
    (values: OfferFormPayload): void => {
      if (!candidate) return

      if (candidate.atsUserId && candidate.jobSubmissionId) {
        updateAtsStatus(
          {
            atsUserId: candidate.atsUserId,
            jobSubmissionId: candidate.jobSubmissionId,
            additionalData: {
              offerExtendedNote: values.offerExtendedNote,
              offerExtendedDateTime: new Date(
                new Date(Date.now()).toUTCString()
              ).toISOString(),
            },
          },
          modalAtsStatus,
          {
            complete: () => {
              setIsCommentsSent(true)
              addAlert({
                severity: 'success',
                message: t('common.alert.itemSaved'),
              })
              candidatesPrepStore.dispatch({
                candidateStatus: modalAtsStatus as JobSubmissionAtsStatusLabels,
              })
            },
            error: () => {
              candidatesPrepStore.dispatch({
                isModalExtendOfferOpen: false,
              })
              addAlert({
                message: t('common.alert.somethingWrong'),
                severity: 'error',
              })
            },
          }
        )
      } else {
        candidatesPrepStore.dispatch({
          isModalExtendOfferOpen: false,
        })
        addAlert({
          message: t('common.alert.somethingWrong'),
          severity: 'error',
        })
      }
    },
    [modalAtsStatus, addAlert, t, candidate, updateAtsStatus]
  )

  const handleClose = useCallback(() => {
    candidatesPrepStore.dispatch({
      isModalExtendOfferOpen: false,
      modalCandidateData: {},
      modalAtsStatus: '',
    })
    setIsCommentsSent(false)
  }, [])

  return (
    <CandidateExtendOfferModalContent
      open={open}
      handleClose={handleClose}
      isCommentsSent={isCommentsSent}
      accountManagerName={accountManagerName}
      candidate={candidate}
      jobTitle={jobTitle}
      handleUpdateCandidate={handleUpdateCandidate}
    />
  )
}
