import React from 'react'

import { Box, styled } from '@mui/material'

type PreloaderProps = {
  center?: boolean
}

const LoaderContainer = styled('div')(() => ({
  position: 'relative',
  display: 'block',
  width: '20rem',
  height: '20rem',
  transformStyle: 'preserve-3d',
  transform: 'perspective(1000px) rotateY(45deg) rotateX(45deg)',
}))

const Circle = styled('div')(() => ({
  '&:nth-of-type(0)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '0rem',
    top: '0rem',
    width: '20rem',
    height: '20rem',
    animation: 'spin Infinitys infinite linear',
  },
  '&:nth-of-type(1)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '0.625rem',
    top: '0.625rem',
    width: '18.75rem',
    height: '18.75rem',
    animation: 'spin 12s infinite linear',
  },
  '&:nth-of-type(2)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '1.25rem',
    top: '1.25rem',
    width: '17.5rem',
    height: '17.5rem',
    animation: 'spin 6s infinite linear',
  },
  '&:nth-of-type(3)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '1.875rem',
    top: '1.875rem',
    width: '16.25rem',
    height: '16.25rem',
    animation: 'spin 4s infinite linear',
  },
  '&:nth-of-type(4)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '2.5rem',
    top: '2.5rem',
    width: '15rem',
    height: '15rem',
    animation: 'spin 3s infinite linear',
  },
  '&:nth-of-type(5)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '3.125rem',
    top: '3.125rem',
    width: '13.75rem',
    height: '13.75rem',
    animation: 'spin 2.4s infinite linear',
  },
  '&:nth-of-type(6)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '3.75rem',
    top: '3.75rem',
    width: '12.5rem',
    height: '12.5rem',
    animation: 'spin 2s infinite linear',
  },
  '&:nth-of-type(7)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '4.375rem',
    top: '4.375rem',
    width: '11.25rem',
    height: '11.25rem',
    animation: 'spin 1.714285714285714s infinite linear',
  },
  '&:nth-of-type(8)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '5rem',
    top: '5rem',
    width: '10rem',
    height: '10rem',
    animation: 'spin 1.5s infinite linear',
  },
  '&:nth-of-type(9)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '5.625rem',
    top: '5.625rem',
    width: '8.75rem',
    height: '8.75rem',
    animation: 'spin 1.333333333333333s infinite linear',
  },
  '&:nth-of-type(10)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '6.25rem',
    top: '6.25rem',
    width: '7.5rem',
    height: '7.5rem',
    animation: 'spin 1.2s infinite linear',
  },
  '&:nth-of-type(11)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '6.875rem',
    top: '6.875rem',
    width: '6.25rem',
    height: '6.25rem',
    animation: 'spin 1.090909090909091s infinite linear',
  },
  '&:nth-of-type(12)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '7.5rem',
    top: '7.5rem',
    width: '5rem',
    height: '5rem',
    animation: 'spin 1s infinite linear',
  },
  '&:nth-of-type(13)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '8.125rem',
    top: '8.125rem',
    width: '3.75rem',
    height: '3.75rem',
    animation: 'spin 0.923076923076923s infinite linear',
  },
  '&:nth-of-type(14)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '8.75rem',
    top: '8.75rem',
    width: '2.5rem',
    height: '2.5rem',
    animation: 'spin 0.857142857142857s infinite linear',
  },
  '&:nth-of-type(15)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '9.375rem',
    top: '9.375rem',
    width: '1.25rem',
    height: '1.25rem',
    animation: 'spin 0.8s infinite linear',
  },
  '&:nth-of-type(16)': {
    position: 'absolute',
    background: 'transparent',
    border: '2px solid #008BCC',
    borderRadius: '50%',
    left: '10rem',
    top: '10rem',
    width: '0rem',
    height: '0rem',
    animation: 'spin 0.75s infinite linear',
  },
  '&:nth-of-type(2n)': { border: '2px dashed rgba(59, 86, 125, 0.5)' },
  '&:last-child': { display: 'none' },
  '@-moz-keyframes spin': {
    '0%': {
      transform: 'rotateX(0deg)',
    },
    '100%': {
      transform: 'rotateX(360deg)',
    },
  },

  '@-webkit-keyframes spin': {
    '0%': {
      transform: 'rotateX(0deg)',
    },
    '100%': {
      transform: 'rotateX(360deg)',
    },
  },

  '@-o-keyframes spin': {
    '0%': {
      transform: 'rotateX(0deg)',
    },
    '100%': {
      transform: 'rotateX(360deg)',
    },
  },

  '@keyframes spin': {
    '0%': {
      transform: 'rotateX(0deg)',
    },
    '100%': {
      transform: 'rotateX(360deg)',
    },
  },
}))
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'center',
})<PreloaderProps>(({ center }) => ({
  ...(center && {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
}))

export const Preloader3D: React.FC<PreloaderProps> = ({
  center,
}: PreloaderProps) => (
  <StyledBox center={center}>
    <LoaderContainer>
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
    </LoaderContainer>
  </StyledBox>
)

Preloader3D.defaultProps = {
  center: false,
}
