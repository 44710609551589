import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {
  alpha,
  Avatar,
  Box,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  DateValueFormat,
  formatDates,
  getInitials,
  JobStatusActions,
  JobSubmissionAtsStatusLabels,
  JobSubmissionAtsStatusTypeMap,
} from '@procom-labs/common'
import { LegendChipDropdown, useAvatarColor } from '@procom-labs/molecules'

import {
  useAtsStatuses,
  useTriggerActionPopup,
  useUpdateAtsStatus,
} from '@submission-portal/components/candidate-prep/hooks/candidate-prep-info-hooks'
import { avatarStore } from '@submission-portal/stores'
import {
  CandidatePrepAtsStatusColor,
  CandidatePrepInfoRecord,
} from '@submission-portal/types'

interface CandidatePrepInfoCellProps {
  onChange?: (status: string) => void
  record: CandidatePrepInfoRecord
}

export const CandidatePrepInfo: FC<CandidatePrepInfoCellProps> = ({
  record,
  onChange,
}) => {
  const {
    firstName,
    lastName,
    atsSubmissionStatus,
    atsJobSubmissionId,
    dateAddedOnAts,
    hiringManagerName,
  } = record

  const theme = useTheme()
  const { t } = useTranslation()
  const statusKey = JobSubmissionAtsStatusTypeMap[atsSubmissionStatus as string]
  const colorMap = CandidatePrepAtsStatusColor(theme)
  const legendColor = atsSubmissionStatus
    ? colorMap[atsSubmissionStatus] ?? theme.palette.primary.light
    : theme.palette.primary.light

  const items = useAtsStatuses()
  const triggerActionPopup = useTriggerActionPopup()
  const updateAtsStatus = useUpdateAtsStatus()
  const handleOnSelect = useCallback(
    (value: string): void => {
      const item = items.find(({ value: current }) => current === value)

      if (item) {
        if (item.actions) {
          triggerActionPopup(
            record,
            item.actions as JobStatusActions,
            item.label
          )
        } else {
          updateAtsStatus(record, item.label, {
            complete: () => {
              onChange?.(item.label)
            },
          })
        }
      }
    },
    [items, onChange, updateAtsStatus, record, triggerActionPopup]
  )

  const text = `${firstName} ${lastName}`

  const avatarInitials = getInitials(text, 2)
  const avatarBgColor = useAvatarColor(
    atsJobSubmissionId.toString(),
    avatarStore
  )

  const infoText = useMemo(() => {
    if (!dateAddedOnAts) {
      return hiringManagerName
    }
    const date = formatDates(DateValueFormat.DayMonthYearShort, dateAddedOnAts)

    return `${date} • ${hiringManagerName}`
  }, [dateAddedOnAts, hiringManagerName])

  return (
    <Box display="flex" alignItems="center" gap={2} sx={{ width: '100%' }}>
      <Avatar
        style={{
          backgroundColor: `#${avatarBgColor.color}`,
          width: '45px',
          height: '45px',
        }}
      >
        {avatarInitials}
      </Avatar>

      <Box flexGrow={1} display="flex" flexDirection="column" gap={0}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Typography variant="body1" display="inline">
            {text}
          </Typography>

          {atsSubmissionStatus && (
            <Tooltip title={t('candidatePreb.statusTooltip')} placement="top">
              <Box sx={{ ml: '3rem' }}>
                <LegendChipDropdown
                  id="candidate-prop-chip-dropdown"
                  value={statusKey}
                  items={items}
                  onSelect={handleOnSelect}
                  fallbackLabel={atsSubmissionStatus}
                  legendColor={legendColor}
                  renderMenuItem={({
                    item,
                    handleItemSelect,
                    selectedItemLabel,
                    sx,
                  }): React.ReactNode => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      title={item.label}
                      onClick={handleItemSelect}
                      sx={{
                        ...(sx && { ...sx }),
                        backgroundColor: alpha(
                          colorMap[item.label as JobSubmissionAtsStatusLabels],
                          0.12
                        ),
                        border:
                          selectedItemLabel === item.label
                            ? `1px solid ${alpha(
                                colorMap[
                                  item.label as JobSubmissionAtsStatusLabels
                                ],
                                0.5
                              )}`
                            : '1px solid transparent',
                        '&:hover': {
                          backgroundColor: alpha(
                            colorMap[
                              item.label as JobSubmissionAtsStatusLabels
                            ],
                            0.12
                          ),
                          border: `1px solid ${alpha('#000', 0.26)}`,
                        },
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  )}
                />
              </Box>
            </Tooltip>
          )}
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <AccessTimeIcon sx={{ fontSize: 16 }} />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {infoText}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
