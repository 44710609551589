import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  debounce,
  FormControl,
  FormLabel,
  Grid,
  GridSize,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'

const handleDebounce = debounce((callBack: () => void): void => {
  callBack()
}, 300)

const SearchBar: React.FC<{
  handleChangeKeyword: (e: React.ChangeEvent<HTMLInputElement>) => void
  keyword: string
  customLabel?: boolean
  searchLabel?: string
  handleClearKeyword?: () => void
}> = ({
  handleChangeKeyword,
  keyword,
  customLabel,
  searchLabel = '',
  handleClearKeyword,
}) => {
  const { t } = useTranslation('main')

  const [value, setValue] = useState('')

  useEffect(() => {
    const val = typeof keyword === 'string' ? keyword ?? '' : ''
    setValue(val)
  }, [keyword])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setValue(e.target.value)
      handleDebounce(() => {
        handleChangeKeyword(e)
      })
    },
    [handleChangeKeyword]
  )

  return (
    <Grid container width="100%" flexWrap="nowrap">
      <Grid item xs={12}>
        <FormControl fullWidth>
          {customLabel ? (
            <FormLabel>
              {searchLabel || t('common.form.search.label')}
            </FormLabel>
          ) : null}
          <TextField
            id="keyword"
            size="small"
            value={value}
            onChange={handleChange}
            label={
              !customLabel
                ? searchLabel || t('common.form.search.label')
                : undefined
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {value && handleClearKeyword ? (
                    <IconButton onClick={handleClearKeyword}>
                      <CloseIcon color="disabled" />
                    </IconButton>
                  ) : (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              maxHeight: '44px',
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

const FilterButton: React.FC<{
  handleOpenModal: () => void
  filterCount: number
  label?: string
  isFilterIcon?: boolean
  iconSx?: React.CSSProperties
}> = ({ handleOpenModal, filterCount, iconSx, label, isFilterIcon = true }) => {
  const theme = useTheme()
  const isBreakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'))
  const { t } = useTranslation('main')
  if (isBreakpointUpLg) {
    return (
      <Button
        fullWidth
        variant={filterCount ? 'contained' : 'outlined'}
        startIcon={isFilterIcon ? <FilterListIcon /> : null}
        color={filterCount ? 'secondary' : undefined}
        disableElevation
        onClick={handleOpenModal}
        sx={{ maxWidth: 140, maxHeight: 40 }}
      >
        {`${label ?? t('common.btn.filter')}`}
        {!!filterCount && `(${filterCount})`}
      </Button>
    )
  }
  return (
    <IconButton
      onClick={handleOpenModal}
      size="small"
      color={filterCount ? 'primary' : 'inherit'}
      sx={iconSx}
    >
      <FilterListIcon />
    </IconButton>
  )
}

const SearchAndFilterBar: React.FC<{
  handleChangeKeyword: (e: React.ChangeEvent<HTMLInputElement>) => void
  keyword: string
  handleOpenModal: () => void
  filterCount: number
  handleShowSearch: () => void
  iconSx?: React.CSSProperties
  isFilterIcon?: boolean
  customLabel?: boolean
  searchLabel?: string
  btnLabel?: string
  searchBarWidth?: GridSize
  searchStyle?: SxProps
  handleClearKeyword?: () => void
}> = ({
  handleChangeKeyword,
  keyword,
  handleOpenModal,
  filterCount,
  handleShowSearch,
  iconSx,
  isFilterIcon,
  customLabel,
  searchLabel = '',
  btnLabel,
  searchBarWidth = 'auto',
  searchStyle = {},
  handleClearKeyword,
}) => {
  const theme = useTheme()
  const isBreakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Grid
      container
      item
      xs={12}
      columnGap={3.5}
      alignItems="end"
      flexWrap="nowrap"
    >
      <Grid item xs={searchBarWidth} sx={{ ...searchStyle }}>
        {isBreakpointUpLg ? (
          <SearchBar
            handleChangeKeyword={handleChangeKeyword}
            keyword={keyword}
            customLabel={customLabel}
            searchLabel={searchLabel}
            handleClearKeyword={handleClearKeyword}
          />
        ) : (
          <IconButton
            onClick={() => {
              handleShowSearch?.()
            }}
            size="small"
            sx={iconSx}
          >
            <SearchIcon color="primary" />
          </IconButton>
        )}
      </Grid>

      <FilterButton
        filterCount={filterCount}
        handleOpenModal={handleOpenModal}
        label={btnLabel}
        isFilterIcon={isFilterIcon}
        iconSx={iconSx}
      />
    </Grid>
  )
}

export { SearchAndFilterBar, SearchBar, FilterButton }
