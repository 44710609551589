import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { Box } from '@mui/material'

const SCROLLBAR_WIDTH = 15

export const PDFViewer: React.FC<{
  file: string
}> = ({ file }) => {
  const pdfViewRef = useRef<HTMLDivElement | null>(null)
  const [containerWidth, setContainerWidth] = useState<number>(0)

  // Function to render the PDF
  const renderPDF = useCallback(async () => {
    if (!file || !window.pdfjsLib || containerWidth === 0) return

    try {
      // Load the PDF document
      const loadingTask = window.pdfjsLib.getDocument(file)
      const pdf = await loadingTask.promise

      // Clear previous canvases if any
      if (pdfViewRef.current) {
        pdfViewRef.current.innerHTML = ''
      }

      // Fetch all pages concurrently
      const pagePromises = []
      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum += 1) {
        pagePromises.push(pdf.getPage(pageNum))
      }
      const pages = await Promise.all(pagePromises)

      // Render each page on a new canvas
      pages.forEach((page) => {
        // Initial viewport to get original page dimensions
        const viewport = page.getViewport({ scale: 1 })

        // Calculate the scale to fit the container width
        const scale = containerWidth / viewport.width
        const scaledViewport = page.getViewport({ scale })

        // Create canvas container
        const canvasContainer = document.createElement('div')
        canvasContainer.style.position = 'relative'
        canvasContainer.style.width = `${scaledViewport.width}px`
        canvasContainer.style.height = `${scaledViewport.height}px`
        canvasContainer.style.marginBottom = '20px'

        // Create and set up canvas
        const canvas = document.createElement('canvas')
        canvas.height = scaledViewport.height
        canvas.width = scaledViewport.width
        canvasContainer.appendChild(canvas)

        // Create text layer div for this page
        const textLayerDiv = document.createElement('div')
        textLayerDiv.className = 'textLayer'
        textLayerDiv.style.width = `${scaledViewport.width}px`
        textLayerDiv.style.height = `${scaledViewport.height}px`
        textLayerDiv.style.position = 'absolute'
        textLayerDiv.style.top = '0'
        textLayerDiv.style.left = '0'
        textLayerDiv.style.zIndex = '1'
        canvasContainer.appendChild(textLayerDiv)

        const context = canvas.getContext('2d')

        if (context) {
          // Render the page on the canvas
          const renderContext = {
            canvasContext: context,
            viewport: scaledViewport,
          }

          // First render the page
          page.render(renderContext).promise.then(() => {
            // Then get and render the text content
            return page.getTextContent().then((textContent: string) => {
              window.pdfjsLib.renderTextLayer({
                textContent,
                container: textLayerDiv,
                viewport: scaledViewport,
                textDivs: [],
              })
            })
          })

          pdfViewRef.current?.appendChild(canvasContainer)
        }
      })
    } catch (error) {
      if (error instanceof Error && pdfViewRef.current) {
        pdfViewRef.current.innerHTML = `Failed to load PDF file: ${error.message}`
      }
    }
  }, [file, containerWidth])

  // Function to load the pdf.js library script dynamically
  const loadPdfJsLib = useCallback(() => {
    // Check if the script is already added to the document
    if (!window.pdfjsLib) {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf_viewer.min.css'
      document.head.appendChild(link)

      const script = document.createElement('script')
      script.src =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.min.js' // Ensure this matches the version you want
      script.async = true
      script.onload = () => {
        // Configure the workerSrc for pdf.js after loading
        window.pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`
        renderPDF() // Trigger rendering after loading the library
      }
      document.body.appendChild(script)
    } else {
      renderPDF() // Render immediately if pdf.js is already loaded
    }
  }, [renderPDF])

  const updateWidth = useCallback(() => {
    if (pdfViewRef.current) {
      setContainerWidth((prevState) => {
        if (
          pdfViewRef?.current?.offsetWidth &&
          Math.abs(prevState - pdfViewRef.current.offsetWidth) > SCROLLBAR_WIDTH
        ) {
          return pdfViewRef.current?.offsetWidth
        }
        return prevState
      })
    }
  }, [])

  // Resize observer to handle width changes of the container
  useLayoutEffect(() => {
    // Initial update
    updateWidth()

    // Set up ResizeObserver to handle dynamic resizing
    const resizeObserver = new ResizeObserver(() => {
      updateWidth()
    })

    if (pdfViewRef.current) {
      resizeObserver.observe(pdfViewRef.current)
    }

    // Cleanup observer on unmount
    return () => {
      resizeObserver.disconnect()
    }
  }, [updateWidth])

  // Trigger rendering when the file or container width changes
  useEffect(() => {
    loadPdfJsLib()
  }, [loadPdfJsLib])

  return (
    <Box width="100%" position="relative">
      <Box ref={pdfViewRef} width="100%" />
    </Box>
  )
}

export default PDFViewer
