import {
  BaseSubjectStore,
  DataObject,
  DataOption,
  Employee,
  IContractorProfileResume,
  Job,
  LegacySubmissionTemplate,
} from '@procom-labs/common'

export interface ILegacyCopilotStoreState {
  candidateSelection: string
  candidateSelections: DataOption<Employee>[]
  resumeSelections: DataOption<DataObject>[]
  resumeSelection: string
  resumeFile: IContractorProfileResume | null
  resumeFileBlob: string
  resumeSelectionBlob: string
  resumeSelectionFileSize: number

  coverPageJobSelections: DataOption<Job>[]
  coverPageJobSelection: string

  coverPageSelections: DataOption<DataObject>[]
  coverPageSelection: string
  coverPageFile: IContractorProfileResume | null
  coverPageFileBlob: string
  coverPageSelectionBlob: string

  submissionTemplateSelections: DataOption<LegacySubmissionTemplate>[]
  submissionTemplateSelection: string

  resumeFileSfdtText: string
  generating: boolean

  removePersonalInformation: boolean

  processingResume: boolean
  canSelectCandidate: boolean
}

export class LegacyCopilotStore extends BaseSubjectStore<ILegacyCopilotStoreState> {
  getDefaultValue(): ILegacyCopilotStoreState {
    return {
      candidateSelection: '',
      candidateSelections: [],
      canSelectCandidate: true,
      resumeSelections: [],
      resumeSelection: '',
      resumeFile: null,
      resumeFileBlob: '',
      resumeSelectionBlob: '',

      coverPageJobSelections: [],
      coverPageJobSelection: '',
      coverPageSelections: [],
      coverPageSelection: '',
      coverPageFile: null,
      coverPageFileBlob: '',
      coverPageSelectionBlob: '',

      submissionTemplateSelections: [],
      submissionTemplateSelection: '',

      resumeFileSfdtText: '',
      generating: false,
      removePersonalInformation: false,
      processingResume: false,
      resumeSelectionFileSize: 0,
    }
  }
}

export const legacyCopilotStore = new LegacyCopilotStore()
