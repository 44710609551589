import React, { FC } from 'react'
import { FieldError, Merge } from 'react-hook-form'

import { Autocomplete, Chip, TextField, TextFieldProps } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { validEmailRegex } from '@procom-labs/common'

export const AllowedDelimiters = [';', ',', ' ']

export const AutocompleteInputList: FC<
  TextFieldProps & {
    id: string
    label: string
    error?: Merge<FieldError, (FieldError | undefined)[]>
    endAdornment?: React.ReactNode
    disabled?: boolean
    value: any
    onChange: (...event: any[]) => void
  }
> = ({
  id,
  label,
  error,
  endAdornment,
  value,
  onChange,
  disabled,
  ...rest
}) => {
  return (
    <Autocomplete
      multiple
      id={id}
      freeSolo
      options={[]}
      disabled={disabled}
      disableClearable
      clearOnBlur
      renderInput={(params) => {
        const previousValue = `${params.inputProps.value}`
        return (
          <TextField
            {...params}
            {...rest}
            aria-label={label}
            error={!!error}
            autoComplete="off"
            helperText={
              Array.isArray(error)
                ? error.find((e) => e?.type === 'email')?.message
                : error?.message
            }
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                autoComplete: 'off',
                sx: { display: disabled ? 'none' : 'initial' },
              },
              sx: { pt: 1, pl: 1 },
              autoComplete: 'off',
              startAdornment: (
                <>
                  <InputAdornment position="start" sx={{ height: '24px' }}>
                    {label}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {endAdornment}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            onBlur={() => {
              if (previousValue && validEmailRegex.exec(previousValue)) {
                onChange([...value, previousValue])
              }
            }}
            onChange={(event) => {
              const currentValue = `${event.target.value}`
              const isDelimiterCharacter = currentValue?.charAt(
                currentValue.length - 1
              )
              if (
                isDelimiterCharacter &&
                AllowedDelimiters.includes(isDelimiterCharacter) &&
                validEmailRegex.exec(`${previousValue}`)
              ) {
                onChange([...value, previousValue])
              }
            }}
          />
        )
      }}
      renderTags={(tagValue, getTagProps) =>
        (tagValue as string[]).map((option: string, index: number) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      onChange={(e, data) => onChange(data)}
      value={value}
    />
  )
}
