/* eslint-disable no-param-reassign */
import { AxiosRequestConfig } from 'axios'
import Axios from 'axios-observable'

import { environment } from '@submission-portal/environment'
import { performanceTrackingService } from '@submission-portal/services/performance-tracking.service'

import { authStorage } from './auth-storage'

export const axios = Axios.create({})

function authRequestInterceptor(
  config: AxiosRequestConfig
): AxiosRequestConfig {
  if (environment.ENABLE_PERFORMANCE_TRACKING === 'true') {
    const metadataPerfTracking =
      performanceTrackingService.getRequestPerfTracking(config)
    if (metadataPerfTracking) config.metadataPerfTracking = metadataPerfTracking
  }

  if (config.headers) {
    const storedData = authStorage.get(true)
    if (storedData && storedData.token && !config.headers.authorization) {
      config.headers.authorization = `Bearer ${storedData.token}`
    }
    config.headers.Accept = 'application/json'
    config.headers['Content-Type'] =
      config.headers['Content-Type'] || 'application/json'
  }
  return config
}

axios.interceptors.request.use(authRequestInterceptor)
