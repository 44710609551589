export enum BlobTypes {
  Image = 'image/*',
  Pdf = 'application/pdf',
  Csv = 'text/csv',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Xls = 'application/vnd.ms-excel',
  Doc = 'application/msword',
  Txt = 'text/plain',
  Json = 'application/json',
  Xml = 'application/xml',
  Html = 'text/html',
}
