import { defer, fromEventPattern, Observable, switchMap } from 'rxjs'

import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr'

import { SignalrEventNames } from '../enums'
import { DeltaResponse, SuperSubject } from '../models'
import { authStorage, SequencedDataEmitter } from '../util'

export abstract class SignalrService {
  private readonly apiKey: string = ''

  private token: string | undefined = undefined

  private signalRConnection: HubConnection | null = null

  private url: string = ''

  private readonly tokenRefreshed$: Observable<any> | undefined

  private isSignalRConnectedSubject = new SuperSubject<boolean>(false)

  isSignalRConnected$ = this.isSignalRConnectedSubject.observable$

  protected constructor(
    apiKey: string,
    tokenRefreshed: Observable<any> | undefined = undefined
  ) {
    this.apiKey = apiKey
    this.tokenRefreshed$ = tokenRefreshed
    this.listenForTokenRefresh()
  }

  protected get signalRConnectionId(): string | null {
    return this.signalRConnection?.connectionId ?? null
  }

  async connect(): Promise<void> {
    if (
      !this.signalRConnection ||
      this.signalRConnection.state !== HubConnectionState.Connected
    ) {
      await this.startConnection()
    }
  }

  protected getEventStream(
    streamUrl: string,
    eventName: SignalrEventNames
  ): Observable<string> {
    this.url = streamUrl
    return defer(() => this.connect()).pipe(
      switchMap(() => {
        return fromEventPattern<string>(
          (handler) => {
            const dataEmitter = new SequencedDataEmitter({ handler })
            this.signalRConnection?.on(eventName, (data: string) => {
              // Replace \n with <br> for parsing
              const parsableData = data.replace(/\n/g, '<br>')
              const parsedData = JSON.parse(parsableData) as DeltaResponse
              // Replace <br> back to \n
              parsedData.value = parsedData.value.replace(/<br>/g, '\n')
              dataEmitter.receiveDataPacket(parsedData)
            })
          },
          async (handler) => {
            this.signalRConnection?.off(eventName, handler)
            await this.signalRConnection?.stop()
            this.signalRConnection = null
          }
        )
      })
    )
  }

  private async startConnection(): Promise<void> {
    /** 
     isSignalRConnectedSubject to false at beginning. Once the connection is completed it is set to true.
     Helps when user revisits the page and then doesnt get isSignalRConnectedSubject to true even if a new connection
     is being established.
    */
    this.isSignalRConnectedSubject.value = false
    const storedData = authStorage.get()
    const token = this.token ?? storedData?.token ?? ''
    this.signalRConnection = new HubConnectionBuilder()
      .withUrl(this.url, {
        accessTokenFactory: () => token,
        headers: !token ? { 'api-key': this.apiKey } : {},
      })
      .withAutomaticReconnect()
      .build()
    await this.signalRConnection.start()
    if (this.signalRConnectionId) {
      this.isSignalRConnectedSubject.value = true
    }
  }

  private listenForTokenRefresh(): void {
    if (this.tokenRefreshed$) {
      this.tokenRefreshed$.subscribe({
        next: async (data) => {
          this.token = data?.token ?? undefined
          if (
            this.signalRConnection &&
            this.signalRConnection.state !== HubConnectionState.Connected
          ) {
            await this.startConnection()
          }
        },
      })
    }
  }
}
