import React from 'react'
import { LoadingButton } from '@mui/lab'

export interface GeneratorButtonProps
  extends React.ComponentProps<typeof LoadingButton> {
  alt?: string
}

export const GeneratorButton: React.FC<GeneratorButtonProps> = ({
  alt,
  sx,
  ...props
}) => {
  return (
    <LoadingButton
      variant="contained"
      startIcon={
        <img src="/assets/images/ai-generator-white.svg" alt={alt ?? ''} />
      }
      {...props}
      sx={{
        backgroundColor: '#8A325F',
        '&:hover': {
          backgroundColor: '#72284f',
        },
        ...sx,
      }}
    />
  )
}
