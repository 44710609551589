import React, { FC, memo } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from '@mui/material'
import Typography from '@mui/material/Typography'
import { GorillaStack } from '@procom-labs/atoms'

import { EditableSkills } from './editable-skills'

export const RolesEdit: FC = memo(() => {
  const { t } = useTranslation('main')
  return (
    <GorillaStack
      p={3}
      gap={1}
      sx={{ backgroundColor: 'onePager.boxBg', borderRadius: 2 }}
    >
      <GorillaStack gap={2} direction="row" alignItems="center">
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {t('organisms.candidateOnePager.openToRoles')}
        </Typography>
        <Controller
          name="visibility.OpenToRoles"
          defaultValue={false}
          render={({ field }) => (
            <Switch
              {...field}
              color="primary"
              checked={Boolean(field.value)}
              sx={{ ml: '-12px' }}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      </GorillaStack>
      <EditableSkills
        removePrompt={t('organisms.candidateOnePager.openToRolesRemove')}
        name="roles"
        emptyAdd=""
        hideYears
      />
    </GorillaStack>
  )
})
