import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  catchError,
  concatMap,
  interval,
  map,
  of,
  Subscription,
  switchMap,
} from 'rxjs'

import {
  CandidateOnePagerStateEnum,
  candidateOnePagerStore,
  GorillaResumeParsingStatus,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'

import {
  candidateOnePagerService,
  contractorProfileService,
} from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores'

type CandidateOnePagerParserHookType = {
  parse: (file: File, jobSubmissionId: string) => void
  pullOnePagerStatus: (taskId: string, jobSubmissionId: string) => void
  statusSubscriptionRef: MutableRefObject<Subscription | null>
}

enum ProgressMessages {
  STEP1 = 'Step 1/4: Parsing document pages',
  STEP2 = 'Step 2/4: Extracting resume metadata',
  STEP3 = 'Step 3/4: Extracting resume details',
  COMPLETE = 'Extraction complete',
}

const getTransKey = (progress: string) => {
  switch (progress) {
    case ProgressMessages.STEP1:
      return 'step1'
    case ProgressMessages.STEP2:
      return 'step2'
    case ProgressMessages.STEP3:
      return 'step3'

    case ProgressMessages.COMPLETE:
      return 'complete'
    default:
      return null
  }
}

export const useOnePagerParser = (): CandidateOnePagerParserHookType => {
  const { t } = useTranslation('main')
  const subscriptionRef = useSubscriptionRef()
  const statusSubscriptionRef = useSubscriptionRef()

  const resetAll = useCallback((): void => {
    candidateOnePagerStore.dispatch({
      state: CandidateOnePagerStateEnum.NO_FILE,
      onePager: null,
      progress: '',
      error: null,
    })
  }, [])

  // Pull the status every 2 seconds
  const pullOnePagerStatus = useCallback(
    (taskId: string, jobSubmissionId: string): void => {
      statusSubscriptionRef.current?.unsubscribe()
      const source = interval(2000)

      statusSubscriptionRef.current = source
        .pipe(
          switchMap(() =>
            candidateOnePagerService.getParsedResumeStatus(
              taskId,
              jobSubmissionId
            )
          )
        )
        .subscribe({
          next: (data) => {
            // If the job is complete, stop the interval and update the state

            const key = getTransKey(data.progressMessage)

            let progress = data.progressMessage
            if (key) {
              progress = t(`candidateOnePagerParser.progressMessages.${key}`)
            }
            if (data.status === GorillaResumeParsingStatus.Complete) {
              statusSubscriptionRef.current?.unsubscribe()
              candidateOnePagerStore.dispatch({
                state: CandidateOnePagerStateEnum.DONE,
                progress,
                // Update the store with the one pager
                onePager: data,
              })
            } else {
              candidateOnePagerStore.dispatch({
                progress,
              })

              if (data.status === GorillaResumeParsingStatus.Error) {
                statusSubscriptionRef.current?.unsubscribe()
                candidateOnePagerStore.dispatch({
                  state: CandidateOnePagerStateEnum.ERROR,
                  error: t('submissionDetail.candidateOnePager.errors.generic'),
                })
              }
            }
          },
          error: () => {
            statusSubscriptionRef.current?.unsubscribe()
            candidateOnePagerStore.dispatch({
              state: CandidateOnePagerStateEnum.ERROR,
              error: t('submissionDetail.candidateOnePager.errors.generic'),
            })
          },
        })
    },
    [statusSubscriptionRef, t]
  )

  const parse = useCallback(
    (file: File, jobSubmissionId: string) => {
      if (!file) return

      // When parsing a new resume, reset the state
      resetAll()

      candidateOnePagerStore.dispatch({
        state: CandidateOnePagerStateEnum.UPLOADING,
      })

      const formData = new FormData()
      formData.append('file', file)
      formData.append('JobSubmissionId', jobSubmissionId)

      subscriptionRef.current = contractorProfileService
        .uploadResume(formData)
        .pipe(
          switchMap((response) => {
            if (!response.contractorResume.fileStorageId) {
              throw new Error(
                t('candidateOnePagerParser.errors.fileStorageError')
              )
            }
            return candidateOnePagerService.startResumeParse(
              formData,
              jobSubmissionId,
              response.contractorResume.fileStorageId
            )
          })
        )
        .subscribe({
          next: ({ taskId }) => {
            // Start pulling the status of the job
            pullOnePagerStatus(taskId, jobSubmissionId)
          },
          error: (e) => {
            candidateOnePagerStore.dispatch({
              state: CandidateOnePagerStateEnum.ERROR,
              error: `${t(
                'candidateOnePagerParser.errors.resumeUploadError'
              )}: ${e}`,
            })
          },
        })
    },
    [resetAll, pullOnePagerStatus, subscriptionRef, t]
  )

  return {
    parse,
    pullOnePagerStatus,
    statusSubscriptionRef,
  }
}

export const useMultipleOnePagerPolling = (): void => {
  const { t } = useTranslation('main')

  const { pendingOnePagers } = useSubjectSelector(submissionStore, [
    'pendingOnePagers',
  ])
  const ongoingSubscriptions = useRef<Record<string, Subscription>>({})

  const pollItem = useCallback(
    (taskId: string, jobSubmissionId: string) => {
      const polling$ = interval(2000).pipe(
        concatMap(() =>
          candidateOnePagerService.getParsedResumeStatus(
            taskId,
            jobSubmissionId
          )
        ),
        map((data) => ({ data, error: null })),
        catchError((error) => of({ data: null, error }))
      )

      // Store the subscription for this specific item
      ongoingSubscriptions.current[taskId] = polling$.subscribe({
        next: ({ data, error }) => {
          if (
            error ||
            !data ||
            data.status === GorillaResumeParsingStatus.Error
          ) {
            submissionStore.removePendingOnePager(taskId)
            submissionStore.updateFinishedOnePagers({
              state: CandidateOnePagerStateEnum.ERROR,
              onePager: null,
              taskId,
              progress: '',
              jobSubmissionId,
              error: t('submissionDetail.candidateOnePager.errors.generic'),
            })
            return
          }

          if (data.status === GorillaResumeParsingStatus.Complete) {
            submissionStore.removePendingOnePager(taskId)
            submissionStore.updateFinishedOnePagers({
              state: CandidateOnePagerStateEnum.DONE,
              onePager: data,
              taskId,
              progress: t('candidateOnePagerParser.progressMessages.complete'),
              jobSubmissionId,
              error: null,
            })
          } else {
            const key = getTransKey(data.progressMessage)
            const progress = key
              ? t(`candidateOnePagerParser.progressMessages.${key}`)
              : data.progressMessage

            submissionStore.updatePendingOnePagers({
              taskId,
              jobSubmissionId,
              progress,
            })
          }
        },
      })
    },
    [t]
  )

  useEffect(() => {
    pendingOnePagers.forEach(({ taskId, jobSubmissionId }) => {
      if (!ongoingSubscriptions.current[taskId]) {
        pollItem(taskId, jobSubmissionId) // Start polling only for new items
      }
    })
  }, [pendingOnePagers, pollItem])

  useEffect(() => {
    Object.keys(ongoingSubscriptions.current).forEach((taskId) => {
      if (pendingOnePagers.every((p) => p.taskId !== taskId)) {
        // Unsubscribe from items that are no longer in the pending list
        if (
          ongoingSubscriptions.current[taskId] &&
          !ongoingSubscriptions.current[taskId].closed
        ) {
          ongoingSubscriptions.current[taskId]?.unsubscribe()
          submissionStore.removePendingOnePager(taskId)
        }
      }
    })
  }, [pendingOnePagers])

  useEffect(() => {
    return () => {
      // Clean up subscriptions when component unmounts
      Object.values(ongoingSubscriptions.current).forEach((subscription) => {
        if (!subscription.closed) {
          subscription.unsubscribe()
        }
      })
    }
  }, [])
}
