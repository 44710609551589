import { ChangeEvent, useCallback, useState } from 'react'

export const useActiveTab = (
  initialValue: number = 0
): [number, (event: ChangeEvent<{}>, newValue: number) => void] => {
  const [value, setValue] = useState(initialValue)

  const handleChange = useCallback(
    (event: ChangeEvent<{}>, newValue: number): void => {
      setValue(newValue)
    },
    []
  )

  return [value, handleChange]
}
