import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ListItem } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { IOnePagerJobs } from '@procom-labs/common'

import { getSkillProgress } from '../one-pager-utils'
import { SkillProgress } from './skill-progress'
import { SkillsWrapper } from './skills-wrapper'

export const Jobs: FC<{ jobs: IOnePagerJobs }> = memo(({ jobs }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <SkillsWrapper
      title={t('organisms.candidateOnePager.jobs')}
      minYears={jobs.minYears}
      maxYears={jobs.maxYears}
    >
      {jobs.jobs?.map((job) => (
        <ListItem sx={{ p: 0, pb: 2 }} key={job.name}>
          <SkillProgress
            label={job.name}
            years={job.yearsOfExperience}
            value={getSkillProgress(job.yearsOfExperience, jobs.maxYears)}
            linearProgressColor={theme.palette.onePager.jobsProgress}
            linearProgressColorBg={theme.palette.onePager.jobsProgressBg}
          />
        </ListItem>
      ))}
    </SkillsWrapper>
  )
})
