export enum JobSubmissionStatusTypes {
  ClientSubmission = 'Client Submission',
  SubmittedToManager = 'Submitted to Manager',
  Pipeline = 'Pipeline',
  Interviewing = 'Interviewing',
  Confirmed = 'Confirmed',
  Placement = 'Placement',
  WithdrawalByCandidate = 'Withdrawal by Candidate',
  ClientInterview = 'Client Interview',
  ClientFeedback = 'Client Feedback',
  ClientRejected = 'Client Rejected',
  ClientShortlisted = 'Client Shortlisted',
  DeclinedbyClient = 'Declined by Client',
  DeclinedbyClient_DoubleSub = 'Declined by Client-Double Sub',
  InterviewRequested = 'Interview Requested',
  InterviewScheduled = 'Interview Scheduled',
  Offer = 'Offer',
  OfferExtended = 'Offer Extended',
  OfferRejected = 'Offer Rejected',
  OfferWithdrawn = 'Offer Withdrawn',
  AmDeclineFit = 'AM Decline-Fit',
  Rejected = 'rejected',
}

export enum PortalStatus {
  ClientRejected = 'Client Rejected',
  ClientShortlisted = 'Client Shortlisted',
  OfferExtended = 'Offer Extended',
  ClientInterview = 'Client Interview',
}

export enum JobSubmissionPortalStatusTypes {
  ReadyToPrep = 'readyToPrep',
  SubmittedToManager = 'submittedToManager',
  SubmittedToClient = 'submittedToClient',
}

export enum JobSubmissionPortalStatusLabels {
  ReadyToPrep = 'Ready to Prep',
  SubmittedToManager = 'Submitted to Manager',
  SubmittedToClient = 'Submitted to Client',
  ClientSubmission = 'Client Submission',
  ClientShortlisted = 'Client Shortlisted',
  ClientRejected = 'Client Rejected',
  ClientInterview = 'Client Interview',
  OfferExtended = 'Offer Extended',
}

export const JobSubmissionPortalStatusActionMap: Record<string, string> = {
  [JobSubmissionPortalStatusLabels.ReadyToPrep]:
    JobSubmissionPortalStatusLabels.ReadyToPrep.valueOf(),
  [JobSubmissionPortalStatusLabels.SubmittedToManager]:
    JobSubmissionPortalStatusLabels.SubmittedToManager.valueOf(),

  // Client Submission is an exception
  [JobSubmissionPortalStatusLabels.SubmittedToClient]: 'Client Submission',
}

// The allowed actions to use when updating the portal status
export enum SubmissionActions {
  ClientSubmission = 'Client Submission',
  SubmittedToManager = 'Submitted to Manager',
}

export const JobSubmissionPortalStatusTypeMap: Record<
  string,
  JobSubmissionPortalStatusTypes
> = {
  [JobSubmissionPortalStatusLabels.ReadyToPrep]:
    JobSubmissionPortalStatusTypes.ReadyToPrep,
  [JobSubmissionPortalStatusLabels.SubmittedToManager]:
    JobSubmissionPortalStatusTypes.SubmittedToManager,
  [JobSubmissionPortalStatusLabels.SubmittedToClient]:
    JobSubmissionPortalStatusTypes.SubmittedToClient,
}

export enum JobSubmissionAtsStatusTypes {
  Rejected = 'rejected',
  AMHoldNeedInfo = 'aMHoldNeedInfo',
  AMHoldRTR = 'aMHoldRTR',
  AMHoldShowMore = 'aMHoldShowMore',
  AMHoldTentative = 'aMHoldTentative',
  AMHoldWillSubmit = 'aMHoldWillSubmit',
  AMLockUp = 'aMLockUp',
  ClientFeedback = 'clientFeedback',
  Confirmed = 'confirmed',
  InterviewRequested = 'interviewRequested',
  Interviewing = 'interviewing',
  Placement = 'placement',
  ShortlistedByMSP = 'shortlistedByMSP',
}

export enum JobSubmissionAtsStatusLabels {
  Rejected = 'Rejected',
  AMHoldNeedInfo = 'AM Hold-Need Info',
  AMHoldRTR = 'AM Hold-RTR',
  AMHoldShowMore = 'AM Hold-Show More',
  AMHoldTentative = 'AM Hold-Tentative',
  AMHoldWillSubmit = 'AM Hold-Will Submit',
  AMLockUp = 'AM-Lock Up',
  ClientFeedback = 'Client Feedback',
  Confirmed = 'Confirmed',
  InterviewRequested = 'Interview Requested',
  Interviewing = 'Interviewing',
  Placement = 'Placement',
  ShortlistedByMSP = 'Shortlisted by MSP',
  Handoff = 'Handoff',
}

export const JobSubmissionAtsStatusTypeMap: Record<
  string,
  JobSubmissionAtsStatusTypes
> = {
  [JobSubmissionAtsStatusLabels.Rejected]: JobSubmissionAtsStatusTypes.Rejected,
  [JobSubmissionAtsStatusLabels.AMHoldNeedInfo]:
    JobSubmissionAtsStatusTypes.AMHoldNeedInfo,
  [JobSubmissionAtsStatusLabels.AMHoldRTR]:
    JobSubmissionAtsStatusTypes.AMHoldRTR,
  [JobSubmissionAtsStatusLabels.AMHoldShowMore]:
    JobSubmissionAtsStatusTypes.AMHoldShowMore,
  [JobSubmissionAtsStatusLabels.AMHoldTentative]:
    JobSubmissionAtsStatusTypes.AMHoldTentative,
  [JobSubmissionAtsStatusLabels.AMHoldWillSubmit]:
    JobSubmissionAtsStatusTypes.AMHoldWillSubmit,
  [JobSubmissionAtsStatusLabels.AMLockUp]: JobSubmissionAtsStatusTypes.AMLockUp,
  [JobSubmissionAtsStatusLabels.ClientFeedback]:
    JobSubmissionAtsStatusTypes.ClientFeedback,
  [JobSubmissionAtsStatusLabels.Confirmed]:
    JobSubmissionAtsStatusTypes.Confirmed,
  [JobSubmissionAtsStatusLabels.InterviewRequested]:
    JobSubmissionAtsStatusTypes.InterviewRequested,
  [JobSubmissionAtsStatusLabels.Interviewing]:
    JobSubmissionAtsStatusTypes.Interviewing,
  [JobSubmissionAtsStatusLabels.Placement]:
    JobSubmissionAtsStatusTypes.Placement,
  [JobSubmissionAtsStatusLabels.ShortlistedByMSP]:
    JobSubmissionAtsStatusTypes.ShortlistedByMSP,
}

export enum JobStatusActions {
  RejectionPopup = 'RejectionPopup',
  ShortlistPopup = 'ShortlistPopup',
  OfferPopup = 'OfferPopup',
  InterviewPopup = 'InterviewPopup',
}

export interface JobStatusMapping {
  bullhornStatus: JobSubmissionAtsStatusLabels
  portalStatus: PortalStatus
  contractorPortalStatus: string
  excludeFromPortal: boolean
  keepPreviousStatus: boolean
  isSubmissionToolStatus: boolean
  entityType: string
  actions: JobStatusActions | null
  id: string
  isDeleted: boolean
  partitionKey: string
  environmentName: string
  _etag: string
  createdByProfileGuid: string | null
  lastModifiedByProfileGuid: string | null
  createdDateTime: string
  lastModifiedDateTime: string
}

export enum ContractorPortalJobStatus {
  Open = 'Open',
  InterviewingApplicants = 'Interviewing Applicants',
  ReviewingCandidates = 'Reviewing Candidates',
  NoSubmissions = 'No Submissions',
  Draft = 'Draft',
  OnHold = 'On Hold',
  Filled = 'Filled',
  FilledbyOther = 'Filled by Other',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
}

export enum RequirePreferenceCodeEnum {
  Required = 'Required',
  Desired = 'Desired',
}

export enum JobPositionStatusCodeEnum {
  FullTime = 'Full-time',
  PartTime = 'Part-time',
}

export enum StatesRequiredToShowPay {
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  MD = 'MD',
  NV = 'NV',
  NJ = 'NJ',
  NY = 'NY',
  RI = 'RI',
  WA = 'WA',
  BC = 'BC',
  PEI = 'PEI',
  PE = 'PE',
}

export enum JobStatus {
  ALL = 'all',
  Active = 'Active',
  Paused = 'On Hold',
  Closed = 'Closed',
  Draft = 'Draft',
  PendingReview = 'Pending Review',
}

export enum JobAiQuestionsTypes {
  Behavioural = 'Behavioural',
  Technical = 'Technical',
}

export enum JobKeywordFields {
  Title = 'Title',
  HiringManagerName = 'HiringManagerName',
  City = 'City',
  Country = 'Country',
  State = 'State',
  ClientRequisitionNumber = 'ClientRequisitionNumber',
}

export enum JobSearchType {
  Jobs = 'Jobs',
  Candidates = 'Candidates',
}

export enum JobFilterFields {
  DateAdded = 'DateAdded',
  ClientCode = 'ClientCode',
  LastModifiedDateTime = 'LastModifiedDateTime',
  CreatedDateTime = 'CreatedDateTime',
  DatePosted = 'DatePosted',
}

export enum TalentPoolsSortOptions {
  Newest = 'newest',
  Oldest = 'oldest',
  Name = 'name',
  Interests = 'interests',
}

export enum TalentPoolsFilters {
  interestedMe = 'interestedMe',
  interestedCollaborators = 'interestedCollaborators',
  notInterestedMe = 'notInterestedMe',
  notInterestedCollaborators = 'notInterestedCollaborators',
}

export enum TalentPoolReactionsSizes {
  Small = 'small',
  Medium = 'medium',
}

export enum TalentPoolTabsMapping {
  Resume,
  Notes,
  Timeline,
}

export enum ResponseTypeEnum {
  Numeric = 'Numeric',
  MultipleChoice = 'MultipleChoice',
}

export enum ScreeningQuestionType {
  Mandatory = 'Mandatory',
  Preferred = 'Preferred',
}

export enum MultipleChoiceAnswers {
  Yes = 'Yes',
  No = 'No',
}
