import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useSubjectSelector } from '@procom-labs/common'
import { DropDownActionMenuButton } from '@procom-labs/molecules'
import { CellDateTimeView } from '@procom-labs/organisms'

import { CandidatePrepActionsContent } from '@submission-portal/components/candidate-prep/candidate-prep-actions'
import { CandidatePrepEditorInfo } from '@submission-portal/components/candidate-prep/components/candidate-prep-editor-info'
import { useIsCandidatePrepReadOnly } from '@submission-portal/components/candidate-prep/hooks/candidate-prep-core-hooks'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

export const CandidatePrepCandidateEditorHeader: React.FC = () => {
  const { t } = useTranslation('main')

  const { candidate: submission } = useSubjectSelector(candidatesPrepStore, [
    'candidate',
  ])

  const isTabbingDisabled = useIsCandidatePrepReadOnly()

  if (!submission) {
    return <CircularProgress size={16} sx={{ color: 'text.disabled' }} />
  }

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={6}
        md
        container
        alignItems="center"
        justifyContent="flex-start"
      >
        <CandidatePrepEditorInfo />
      </Grid>

      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {submission.dateLastModifiedOnAts && (
          <Box mr={4}>
            <CellDateTimeView
              value={submission.dateLastModifiedOnAts}
              label={t(`candidatePreb.modified`)}
            />
          </Box>
        )}

        {submission.lastModifiedDateTime && (
          <Box mr={4}>
            <CellDateTimeView
              value={submission.lastModifiedDateTime}
              label={t(`candidatePreb.lastViewed`)}
            />
          </Box>
        )}

        <Box mr={4}>
          <Button
            disabled={isTabbingDisabled}
            id="btn-submit-candidate-prep"
            size="medium"
            type="submit"
          >
            {t('common.btn.save')}
          </Button>
        </Box>

        <Box>
          <DropDownActionMenuButton
            buttonProps={{
              disabled: isTabbingDisabled,
              variant: 'contained',
              color: 'primary',
              children: t('common.btn.submit'),
            }}
            id="cta-submit-candidate"
          >
            {(close) => (
              <CandidatePrepActionsContent
                jobSubmissionId={submission?.id}
                close={close}
                status={submission?.status}
              />
            )}
          </DropDownActionMenuButton>
        </Box>
      </Box>
    </Grid>
  )
}
