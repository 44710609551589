import { FC, memo } from 'react'
import { Box, Grid, Typography, Checkbox } from '@mui/material'

interface ITipsAndSuggestionsCard {
  title: string
  description: string
}

export const TipsSuggestionsCard: FC<ITipsAndSuggestionsCard> = memo(
  ({ title, description }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          my: 1,
          pb: 2,
          border: '1px solid',
          flexWrap: 'wrap',
          borderRadius: '20px',
          '& > :not(style)': {
            minHeight: 50,
            height: 'fit-content',
          },
        }}
      >
        <Grid container spacing={1} px={1}>
          <Grid item>
            <Checkbox />
          </Grid>
          <Grid item mt={1}>
            <Typography
              variant="body1"
              sx={{
                maxWidth: '300px',
              }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Typography px={2} variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
          {description}
        </Typography>
      </Box>
    )
  }
)
