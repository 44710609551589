import React, { FC } from 'react'
import { Field, FieldProps } from 'formik'

import { OutlinedInputProps } from '@mui/material'

import { CompensationField, CompensationFieldType } from './compensation-field'
import { RatePeriodSelect } from './rateperiod-field'

export const BillRateFormikField: FC<{
  name: string
  ratePeriodFieldName: string
  size: OutlinedInputProps['size']
  sx?: OutlinedInputProps['sx']
}> = ({ name, ratePeriodFieldName, size, sx }) => {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <CompensationField
          {...field}
          type={CompensationFieldType.BillRate}
          size={size}
          sx={sx}
          renderSelect={
            <Field name={ratePeriodFieldName}>
              {({ field: ratePeriodTypeField }: FieldProps) => (
                <RatePeriodSelect field={ratePeriodTypeField} />
              )}
            </Field>
          }
          aria-describedby="billRate-text"
        />
      )}
    </Field>
  )
}
