import { styled } from '@mui/material/styles'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { Accordion as MuiAccordion, AccordionProps } from '@mui/material'
import React from 'react'

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowRightIcon
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  height: 42,
  '.Mui-expanded.MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  color: theme.palette.primary.main,
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  borderTop: '0',
}))

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={3} {...props} />
))(() => ({
  marginBottom: 10,
  boxShadow: 'none',
}))

export const MobileAccordion: React.FC<{ details: React.ReactNode }> = ({
  children,
  details,
}) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary>{children}</AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  )
}
