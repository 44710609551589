import { BaseSubjectStore, JobStatusMapping } from '@procom-labs/common'

export interface IJobStatusStoreState {
  jobStatuses: JobStatusMapping[]
}

export class JobStatusStore extends BaseSubjectStore<IJobStatusStoreState> {
  getDefaultValue(): IJobStatusStoreState {
    return {
      jobStatuses: [],
    }
  }
}

export const jobStatusStore = new JobStatusStore()
