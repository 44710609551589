import React, { ReactNode } from 'react'
import { Box } from '@mui/material'

export interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...rest
}) => {
  return (
    <Box role="tabpanel" hidden={value !== index} {...rest}>
      {value === index && <Box p={1}>{children}</Box>}
    </Box>
  )
}
