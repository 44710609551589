import { useEffect, useState } from 'react'
import {
  debounce,
  delay,
  fromEvent,
  interval,
  merge,
  of,
  Subscription,
  tap,
} from 'rxjs'

export const useIdle = (ms = 300000): boolean => {
  const [idle, setIdle] = useState(false)

  useEffect(() => {
    let idleTimer: Subscription

    const startIdleTimer = (): void => {
      idleTimer = of(true)
        .pipe(delay(ms))
        .subscribe(() => setIdle(true))
    }

    const resetIdleTimer = (): void => {
      if (idleTimer) {
        idleTimer.unsubscribe()
      }
      startIdleTimer()
    }

    const activity$ = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'mousedown'),
      fromEvent(window, 'resize'),
      fromEvent(window, 'keydown'),
      fromEvent(window, 'touchstart'),
      fromEvent(window, 'wheel')
    ).pipe(
      debounce(() => interval(1000)),
      tap(() => {
        if (idle) {
          setIdle(false) // Set idle state to false on any activity
        }
        resetIdleTimer() // Reset the idle timer
      })
    )

    startIdleTimer() // Start idle timer initially

    const subscription = activity$.subscribe()

    return () => {
      subscription.unsubscribe()
      if (idleTimer) idleTimer.unsubscribe() // Cleanup timer on component unmount
    }
  }, [ms])

  return idle
}
