import React, { FC, ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Slide } from '@mui/material'

import { IconButtonComponent } from './icon-button'

interface IGorillaSliderBase {
  direction?: 'left' | 'right'
  children: ReactElement[]
  selectedSlideIndex?: number
  setSelectedSlideIndex?: (slideIndex: number) => void
}

interface IGorillaSliderControlled extends IGorillaSliderBase {
  selectedSlideIndex: number
  setSelectedSlideIndex: (slideIndex: number) => void
}

interface IGorillaSliderUncontrolled extends IGorillaSliderBase {
  selectedSlideIndex?: never
  setSelectedSlideIndex?: never
}

type GorillaSliderProps = IGorillaSliderControlled | IGorillaSliderUncontrolled

export const GorillaSlider: FC<GorillaSliderProps> = ({
  direction = 'left',
  children,
  selectedSlideIndex,
  setSelectedSlideIndex,
}) => {
  const { t } = useTranslation('main')
  const totalSlides = React.Children.count(children)

  const [internalSlideIndex, setInternalSlideIndex] = useState<number>(0)

  const slideIndex =
    selectedSlideIndex !== undefined ? selectedSlideIndex : internalSlideIndex
  const setSlideIndex =
    setSelectedSlideIndex !== undefined
      ? setSelectedSlideIndex
      : setInternalSlideIndex

  const handlePreviousSlide = useCallback((): void => {
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1)
    }
  }, [slideIndex, setSlideIndex])

  const handleNextSlide = useCallback((): void => {
    if (slideIndex < totalSlides - 1) {
      setSlideIndex(slideIndex + 1)
    }
  }, [slideIndex, setSlideIndex, totalSlides])

  return (
    <Box m="auto" width="100%">
      {/* Slider navigation buttons */}
      {totalSlides > 1 && (
        <Grid
          container
          display="grid"
          gridTemplateColumns="1fr 1fr"
          justifyContent="center"
          sx={{ backgroundColor: 'common.purple' }}
        >
          <Grid item display="flex" justifyContent="flex-start">
            <IconButtonComponent
              buttonText={t('common.candidates.dialog.interview.dates')}
              isDisabled={slideIndex === 0}
              navigateSlides={handlePreviousSlide}
              direction="left"
            />
          </Grid>
          <Grid item display="flex" justifyContent="flex-end">
            <IconButtonComponent
              buttonText={t('common.candidates.dialog.interview.times')}
              isDisabled={totalSlides === slideIndex + 1}
              navigateSlides={handleNextSlide}
              direction="right"
            />
          </Grid>
        </Grid>
      )}
      {/* Slider content */}
      {React.Children.map(children, (child: ReactElement, index) => (
        <Slide
          direction={direction}
          in={slideIndex === index}
          mountOnEnter
          unmountOnExit
        >
          {child}
        </Slide>
      ))}
    </Box>
  )
}
