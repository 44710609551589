import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'
import { Observable, Subscription } from 'rxjs'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Box, Typography } from '@mui/material'
import { CapsuleButton, Preloader } from '@procom-labs/atoms'
import {
  errorToString,
  IEntityType,
  IFile,
  IResume,
  ITrackData,
  PageTypes,
  TrackingEvents,
  useExecuteOnUnmountOrIdle,
  useGtmVpv,
} from '@procom-labs/common'
import { PDFViewer } from '@procom-labs/molecules'

type ResumeMeta = {
  isLoading: boolean
  url?: string
  error?: string
}

export const Resume: React.FC<{
  resume$: Observable<ArrayBuffer | null>
  candidateResume: IResume | IFile | null
  entityType: IEntityType
  entityId: string
  parentEntityId?: string
  parentEntityType?: IEntityType
  jobTitle?: string
  talentPoolName?: string
  showResumeShortErr?: boolean
  downloadDataTrackingEvents?: TrackingEvents.DataTracking[]
  handleResumeUnmount?: (isSuddenShutDown?: boolean) => void
}> = ({
  resume$,
  candidateResume,
  entityId,
  entityType,
  parentEntityType,
  parentEntityId,
  jobTitle,
  talentPoolName,
  showResumeShortErr = false,
  downloadDataTrackingEvents = [],
  handleResumeUnmount,
}) => {
  const [resumeMeta, setResumeMeta] = useState<ResumeMeta>({
    isLoading: true,
  })

  useExecuteOnUnmountOrIdle(handleResumeUnmount)
  const subscriptionRef = useRef<Subscription>()

  const { trackEvent } = useTracking<ITrackData>({
    entityType,
    entityId,
    parentEntityId,
    parentEntityType,
    jobTitle,
    talentPoolName,
  })
  const { t } = useTranslation('main')

  const clearSubscription = (): void => {
    const { current } = subscriptionRef
    if (current && !current.closed) {
      current.unsubscribe()
    }
  }

  useGtmVpv({
    pageType: PageTypes.CandidateViewResume,
    pageTitle: t('organisms.candidateDetails.resume'),
  })

  useEffect(() => {
    subscriptionRef.current = resume$.subscribe({
      next: (value) => {
        if (value) {
          const file = new Blob([value], { type: 'application/pdf' })
          const url = URL.createObjectURL(file)
          setResumeMeta({
            isLoading: false,
            url,
          })
        } else {
          setResumeMeta({
            isLoading: false,
          })
        }
      },
      error: (err) => {
        setResumeMeta({
          isLoading: false,
          error: errorToString(err),
        })
      },
    })
    return clearSubscription
  }, [resume$])

  const handleResumeDownloadTracking = useCallback(() => {
    trackEvent({
      event: TrackingEvents.CandidateDetail.DownloadResume,
    })
    downloadDataTrackingEvents.map((x) =>
      trackEvent({
        event: x,
      })
    )
  }, [downloadDataTrackingEvents, trackEvent])

  const resumeUnavailableErr = showResumeShortErr
    ? t('organisms.candidateDetails.resumeUnavailableShort')
    : t('organisms.candidateDetails.resumeUnavailable')

  return (
    <Box
      pt={2}
      px={{ xs: 0, md: 2 }}
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      {(() => {
        if (resumeMeta.isLoading) {
          return <Preloader />
        }

        if (resumeMeta.url && candidateResume) {
          return (
            <>
              <CapsuleButton
                color="secondary"
                variant="contained"
                href={resumeMeta.url}
                id="btn-download-resume"
                download={`${candidateResume.name}${candidateResume.extension}`}
                startIcon={<ArrowDownwardIcon />}
                onClick={handleResumeDownloadTracking}
                sx={{
                  marginLeft: 'auto',
                  marginBottom: 2,
                  zIndex: 999,
                }}
              >
                {t('organisms.candidateDetails.downloadResume')}
              </CapsuleButton>
              <PDFViewer file={resumeMeta.url} />
            </>
          )
        }

        if (resumeMeta.error) {
          return (
            <Typography variant="h6" color="error.light" paragraph>
              {resumeMeta.error}
            </Typography>
          )
        }
        return (
          <Typography
            variant="h6"
            component="h3"
            sx={{
              alignItems: 'center',
              display: 'flex',
              mb: 2,
              mt: 5,
            }}
          >
            {t('organisms.candidateDetails.resume')}
            {resumeUnavailableErr}
          </Typography>
        )
      })()}
    </Box>
  )
}
