import { styled } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'

export const SubLabel = styled(InputLabel)(({ theme }) => ({
  transform: 'none',
  position: 'relative',
  fontWeight: 500,
  marginBottom: theme.spacing(2),
  color: theme.palette.common.black,
  span: {
    fontWeight: 400,
  },
}))

export const SubHelperText = styled(FormHelperText)(() => ({
  marginLeft: 0,
}))
