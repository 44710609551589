import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import EmailIcon from '@mui/icons-material/Email'
import Insights from '@mui/icons-material/Insights'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'

export const MoreActions: FC<{
  close: () => void
}> = ({ close }) => {
  const { t } = useTranslation('main')

  const items = useMemo(() => {
    return [
      {
        id: 'btn-follow-up',
        label: `${t('submissionList.btn.followUp')} (${t(
          'submissionList.btn.comingSoon'
        )})`,
        icon: <EmailIcon fontSize="small" />,
        disabled: true,
      },
      {
        id: 'btn-email-candidate',
        label: `${t('submissionList.btn.emailCandidate')} (${t(
          'submissionList.btn.comingSoon'
        )})`,
        icon: <EmailIcon fontSize="small" />,
        disabled: true,
      },
      {
        id: 'btn-get-insights',
        label: `${t('submissionList.btn.getInsights')} (${t(
          'submissionList.btn.comingSoon'
        )})`,
        icon: <Insights fontSize="small" />,
        disabled: true,
      },
    ]
  }, [t])

  return (
    <>
      {items.map(({ id: itemId, icon, label, disabled }) => (
        <MenuItem id={itemId} key={itemId} onClick={close} disabled={disabled}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      ))}
    </>
  )
}
