import { FC, ReactNode, useState } from 'react'
import { styled } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel'
import { Box } from '@mui/material'
import { BoxProps } from '@mui/material/Box'

export interface TitledPanelProps extends BoxProps {
  label: string
  children: ReactNode
}

const legendTransition = [
  'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  'max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
].join(', ')

const Legend = styled(InputLabel)(({ focus }: { focus: string }) => ({
  color: focus === 'true' ? '#408ccc' : '#00000099',
  position: 'absolute',
  top: '0px',
  left: '8px',
  transform: 'translate(0px, -9px) scale(0.75)',
  transformOrigin: 'top left',
  zIndex: '1',
  backgroundColor: 'white',
  padding: '0 10px',
  maxWidth: 'calc(133% - 32px)',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  pointerEvents: 'auto',
  userSelect: 'none',
  transition: legendTransition,
}))
const BorderBox = styled(Box)(({ focus }: { focus: string }) => ({
  position: 'relative',
  border:
    focus === 'true' ? '2px solid #408ccc' : '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: 4,
  padding: 20,
  '&:hover': {
    borderColor: focus === 'true' ? null : '#000',
  },
}))
export const TitledPanel: FC<TitledPanelProps> = ({
  label,
  children,
  ...restProps
}) => {
  const [focus, setFocus] = useState(false)

  return (
    <BorderBox
      focus={focus.toString()}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      {...restProps}
    >
      <Legend focus={focus.toString()} shrink>
        {label}
      </Legend>
      {children}
    </BorderBox>
  )
}
