import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import Parse from 'html-react-parser'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { finalize, from, switchMap } from 'rxjs'

import { LoadingButton } from '@mui/lab'
import {
  Card,
  CardActions,
  CardContent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
  errorToString,
  PreferredLanguageType,
  RecaptchaActions,
  useRecaptcha,
  useSubscriptionRef,
} from '@procom-labs/common'
import GorillaWorksSvg from '@procom-labs/common/src/assets/vendors/PCGL/GorillaWorks/logo-dark.png'
import { TextHookField, useAlert } from '@procom-labs/molecules'

import { languageCopilotService } from '@submission-portal/services/language-copilot.service'

import { rollbarInstance } from '../providers'

const CHARACTER_LIMIT = 10000
const targetLanguageToFollowLabelEn = 'English version to follow'
const targetLanguageToFollowLabelFr = 'Version français à suivre'
const gptTranslationLabelEn = 'Translated by ChatGPT'
const gptTranslationLabelFr = 'Traduit par ChatGPT'

type FormInputs = {
  languageTextInput: string
}

const SvgProcomLogo = React.memo(() => {
  const SVG = styled('svg')(({ theme }) => ({
    fill: theme.palette.primary.main,
    display: 'block',
    width: 300,
    height: 60,
    objectFit: 'contain',
    objectPosition: 'center center',
    use: {
      '--text-color': theme.palette.primary.main,
      '--translate': '0px',
    },
  }))
  return (
    <SVG>
      <use href="/assets/clients/Procom/procom-logo.svg#default" />
    </SVG>
  )
})

export const LanguageCopilotMain: FC = () => {
  const { t, i18n } = useTranslation('main')
  const { addAlert } = useAlert()
  const translatedContainerRef = useRef<HTMLDivElement>(null)
  const subscriptionRef = useSubscriptionRef()
  const deltaStreamRef = useSubscriptionRef()
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [originalText, setOriginalText] = useState<string>('')
  const [translatedText, setTranslatedText] = useState<string>('')

  const isFrenchSelected = useMemo(() => {
    return (
      i18n.language?.toLowerCase() === PreferredLanguageType.FR.toLowerCase()
    )
  }, [i18n.language])

  const { handleSubmit, control } = useForm<FormInputs>({
    defaultValues: {
      languageTextInput: '',
    },
  })

  const { getRecaptchaToken } = useRecaptcha({ rollbarInstance })

  const onSubmit: SubmitHandler<FormInputs> = (data): void => {
    const { languageTextInput } = data
    const inputText = languageTextInput.trim()
    if (!inputText) {
      addAlert({
        message: t('languageCopilot.noTextWarning'),
        severity: 'warning',
      })
      return
    }
    setIsDataLoading(true)
    setOriginalText(inputText)
    subscriptionRef.current = from(
      getRecaptchaToken(RecaptchaActions.LanguageCopilot)
    )
      .pipe(
        switchMap((token: string) => {
          setTimeout(() => {
            if (translatedContainerRef.current) {
              translatedContainerRef.current.scrollIntoView({
                behavior: 'smooth',
              })
            }
          }, 100)
          return languageCopilotService.getTranslation(
            inputText,
            i18n.language,
            token
          )
        }),
        finalize(() => setIsDataLoading(false))
      )
      .subscribe({
        next: (result) => {
          setTranslatedText(result)
        },
        error: (err) => {
          addAlert({
            message: errorToString(err),
            severity: 'error',
          })
        },
      })
  }

  const onLanguageChange = (newLanguage: PreferredLanguageType): void => {
    from(i18n.changeLanguage(newLanguage)).subscribe()
  }

  const copyToClipboard = (): void => {
    // this is intentional. When en is selected, it needs to show fr text
    const title1 = isFrenchSelected
      ? targetLanguageToFollowLabelEn
      : targetLanguageToFollowLabelFr
    const title2 = isFrenchSelected
      ? gptTranslationLabelEn
      : gptTranslationLabelFr
    const formattedEn = originalText.replace(/\n/g, '<br />')
    const text = `<h4><i>${title1}</i></h4><div>${formattedEn}</div><h4><i>${title2}</i></h4><div>${translatedText}</div>`
    const clipboardItem = new ClipboardItem({
      'text/html': new Blob([text], { type: 'text/html' }),
    })
    navigator.clipboard.write([clipboardItem]).then(() => {
      addAlert({
        message: t('languageCopilot.copied'),
      })
    })
  }

  useEffect(() => {
    document.title = t('languageCopilot.languageCopilot')
  }, [t])

  useEffect(() => {
    deltaStreamRef.current = languageCopilotService
      .getTranslationStream()
      .subscribe({
        next: (data) => {
          setTranslatedText(data)
        },
      })
  }, [deltaStreamRef])

  return (
    <>
      <Stack direction="column" height="100vh" overflow="auto">
        <Box m={2}>
          <SvgProcomLogo />
        </Box>
        <Box sx={{ flex: 1 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction="column"
              spacing={2}
              sx={{ maxWidth: '1000px', margin: '0 auto' }}
            >
              <Grid item>
                <h3>{t('languageCopilot.languageCopilot')}</h3>
              </Grid>
              <Grid item xs={12} mb={2}>
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  value={i18n.language}
                  aria-label={t('common.jobGenerator.fineTuneJobLanguageType')}
                  onChange={(_, value) => {
                    if (value) {
                      onLanguageChange(value)
                    }
                  }}
                >
                  <ToggleButton value={PreferredLanguageType.EN}>
                    {t('common.jobGenerator.preferredLanguage.en')}
                  </ToggleButton>
                  <ToggleButton value={PreferredLanguageType.FR}>
                    {t('common.jobGenerator.preferredLanguage.fr')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <TextHookField
                    control={control}
                    name="languageTextInput"
                    multiline
                    inputProps={{ maxLength: CHARACTER_LIMIT }}
                    minRows={10}
                    fullWidth
                    helperText={`${originalText.length}/${CHARACTER_LIMIT}`}
                    disabled={isDataLoading}
                  />
                </Stack>
              </Grid>
              <Grid item>
                <LoadingButton
                  sx={{ marginTop: '20px' }}
                  loading={isDataLoading}
                  type="submit"
                  variant="contained"
                >
                  {t('languageCopilot.translate')}
                </LoadingButton>
              </Grid>
              <Grid
                item
                mt={10}
                ref={translatedContainerRef}
                sx={{ minHeight: '350px' }}
              >
                {originalText && translatedText && (
                  <>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography
                          sx={{
                            fontStyle: 'italic',
                          }}
                          variant="h6"
                        >
                          {/* this is intentional. When en is selected, it needs to show fr text */}
                          {isFrenchSelected
                            ? targetLanguageToFollowLabelEn
                            : targetLanguageToFollowLabelFr}
                        </Typography>
                        <Typography>
                          {Parse(originalText.replace(/\n/g, '<br />'))}
                        </Typography>
                        <Typography
                          sx={{
                            fontStyle: 'italic',
                          }}
                          variant="h6"
                        >
                          {isFrenchSelected
                            ? gptTranslationLabelEn
                            : gptTranslationLabelFr}
                        </Typography>
                        <Typography>{Parse(translatedText)}</Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          onClick={copyToClipboard}
                          size="small"
                          disabled={isDataLoading}
                        >
                          {t('languageCopilot.copyToClipboard')}
                        </Button>
                      </CardActions>
                    </Card>
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box m={2}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Typography variant="body2" mr={3}>
              Powered by GorillaWorks / Propulsé par GorillaWorks
            </Typography>
            <img src={GorillaWorksSvg} alt="logo" width="150px" />
          </Stack>
        </Box>
      </Stack>
    </>
  )
}
