import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import {
  Box,
  IconButtonProps,
  List,
  ListItem,
  ListItemText,
  SxProps,
} from '@mui/material'

import { ArrowButtonComponent } from './arrow-button'

interface ICustomList {
  dataList?: string[]
  children?: ReactNode
  scrollAmountInPixel?: number
  id: string
  listContainerSX?: SxProps
  listSX?: SxProps
  iconButtonSX?: IconButtonProps
}

type CustomListWithRequiredDataListOrChildren = ICustomList &
  ({ dataList: string[] } | { children: ReactNode })

const ListItemComponent: FC<{
  listItem: string
  id: string
  index: number
}> = memo(({ listItem, id, index }) => (
  <ListItem key={`${id}-${index}`}>
    <ListItemText>{listItem}</ListItemText>
  </ListItem>
))

export const ScrollButtonList: FC<CustomListWithRequiredDataListOrChildren> = ({
  dataList,
  children,
  id,
  scrollAmountInPixel,
  listContainerSX,
  listSX,
  iconButtonSX,
}) => {
  const [listHeight, setListHeight] = useState('')
  const listRef = useRef<any>(null)
  const containerRef = useRef<HTMLElement>(null)
  const iconRef = useRef<HTMLElement>(null)

  const handleScrollUp = useCallback((): void => {
    if (listRef.current) {
      listRef.current.scrollTop -= scrollAmountInPixel || 50
    }
  }, [scrollAmountInPixel])

  const handleScrollDown = useCallback((): void => {
    if (listRef.current) {
      listRef.current.scrollTop += scrollAmountInPixel || 50
    }
  }, [scrollAmountInPixel])

  useLayoutEffect(() => {
    const containerWidth = containerRef.current?.getBoundingClientRect().height
    const iconWidth = iconRef?.current?.getBoundingClientRect().height
    if (containerWidth && iconWidth) {
      setListHeight(`${containerWidth - 2 * iconWidth}px`)
    }
  }, [])

  return (
    <Box ref={containerRef} sx={{ ...listContainerSX, overflow: 'hidden' }}>
      <ArrowButtonComponent
        scrollList={handleScrollUp}
        direction="top"
        iconButtonSX={iconButtonSX}
        ref={iconRef}
      />
      <List
        ref={listRef}
        sx={{
          overflow: 'auto',
          maxHeight: listHeight,
          padding: '10px 0',
          '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for Chrome, Safari and Opera
          },
          '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
          'scrollbar-width': 'none', // Hide scrollbar for Firefox
          ...listSX,
        }}
      >
        {dataList ? (
          dataList.map((listItem, index) => (
            <ListItemComponent listItem={listItem} id={id} index={index} />
          ))
        ) : (
          <>{children}</>
        )}
      </List>
      <ArrowButtonComponent
        scrollList={handleScrollDown}
        direction="down"
        iconButtonSX={iconButtonSX}
        ref={iconRef}
      />
    </Box>
  )
}
