import React, { FC, ReactNode, useCallback, useState } from 'react'
import { ArrowDropDown } from '@mui/icons-material'
import { Chip, Menu, MenuItem, SxProps, Theme } from '@mui/material'

export interface DropdownItem {
  value: string
  label: string
}

export type ExtendedSxProps<T extends object> = SxProps<T> & {
  deleteIconColor?: string | null | undefined
}

export interface ChipDropdownProps {
  id: string
  fallbackLabel?: string
  value: string
  items: DropdownItem[]
  sx?: ExtendedSxProps<Theme>
  menuSx?: ExtendedSxProps<Theme>
  menuItemsSx?: ExtendedSxProps<Theme>
  disabled?: boolean
  onSelect: (value: string) => void
  renderMenuItem: (props: {
    item: DropdownItem
    selectedItemLabel: string
    handleItemSelect: (event: React.MouseEvent<HTMLElement>) => void
    sx?: ExtendedSxProps<Theme>
  }) => ReactNode
}

export const ChipDropdown: FC<ChipDropdownProps> = ({
  id,
  fallbackLabel,
  value,
  items,
  disabled,
  onSelect,
  sx,
  menuSx,
  menuItemsSx,
  renderMenuItem,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const selectedItem = items.find((item) => item.value === value)

  const handleDropdownClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleItemSelect = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      const selectedValue = event.currentTarget.getAttribute('value') || ''
      onSelect(selectedValue)
      setAnchorEl(null)
    },
    [onSelect]
  )

  const handleClose = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const deleteIconColor = sx?.deleteIconColor
  const showDropdownIcon =
    !disabled && deleteIconColor !== undefined && deleteIconColor !== null

  const selectedItemLabel = selectedItem?.label ?? fallbackLabel ?? ''

  return (
    <>
      <Chip
        component="button"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        label={selectedItemLabel}
        onClick={!disabled ? handleDropdownClick : undefined}
        onDelete={
          showDropdownIcon
            ? (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                handleDropdownClick(event)
            : undefined
        }
        deleteIcon={
          showDropdownIcon ? (
            <ArrowDropDown style={{ color: deleteIconColor }} />
          ) : undefined
        }
        sx={sx}
      />
      {!disabled && (
        <Menu
          id="chip-dropdown-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': id,
          }}
          sx={menuSx}
        >
          {items.map(({ value: itemValue, label }) =>
            renderMenuItem ? (
              renderMenuItem({
                item: { value: itemValue, label },
                selectedItemLabel,
                handleItemSelect,
                sx: menuItemsSx,
              })
            ) : (
              <MenuItem
                key={itemValue}
                value={itemValue}
                title={label}
                onClick={handleItemSelect}
              >
                {label}
              </MenuItem>
            )
          )}
        </Menu>
      )}
    </>
  )
}
