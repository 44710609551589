import { useMemo } from 'react'

import { useGetDateAvailable } from './use-get-date-available'

export const useFormatDateAvailable = (
  dateAvailable: string | undefined,
  dateAvailableType: string,
  dateAvailableUserInput: string | undefined
): string => {
  const getDateAvailable = useGetDateAvailable()

  return useMemo(
    () =>
      getDateAvailable(
        dateAvailable,
        dateAvailableType,
        dateAvailableUserInput
      ),
    [dateAvailable, dateAvailableType, dateAvailableUserInput, getDateAvailable]
  )
}
