import { useCallback, useEffect } from 'react'

import { documentTitleStore, useSubjectSelector } from '../store'

const useDocumentTitle = (): {
  setTitle: (title?: string | null) => void
  setNotificationCount: (notificationCount?: number | null) => void
} => {
  const { title: stateTitle, notificationCount: stateCount } =
    useSubjectSelector(documentTitleStore, ['title', 'notificationCount'])

  useEffect(() => {
    if (stateCount > 0) {
      document.title = `(${stateCount}) ${stateTitle}`
    } else {
      document.title = stateTitle
    }
    return () => {
      document.title = stateTitle
    }
  }, [stateCount, stateTitle])
  return {
    setTitle: useCallback((title?: string | null) => {
      if (title !== null && title !== undefined) {
        documentTitleStore.setTitle(title)
      }
    }, []),
    setNotificationCount: useCallback((notificationCount?: number | null) => {
      if (notificationCount !== null && notificationCount !== undefined) {
        documentTitleStore.setNotificationCount(notificationCount)
      }
    }, []),
  }
}

export { useDocumentTitle }
