import React, { ReactNode } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

import { Checkbox, FormControlLabel } from '@mui/material'

interface CustomCheckboxFieldProps<
  TFieldValues extends FieldValues = FieldValues
> {
  control: Control<TFieldValues> | undefined
  name: Path<TFieldValues>
  label: string | ReactNode
  required?: boolean
  disabled?: boolean
  handleChange?: (checked: boolean) => void
}
export const CheckboxHookField = <
  TFieldValues extends FieldValues = FieldValues
>({
  control,
  name,
  label,
  required,
  disabled,
  handleChange,
}: CustomCheckboxFieldProps<TFieldValues>): React.ReactElement => {
  return (
    <FormControlLabel
      control={
        control ? (
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Checkbox
                {...field}
                onChange={(e, checked) => {
                  if (handleChange) {
                    handleChange(checked)
                  } else {
                    field.onChange(e)
                  }
                }}
                disabled={disabled}
                checked={field.value || false}
                required={required}
                color="primary"
              />
            )}
          />
        ) : (
          <Checkbox disabled checked={false} />
        )
      }
      label={label}
      disabled={disabled}
    />
  )
}
