import { LoadingButton } from '@mui/lab'
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton'
import { Button, ButtonProps, styled } from '@mui/material'

export const ResumeCopilotProButton = styled(Button)<ButtonProps>(() => ({
  borderRadius: '24px',
  textTransform: 'none',
  minWidth: 'auto',
}))

export const ResumeCopilotProLoadingButton = styled(
  LoadingButton
)<LoadingButtonProps>(() => ({
  borderRadius: '24px',
  textTransform: 'none',
  minWidth: 'auto',
}))
