export enum CartCandidateStatuses {
  Ready = 'Ready',
  PartiallyFilled = 'PartiallyFilled',
  NotStarted = 'NotStarted',
}

export enum CartStatuses {
  InProgress = 'In Progress',
  Complete = 'Complete',
}
