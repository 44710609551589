import { useCallback } from 'react'
import { arrayToBlobUrl, downloadFile, IResume } from '@procom-labs/common'
import { contractorProfileService } from '@submission-portal/services'
import { FileActions } from '@submission-portal/types'

export const useViewOrDownloadFile = (
  userId: string,
  setBlobUrl: (arg: string) => void,
  setShowingFile: (arg: boolean) => void,
  setProcessing: (arg: boolean) => void
): ((resumes: IResume[], actionType: string) => void) => {
  return useCallback(
    (resumes: IResume[], actionType: string) => {
      const [{ fileStorageId, name }] = resumes
      if (fileStorageId) {
        contractorProfileService
          .downloadResume(fileStorageId, userId)
          .subscribe({
            next: (data) => {
              if (actionType === FileActions.Download) {
                downloadFile(data, name, false, 'application/pdf')
              } else if (actionType === FileActions.View) {
                const newBlobUrl = arrayToBlobUrl(data, 'application/pdf')
                setBlobUrl(newBlobUrl)
                setShowingFile(true)
              }

              setProcessing(false)
            },
          })
      } else {
        setProcessing(false)
      }
    },
    [userId, setShowingFile, setBlobUrl, setProcessing]
  )
}
