import { FC, useMemo, useRef } from 'react'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Typography,
  Grid,
} from '@mui/material'
import { GorillaGrid, GorillaTable } from '@procom-labs/atoms'
import { TableRowSpacer } from '@procom-labs/organisms'
import {
  JobSubmissionReport,
  LeaderboardHeaderType,
} from '@submission-portal/models'
import { useTranslation } from 'react-i18next'
import { PaletteModeCode } from '@procom-labs/common'
import { LeaderBoardListItem } from './leader-board-list-item'

export const LeaderBoardComponent: FC<{ eventData: JobSubmissionReport[] }> = ({
  eventData,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const tableRef = useRef<any>(null)

  const tableHeaderList = useMemo(() => {
    const list = [
      {
        field: LeaderboardHeaderType.AccountManager,
        transKey: 'common.eventTracking.leaderboardTableHeaders.accountManager',
        width: 246,
      },
      {
        field: LeaderboardHeaderType.VMSMSP,
        transKey: 'common.eventTracking.leaderboardTableHeaders.vmpmsp',
        width: 166,
        sortable: true,
      },
      {
        field: LeaderboardHeaderType.Email,
        transKey: 'common.eventTracking.leaderboardTableHeaders.email',
        sortable: true,
        width: 166,
      },
      {
        field: LeaderboardHeaderType.Portal,
        transKey: 'common.eventTracking.leaderboardTableHeaders.portal',
        sortable: true,
        width: 166,
      },
    ]
    return list
  }, [])

  const groupedAccountManagerData = useMemo(() => {
    const groupedData = eventData.reduce(
      (acc: any, obj: JobSubmissionReport) => {
        const { accountManagerName, submissionType } = obj
        if (!acc[accountManagerName]) {
          acc[accountManagerName] = {
            vmsEvents: 0,
            emailEvents: 0,
            portalEvents: 0,
          }
        }

        if (submissionType === 1) {
          acc[accountManagerName].vmsEvents += 1
        } else if (submissionType === 2) {
          acc[accountManagerName].emailEvents += 1
        } else if (submissionType === 3) {
          acc[accountManagerName].portalEvents += 1
        }

        return acc
      },
      {}
    )

    const mappedAccountManagerData = Object.entries(groupedData).map(
      ([key, value]) => ({
        [key]: value,
      })
    )

    const totalCounts: any = {}

    mappedAccountManagerData?.forEach((obj) => {
      const [key, value]: any = Object.entries(obj)[0]
      const counts = Object.values(value).reduce(
        (acc: any, count) => acc + count,
        0
      )
      totalCounts[key] = counts
    })

    mappedAccountManagerData?.sort((a, b) => {
      const [keyA] = Object.entries(a)[0]
      const [keyB] = Object.entries(b)[0]
      return totalCounts[keyB] - totalCounts[keyA]
    })
    return mappedAccountManagerData?.slice(0, 5) // for now showing only top 5 events
  }, [eventData])

  return (
    <Grid container>
      <Typography
        variant="h5"
        gutterBottom
        mt={3}
        color={theme.palette.common.white}
      >
        {t('common.eventTracking.leaderboard')}
      </Typography>
      <GorillaGrid
        container
        sx={{
          position: 'relative',
          mb: 1,
          height: 'fit-content',
          pr: 1,
          overflow: 'auto',
        }}
      >
        <TableContainer
          sx={{
            height: '100%',
            overflowX: 'unset',
            '::-webkit-scrollbar': {
              height: '.5em',
            },
          }}
          ref={tableRef}
        >
          <GorillaTable
            themeMode={PaletteModeCode.glass}
            sx={{
              minWidth: 900,
            }}
          >
            <TableHead
              sx={{
                '& .MuiTableCell-root': {
                  padding: '16px 42px',
                },
              }}
            >
              <TableRow sx={{ fontSize: '1.4rem' }}>
                {tableHeaderList.map((column) => (
                  <TableCell
                    key={column.field}
                    sx={{
                      minWidth: column.width,
                    }}
                  >
                    {column.field}
                  </TableCell>
                ))}
              </TableRow>
              <TableRowSpacer sx={{ height: 20 }} />
            </TableHead>

            <TableBody>
              {groupedAccountManagerData?.map((obj) => {
                const [key, value]: any = Object.entries(obj)[0]
                return (
                  <LeaderBoardListItem
                    key={`${key}-${value.vmsEvents}`}
                    submitter={key}
                    vmsEvents={value.vmsEvents}
                    emailEvents={value.emailEvents}
                    portalEvents={value.portalEvents}
                  />
                )
              })}
            </TableBody>
          </GorillaTable>
        </TableContainer>
      </GorillaGrid>
    </Grid>
  )
}
