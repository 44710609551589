import { alpha, Theme } from '@mui/material'
import {
  CandidateStatusType,
  JobSubmissionAtsStatusLabels,
  JobSubmissionPortalStatusLabels,
  JobSubmissionStatusTypes,
} from '@procom-labs/common'

import { IEmailForm } from '@submission-portal/types/interfaces'

// First three statuses are for PCGL instance
export const ValidViewSubmissionStatuses = [
  JobSubmissionStatusTypes.ClientSubmission.toLowerCase(),
  JobSubmissionStatusTypes.Offer.toLowerCase(),
  JobSubmissionStatusTypes.InterviewRequested.toLowerCase(),
  CandidateStatusType.Offer.toLowerCase(),
  CandidateStatusType.Rejected.toLowerCase(),
  CandidateStatusType.Interview.toLowerCase(),
  CandidateStatusType.Shortlist.toLowerCase(),
]

export const CandidatePrepPortalStatusColor = ({
  palette,
}: Theme): Record<string, string> => ({
  [JobSubmissionPortalStatusLabels.ReadyToPrep]: '#E2F49A99',
  [JobSubmissionPortalStatusLabels.SubmittedToManager]: '#C3EA9299',
  [JobSubmissionPortalStatusLabels.SubmittedToClient]: '#9ADC8F99',
  [JobSubmissionPortalStatusLabels.ClientSubmission]: '#9ADC8F99',
  [JobSubmissionPortalStatusLabels.ClientShortlisted]: alpha(
    palette.candidatesFunnel.shortlist,
    0.3
  ),
  [JobSubmissionPortalStatusLabels.ClientRejected]: alpha(
    palette.candidatesFunnel.rejected,
    0.3
  ),
  [JobSubmissionPortalStatusLabels.ClientInterview]: alpha(
    palette.candidatesFunnel.interview,
    0.3
  ),
  [JobSubmissionPortalStatusLabels.OfferExtended]: alpha(
    palette.candidatesFunnel.offer,
    0.3
  ),
})
export const CandidatePrepAtsStatusColor = ({
  palette,
}: Theme): Record<JobSubmissionAtsStatusLabels, string> => ({
  [JobSubmissionAtsStatusLabels.AMHoldNeedInfo]: palette.warning.main,
  [JobSubmissionAtsStatusLabels.AMHoldRTR]: palette.warning.main,
  [JobSubmissionAtsStatusLabels.AMHoldShowMore]: palette.warning.main,
  [JobSubmissionAtsStatusLabels.AMHoldTentative]: palette.warning.main,
  [JobSubmissionAtsStatusLabels.AMHoldWillSubmit]: palette.warning.main,
  [JobSubmissionAtsStatusLabels.AMLockUp]: palette.warning.main,
  [JobSubmissionAtsStatusLabels.Rejected]: palette.error.main,
  [JobSubmissionAtsStatusLabels.ClientFeedback]: palette.primary.main,
  [JobSubmissionAtsStatusLabels.Confirmed]: palette.success.main,
  [JobSubmissionAtsStatusLabels.InterviewRequested]: palette.primary.main,
  [JobSubmissionAtsStatusLabels.Interviewing]: palette.primary.main,
  [JobSubmissionAtsStatusLabels.Placement]: palette.success.main,
  [JobSubmissionAtsStatusLabels.ShortlistedByMSP]: palette.success.main,
  [JobSubmissionAtsStatusLabels.Handoff]: palette.primary.main,
})

export const EmailFormDefaultValues: IEmailForm = {
  language: '',
  emailFrom: [],
  emailContent: '',
  emailSubject: '',
  emailTo: [],
  emailToCc: [],
  emailToBcc: [],
  sendEmailCopyToSender: false,
}

export const SendEmailCopyKey = 'sendEmailCopyToSender'

export const gorillaWorksUrl: string = 'https://gorillaworks.io/'
