import { performanceTrackingService } from '@submission-portal/services/performance-tracking.service'

export const onDownloadCopilotResume = (): void => {
  const {
    location: { origin, pathname },
  } = window
  performanceTrackingService.sendDirectPerformanceTracking({
    url: `${origin}${pathname}/download-resume`,
  })
}
