import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Stack, Typography } from '@mui/material'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { useTheme } from '@mui/material/styles'

export const Pagination: React.FC<{
  handlePrevious: () => void
  handleNext: () => void
  page: number
  pages: number
  sx?: React.CSSProperties
}> = ({ handlePrevious, handleNext, page, pages, sx }) => {
  const { t } = useTranslation('main')
  const theme = useTheme()
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        justifyContent: 'space-between',
        marginLeft: 0,
        [theme.breakpoints.up('lg')]: {
          width: '80%',
        },
        ...sx,
      }}
    >
      <IconButton
        aria-label={t('common.aria.previous')}
        onClick={handlePrevious}
        disabled={page === 1}
        color="primary"
        disableRipple={page === 1}
        size="small"
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <Typography variant="body1" component="span">
        {page}/{pages}
      </Typography>
      <IconButton
        aria-label={t('common.aria.next')}
        onClick={handleNext}
        disabled={page >= pages}
        color="primary"
        disableRipple={page >= pages}
        size="small"
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Stack>
  ) as ReactElement
}
