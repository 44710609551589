import { useMemo } from 'react'

import { Table, TableProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { deepMergeObjects, PaletteModeCode } from '@procom-labs/common'

import { WithGorillaInnerTheme } from './with-inner-theme'

type ComponentProps = TableProps & {
  shrinkRows?: boolean
}

const StyledGorillaTable = styled(Table, {
  shouldForwardProp: (prop) => prop !== 'shrinkRows',
})<ComponentProps>(({ sx, theme, shrinkRows }) => {
  const styleGorillaTableConfig = useMemo(
    () => ({
      '.MuiTableHead-root': {
        '.MuiTableRow-root': {
          '.MuiTableCell-root': {
            border: 'none',
            backgroundColor: 'inherit',
            '.MuiTableSortLabel-root': {
              fontSize: '16px',
              fontWeight: '700',
            },
            padding: shrinkRows ? '16px 15px' : undefined,
          },
        },
        '.MuiTableRow-root:first-of-type': {
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
      },
      '.MuiTableBody-root': {
        '.MuiTableRow-root': {
          '&:not(.not-found):not(:empty)': {
            backgroundColor:
              theme.palette.mode === PaletteModeCode.dark
                ? theme.palette.common.glassOnGlass
                : theme.palette.background.default,
            borderRadius: '20px',
            ...(theme.palette.mode === PaletteModeCode.light && {
              borderRadius: '10px',
              boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
            }),
            ...(theme.palette.mode !== PaletteModeCode.light && {
              '&:hover:not(.no-hover)': {
                boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
              },
            }),
          },
          '.MuiTableCell-root': {
            border: 'none',
            padding: shrinkRows ? '13px 15px' : '36px 15px',
            '&:first-of-type': {
              borderRadius: '20px 0 0 20px',
            },
            '&:last-of-type': {
              borderRadius: ' 0 20px 20px 0',
            },
          },
          '&:is(.dialog-table-row)': {
            '.MuiTableCell-root': {
              padding: '18.5px 42px',
            },
          },
        },
        '.MuiTableCell-root': {
          border: 'none',
        },
      },
    }),
    [theme]
  )
  if (sx)
    return useMemo(
      () => deepMergeObjects(styleGorillaTableConfig, sx),
      [styleGorillaTableConfig, sx]
    )
  return styleGorillaTableConfig
})
export const GorillaTable =
  WithGorillaInnerTheme<ComponentProps>(StyledGorillaTable)
