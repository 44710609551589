import React, { FC, useMemo, useState } from 'react'
import { AlertProps, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { GorillaAlert } from '@procom-labs/atoms'

interface CloseableAlertProps extends AlertProps {
  /**
   * Key to track the alert state (open/closed) in the session storage.
   * If provided, the component will remember its closed state across component
   * re-renders using the session storage.
   */
  trackKey?: string

  /**
   * Value to be stored in the session storage when the alert is closed.
   * Default value is 'closed'.
   */
  trackValue?: string
}

/**
 * A customizable alert component that can be closed and optionally remember
 * its closed state across component re-renders using the session storage.
 *
 * @example
 * // This alert will remember its closed state across re-renders
 * <SessionCloseableAlert trackKey="my-alert-key" severity="error">
 *   This is an error message!
 * </SessionCloseableAlert>
 *
 * @example
 * // This alert will not remember its closed state
 * <SessionCloseableAlert severity="info">
 *   This is an informational message!
 * </SessionCloseableAlert>
 */
export const SessionCloseableAlert: FC<CloseableAlertProps> = ({
  trackKey,
  trackValue = 'closed',
  ...alertProps
}) => {
  // Determine if the alert should initially be open or not
  const initialOpenValue = useMemo(() => {
    if (trackKey) {
      // If trackKey is provided and its value in sessionStorage is the trackValue, return false.
      return sessionStorage.getItem(trackKey) !== trackValue
    }

    return true
  }, [trackKey, trackValue])

  const [open, setOpen] = useState(initialOpenValue)

  /**
   * Handle the closing of the alert.
   * If trackKey is provided, it sets the item in session storage.
   */
  const handleClose = (): void => {
    if (trackKey) {
      sessionStorage.setItem(trackKey, trackValue)
    }
    setOpen(false)
  }

  // If the alert is not open, don't render anything
  if (!open) {
    return null
  }

  return (
    <GorillaAlert
      {...alertProps}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {alertProps.children}
    </GorillaAlert>
  )
}
