import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { HighlightOutlined } from '@mui/icons-material'
import {
  IAdditionalHighlight,
  OnePagerListItemObject,
} from '@procom-labs/common'

import { useCandidateOnePager } from '../one-pager-hooks'
import { List } from './list'

export const Highlights: FC<{
  additionalHighlights: IAdditionalHighlight[]
}> = memo(({ additionalHighlights }) => {
  const { t } = useTranslation()
  const { getHighlightText } = useCandidateOnePager()
  const items: OnePagerListItemObject[] = useMemo(
    () =>
      additionalHighlights.map((highlight) => ({
        primary: getHighlightText(highlight),
      })),
    [additionalHighlights, getHighlightText]
  )

  return (
    <List
      icon={HighlightOutlined}
      items={items}
      title={t('organisms.candidateOnePager.additionalHighlights')}
    />
  )
})
