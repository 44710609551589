import * as React from 'react'
import { useMemo } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import {
  BoxProps,
  Grid,
  GridProps,
  LinearProgress,
  Stack,
} from '@mui/material'
import {
  DataGridProps,
  GridRowHeightReturnValue,
  GridRowIdGetter,
  GridRowSpacingParams,
} from '@mui/x-data-grid'
import { GorillaDataGrid } from '@procom-labs/atoms'
import { GlassBoxWrapper } from '../layout'

interface DefaultAppTableSlots {
  noRowsOverlay: (props: any) => React.ReactElement | null
  noResultsOverlay: (props: any) => React.ReactElement | null
  loadingOverlay: typeof LinearProgress
  columnSortedDescendingIcon: typeof ArrowDropDownIcon
  columnSortedAscendingIcon: typeof ArrowDropUpIcon
}

export const useDefaultAppTableSlots = (
  label?: string
): DefaultAppTableSlots | undefined => {
  const Overlay = useMemo(
    () => (): React.ReactElement | null => {
      return label ? (
        <Stack height="100%" alignItems="center" justifyContent="center">
          {label}
        </Stack>
      ) : null
    },
    [label]
  )

  return label
    ? {
        noRowsOverlay: Overlay,
        noResultsOverlay: Overlay,
        loadingOverlay: LinearProgress,
        columnSortedDescendingIcon: ArrowDropDownIcon,
        columnSortedAscendingIcon: ArrowDropUpIcon,
      }
    : undefined
}

export interface AppDataGridProps
  extends Omit<
    DataGridProps,
    | 'getRowId'
    | 'getRowHeight'
    | 'disableColumnMenu'
    | 'hideFooterSelectedRowCount'
    | 'sx'
  > {
  getRowId?: GridRowIdGetter
  getRowHeight?: () => GridRowHeightReturnValue
  disableColumnMenu?: boolean
  hideFooterSelectedRowCount?: boolean
  sx?: object
  slotLabel?: string
  gridProps?: GridProps
  boxProps?: BoxProps
  idField?: string
}

export const AppDataGrid: React.FC<AppDataGridProps> = ({
  idField = 'id', // By default, the idField will be 'id'
  getRowId = (params) => params[idField],
  getRowHeight = () => 'auto',
  disableColumnMenu = true,
  hideFooterSelectedRowCount = true,
  sx = {},
  slotLabel,
  gridProps,
  boxProps,
  ...props
}) => {
  const slots = useDefaultAppTableSlots(slotLabel)

  // Remove, and rely on styled(DataGrid)
  const defaultStyles = {
    minHeight: '200px',
  }

  const combinedSx = { ...defaultStyles, ...sx }
  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.indexRelativeToCurrentPage === 0 ? 12 : 0,
      bottom: 20,
    }
  }, [])

  return (
    <Grid item display="flex" flexGrow={1} mb={8} {...gridProps}>
      <GlassBoxWrapper {...boxProps}>
        <GorillaDataGrid
          isDarkMode
          disableVirtualization
          getRowId={getRowId}
          getRowHeight={getRowHeight}
          getRowSpacing={getRowSpacing}
          disableColumnMenu={disableColumnMenu}
          hideFooterSelectedRowCount={hideFooterSelectedRowCount}
          sx={combinedSx}
          aria-label={slotLabel}
          slots={slots}
          {...props}
        />
      </GlassBoxWrapper>
    </Grid>
  )
}

export default AppDataGrid
