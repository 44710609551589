import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { forkJoin } from 'rxjs'

import { alpha, Backdrop, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  mapToDataArray,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert, useDefaultErrorHandler } from '@procom-labs/molecules'

import { AiLoader } from '@submission-portal/components'
import { CandidatePrepGeneratorButton } from '@submission-portal/components/candidate-prep/components/candidate-prep-generator-button'
import { SubmissionPrepAdditionalDocs } from '@submission-portal/components/candidate-prep/components/submission-prep-additional-docs'
import { SubmissionPrepFileSelector } from '@submission-portal/components/candidate-prep/components/submission-prep-file-selector'
import { ResourceFetchScope } from '@submission-portal/hooks'
import { serviceDescriptorStore } from '@submission-portal/stores'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'

export const SubmissionPrepDocumentUploader: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const subscriptionRef = useSubscriptionRef()
  const { addAlert } = useAlert()
  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const { candidate: submission, preppingSubmission } = useSubjectSelector(
    candidatesPrepStore,
    ['candidate', 'preppingSubmission']
  )

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  const atsUserId = submission?.candidate?.atsUserId
  const atsJobId = submission?.atsJobId

  useEffect(() => {
    if (atsUserId && atsJobId) {
      subscriptionRef.current = forkJoin([
        candidateAtsService.getCandidateFiles({
          atsCandidateId: Number(atsUserId),
        }),
        candidateAtsService.getJobOrderFiles({
          atsJobId,
        }),
      ]).subscribe({
        next: ([resumeData, coverPageData]) => {
          candidatesPrepStore.dispatch({
            resumeSelections: mapToDataArray(resumeData, 'name'),
            coverPageSelections: mapToDataArray(coverPageData, 'name'),
          })
        },
        error: handleError,
      })
    }

    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
      }
    }
  }, [atsUserId, atsJobId, handleError, subscriptionRef, candidateAtsService])

  return (
    <Box>
      <Backdrop
        open={preppingSubmission}
        sx={{
          zIndex: 4,
          color: theme.palette.common.white,
          backgroundColor: alpha(theme.palette.common.black, 0.7),
        }}
      >
        <AiLoader />
      </Backdrop>
      <SubmissionPrepFileSelector
        label={t('candidatePreb.coverPageOptional')}
        property="coverPage"
        placeholder={t('candidatePreb.noCoverPageSelected')}
        scope={ResourceFetchScope.AtsJob}
        sx={{ mt: 2 }}
      />
      <SubmissionPrepFileSelector
        label={t('candidatePreb.resume')}
        property="resume"
        placeholder={t('candidatePreb.noResumeSelected')}
        scope={ResourceFetchScope.AtsCandidate}
        sx={{ mt: 2 }}
        required
      />

      <SubmissionPrepAdditionalDocs />

      <CandidatePrepGeneratorButton />
    </Box>
  )
}
