import { Dialog, DialogContent } from '@mui/material'
import { PDFViewer } from '@procom-labs/molecules'
import React from 'react'

interface PdfViewerDialogProps {
  blobUrl: string
  onClose: () => void
}
export const PdfViewerDialog: React.FC<PdfViewerDialogProps> = ({
  blobUrl,
  onClose,
}) => {
  return (
    <Dialog
      open={!!blobUrl}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          minHeight: 'calc(100vh - 200px)',
          minWidth: '800px',
          overflow: 'auto',
        },
      }}
    >
      <DialogContent>
        <PDFViewer file={blobUrl} />
      </DialogContent>
    </Dialog>
  )
}
