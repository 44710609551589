import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { IAvailabilityPayload } from '@procom-labs/common'
import { useTheme } from '@mui/material/styles'
import { ModalCloseButton } from '@procom-labs/molecules'

export const CandidateRequestInterviewEdit: React.FC<{
  accountManagerName: string
  requestedDate: string
  values: IAvailabilityPayload[]
  interviewNotes: string
  handleClose: () => void
  handleEdit: () => void
}> = ({
  accountManagerName,
  requestedDate,
  values,
  interviewNotes,
  handleClose,
  handleEdit,
}) => {
  const { t } = useTranslation('main')
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <DialogTitle sx={{ paddingBottom: 0 }}>
        <Typography
          variant="h6"
          component="p"
          sx={{ mr: 7, color: 'success.dark' }}
        >
          {t('common.candidates.dialog.interview.editTitle', {
            accountManagerName,
            requestedDate,
          })}
        </Typography>
        <ModalCloseButton handleClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" component="p" sx={{ mb: 2 }}>
          {t('common.candidates.dialog.interview.editAvailability')}
        </Typography>
        <Grid
          maxHeight={200}
          sx={{ overflowY: 'auto', mb: interviewNotes ? 0 : 7.625 }}
        >
          {values.map((entry, index) => (
            <Grid
              container
              direction="row"
              key={index}
              gap={0.75}
              flexWrap="nowrap"
            >
              <Grid container item direction="column" xs={5} md={5}>
                {!index && !mobileView && (
                  <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    color="text.secondary"
                    sx={{ mb: 2.375 }}
                  >
                    {t('common.candidates.dialog.interview.form.date')}
                  </Typography>
                )}
                <Typography variant="body1">{entry.date || ''}</Typography>
              </Grid>
              <Grid item container xs={8} md={5}>
                {!index && !mobileView && (
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    fontWeight={500}
                    sx={{ mb: 2.375 }}
                  >
                    {t('common.candidates.dialog.interview.form.timeSlot')}
                  </Typography>
                )}

                <Grid
                  container
                  alignItems="center"
                  flexWrap="nowrap"
                  gap={2}
                  justifyContent="space-between"
                >
                  <Typography variant="body1" width={70}>
                    {entry.fromTime || ''}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    fontWeight={500}
                  >
                    {t('common.candidates.dialog.interview.form.to')}
                  </Typography>
                  <Typography variant="body1" width={70}>
                    {entry.toTime || ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {interviewNotes ? (
          <TextField
            id="outlined-multiline-flexible"
            multiline
            InputProps={{
              readOnly: true,
            }}
            rows={6}
            fullWidth
            value={interviewNotes}
            sx={{ mt: 7.5 }}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          sx={{
            columnGap: 2,
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: { xs: 1.25, sm: 2 },
          }}
          justifyContent="center"
        >
          <Button
            id="btn-cancel-interview-request-edit"
            variant="outlined"
            onClick={handleClose}
          >
            {t('common.btn.cancel')}
          </Button>
          <Button
            color="secondary"
            id="cta-edit-interview-availability"
            variant="contained"
            type="submit"
            onClick={handleEdit}
          >
            {t('common.candidates.dialog.interview.editAvailabilityBtn')}
          </Button>
        </Grid>
      </DialogActions>
    </>
  )
}
