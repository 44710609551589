import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Grid, useMediaQuery } from '@mui/material'

export const SearchBarWrapper: React.FC<{
  searchOpen: boolean
}> = ({ searchOpen, children }) => {
  const theme = useTheme()
  const breakpointDownLg = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <>
      {breakpointDownLg && searchOpen && (
        <Grid item xs={12} sx={{ marginBlockEnd: 2 }}>
          {children}
        </Grid>
      )}
    </>
  )
}
