import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { ICartEntityStatus, useSubjectSelector } from '@procom-labs/common'

import AddToGroup from '@submission-portal/assets/AddToGroup.svg'
import RemoveGroup from '@submission-portal/assets/RemoveGroup.svg'
import SendCandidate from '@submission-portal/assets/SendCandidate.svg'
import { ISubmissionSummary } from '@submission-portal/models'
import { submissionStore } from '@submission-portal/stores'

export const SubmissionActions: FC<{
  close: () => void
  handleSubmit: () => void
  handleAddCandidateToList: (submission: ISubmissionSummary) => void
  handleRemoveCandidateFromList: (jobSubmissionId: string) => void
  submission: ISubmissionSummary
}> = ({
  close,
  handleSubmit,
  handleAddCandidateToList,
  handleRemoveCandidateFromList,
  submission,
}) => {
  const { t } = useTranslation('main')

  const { cart } = useSubjectSelector(submissionStore, ['cart'])
  const items = useMemo(() => {
    const isInCart = cart?.entities.find(
      (entity?: ICartEntityStatus) => entity?.id === submission.jobSubmissionId
    )

    return [
      {
        id: 'btn-submit-candidate',
        label: t('submissionList.btn.submitCandidate'),
        icon: <svg data-src={SendCandidate} data-loading="lazy" />,
        disabled: false,
      },
      isInCart
        ? {
            id: 'btn-remove-from-list',
            label: t('submissionList.btn.removeFromList'),
            icon: <svg data-src={RemoveGroup} data-loading="lazy" />,
            disabled: false,
          }
        : {
            id: 'btn-add-to-list',
            label: t('submissionList.btn.addToList'),
            icon: <svg data-src={AddToGroup} data-loading="lazy" />,
            disabled: false,
          },
    ]
  }, [t, submission.jobSubmissionId, cart])

  const handleMenuItemClick = useCallback(
    (id: string): void => {
      switch (id) {
        case 'btn-submit-candidate':
          handleSubmit()
          break
        case 'btn-add-to-list':
          handleAddCandidateToList(submission)
          break
        case 'btn-remove-from-list':
          handleRemoveCandidateFromList(submission.jobSubmissionId)
          break
        default:
          break
      }

      close()
    },
    [
      handleAddCandidateToList,
      handleSubmit,
      close,
      handleRemoveCandidateFromList,
      submission,
    ]
  )

  return (
    <>
      {items.map(({ id: itemId, icon, label, disabled }) => (
        <MenuItem
          id={itemId}
          key={itemId}
          onClick={() => handleMenuItemClick(itemId)}
          disabled={disabled}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      ))}
    </>
  )
}
