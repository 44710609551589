import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { useObservable } from 'rxjs-hooks'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material'
import {
  AiFeatureLocations,
  arrayBufferToString,
  DocumentEditorActions,
  FileTextFormat,
  IAiAssistedResume,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  TitledPanel,
  useAlert,
  useDefaultErrorHandler,
  ViewportResizer,
} from '@procom-labs/molecules'
import { DocumentEditor, IDocumentEditorRef } from '@procom-labs/organisms'

import { onDownloadCopilotResume } from '@submission-portal/components/resume-copilot/copilot-resume-utils'
import { environment } from '@submission-portal/environment'
import i18n from '@submission-portal/i18n'
import { jobAiService, submissionService } from '@submission-portal/services'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import { CandidatesPrepStep } from '@submission-portal/types'

interface SubmissionPrepFormattedResumeTabProps extends BoxProps {}

export const SubmissionPrepFormattedResumeTab: FC<
  SubmissionPrepFormattedResumeTabProps
> = (props) => {
  const { candidate: submission, prepStep } = useSubjectSelector(
    candidatesPrepStore,
    ['candidate', 'prepStep']
  )

  const documentEditorRef = useRef<IDocumentEditorRef>()
  const { t } = useTranslation('main')
  const [openReplaceContentModal, setOpenReplaceContentModal] =
    useState<boolean>(false)
  const [selectedText, setSelectedText] = useState<string>('')

  const aiAssistedResumeSubscriptionRef = useSubscriptionRef()

  const textSFDTSubscriptionRef = useSubscriptionRef()

  const aiAssistedResume = useObservable(
    () => jobAiService.aiAssistedResumeDeltas$,
    ''
  )

  const aiAssistedResumeTextSFDT = useObservable(
    () => submissionService.textSfdt$,
    ''
  )

  const isLoading = useObservable(
    () => jobAiService.isAiAssistedResumeLoading$,
    false
  )

  const parsedAiAssistedResume = useMemo(() => {
    if (aiAssistedResume) return Parse(aiAssistedResume)
    return ''
  }, [aiAssistedResume])

  const candidate = submission?.candidate
  const activeName = candidate?.resume?.name
  const formattedSubmissionFile = candidate?.formattedSubmissionFile
  const formattedSubmissionFileStorageId =
    formattedSubmissionFile?.fileStorageId

  // The document title is received externally but then starts to be uncontrolled. In case of making changes
  // to the external file, then we need to re-update the title
  const [resumeTitle, setResumeTitle] = useState('')
  useEffect(() => {
    setResumeTitle(activeName ?? 'Untitled')
  }, [activeName])

  const { addAlert } = useAlert()
  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  useEffect(() => {
    const { current } = documentEditorRef
    return () => {
      if (current) current.destroy()
    }
  }, [documentEditorRef])

  useEffect(() => {
    if (
      prepStep === CandidatesPrepStep.Prep &&
      formattedSubmissionFileStorageId
    ) {
      submissionService.getResume(formattedSubmissionFileStorageId).subscribe({
        next: (resume) => {
          const asString = arrayBufferToString(resume)
          documentEditorRef?.current?.documentEditor?.open(asString)
        },
        error: handleError,
      })
    }
  }, [prepStep, formattedSubmissionFileStorageId, handleError])

  const handleGenerateReplaceContent = useCallback(
    (text: string, option: DocumentEditorActions) => {
      const payload: IAiAssistedResume = {
        option,
        text,
        language: i18n.language,
      }
      aiAssistedResumeSubscriptionRef.current = jobAiService
        .generateAiAssistedResume(payload)
        .subscribe({
          next: (data: string) => {
            const htmlText = data.replaceAll('<br>', '<br/>')
            textSFDTSubscriptionRef.current = submissionService
              .getTextSFDT(htmlText, FileTextFormat.HTML)
              .subscribe({
                complete: () => {
                  jobAiService.setAiAssistedResumeLoading(false)
                },
              })
          },
        })
    },
    [aiAssistedResumeSubscriptionRef, textSFDTSubscriptionRef]
  )

  const handleOpenReplaceModal = useCallback(
    (text: string, selectedActionType: DocumentEditorActions) => {
      setOpenReplaceContentModal(true)
      setSelectedText(text)
      handleGenerateReplaceContent(text, selectedActionType)
    },
    [handleGenerateReplaceContent]
  )

  const handleClose = useCallback(() => {
    setOpenReplaceContentModal(false)
  }, [])

  const handleReplaceContent = useCallback(() => {
    documentEditorRef?.current?.documentEditor.editor.paste(
      aiAssistedResumeTextSFDT
    )
    handleClose()
  }, [aiAssistedResumeTextSFDT, handleClose])

  return (
    <Box {...props}>
      <DocumentEditor
        serviceUrl={environment.SYNC_FUSION_EDITOR_SERVICE_URL}
        onDownload={onDownloadCopilotResume}
        resumeTitle={resumeTitle}
        setResumeTitle={setResumeTitle}
        height="750px"
        ref={documentEditorRef}
        disableDownload={false}
        featureLocation={AiFeatureLocations.Bullhorn}
        handleOpenReplaceModal={handleOpenReplaceModal}
      />
      <Dialog
        open={openReplaceContentModal}
        onClose={() => {
          if (!isLoading) {
            handleClose()
          }
        }}
        maxWidth="lg"
      >
        <DialogContent dividers tabIndex={-1}>
          <Grid container spacing={4} sx={{ pt: '24px' }}>
            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
              <TitledPanel
                label={t('candidatePreb.original')}
                sx={{ minWidth: 300 }}
              >
                <ViewportResizer delta={300} minHeight={300}>
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                    {Parse(selectedText)}
                  </Typography>
                </ViewportResizer>
              </TitledPanel>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
              <TitledPanel
                label={t('candidatePreb.formatted')}
                sx={{ minWidth: 300 }}
              >
                <ViewportResizer delta={315} minHeight={300}>
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                    {parsedAiAssistedResume}
                  </Typography>
                </ViewportResizer>
              </TitledPanel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button
            id="cta-cancel-replace-content-modal"
            disabled={isLoading}
            onClick={handleClose}
          >
            {t('common.btn.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            id="cta-replace-replace-content-modal"
            onClick={handleReplaceContent}
            loading={isLoading}
          >
            {t('common.btn.replace')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
