import { BaseSubjectStore } from '../subject-store'

interface DocumentTitleStoreState {
  title: string
  notificationCount: number
}

class DocumentTitleStore extends BaseSubjectStore<DocumentTitleStoreState> {
  getDefaultValue(): Required<DocumentTitleStoreState> {
    return {
      title: document.title,
      notificationCount: 0,
    }
  }

  setTitle(title: string): void {
    this.dispatch({ title })
  }

  setNotificationCount(notificationCount: number): void {
    this.dispatch({ notificationCount })
  }
}

export const documentTitleStore = new DocumentTitleStore()
