import React, { useEffect, useState } from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GorillaDialog } from '@procom-labs/atoms'
import { ICandidate } from '@procom-labs/common'
import { ModalCloseButton } from '@procom-labs/molecules'

export type OfferFormPayload = {
  offerExtendedNote: string
}
export const CandidateExtendOfferModalContent: React.FC<{
  open: boolean
  handleClose: () => void
  isCommentsSent: boolean
  accountManagerName: string
  candidate: Partial<ICandidate> | null
  jobTitle: string
  handleUpdateCandidate: (
    values: OfferFormPayload,
    actions?: FormikHelpers<OfferFormPayload>
  ) => void
}> = ({
  open,
  handleClose,
  isCommentsSent,
  accountManagerName,
  candidate,
  jobTitle,
  handleUpdateCandidate,
}) => {
  const theme = useTheme()
  const { t } = useTranslation('main')
  const [initialFormValues, setInitialFormValues] = useState({
    offerExtendedNote: '',
  } as OfferFormPayload)

  useEffect(() => {
    if (candidate) {
      setInitialFormValues({
        offerExtendedNote: candidate.offerExtendedNote,
      } as OfferFormPayload)
    }
  }, [candidate])

  const validationSchema = Yup.object().shape({
    offerExtendedNote: Yup.string()
      .required(t('common.candidates.dialog.offer.requiredError'))
      .nullable(),
  })

  return (
    <GorillaDialog
      isLightMode
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { paddingY: { xs: 3.5, lg: 6.5 }, paddingX: { xs: 2.5, lg: 5 } },
      }}
      sx={{ mt: { xs: 9 } }}
    >
      {!isCommentsSent ? (
        <>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={handleUpdateCandidate}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <DialogTitle sx={{ paddingBottom: 0, px: { xs: 2 } }}>
                    <Typography
                      gutterBottom
                      component="p"
                      variant="h22Bold800"
                      textAlign="center"
                    >
                      {t('common.candidates.dialog.offer.prompt1', {
                        candidateName:
                          candidate?.name ??
                          `${candidate?.firstName} ${candidate?.lastName}`,
                      })}
                    </Typography>

                    <ModalCloseButton
                      handleClose={handleClose}
                      id="btn-close-offer-modal"
                    />
                  </DialogTitle>
                  <DialogContent
                    sx={{ mt: { xs: 5, md: 2 }, ml: 4, mr: 3.5, mb: 4, p: 0 }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      spacing={4}
                    >
                      <Typography
                        variant="body1"
                        gutterBottom
                        component="p"
                        textAlign="center"
                      >
                        {t('common.candidates.dialog.offer.prompt2', {
                          accountManagerName,
                          candidateName:
                            candidate?.name ??
                            `${candidate?.firstName} ${candidate?.lastName}`,
                        })}
                        {jobTitle}
                      </Typography>
                      <Field
                        component={TextField}
                        placeholder={t(
                          'common.candidates.dialog.offer.offerCommentsPlaceholder'
                        )}
                        id="outlined-multiline-static"
                        multiline
                        name="offerExtendedNote"
                        rows={6}
                        fullWidth
                      />
                    </Stack>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      ml: 4,
                      mr: 3.5,
                      mt: 2,
                      paddingRight: 0,
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      id="btn-close-offer-modal"
                      variant="outlined"
                      onClick={handleClose}
                    >
                      {t('common.btn.cancel')}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      id="cta-send-offer"
                      disabled={isSubmitting}
                      endIcon={isSubmitting && <CircularProgress size={18} />}
                    >
                      {t('common.candidates.dialog.offer.sendOfferBtn')}
                    </Button>
                  </DialogActions>
                </Form>
              )
            }}
          </Formik>
        </>
      ) : (
        <>
          <DialogContent>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{ mx: 3, my: 3, textAlign: 'center' }}
            >
              <CheckCircleOutlineIcon
                sx={{ color: theme.palette.success.main, fontSize: 50 }}
              />
              <Typography
                variant="subtitle1Bold"
                gutterBottom
                component="p"
                sx={{ color: theme.palette.success.dark, fontSize: 20 }}
              >
                {t('common.candidates.dialog.offer.success1', {
                  candidateName:
                    candidate?.name ??
                    `${candidate?.firstName} ${candidate?.lastName}`,
                })}
                {jobTitle}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="p"
                sx={{ color: theme.palette.common.black }}
              >
                {t('common.candidates.dialog.offer.success2', {
                  accountManagerName,
                })}
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              id="cta-extend-offer-modal"
              variant="contained"
              onClick={handleClose}
              sx={{ px: 4 }}
            >
              {t('common.btn.gotIt')}
            </Button>
          </DialogActions>
        </>
      )}
    </GorillaDialog>
  )
}
