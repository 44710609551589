import { FC, useMemo } from 'react'

import { Grid, GridProps, useTheme } from '@mui/material'
import { GridRow } from '@mui/x-data-grid'
import { PaletteModeCode } from '@procom-labs/common'

import { WithGorillaInnerTheme } from './with-inner-theme'

export const GorillaDataGridRow = WithGorillaInnerTheme<GridProps>(Grid)

export const GorillaDataGridRowContainer: FC = (props: any) => {
  const { selected } = props
  const theme = useTheme()

  const themeMode: PaletteModeCode = useMemo(() => {
    if (theme.palette.mode === PaletteModeCode.light)
      return PaletteModeCode.light
    return selected ? PaletteModeCode.light : PaletteModeCode.dark
  }, [selected, theme.palette.mode])

  return (
    <GorillaDataGridRow themeMode={themeMode}>
      <GridRow {...props} />
    </GorillaDataGridRow>
  )
}
