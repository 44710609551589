export enum IEntityType {
  Address = 'Address',
  Candidate = 'Candidate',
  Client = 'Client',
  ContractorProfile = 'ContractorProfile',
  Job = 'Job',
  JobSubmission = 'JobSubmission',
  Resume = 'Resume',
  User = 'User',
  UserProfile = 'UserProfile',
  Vendor = 'Vendor',
  TalentPoolCandidate = 'TalentPoolCandidate',
  TalentPool = 'TalentPool',
  OnboardingRequest = 'OnboardingRequest',
  OnboardingPackage = 'OnboardingPackage',
  PayrollOnboarding = 'PayrollOnboarding',
  InformationRequest = 'InformationRequest',
  DocumentComplianceResult = 'DocumentComplianceResult',
  JobIntakeNote = 'JobIntakeNote',
  JobExtract = 'JobExtract',
}

export interface ILocation {
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  streetNumber: string | null
  streetName: string | null
  state: string | null
  stateCode: string | null
  stateIsoCode: string | null
  subPremise: string | null
  countryCode: string | null
  country: string | null
  latitude: string | null
  longitude: string | null
  postalCode: string | null
}

export interface IWorksite {
  addressLine1: string
  addressLine2: string
  city: string
  subdivision: string
  subdivisionCode: string
  country: string
  countryCode: string
  postalCode: string
  internalCompanyCode: string
}

export interface ICountry {
  usa: boolean
  canada: boolean
}

export interface ICodeValue<T = string> {
  code: T
  value: string
  order?: number
  frenchValue?: string
}

export interface ISubDivision extends ICodeValue {
  groupName: string
  parentGroupName: string
  parentCode: string
}
