import { FC, memo, useRef } from 'react'
import i18n from 'i18next'
import { useController } from 'react-hook-form'

import { Box } from '@mui/material'
import { PreferredLanguageType, useIsGorillaTheme } from '@procom-labs/common'

import { Editor, IAllProps } from '@tinymce/tinymce-react'

import { FormEditorToolbar, IFromEditor } from './form-editor'

interface IFormHookEditor extends IFromEditor {
  control: any
  editorSettings?: Partial<IAllProps['init']>
  ignoreInitialChangeEvent?: boolean
  useCDN?: boolean
}

const FormHookEditorComp: FC<IFormHookEditor> = ({
  name,
  initialValue,
  minHeight,
  environment,
  height = 222,
  disabled = false,
  plugins = ['lists'],
  control,
  useCDN,
  editorSettings,
  ignoreInitialChangeEvent = true,
}) => {
  const {
    field: { ref, value, onBlur, onChange, ...restField },
  } = useController({
    name,
    control,
  })
  const isGorillaTheme = useIsGorillaTheme()

  const firstChange = useRef(Boolean(value))

  const language = i18n.language === PreferredLanguageType.FR ? 'fr_FR' : null

  const requireCdn = useCDN || plugins.includes('mentions')

  const isCDNLoaded =
    window?.tinymce?.baseURL?.includes('cdn') || window?.tinyCdnLoading

  if (!isCDNLoaded && requireCdn) {
    window.tinymce = undefined
    window.tinyCdnLoading = true
  }

  return (
    <Box
      sx={
        isGorillaTheme
          ? (theme) => {
              return {
                '.tox-tinymce': {
                  borderColor: 'text.primary',
                  '.tox-editor-header': {
                    borderBottom: '2px solid !important',
                    borderColor: 'text.primary',
                    '.tox-tbtn svg': {
                      fill: 'text.primary',
                    },
                    '.tox-tbtn--disabled svg': {
                      fill: 'rgba(34,47,62,.5) !important',
                    },
                  },
                },
                '.tox .tox-edit-area__iframe, .tox-toolbar, .tox-editor-header, .tox-statusbar':
                  {
                    backgroundColor: `${theme.palette.common.white} !important`,
                  },
              }
            }
          : {}
      }
    >
      <Editor
        {...restField}
        value={value}
        disabled={disabled}
        apiKey={environment.TINY_MCE_API_KEY}
        textareaName={name}
        {...(initialValue && { initialValue })}
        init={{
          height,
          plugins,
          min_height: minHeight,
          branding: false,
          menubar: false,
          elementpath: false,
          toolbar_mode: 'wrap',
          ...{
            ...(language && {
              language,
              language_url: '/assets/locales/fr/tinyMCE.js',
            }),
          },
          toolbar: FormEditorToolbar,
          ...editorSettings,
          content_style: `body { 
                            font-family:Roboto,sans-serif; 
                            font-size:14px;
                            border-color: blue;
                          } ${editorSettings?.content_style}`,
        }}
        onEditorChange={(newValue) => {
          if (!firstChange.current) {
            onChange(newValue)
          } else {
            if (!ignoreInitialChangeEvent) {
              onChange(newValue)
            }
            firstChange.current = false
          }
        }}
        onBlur={onBlur}
        ref={ref}
        tinymceScriptSrc={
          !requireCdn && !isCDNLoaded
            ? `${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`
            : undefined
        }
      />
    </Box>
  )
}

export const FormHookEditor = memo(FormHookEditorComp)
