import { useEffect } from 'react'
import { fromEvent, merge, tap } from 'rxjs'

import { useIdle } from './use-idle'

export const useExecuteOnUnmountOrIdle = (
  executeFunc?: (isSuddenShutDown?: boolean) => void
): void => {
  const isIdle = useIdle()

  // Detect the user being Idle
  useEffect(() => {
    if (isIdle) {
      executeFunc?.()
    }
  }, [isIdle, executeFunc])

  // This HOOK listens for Window/Tab close event
  useEffect(() => {
    const unloadEvents = merge(
      fromEvent(window, 'beforeunload'),
      fromEvent(window, 'onbeforeunload')
    )
      .pipe(
        tap(() => {
          // isSuddenShutDown flag in executeFunc callback is used to detect
          // whether unmount is happening due to window/tab close
          executeFunc?.(true)
        })
      )
      .subscribe()

    return () => unloadEvents.unsubscribe()
  }, [executeFunc])

  // Unmount
  useEffect(() => {
    // Dependency array is kept empty intentionally, in order to mimic the unmount functionality
    // Having executeFunc as dependency will trigger the cleanup even when component mounts
    // Functionality needs to be tested thoroughly, if any dependency is to be added in future

    return () => {
      executeFunc?.()
    }
  }, [])
}
