import { FC, memo } from 'react'

import { ListItemButton, Typography } from '@mui/material'

import { ITimeSlot } from '../types'

interface InterviewTimeSlotsProps {
  timeSlots: ITimeSlot[]
  handleSelectTimeSlot: (timeSlot: ITimeSlot) => void
}
export const InterviewTimeSlots: FC<InterviewTimeSlotsProps> = memo(
  ({ timeSlots, handleSelectTimeSlot }) => {
    return (
      <>
        {timeSlots.map((timeSlot, index) => (
          <ListItemButton
            key={`available-interview-timeslot-${index}`}
            selected={timeSlot.selected}
            sx={{
              justifyContent: 'center',
              borderColor: (theme) => theme.palette.common.black,
              borderRadius: '5px',
              border: '1px solid',
              mb: '5px',
              '&.Mui-selected': {
                background: (theme) => theme.palette.common.purple,
                color: (theme) => theme.palette.common.white,
              },
              width: { sm: '100%', md: '210px', lg: '210px' },
            }}
            onClick={() => handleSelectTimeSlot(timeSlot)}
          >
            <Typography align="center">
              {`${timeSlot.fromTime} - ${timeSlot.toTime}`}
            </Typography>
          </ListItemButton>
        ))}
      </>
    )
  }
)
