import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GorillaDialog } from '@procom-labs/atoms'
import { useSubjectSelector } from '@procom-labs/common'

import { useRemoveCandidateFromList } from '@submission-portal/hooks'
import { submissionStore } from '@submission-portal/stores'

export const RemoveConfirmationModal: React.FC<{}> = () => {
  const theme = useTheme()
  const { t } = useTranslation('main')
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))

  const removeCandidate = useRemoveCandidateFromList()
  const { isCandidateRemoveModalOpen, touchedCandidate } = useSubjectSelector(
    submissionStore,
    ['isCandidateRemoveModalOpen', 'touchedCandidate']
  )

  const handleRemoveConfirmation = useCallback(() => {
    if (touchedCandidate) {
      removeCandidate(touchedCandidate)
    }
  }, [removeCandidate, touchedCandidate])

  const handleCancelConfirmation = useCallback(() => {
    submissionStore.dispatch({
      isCandidateRemoveModalOpen: false,
    })
  }, [])

  return (
    <GorillaDialog
      open={isCandidateRemoveModalOpen}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
    >
      <Grid>
        <DialogTitle>
          {t('submissionList.multipleCandidates.alerts.removeTitle')}
        </DialogTitle>
      </Grid>
      <Grid>
        <DialogContent>
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="contained" onClick={handleRemoveConfirmation}>
              {t(
                'submissionList.multipleCandidates.alerts.removeActionConfirm'
              )}
            </Button>
            <Button onClick={handleCancelConfirmation}>
              {t('submissionList.multipleCandidates.alerts.removeActionCancel')}
            </Button>
          </Stack>
        </DialogContent>
      </Grid>
    </GorillaDialog>
  )
}
