import { FC, memo, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useObservable } from 'rxjs-hooks'

import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import {
  IAtsCandidateFile,
  JobAiService,
  PreferredLanguageType,
} from '@procom-labs/common'
import { SelectHookField, TextHookField } from '@procom-labs/molecules'

import { FormHookEditor } from '../form-editor/form-hook-editor'

interface IAiSummaryGenerator {
  title: string
  description?: string
  handleGenerateCandidateSummary?: (
    preferredLanguage?: string,
    atsCandidateResumeId?: number
  ) => void
  isQuickView?: boolean
  isSubmittedJobCandidateView?: boolean
  isBullhornPortal?: boolean
  environment: any
  jobAiService: JobAiService
  isSubmissionPortal?: boolean
  hasResume?: boolean
  isEditable?: boolean
  trackingEventName?: string
  isFailedResumeCall?: boolean
  handleResetBadResumeRequestFlag?: () => void
}

interface ICandidateSummary {
  isQuickView?: boolean
  highlights?: string
  isBullhornPortal?: boolean
  environment: any
  isLoading: boolean
  isEditable: boolean
  sx: SxProps
}

interface InitialValues {
  highlights: string
  preferredLanguage?: string
  resume?: string
}

const CandidateHighlights: FC<ICandidateSummary> = memo(
  ({
    isQuickView = false,
    highlights,
    isBullhornPortal,
    environment,
    isLoading = false,
    isEditable,
    sx,
  }) => {
    const { t } = useTranslation('main')
    const { control, setValue } = useFormContext<InitialValues>()

    useEffect(() => {
      setValue(
        'highlights',
        isBullhornPortal
          ? highlights ?? ''
          : highlights?.split('<br>')?.join('\n') ?? ''
      )
    }, [highlights, isBullhornPortal, setValue])

    return (
      <Grid item xs={12} mt={3}>
        {!isBullhornPortal ? (
          <TextHookField
            control={control}
            name="highlights"
            fullWidth
            rows={8}
            multiline
            InputProps={{
              readOnly: !isEditable,
              sx: { ...sx },
            }}
            placeholder={
              isQuickView
                ? t('organisms.summaryGenerator.placeholder2')
                : t('organisms.summaryGenerator.placeholder1')
            }
          />
        ) : (
          <>
            <FormHookEditor
              name="highlights"
              environment={environment}
              control={control}
              height={280}
              minHeight={280}
              initialValue={highlights}
            />

            <Grid container item p={1} justifyContent="flex-end">
              <Button
                id="btn-clear-highlights"
                variant="contained"
                onClick={() => setValue('highlights', '')}
                disabled={isLoading}
                sx={{ px: 3 }}
              >
                {t('organisms.summaryGenerator.btn.clear')}
              </Button>
              {/* As discussed, removing the copy text button for now */}
              {/* <Button
                id="btn-copy-highlights"
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => {
                  from(
                    navigator.clipboard.writeText(getValues('highlights'))
                  ).subscribe({
                    complete: () => {
                      addAlert({
                        message: t(
                          'organisms.summaryGenerator.btn.copiedTextMessage'
                        ),
                        severity: 'success',
                      })
                    },
                  })
                }}
                startIcon={<ContentCopyIcon />}
                disabled={isLoading}
              >
                {t('organisms.summaryGenerator.btn.copyText')}
              </Button> */}
            </Grid>
          </>
        )}
      </Grid>
    )
  }
)

const LanguageSelector: FC = () => {
  const { t } = useTranslation('main')
  const { control } = useFormContext<InitialValues>()
  return (
    <SelectHookField
      customLabel={false}
      label={t('organisms.summaryGenerator.language')}
      control={control}
      name="preferredLanguage"
      showPleaseSelect={false}
      size="small"
      sx={{ height: '44px' }}
    >
      {Object.values(PreferredLanguageType).map((val) => (
        <MenuItem key={val} value={val}>
          <ListItemText
            primary={t(
              `organisms.summaryGenerator.inputs.preferredLanguage.${val}`
            )}
          />
        </MenuItem>
      ))}
    </SelectHookField>
  )
}

const ResumeSelector: FC<{
  jobAiService: JobAiService
  selectedCandidateResume: IAtsCandidateFile | undefined
  handleCandidateResumeChange: (candidateResume: IAtsCandidateFile) => void
}> = ({
  jobAiService,
  selectedCandidateResume,
  handleCandidateResumeChange,
}) => {
  const { t } = useTranslation('main')
  const { control } = useFormContext<InitialValues>()

  const candidateFiles = useObservable(() => jobAiService.candidateAtsFiles$)

  useEffect(() => {
    if (candidateFiles && candidateFiles.length) {
      // Setting the default value
      handleCandidateResumeChange(candidateFiles[0])
    }
  }, [candidateFiles, handleCandidateResumeChange])

  if (!candidateFiles || !selectedCandidateResume) return null

  return (
    <SelectHookField
      customLabel={false}
      label={t('organisms.candidateDetails.resume')}
      control={control}
      name="resume"
      renderValue={() => selectedCandidateResume?.name}
      showPleaseSelect={false}
      size="small"
      sx={{ height: '44px' }}
      onChange={(event) => {
        const validResume = candidateFiles?.find(
          (resume) => resume.id === event.target.value
        )
        if (validResume) {
          handleCandidateResumeChange(validResume)
        }
      }}
    >
      {candidateFiles?.map((val) => (
        <MenuItem key={val.id} value={val.id}>
          <ListItemText primary={val.name} />
        </MenuItem>
      ))}
    </SelectHookField>
  )
}

export const AiSummaryGenerator: FC<IAiSummaryGenerator> = ({
  title,
  description,
  handleGenerateCandidateSummary,
  isQuickView = false,
  isBullhornPortal = false,
  environment,
  jobAiService,
  isSubmittedJobCandidateView = false,
  isSubmissionPortal = false,
  hasResume = true,
  isEditable = true,
  trackingEventName,
  isFailedResumeCall,
  handleResetBadResumeRequestFlag,
}) => {
  const { t } = useTranslation('main')
  const [selectedAtsCandidateResume, setSelectedAtsCandidateResume] =
    useState<IAtsCandidateFile>()

  const isLoading = useObservable(() => jobAiService.isJobLoading$, false)

  const isValidResume =
    selectedAtsCandidateResume?.fileSize && !isFailedResumeCall

  const candidateHighlights = useObservable(
    () => jobAiService.candidateHighlightDeltas$,
    ''
  )

  const atsCandidateHighlights = useObservable(
    () => jobAiService.atsCandidateHighlightDelta$,
    ''
  )

  const formInstance = useForm<InitialValues>({
    values: {
      highlights: '',
      resume: '',
      preferredLanguage: PreferredLanguageType.EN,
    },
    mode: 'onBlur',
  })

  const handleCandidateResumeChange = useCallback(
    (candidateResume: IAtsCandidateFile) => {
      setSelectedAtsCandidateResume(candidateResume)
      if (handleResetBadResumeRequestFlag) {
        handleResetBadResumeRequestFlag()
      }
    },
    [handleResetBadResumeRequestFlag]
  )

  return (
    <FormProvider {...formInstance}>
      <Grid
        container
        item
        xs={isQuickView || isSubmittedJobCandidateView ? 12 : 8}
        alignItems="center"
        justifyContent="center"
        rowGap={1}
      >
        <Grid item xs={12}>
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
          {description && (
            <Typography variant="subtitle1">{description}</Typography>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          columnSpacing={2}
        >
          {isBullhornPortal && (
            <Grid item xs={6}>
              <Stack direction="row" gap={2}>
                <ResumeSelector
                  jobAiService={jobAiService}
                  selectedCandidateResume={selectedAtsCandidateResume}
                  handleCandidateResumeChange={handleCandidateResumeChange}
                />
                <LanguageSelector />
              </Stack>
            </Grid>
          )}
          {isBullhornPortal && !isValidResume && (
            <Box ml={2}>
              <Tooltip
                title={t('organisms.error.invalidResume')}
                placement="top"
              >
                <IconButton>
                  <PriorityHighIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Grid item>
            <LoadingButton
              color="secondary"
              id="cta-generate-candidate-highlights"
              variant="contained"
              size="large"
              type="submit"
              startIcon={
                <img
                  src="/assets/images/ai-generator-white.svg"
                  alt={t(`common.form.jobDescription.aiJobDescription`)}
                />
              }
              loading={isLoading}
              disabled={
                isLoading || !hasResume || (isBullhornPortal && !isValidResume)
              }
              name={trackingEventName}
              onClick={() => {
                handleGenerateCandidateSummary?.(
                  formInstance.getValues('preferredLanguage') || '',
                  selectedAtsCandidateResume?.id
                )
              }}
            >
              {t('organisms.summaryGenerator.btn.generate')}
            </LoadingButton>
          </Grid>
        </Grid>
        <CandidateHighlights
          isQuickView={isQuickView}
          highlights={
            isSubmissionPortal ? atsCandidateHighlights : candidateHighlights
          }
          isBullhornPortal={isBullhornPortal}
          environment={environment}
          isLoading={isLoading}
          isEditable={isEditable}
          sx={{ borderRadius: 2.5 }}
        />
      </Grid>
    </FormProvider>
  )
}
