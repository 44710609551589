import { useCallback } from 'react'

import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { BaseSubjectStore, SortOrder } from '@procom-labs/common'

import { IPaginationProps, usePaginationProps } from './use-pagination-props'

interface SearchFilter {
  currentPage: number
  pageSize: number
}

export const useDataPaginationProps = <
  TState,
  TSearchFilter extends SearchFilter
>(
  store: BaseSubjectStore<TState>,
  searchFilter: TSearchFilter
): IPaginationProps => {
  const handleChangePage = useCallback(
    (model: GridPaginationModel): void => {
      store.dispatch({
        searchFilter: {
          ...searchFilter,
          currentPage: model.page,
        },
      } as unknown as Partial<TState>)
    },
    [store, searchFilter]
  )

  return usePaginationProps(
    handleChangePage,
    searchFilter.currentPage,
    searchFilter.pageSize
  )
}

type UseDataSortHandlerOptions<TSearchFilter> = {
  searchFilter: TSearchFilter
  store: BaseSubjectStore<any>
}

export const useDataSortHandler = <
  TSearchFilter extends { sortField?: string; sortOrder?: SortOrder }
>(
  options: UseDataSortHandlerOptions<TSearchFilter>
): any => {
  const { store, searchFilter } = options

  return useCallback(
    (model: GridSortModel): void => {
      const sortModel = model[0] ?? { field: '', sort: SortOrder.desc }
      const { field: sortField, sort } = sortModel
      store.dispatch({
        searchFilter: {
          ...searchFilter,
          sortField,
          sortOrder: SortOrder[sort ?? SortOrder.asc],
        },
      } as unknown as Partial<TSearchFilter>)
    },
    [store, searchFilter]
  )
}
