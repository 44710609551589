import React, { FC } from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

type MaskOptions = {
  prefix: string
  suffix: string
  includeThousandsSeparator: boolean
  thousandsSeparatorSymbol: string
  allowDecimal: boolean
  decimalSymbol: string
  decimalLimit: number
  requireDecimal: boolean
  allowNegative: boolean
  allowLeadingZeroes: boolean
  integerLimit: number
}

type CurrencyInputProps = {
  inputMode?: any
  maskOptions?: Partial<MaskOptions>
}

const defaultMaskOptions: MaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 12, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
  requireDecimal: false,
}

export const CurrencyInput: FC<CurrencyInputProps> = React.forwardRef(
  ({ maskOptions, ...inputProps }: CurrencyInputProps, inputRef: any) => {
    const currencyMask = createNumberMask({
      ...defaultMaskOptions,
      ...maskOptions,
    })

    return (
      <MaskedInput
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null)
        }}
        mask={currencyMask}
        {...inputProps}
      />
    )
  }
)

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {} as MaskOptions,
}
