import { FC, memo } from 'react'

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import {
  alpha,
  ListItem,
  ListItemIcon,
  ListItemText,
  List as MuiList,
  SvgIconTypeMap,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useTheme } from '@mui/material/styles'
import { GorillaStack } from '@procom-labs/atoms'
import { OnePagerListItemObject } from '@procom-labs/common'

import { SectionTitle } from '../common'

export const List: FC<{
  title: string
  items: OnePagerListItemObject[]
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string }
}> = memo(({ title, items, icon: IconComponent }) => {
  const theme = useTheme()

  return (
    <GorillaStack gap={2}>
      <SectionTitle
        icon={
          <IconComponent
            sx={{
              color: theme.palette.secondary.main,
              fontSize: '18px',
            }}
          />
        }
        title={title}
        sx={{
          flex: 1,
          paddingBottom: '12px',
          borderBottom: `2px solid ${alpha(theme.palette.divider, 0.3)}`,
        }}
      />
      {items?.length !== 0 && (
        <MuiList dense>
          {items?.map((item) => (
            <ListItem sx={{ gap: 1, alignItems: 'start' }} key={item.primary}>
              <ListItemIcon sx={{ minWidth: 'auto', mt: '3px' }}>
                <CheckCircleOutlineRoundedIcon
                  fontSize="small"
                  sx={{ color: theme.palette.success.main }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={item.primary}
                primaryTypographyProps={{ variant: 'body2', display: 'inline' }}
                secondary={item.secondary}
                secondaryTypographyProps={{
                  variant: 'body2',
                  display: 'inline',
                }}
              />
            </ListItem>
          ))}
        </MuiList>
      )}
    </GorillaStack>
  )
})
