import { debounceTime, map, Observable } from 'rxjs'

import {
  concatAndFormatAiOutput,
  SignalrEventNames,
  SignalrService,
  VendorCodes,
} from '@procom-labs/common'

import { environment } from '@submission-portal/environment'
import { axios } from '@submission-portal/lib'

class LanguageCopilotService extends SignalrService {
  private readonly streamUrl: string

  private translationGenerativeOutput = ''

  constructor() {
    super(
      environment.PROCOM_VENDOR_API_KEYS[
        (new URLSearchParams(window.location.search).get(
          'vendorCode'
        ) as VendorCodes) || VendorCodes.PCGL
      ]
    )
    this.streamUrl = `${environment.JOB_API_URL}/LanguageDataStream`
  }

  getTranslation(
    text: string,
    language: string,
    recaptchaToken: string
  ): Observable<string> {
    this.translationGenerativeOutput = ''
    return axios
      .post<string>(`${environment.JOB_API_URL}/Copilot/translate`, {
        Text: text,
        clientSocketConnectionId: this.signalRConnectionId,
        TextLanguage: language,
        RecaptchaToken: recaptchaToken,
      })
      .pipe(map(({ data }) => concatAndFormatAiOutput('', data)))
  }

  getTranslationStream(debounceMillis?: number): Observable<string> {
    return super
      .getEventStream(
        this.streamUrl,
        SignalrEventNames.LanguageTranslationDelta
      )
      .pipe(
        map((message) => {
          this.translationGenerativeOutput = concatAndFormatAiOutput(
            this.translationGenerativeOutput,
            message
          )
          return this.translationGenerativeOutput
        }),
        debounceTime(debounceMillis || 50)
      )
  }
}

export const languageCopilotService = new LanguageCopilotService()
