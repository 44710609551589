import { CandidateOnePagerStateEnum } from '../../enums'
import { ICandidateOnePager } from '../../models'
import { BaseSubjectStore } from '../subject-store'

interface CandidateOnePagerState {
  state: CandidateOnePagerStateEnum
  onePager: ICandidateOnePager | null
  taskId: string | null
  progress: string
  error: string | null
  avatarColor: string | null
}

class CandidateOnePagerStore extends BaseSubjectStore<CandidateOnePagerState> {
  getDefaultValue(): Required<CandidateOnePagerState> {
    return {
      state: CandidateOnePagerStateEnum.NO_FILE,
      onePager: null,
      taskId: null,
      error: null,
      progress: '',
      avatarColor: null,
    }
  }
}

export const candidateOnePagerStore = new CandidateOnePagerStore()
