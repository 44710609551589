import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Observable, of, Subject } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import CloseIcon from '@mui/icons-material/Close'
import {
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import { GorillaDialog, Preloader } from '@procom-labs/atoms'
import {
  AiFeatureLocations,
  candidateOnePagerStore,
  CandidateTabs,
  FeatureFlagNames,
  getRandomColor,
  gtmEvents,
  IAtsCandidateHighlights,
  ICandidate,
  IEntityType,
  IJobSubmission,
  PreferredLanguageType,
  useIsFeatureFlagEnabled,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { Pagination } from '@procom-labs/molecules'
import {
  CandidateDetails,
  OnePagerView,
  OnePagerWrapper,
  Resume,
  useLoadCandidateOnePager,
} from '@procom-labs/organisms'

import { environment } from '@submission-portal/environment'
import { useTrackingWrapper } from '@submission-portal/hooks'
import {
  authService,
  candidateOnePagerService,
  jobAiService,
  submissionService,
} from '@submission-portal/services'

export const PreviewModal: FC<{
  open: boolean
  handleClose: () => void
  jobSubmissions?: IJobSubmission[]
  start?: Subject<string>
  pause?: Subject<string>
}> = ({ open, handleClose, jobSubmissions, start, pause }) => {
  const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
    FeatureFlagNames.ResumeOnePager
  )
  const { t } = useTranslation('main')
  const theme = useTheme()
  const generateCandidateHighlightsRef = useSubscriptionRef()
  const isBreakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'))
  const { onePager } = useSubjectSelector(candidateOnePagerStore, ['onePager'])
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(0)
  const [candidate, setCandidate] = useState<ICandidate>()

  const { showOnePager } = useLoadCandidateOnePager(
    candidateOnePagerService,
    candidate
  )

  const clientData = useObservable(() => authService.clientData$)

  const avatarColor: string = useMemo(() => {
    return getRandomColor()
  }, [])
  const { track } = useTrackingWrapper()

  const resume$: Observable<ArrayBuffer | null> = useMemo(() => {
    if (candidate) {
      return candidate.resume?.fileStorageId
        ? submissionService.getResume(candidate.resume.fileStorageId)
        : of(null)
    }
    return of(null)
  }, [candidate])

  const handleGenerateCandidateHighlights = useCallback(
    (preferredLanguage) => {
      start?.next('')
      const payload: IAtsCandidateHighlights = {
        atsCandidateId: Number(candidate?.atsUserId),
        language: preferredLanguage || PreferredLanguageType.EN,
      }
      generateCandidateHighlightsRef.current = jobAiService
        .generateAtsCandidateHighlights(payload)
        .subscribe({
          complete: () => {
            track({
              eventName: gtmEvents.GenerateAiCandidateHighlights,
              ...payload,
              resumeFileStorageId: candidate?.resume?.fileStorageId,
              featureLocation: AiFeatureLocations.Bullhorn,
            })
            pause?.next('')
          },
        })
    },
    [
      candidate?.resume?.fileStorageId,
      generateCandidateHighlightsRef,
      pause,
      track,
      start,
      candidate?.atsUserId,
    ]
  )

  const handleNext = useCallback(() => {
    if (jobSubmissions) {
      const nextCandidate = jobSubmissions[page].candidate

      setPage(page + 1)
      setCandidate(nextCandidate)
    }
  }, [jobSubmissions, page])

  const handlePrevious = useCallback(() => {
    if (jobSubmissions) {
      const previousCandidate = jobSubmissions[page - 2].candidate

      setPage(page - 1)
      setCandidate(previousCandidate)
    }
  }, [jobSubmissions, page])

  useEffect(() => {
    if (jobSubmissions?.length) {
      setPages(jobSubmissions.length)
      setPage(1)
      setCandidate(jobSubmissions[0].candidate)
    }
  }, [jobSubmissions])

  if (!candidate) return null

  return (
    <GorillaDialog
      isLightMode
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop={!isBreakpointUpLg}
      fullScreen={!isBreakpointUpLg}
      maxWidth="xl"
      fullWidth
      scroll="body"
      PaperProps={{
        elevation: 0,
        sx: {
          padding: { xs: 2.5, sm: 5 },
          verticalAlign: 'bottom',
        },
      }}
    >
      <IconButton
        aria-label={t('common.aria.close')}
        onClick={handleClose}
        sx={{ position: 'absolute', top: '4px', right: '4px' }}
      >
        <CloseIcon color="primary" sx={{ fontSize: 24 }} />
      </IconButton>
      <DialogContent>
        <Grid
          container
          sx={{
            mt: 2.5,
            pt: 4,
            pb: 3,
            paddingX: 5,
            borderRadius: 5,
            marginBottom: 2.5,
            alignItems: 'center',
            border: '1px solid',
          }}
        >
          <Grid item xs>
            <Box>
              <Typography variant="h6" component="h1" sx={{ flex: '1', m: 0 }}>
                {candidate.jobTitle}
              </Typography>
              {jobSubmissions && jobSubmissions?.length > 1 && (
                <Box
                  sx={{
                    pt: 2.75,
                    top: 0,
                    zIndex: 1,
                    width: '100%',
                    position: 'sticky',
                  }}
                >
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} lg="auto" sx={{ minWidth: 200 }}>
                      <Pagination
                        handlePrevious={handlePrevious}
                        handleNext={handleNext}
                        page={page}
                        pages={pages}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <CandidateDetails
          isSubmissionPortal
          jobAiService={jobAiService}
          avatarColor={avatarColor}
          data={{
            ...candidate,
            comments: `<p>${candidate.comments}</p>`,
            showBillRate: clientData?.showBillRateToHiringManager || false,
          }}
          environment={environment}
          handleGenerateCandidateHighlights={handleGenerateCandidateHighlights}
          {...(showOnePager &&
            IS_ONE_PAGER_ENABLED && {
              onePagerTab: onePager ? (
                <OnePagerWrapper hideButtons>
                  <OnePagerView hideCandidateDescription isClientPortal />
                </OnePagerWrapper>
              ) : (
                <Preloader center height="100%" />
              ),
            })}
          resumeTab={
            <Resume
              resume$={resume$}
              candidateResume={candidate?.resume ?? null}
              entityType={IEntityType.JobSubmission}
              entityId={candidate.jobSubmissionId}
            />
          }
          notesTab={<Box />}
          timelineTab={<Box />}
          additionalDocumentsTab={<Box />}
          disableButtons
          candidateTabDefault={CandidateTabs.resume}
          handleCandidateRejected={() => {}}
          handleCandidateRequestInterview={() => {}}
          handleCandidateExtendOffer={() => {}}
          handleContactAm={() => {}}
        />
      </DialogContent>
    </GorillaDialog>
  )
}
