import {
  Employee,
  Job,
  LegacySubmissionTemplate,
  SearchPayload,
  SubmissionTemplate,
} from '@common/models'
import { DataOption } from '@common/models/select'

const CANDIDATE_SEARCH_FIELDS = [
  'AddedBy',
  'Candidate',
  'Contact',
  'DateAdded',
  'JobTitle',
  'JobId',
  'JobSubmissionId',
  'Status',
  'DateLastModified',
  'CandidateFirstName',
  'CandidateLastName',
  'CandidateFullName',
  'CandidateEmail',
  'CandidateId',
]
export const quickSearchPayload = (
  keyword: string,
  count: number = 10
): SearchPayload => {
  const searchQueries = CANDIDATE_SEARCH_FIELDS.map((fieldName) => ({
    fieldName,
    condition: keyword,
  }))

  return {
    searchQueries,
    count,
  }
}
export const mapToCandidates = (data: Employee[]): DataOption<Employee>[] => {
  return data.map((post) => {
    const { id, firstName, lastName, email } = post
    return {
      id: id.toString(),
      title: `${firstName} ${lastName} - ${email}`,
      data: post,
    }
  })
}
export const mapToJobs = (data: Job[]): DataOption<Job>[] => {
  return data.map((post) => {
    const { id, title } = post
    return {
      id: id.toString(),
      title: `${title} - ${id}`,
      data: post,
    }
  })
}

export const mapToSubmissionTemplates = (
  data: SubmissionTemplate[]
): DataOption<SubmissionTemplate>[] => {
  return data
    .map((template) => {
      const { templateId: id, name: title } = template
      return {
        id: id.toString(),
        title,
        data: template,
      }
    })
    .sort((a, b) => a.title.localeCompare(b.title))
}
export const mapToLegacySubmissionTemplates = (
  data: LegacySubmissionTemplate[]
): DataOption<LegacySubmissionTemplate>[] => {
  return data
    .map((template) => {
      const { fileStorageId: id, name: title } = template
      return {
        id: id.toString(),
        title,
        data: template,
      }
    })
    .sort((a, b) => a.title.localeCompare(b.title))
}

export const mapToDataArray = <T extends { id: number | string }>(
  data: T[],
  titleField: keyof T
): Array<{ id: string; title: string; data: T }> => {
  return data.map((item) => {
    const title = (item[titleField] as any).toString()
    return {
      id: item.id.toString(),
      title,
      data: item,
    }
  })
}
